/*filter__container
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 17/12/2020, 13:14
 */

// all functions are migrated to api.  02/06/2022
// import http from '../http';

import api from '../interceptor';
/* const recordingsDomain = 'https://api-test.heaven11.pro/rod/v1'; */

export default class Dictionaries {
	//++
	static getCountries(params) {
		return api.get(`/dictionaries/country-code${params ? '?' + params : ''}`);
	}

	static getContractPeriods() {
		//++
		return api.get(`/dictionaries/terms-period`);
	}

	static getCurrencies() {
		//++
		return api.get(`/dictionaries/currency`);
	}

	static getContractSignTypes() {
		return api.get(`/dictionaries/signs`);
	}

	static suggestSociety(query) {
		//++
		if (query) {
			return api.get(`/society/suggest?query=${query}`);
		} else {
			return api.get(`/society/suggest`);
		}
	}

	static getCompositionTypes() {
		return api.get(`/dictionaries/composition-types`);
	}

	//=====================** RECORDING **===========================
	// https://api-test.heaven11.pro/rod/v1/
	//===============================================================

	static getExploitationTypes() {
		return api.get(`/dictionaries/exploitation-types-with-rights`);
	}
	static getRecordingTypes() {
		return api.get(`/dictionaries/recording-types`);
	}
	static getCommercialModels() {
		return api.get(`/dictionaries/commercial-models`);
	}

	static getContributorRoles(lang = '') {
		return api.get(`/dictionaries/contributor-roles?language=${lang}`);
	}

	static getContributorRolesByAccId(accountId, lang = '') {
		return api.get(
			`/dictionaries/contributor-roles?account_id=${accountId}&language=${lang}`
		);
	}
}
