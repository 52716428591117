// Core
import React from 'react';

// Icons
import cross from 'images/cross_13.svg';

// Styles
import styles from './AddReleaseModal.module.css';

const AddReleaseModal = ({
	title,
	subtitle,
	newReleaseButtonLabel,
	transferReleaseButtonLabel,
	newReleaseButtonHandler,
	transferReleaseButtonHandler,
	onClose,
}) => {
	return (
		<div className={styles.modalBackground}>
			<div className={styles.modal}>
				<img
					src={cross}
					alt="cross"
					className={styles.closeIcon}
					onClick={onClose}
				/>
				<h2 className={styles.modalTitle}>{title}</h2>
				{subtitle && <p className={styles.modalSubtitle}>{subtitle}</p>}
				<div className={styles.buttonContainer}>
					<button
						className={styles.modalButton}
						onClick={newReleaseButtonHandler}
					>
						{newReleaseButtonLabel}
					</button>
					<button
						className={styles.modalButton}
						onClick={transferReleaseButtonHandler}
					>
						{transferReleaseButtonLabel}
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddReleaseModal;
