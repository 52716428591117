// Core
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

// UI
import { Input } from 'components/Inputs/Input';
import { withAuth, withUI } from 'hocs';

// Constants
import errorRules from 'constants/errorRules';
import { INPUT_TIME_COUNTER } from 'constants/timeConstants';

// Styles
import styles from './CodeInput.module.css';

class CodeInput extends PureComponent {
	constructor(props) {
		super(props);
		const {
			UIContext: { putResendSMSTime, resendSMSTime },
		} = this.props;
		this.state = {
			time: moment(new Date(INPUT_TIME_COUNTER)),
			isRemainSMSTime: false,
			showResendButton: false,
			resendSMSTime: resendSMSTime,
			putResendSMSTime: putResendSMSTime,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.remainSMSTime && !this.state.isRemainSMSTime) {
			this.setState({
				time: moment(new Date(this.props.remainSMSTime)),
				isRemainSMSTime: true,
			});
		}
	}

	componentDidMount() {
		if (this.state.resendSMSTime === INPUT_TIME_COUNTER) {
			this.state.putResendSMSTime(new Date().getTime());
			this.setState({ time: moment(new Date(this.state.resendSMSTime)) });
		} else if (
			INPUT_TIME_COUNTER - (new Date().getTime() - this.state.resendSMSTime) <=
			0
		) {
			this.setState({
				time: moment(new Date(0)),
			});
		} else {
			if (
				(new Date(
					Math.abs(
						INPUT_TIME_COUNTER -
							(new Date().getTime() - this.state.resendSMSTime)
					)
				).getMinutes() +
					('.' +
						new Date(
							Math.abs(
								INPUT_TIME_COUNTER -
									(new Date().getTime() - this.state.resendSMSTime)
							)
						).getSeconds())) *
					60 *
					1000 >
				INPUT_TIME_COUNTER
			) {
				this.setState({
					time: moment(
						new Date(Math.abs(new Date().getTime() - this.state.resendSMSTime))
					),
				});
			} else {
				this.setState({
					time: moment(
						new Date(
							INPUT_TIME_COUNTER -
								(new Date().getTime() - this.state.resendSMSTime)
						)
					),
				});
			}
		}

		setTimeout(() => {
			this.reset();
		}, 0);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.reset();
	}

	reset() {
		this.interval = setInterval(() => {
			if (this.state.time.valueOf() > 0) {
				const time = moment(this.state.time.subtract(1, 'seconds'));
				this.setState({ time });
			} else {
				clearInterval(this.interval);
				this.setState({ showResendButton: true });
				if (!this.props.withParentErrors) {
					this.props.clearAllErrors();
				}
			}
		}, 1000);
	}

	handleResend = () => {
		const { onResend } = this.props;

		this.setState(
			{
				time: moment(new Date(this.context['resendSMSTime'])),
				showResendButton: false,
			},
			() => {
				if (typeof onResend === 'function') {
					onResend();
				}
				this.state.putResendSMSTime(new Date().getTime());
				this.setState({ time: moment(new Date(INPUT_TIME_COUNTER)) });
				this.reset();
			}
		);
	};

	showError = (error) => {
		const { register } = this.props;

		switch (error.rule) {
			case errorRules.INVALID:
				if (error.remaining === 0) {
					if (this.props.isChangeSignTypeAvailable) {
						return <FormattedHTMLMessage id={'rod.error.code.limit'} />;
					}

					return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
				} else {
					return (
						<FormattedHTMLMessage
							id={'rod.error.code.invalid'}
							values={{ count: error.remaining }}
						/>
					);
				}
			case errorRules.RETRY:
				return <FormattedHTMLMessage id={'rod.error.code.retry'} />;

			case errorRules.FORBIDDEN:
				if (register) {
					return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
				}
				return <FormattedHTMLMessage id={'rod.error.code.forbidden'} />;

			case errorRules.EXHAUSTED:
				if (this.props.isChangeSignTypeAvailable) {
					return <FormattedHTMLMessage id={'rod.error.code.limit'} />;
				}

				return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;

			default:
				return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
		}
	};

	onChange(text) {
		if (this.props.clearAllErrors) {
			this.props.clearAllErrors();
		}
	}

	changeRemainTime = (t) => {
		this.setState({ time: moment(new Date(t)) });
	};

	render() {
		let { register, onResend, errors, remainSMSTime, ...props } = this.props;
		let combinedErrors = [];

		if (register) {
			const {
				authContext: { errors: contextErrors },
			} = this.props;

			if (Object.keys(contextErrors) && contextErrors?.code) {
				// Combine errors and contextErrors into a single array
				combinedErrors = [...errors, ...contextErrors?.code];
			} else {
				combinedErrors = [...errors];
			}
		}

		// Function to remove duplicates based on 'rule' property
		const removeDuplicates = (array) => {
			const uniqueArray = array.filter(
				(item, index, self) =>
					index === self.findIndex((t) => t.rule === item.rule)
			);
			return uniqueArray;
		};

		if (errors.find((el) => el.rule === 'forbidden')) {
			this.setState({
				showResendButton: false,
			});
		}

		const { time, showResendButton } = this.state;

		return (
			<FormattedHTMLMessage id={'rod.field.sms.helper'}>
				{(helper) => {
					return (
						<div className={styles.CodeInput}>
							<FormattedMessage id={'rod.verify_sms.placeholder'}>
								{(placeholder) => (
									<Input
										{...props}
										className={styles.Input}
										placeholder={register ? placeholder : 'xxxxxx'}
										clearInputIfHasError={register ? false : true}
										errors={
											register ? removeDuplicates(combinedErrors) : errors
										}
										onChange={(s) => {
											this.onChange(s);
										}}
										name={'code'}
										showError={this.showError}
										helper={
											!showResendButton && !this.props.isLoading
												? `${helper} ${
														time.minutes() > 30
															? '0:00'
															: `${moment(time).format('mm:ss')}`
												  }`
												: undefined
										}
										register={register}
									/>
								)}
							</FormattedMessage>
							{showResendButton && (
								<button
									className={styles.resendBtn}
									onClick={this.handleResend}
								>
									<FormattedHTMLMessage
										id={
											register
												? 'rod.verify_sms.send_again'
												: 'rod.action.sms.resend'
										}
									/>
								</button>
							)}
						</div>
					);
				}}
			</FormattedHTMLMessage>
		);
	}
}

CodeInput.propTypes = {
	onResend: PropTypes.func,
	errors: PropTypes.array,
	isChangeSignTypeAvailable: PropTypes.bool,
};

CodeInput.defaultProps = {
	errors: [],
};

export default compose(withAuth, withUI)(CodeInput);
