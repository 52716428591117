// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Utils
import { getLink } from './utils/getLink';

// UI
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import {
	RepertoireReleaseInfo,
	RepertoireReleaseNotices,
	RepertoireReleaseModeration,
	RepertoireReleaseShipments,
	RepertoireReleaseLogs,
} from '../RepertoireReleaseTab';
import { StatisticByDatesSectionForRelease } from 'material-design/Statistic/StatisticCategory';
import error from 'images/error-2.svg';

// Styles
import { HeaderButton } from '../HeaderButton.styled';
import styles from '../RepertoireRelease.module.css';

const Tabs = ({
	props,
	defaultTab,
	isReleaseReady,
	isReleaseInQueue,
	moderationStatus,
	tracks,
	getInfo,
	hasPriveleges,
	shouldShowModerationTab,
	shouldShowDeliveriesTab,
	shouldShowLogsTab,
	shouldShowStatisticsTab,
	allShipmentsArr,
	allTakenDownsArr,
}) => {
	const history = useHistory();
	const { id: releaseId, tab } = useParams();
	const { pathname } = useLocation();
	const { isFeatureStatisticsReleaseTab } = useContext(UIContext);
	const recordingIds = tracks.map((track) => track.asset_id);
	const [chartMode, setChartMode] = useState('phono');

	useEffect(() => {
		if (tab === 'phonograms') {
			setChartMode('phono');
		} else if (tab === 'compositions') {
			setChartMode('compo');
		} else {
			setChartMode('phono');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<TabsUnstyled defaultValue={defaultTab} className={styles.repertoireSong}>
			<TabsListUnstyled className={styles.repertoireSong__header}>
				<HeaderButton
					onClick={() => getLink('info', history, releaseId)}
					className={styles.headerButton}
				>
					<FormattedMessage id={'rod.release.tab.main'} />
				</HeaderButton>

				{!isReleaseReady && hasPriveleges && (
					<HeaderButton
						onClick={() => getLink('notices', history, releaseId)}
						className={styles.headerButton}
					>
						<FormattedMessage id={'rod.release.tab.not_ready'} />
					</HeaderButton>
				)}

				{shouldShowModerationTab && (
					<HeaderButton
						onClick={() => getLink('moderation', history, releaseId)}
						className={styles.headerButton}
					>
						<div className={styles.tabContent}>
							{moderationStatus === 'rejected' && <img src={error} alt="" />}
							<FormattedMessage id={'rod.release.tab.moderation'} />
						</div>
					</HeaderButton>
				)}

				{shouldShowDeliveriesTab && (
					<HeaderButton
						onClick={() => getLink('deliveries', history, releaseId)}
						className={styles.headerButton}
					>
						<FormattedMessage id={'rod.release.info.deliveries'} />
					</HeaderButton>
				)}

				{shouldShowLogsTab && (
					<HeaderButton
						onClick={() => getLink('logs', history, releaseId)}
						className={styles.headerButton}
					>
						<FormattedMessage id={'rod.release.info.logs'} />
					</HeaderButton>
				)}

				{shouldShowStatisticsTab && (
					<HeaderButton
						onClick={() =>
							getLink('stat', history, releaseId, isFeatureStatisticsReleaseTab)
						}
						className={styles.headerButton}
					>
						<FormattedMessage id={'rod.release.info.stat'} />
					</HeaderButton>
				)}
			</TabsListUnstyled>

			<div className={styles.repertoireSong__main}>
				<TabPanelUnstyled value={0}>
					<RepertoireReleaseInfo
						getInfo={getInfo}
						tracks={tracks}
						allShipmentsArr={allShipmentsArr}
						allTakenDownsArr={allTakenDownsArr}
					/>
				</TabPanelUnstyled>

				{!isReleaseReady && hasPriveleges && (
					<TabPanelUnstyled value={1}>
						<RepertoireReleaseNotices
							props={props}
							releaseId={releaseId}
							getInfo={getInfo}
						/>
					</TabPanelUnstyled>
				)}

				{shouldShowModerationTab && (
					<TabPanelUnstyled value={1}>
						<RepertoireReleaseModeration
							props={props}
							releaseId={releaseId}
							getInfo={getInfo}
							isReleaseInQueue={isReleaseInQueue}
						/>
					</TabPanelUnstyled>
				)}

				{shouldShowDeliveriesTab && (
					<TabPanelUnstyled
						value={shouldShowModerationTab || !isReleaseReady ? 2 : 1}
					>
						<RepertoireReleaseShipments
							props={props}
							releaseId={releaseId}
							getInfo={getInfo}
						/>
					</TabPanelUnstyled>
				)}

				{shouldShowLogsTab && (
					<TabPanelUnstyled
						value={
							shouldShowModerationTab
								? shouldShowDeliveriesTab
									? 3
									: 2
								: shouldShowDeliveriesTab
								? 2
								: 1
						}
					>
						<RepertoireReleaseLogs
							props={props}
							releaseId={releaseId}
							getInfo={getInfo}
						/>
					</TabPanelUnstyled>
				)}

				{shouldShowStatisticsTab && (
					<TabPanelUnstyled
						value={
							!isReleaseReady && hasPriveleges
								? shouldShowModerationTab
									? shouldShowDeliveriesTab
										? shouldShowLogsTab
											? 5 // Not Ready + Moderation + Deliveries + Logs
											: 4 // Not Ready + Moderation + Deliveries
										: shouldShowLogsTab
										? 4 // Not Ready + Moderation + Logs
										: 3 // Not Ready + Moderation
									: shouldShowDeliveriesTab
									? shouldShowLogsTab
										? 4 // Not Ready + Deliveries + Logs
										: 3 // Not Ready + Deliveries
									: shouldShowLogsTab
									? 3 // Not Ready + Logs
									: 2 // Not Ready only
								: shouldShowModerationTab // No "Not Ready" tab
								? shouldShowDeliveriesTab
									? shouldShowLogsTab
										? 4 // Moderation + Deliveries + Logs
										: 3 // Moderation + Deliveries
									: shouldShowLogsTab
									? 3 // Moderation + Logs
									: 2 // Moderation only
								: shouldShowDeliveriesTab
								? shouldShowLogsTab
									? 3 // Deliveries + Logs
									: 2 // Deliveries only
								: 1 // Only one tab (default)
						}
					>
						<StatisticByDatesSectionForRelease
							release
							releaseId={releaseId}
							recordingIds={recordingIds}
							releaseTracks={tracks}
							chartMode={chartMode}
						/>
					</TabPanelUnstyled>
				)}
			</div>
		</TabsUnstyled>
	);
};

export default Tabs;
