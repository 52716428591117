import React from 'react';

import EmptyData from '../../EmptyData/EmptyData';
import styles from './TopArtistsEmpty.module.css';

const TopArtistsEmpty = () => {
	return (
		<div className={styles.wrapperEmpty}>
			<EmptyData />
		</div>
	);
};

export default TopArtistsEmpty;
