const countriesTitleLangs = [
	{
		id: 257,
		code: 'AI',
		title_ru: 'АНГИЛЬЯ',
		title_en: 'Anguilla',
		dialing_code: '',
	},
	{
		id: 256,
		code: 'AW',
		title_ru: 'АРУБА',
		title_en: 'ARUBA',
		dialing_code: '',
	},
	{
		id: 25,
		code: 'AU',
		title_ru: 'Австралия',
		title_en: 'Australia',
		dialing_code: '61',
	},
	{
		id: 26,
		code: 'AT',
		title_ru: 'Австрия',
		title_en: 'Austria',
		dialing_code: '43',
	},
	{
		id: 16,
		code: 'AZ',
		title_ru: 'Азербайджан',
		title_en: 'Azerbaijan',
		dialing_code: '994',
	},
	{
		id: 258,
		code: 'AX',
		title_ru: 'Аландские Острова',
		title_en: 'Aland Islands',
		dialing_code: '',
	},
	{
		id: 123,
		code: 'AL',
		title_ru: 'Албания',
		title_en: 'Albania',
		dialing_code: '355',
	},
	{
		id: 183,
		code: 'DZ',
		title_ru: 'Алжир',
		title_en: 'Algeria',
		dialing_code: '213',
	},
	{
		id: 249,
		code: 'AS',
		title_ru: 'Американское Самоа',
		title_en: 'American Samoa',
		dialing_code: '1684',
	},
	{
		id: 74,
		code: 'AO',
		title_ru: 'Ангола',
		title_en: 'Angola',
		dialing_code: '244',
	},
	{
		id: 184,
		code: 'AD',
		title_ru: 'Андорра',
		title_en: 'Andorra',
		dialing_code: '376',
	},
	{
		id: 165,
		code: 'AG',
		title_ru: 'Антигуа и Барбуда',
		title_en: 'Antigua and Barbuda',
		dialing_code: '1268',
	},
	{
		id: 27,
		code: 'AR',
		title_ru: 'Аргентина',
		title_en: 'Argentina',
		dialing_code: '54',
	},
	{
		id: 10,
		code: 'AM',
		title_ru: 'Армения',
		title_en: 'Armenia',
		dialing_code: '374',
	},
	{
		id: 185,
		code: 'AF',
		title_ru: 'Афганистан',
		title_en: 'Afghanistan',
		dialing_code: '93',
	},
	{
		id: 259,
		code: 'BM',
		title_ru: 'БЕРМУДСКИЕ Острова',
		title_en: 'Bermuda',
		dialing_code: '',
	},
	{
		id: 135,
		code: 'BS',
		title_ru: 'Багамы',
		title_en: 'Bahamas',
		dialing_code: '1242',
	},
	{
		id: 70,
		code: 'BD',
		title_ru: 'Бангладеш',
		title_en: 'Bangladesh',
		dialing_code: '880',
	},
	{
		id: 147,
		code: 'BB',
		title_ru: 'Барбадос',
		title_en: 'Barbados',
		dialing_code: '1246',
	},
	{
		id: 104,
		code: 'BH',
		title_ru: 'Бахрейн',
		title_en: 'Bahrain',
		dialing_code: '973',
	},
	{
		id: 5,
		code: 'BY',
		title_ru: 'Беларусь',
		title_en: 'Belarus',
		dialing_code: '375',
	},
	{
		id: 162,
		code: 'BZ',
		title_ru: 'Белиз',
		title_en: 'Belize',
		dialing_code: '501',
	},
	{
		id: 39,
		code: 'BE',
		title_ru: 'Бельгия',
		title_en: 'Belgium',
		dialing_code: '32',
	},
	{
		id: 137,
		code: 'BJ',
		title_ru: 'Бенин',
		title_en: 'Benin',
		dialing_code: '229',
	},
	{
		id: 86,
		code: 'BG',
		title_ru: 'Болгария',
		title_en: 'Bulgaria',
		dialing_code: '359',
	},
	{
		id: 106,
		code: 'BO',
		title_ru: 'Боливия',
		title_en: 'Bolivia',
		dialing_code: '591',
	},
	{
		id: 110,
		code: 'BA',
		title_ru: 'Босния и Герцеговина',
		title_en: 'Bosnia and Herzegovina',
		dialing_code: '387',
	},
	{
		id: 120,
		code: 'BW',
		title_ru: 'Ботсвана',
		title_en: 'Botswana',
		dialing_code: '267',
	},
	{
		id: 31,
		code: 'BR',
		title_ru: 'Бразилия',
		title_en: 'Brazil',
		dialing_code: '55',
	},
	{
		id: 121,
		code: 'BN',
		title_ru: 'Бруней',
		title_en: 'Brunei',
		dialing_code: '673',
	},
	{
		id: 131,
		code: 'BF',
		title_ru: 'Буркина Фасо',
		title_en: 'Burkina Faso',
		dialing_code: '226',
	},
	{
		id: 160,
		code: 'BI',
		title_ru: 'Бурунди',
		title_en: 'Burundi',
		dialing_code: '257',
	},
	{
		id: 163,
		code: 'BT',
		title_ru: 'Бутан',
		title_en: 'Bhutan',
		dialing_code: '975',
	},
	{
		id: 268,
		code: 'VG',
		title_ru: 'ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)',
		title_en: 'British Virgin Islands',
		dialing_code: '',
	},
	{
		id: 171,
		code: 'VU',
		title_ru: 'Вануату',
		title_en: 'Vanuatu',
		dialing_code: '678',
	},
	{
		id: 186,
		code: 'VA',
		title_ru: 'Ватикан',
		title_en: 'Vatican',
		dialing_code: '39',
	},
	{
		id: 22,
		code: 'GB',
		title_ru: 'Великобритания',
		title_en: 'Great Britain',
		dialing_code: '44',
	},
	{
		id: 67,
		code: 'HU',
		title_ru: 'Венгрия',
		title_en: 'Hungary',
		dialing_code: '36',
	},
	{
		id: 49,
		code: 'VE',
		title_ru: 'Венесуэла',
		title_en: 'Venezuela',
		dialing_code: '58',
	},
	{
		id: 251,
		code: 'VI',
		title_ru: 'Виргинские Острова (США)',
		title_en: 'US Virgin Islands',
		dialing_code: '1340',
	},
	{
		id: 174,
		code: 'TL',
		title_ru: 'Восточный Тимор',
		title_en: 'East Timor',
		dialing_code: '670',
	},
	{
		id: 72,
		code: 'VN',
		title_ru: 'Вьетнам',
		title_en: 'Vietnam',
		dialing_code: '84',
	},
	{
		id: 262,
		code: 'GP',
		title_ru: 'ГВАДЕЛУПА',
		title_en: 'Guadeloupe',
		dialing_code: '',
	},
	{
		id: 261,
		code: 'GF',
		title_ru: 'ГВИАНА',
		title_en: 'Guiana',
		dialing_code: '',
	},
	{
		id: 119,
		code: 'GA',
		title_ru: 'Габон',
		title_en: 'Gabon',
		dialing_code: '241',
	},
	{
		id: 136,
		code: 'HT',
		title_ru: 'Гаити',
		title_en: 'Haiti',
		dialing_code: '509',
	},
	{
		id: 155,
		code: 'GY',
		title_ru: 'Гайана',
		title_en: 'Guyana',
		dialing_code: '592',
	},
	{
		id: 166,
		code: 'GM',
		title_ru: 'Гамбия',
		title_en: 'Gambia',
		dialing_code: '220',
	},
	{
		id: 107,
		code: 'GH',
		title_ru: 'Гана',
		title_en: 'Ghana',
		dialing_code: '233',
	},
	{
		id: 88,
		code: 'GT',
		title_ru: 'Гватемала',
		title_en: 'Guatemala',
		dialing_code: '502',
	},
	{
		id: 146,
		code: 'GN',
		title_ru: 'Гвинея',
		title_en: 'Guinea',
		dialing_code: '224',
	},
	{
		id: 170,
		code: 'GW',
		title_ru: 'Гвинея Бисау',
		title_en: 'Guinea Bissau',
		dialing_code: '245',
	},
	{
		id: 30,
		code: 'DE',
		title_ru: 'Германия',
		title_en: 'Germany',
		dialing_code: '49',
	},
	{
		id: 271,
		code: 'GG',
		title_ru: 'Гернси',
		title_en: 'Bailiwick of Guernsey',
		dialing_code: '',
	},
	{
		id: 276,
		code: 'GI',
		title_ru: 'Гибралтар',
		title_en: 'Gibraltar',
		dialing_code: '',
	},
	{
		id: 112,
		code: 'HN',
		title_ru: 'Гондурас',
		title_en: 'Honduras',
		dialing_code: '504',
	},
	{
		id: 206,
		code: 'HK',
		title_ru: 'Гонконг',
		title_en: 'Hong Kong',
		dialing_code: '',
	},
	{
		id: 173,
		code: 'GD',
		title_ru: 'Гренада',
		title_en: 'Grenada',
		dialing_code: '1473',
	},
	{
		id: 270,
		code: 'GL',
		title_ru: 'Гренландия',
		title_en: 'Greenland',
		dialing_code: '',
	},
	{
		id: 47,
		code: 'GR',
		title_ru: 'Греция',
		title_en: 'Greece',
		dialing_code: '30',
	},
	{
		id: 14,
		code: 'GE',
		title_ru: 'Грузия',
		title_en: 'Georgia',
		dialing_code: '995',
	},
	{
		id: 250,
		code: 'GU',
		title_ru: 'Гуам',
		title_en: 'Guam',
		dialing_code: '1671',
	},
	{
		id: 48,
		code: 'DK',
		title_ru: 'Дания',
		title_en: 'Denmark',
		dialing_code: '45',
	},
	{
		id: 118,
		code: 'CD',
		title_ru: 'Демократическая Республика Конго',
		title_en: 'Democratic Republic of the Congo',
		dialing_code: '242',
	},
	{
		id: 263,
		code: 'JE',
		title_ru: 'Джерси',
		title_en: 'Jersey',
		dialing_code: '',
	},
	{
		id: 164,
		code: 'DJ',
		title_ru: 'Джибути',
		title_en: 'Djibouti',
		dialing_code: '253',
	},
	{
		id: 179,
		code: 'DM',
		title_ru: 'Доминика',
		title_en: 'Dominica',
		dialing_code: '1767',
	},
	{
		id: 83,
		code: 'DO',
		title_ru: 'Доминиканская Республика',
		title_en: 'Dominican Republic',
		dialing_code: '1809',
	},
	{
		id: 56,
		code: 'EG',
		title_ru: 'Египет',
		title_en: 'Egypt',
		dialing_code: '20',
	},
	{
		id: 111,
		code: 'ZM',
		title_ru: 'Замбия',
		title_en: 'Zambia',
		dialing_code: '260',
	},
	{
		id: 141,
		code: 'EH',
		title_ru: 'Западная Сахара',
		title_en: 'WESTERN SAHARA',
		dialing_code: '',
	},
	{
		id: 144,
		code: 'ZW',
		title_ru: 'Зимбабве',
		title_en: 'Zimbabwe',
		dialing_code: '263',
	},
	{
		id: 59,
		code: 'IL',
		title_ru: 'Израиль',
		title_en: 'Israel',
		dialing_code: '972',
	},
	{
		id: 32,
		code: 'IN',
		title_ru: 'Индия',
		title_en: 'India',
		dialing_code: '91',
	},
	{
		id: 37,
		code: 'ID',
		title_ru: 'Индонезия',
		title_en: 'Indonesia',
		dialing_code: '62',
	},
	{
		id: 98,
		code: 'JO',
		title_ru: 'Иордания',
		title_en: 'Jordan',
		dialing_code: '962',
	},
	{
		id: 75,
		code: 'IQ',
		title_ru: 'Ирак',
		title_en: 'Iraq',
		dialing_code: '964',
	},
	{
		id: 45,
		code: 'IR',
		title_ru: 'Иран',
		title_en: 'Iran',
		dialing_code: '98',
	},
	{
		id: 58,
		code: 'IE',
		title_ru: 'Ирландия',
		title_en: 'Ireland',
		dialing_code: '353',
	},
	{
		id: 116,
		code: 'IS',
		title_ru: 'Исландия',
		title_en: 'Iceland',
		dialing_code: '354',
	},
	{
		id: 24,
		code: 'ES',
		title_ru: 'Испания',
		title_en: 'Spain',
		dialing_code: '34',
	},
	{
		id: 23,
		code: 'IT',
		title_ru: 'Италия',
		title_en: 'Italy',
		dialing_code: '39',
	},
	{
		id: 96,
		code: 'YE',
		title_ru: 'Йемен',
		title_en: 'Yemen',
		dialing_code: '967',
	},
	{
		id: 193,
		code: 'KP',
		title_ru: 'КНДР',
		title_en: 'North Korea',
		dialing_code: '850',
	},
	{
		id: 159,
		code: 'CV',
		title_ru: 'Кабо Верде',
		title_en: 'Cape Verde',
		dialing_code: '238',
	},
	{
		id: 3,
		code: 'KZ',
		title_ru: 'Казахстан',
		title_en: 'Kazakhstan',
		dialing_code: '7',
	},
	{
		id: 125,
		code: 'KH',
		title_ru: 'Камбоджа',
		title_en: 'Cambodia',
		dialing_code: '855',
	},
	{
		id: 102,
		code: 'CM',
		title_ru: 'Камерун',
		title_en: 'Cameroon',
		dialing_code: '237',
	},
	{
		id: 19,
		code: 'CA',
		title_ru: 'Канада',
		title_en: 'Canada',
		dialing_code: '1',
	},
	{
		id: 68,
		code: 'QA',
		title_ru: 'Катар',
		title_en: 'Qatar',
		dialing_code: '974',
	},
	{
		id: 94,
		code: 'KE',
		title_ru: 'Кения',
		title_en: 'Kenya',
		dialing_code: '254',
	},
	{
		id: 99,
		code: 'CY',
		title_ru: 'Кипр',
		title_en: 'Cyprus',
		dialing_code: '357',
	},
	{
		id: 182,
		code: 'KI',
		title_ru: 'Кирибати',
		title_en: 'Kiribati',
		dialing_code: '686',
	},
	{
		id: 187,
		code: 'CN',
		title_ru: 'Китайская Республика',
		title_en: 'Republic of China',
		dialing_code: '86',
	},
	{
		id: 50,
		code: 'CO',
		title_ru: 'Колумбия',
		title_en: 'Colombia',
		dialing_code: '57',
	},
	{
		id: 177,
		code: 'KM',
		title_ru: 'Коморы',
		title_en: 'Comoros',
		dialing_code: '269',
	},
	{
		id: 207,
		code: 'CD',
		title_ru: 'Конго',
		title_en: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
		dialing_code: '',
	},
	{
		id: 93,
		code: 'CR',
		title_ru: 'Коста Рика',
		title_en: 'Costa Rica',
		dialing_code: '506',
	},
	{
		id: 101,
		code: 'CI',
		title_ru: 'Кот д Ивуар',
		title_en: 'Cote d Ivoire',
		dialing_code: '225',
	},
	{
		id: 188,
		code: 'CU',
		title_ru: 'Куба',
		title_en: 'Cuba',
		dialing_code: '53',
	},
	{
		id: 69,
		code: 'KW',
		title_ru: 'Кувейт',
		title_en: 'Kuwait',
		dialing_code: '965',
	},
	{
		id: 15,
		code: 'KG',
		title_ru: 'Кыргызстан',
		title_en: 'Kyrgyzstan',
		dialing_code: '996',
	},
	{
		id: 260,
		code: 'CW',
		title_ru: 'Кюрасао',
		title_en: 'Curacao',
		dialing_code: '',
	},
	{
		id: 139,
		code: 'LA',
		title_ru: 'Лаос',
		title_en: 'Laos',
		dialing_code: '856',
	},
	{
		id: 12,
		code: 'LV',
		title_ru: 'Латвия',
		title_en: 'Latvia',
		dialing_code: '371',
	},
	{
		id: 158,
		code: 'LS',
		title_ru: 'Лесото',
		title_en: 'Lesotho',
		dialing_code: '266',
	},
	{
		id: 168,
		code: 'LR',
		title_ru: 'Либерия',
		title_en: 'Liberia',
		dialing_code: '231',
	},
	{
		id: 90,
		code: 'LB',
		title_ru: 'Ливан',
		title_en: 'Lebanon',
		dialing_code: '961',
	},
	{
		id: 76,
		code: 'LY',
		title_ru: 'Ливия',
		title_en: 'Libya',
		dialing_code: '218',
	},
	{
		id: 11,
		code: 'LT',
		title_ru: 'Литва',
		title_en: 'Lithuania',
		dialing_code: '370',
	},
	{
		id: 189,
		code: 'LI',
		title_ru: 'Лихтенштейн',
		title_en: 'Liechtenstein',
		dialing_code: '423',
	},
	{
		id: 82,
		code: 'LU',
		title_ru: 'Люксембург',
		title_en: 'Luxembourg',
		dialing_code: '352',
	},
	{
		id: 264,
		code: 'MQ',
		title_ru: 'МАРТИНИКА',
		title_en: 'Martinique',
		dialing_code: '',
	},
	{
		id: 128,
		code: 'MU',
		title_ru: 'Маврикий',
		title_en: 'Mauritius',
		dialing_code: '230',
	},
	{
		id: 149,
		code: 'MR',
		title_ru: 'Мавритания',
		title_en: 'Mauritania',
		dialing_code: '222',
	},
	{
		id: 132,
		code: 'MG',
		title_ru: 'Мадагаскар',
		title_en: 'Madagaskar',
		dialing_code: '261',
	},
	{
		id: 269,
		code: 'YT',
		title_ru: 'Майотта',
		title_en: 'Mayotte',
		dialing_code: '',
	},
	{
		id: 219,
		code: 'MO',
		title_ru: 'Макао',
		title_en: 'MACAO',
		dialing_code: '',
	},
	{
		id: 145,
		code: 'MW',
		title_ru: 'Малави',
		title_en: 'Malawi',
		dialing_code: '265',
	},
	{
		id: 54,
		code: 'MY',
		title_ru: 'Малайзия',
		title_en: 'Malaysia',
		dialing_code: '60',
	},
	{
		id: 129,
		code: 'ML',
		title_ru: 'Мали',
		title_en: 'Mali',
		dialing_code: '223',
	},
	{
		id: 161,
		code: 'MV',
		title_ru: 'Мальдивы',
		title_en: 'Maldives',
		dialing_code: '960',
	},
	{
		id: 133,
		code: 'MT',
		title_ru: 'Мальта',
		title_en: 'Malta',
		dialing_code: '356',
	},
	{
		id: 71,
		code: 'MA',
		title_ru: 'Марокко',
		title_en: 'Morocco',
		dialing_code: '212',
	},
	{
		id: 247,
		code: 'MH',
		title_ru: 'Маршалловы Острова',
		title_en: 'MARSHALL ISLANDS',
		dialing_code: '',
	},
	{
		id: 33,
		code: 'MX',
		title_ru: 'Мексика',
		title_en: 'Mexico',
		dialing_code: '52',
	},
	{
		id: 208,
		code: 'MM',
		title_ru: 'Мианмар',
		title_en: 'Myanmar',
		dialing_code: '',
	},
	{
		id: 126,
		code: 'MZ',
		title_ru: 'Мозамбик',
		title_en: 'Mozambique',
		dialing_code: '258',
	},
	{
		id: 7,
		code: 'MD',
		title_ru: 'Молдавия',
		title_en: 'Moldova',
		dialing_code: '373',
	},
	{
		id: 190,
		code: 'MC',
		title_ru: 'Монако',
		title_en: 'Monaco',
		dialing_code: '377',
	},
	{
		id: 140,
		code: 'MN',
		title_ru: 'Монголия',
		title_en: 'Mongolia',
		dialing_code: '976',
	},
	{
		id: 275,
		code: 'MS',
		title_ru: 'Монтсеррат',
		title_en: 'Montserrat',
		dialing_code: '',
	},
	{
		id: 124,
		code: 'NA',
		title_ru: 'Намибия',
		title_en: 'Namibia',
		dialing_code: '264',
	},
	{
		id: 232,
		code: 'NR',
		title_ru: 'Науру',
		title_en: 'NAURU',
		dialing_code: '',
	},
	{
		id: 113,
		code: 'NP',
		title_ru: 'Непал',
		title_en: 'Nepal',
		dialing_code: '977',
	},
	{
		id: 143,
		code: 'NE',
		title_ru: 'Нигер',
		title_en: 'Niger',
		dialing_code: '227',
	},
	{
		id: 57,
		code: 'NG',
		title_ru: 'Нигерия',
		title_en: 'Nigeria',
		dialing_code: '234',
	},
	{
		id: 35,
		code: 'NL',
		title_ru: 'Нидерланды',
		title_en: 'Netherlands',
		dialing_code: '31',
	},
	{
		id: 138,
		code: 'NI',
		title_ru: 'Никарагуа',
		title_en: 'Nicaragua',
		dialing_code: '505',
	},
	{
		id: 66,
		code: 'NZ',
		title_ru: 'Новая Зеландия',
		title_en: 'New Zealand',
		dialing_code: '64',
	},
	{
		id: 224,
		code: 'NC',
		title_ru: 'Новая Каледония',
		title_en: 'NEW CALEDONIA',
		dialing_code: '',
	},
	{
		id: 43,
		code: 'NO',
		title_ru: 'Норвегия',
		title_en: 'Norway',
		dialing_code: '47',
	},
	{
		id: 253,
		code: 'NF',
		title_ru: 'Норфолк (остров)',
		title_en: 'Norfolk Island',
		dialing_code: '6723',
	},
	{
		id: 254,
		code: 'CX',
		title_ru: 'ОСТРОВ РОЖДЕСТВА',
		title_en: 'Christmas Island',
		dialing_code: '61',
	},
	{
		id: 51,
		code: 'AE',
		title_ru: 'Объединенные Арабские Эмираты',
		title_en: 'United Arab Emirates',
		dialing_code: '971',
	},
	{
		id: 81,
		code: 'OM',
		title_ru: 'Оман',
		title_en: 'Oman',
		dialing_code: '968',
	},
	{
		id: 272,
		code: 'IM',
		title_ru: 'Остров Мэн',
		title_en: 'Isle of Man',
		dialing_code: '',
	},
	{
		id: 283,
		code: 'KY',
		title_ru: 'Острова Кайман',
		title_en: 'Cayman Islands',
		dialing_code: '1',
	},
	{
		id: 282,
		code: 'CK',
		title_ru: 'Острова Кука',
		title_en: 'Cook Islands',
		dialing_code: '682',
	},
	{
		id: 63,
		code: 'PK',
		title_ru: 'Пакистан',
		title_en: 'Pakistan',
		dialing_code: '92',
	},
	{
		id: 248,
		code: 'PW',
		title_ru: 'Палау',
		title_en: 'PALAU',
		dialing_code: '',
	},
	{
		id: 211,
		code: 'PLS',
		title_ru: 'Палестина',
		title_en: 'Palestinian Territory',
		dialing_code: '',
	},
	{
		id: 97,
		code: 'PA',
		title_ru: 'Панама',
		title_en: 'Panama',
		dialing_code: '507',
	},
	{
		id: 130,
		code: 'PG',
		title_ru: 'Папуа Новая Гвинея',
		title_en: 'Papua New Guinea',
		dialing_code: '675',
	},
	{
		id: 108,
		code: 'PY',
		title_ru: 'Парагвай',
		title_en: 'Paraguay',
		dialing_code: '595',
	},
	{
		id: 65,
		code: 'PE',
		title_ru: 'Перу',
		title_en: 'Peru',
		dialing_code: '51',
	},
	{
		id: 40,
		code: 'PL',
		title_ru: 'Польша',
		title_en: 'Poland',
		dialing_code: '48',
	},
	{
		id: 53,
		code: 'PT',
		title_ru: 'Португалия',
		title_en: 'Portugal',
		dialing_code: '351',
	},
	{
		id: 217,
		code: 'PR',
		title_ru: 'Пуэрто-Рико',
		title_en: 'PUERTO RICO',
		dialing_code: '',
	},
	{
		id: 265,
		code: 'RE',
		title_ru: 'РЕЮНЬОН',
		title_en: 'Reunion',
		dialing_code: '',
	},
	{
		id: 281,
		code: 'CG',
		title_ru: 'Республика Конго',
		title_en: 'Republic of the Congo',
		dialing_code: '242',
	},
	{
		id: 127,
		code: 'MK',
		title_ru: 'Республика Македония',
		title_en: 'Macedonia',
		dialing_code: '389',
	},
	{
		id: 274,
		code: 'NU',
		title_ru: 'Республика Ниуэ',
		title_en: 'Niue',
		dialing_code: '',
	},
	{
		id: 1,
		code: 'RU',
		title_ru: 'Россия',
		title_en: 'Russia',
		dialing_code: '7',
	},
	{
		id: 142,
		code: 'RW',
		title_ru: 'Руанда',
		title_en: 'Rwanda',
		dialing_code: '250',
	},
	{
		id: 64,
		code: 'RO',
		title_ru: 'Румыния',
		title_en: 'Romania',
		dialing_code: '40',
	},
	{
		id: 238,
		code: 'SU',
		title_ru: 'СССР',
		title_en: 'USSR',
		dialing_code: '',
	},
	{
		id: 18,
		code: 'US',
		title_ru: 'США',
		title_en: 'USA',
		dialing_code: '1',
	},
	{
		id: 103,
		code: 'SV',
		title_ru: 'Сальвадор',
		title_en: 'El Salvador',
		dialing_code: '503',
	},
	{
		id: 178,
		code: 'WS',
		title_ru: 'Самоа',
		title_en: 'Samoa',
		dialing_code: '685',
	},
	{
		id: 192,
		code: 'SM',
		title_ru: 'Сан Марино',
		title_en: 'San Marino',
		dialing_code: '378',
	},
	{
		id: 181,
		code: 'ST',
		title_ru: 'Сан Томе и Принсипи',
		title_en: 'Sao Tome and Principe',
		dialing_code: '',
	},
	{
		id: 41,
		code: 'SA',
		title_ru: 'Саудовская Аравия',
		title_en: 'Saudi Arabia',
		dialing_code: '966',
	},
	{
		id: 151,
		code: 'SZ',
		title_ru: 'Свазиленд',
		title_en: 'Swaziland',
		dialing_code: '268',
	},
	{
		id: 252,
		code: 'MP',
		title_ru: 'Северные Марианские Острова',
		title_en: 'Northern Mariana Islands',
		dialing_code: '1670',
	},
	{
		id: 169,
		code: 'SC',
		title_ru: 'Сейшельские Острова',
		title_en: 'Seychelles',
		dialing_code: '248',
	},
	{
		id: 277,
		code: 'BL',
		title_ru: 'Сен-Бартельми',
		title_en: 'Saint Barthelemy',
		dialing_code: '',
	},
	{
		id: 117,
		code: 'SN',
		title_ru: 'Сенегал',
		title_en: 'Senegal',
		dialing_code: '221',
	},
	{
		id: 175,
		code: 'VC',
		title_ru: 'Сент Винсент и Гренадины',
		title_en: 'Saint Vincent and the Grenadines',
		dialing_code: '1784',
	},
	{
		id: 176,
		code: 'KN',
		title_ru: 'Сент Китс и Невис',
		title_en: 'Saint Kitts and Nevis',
		dialing_code: '1869',
	},
	{
		id: 167,
		code: 'LC',
		title_ru: 'Сент Люсия',
		title_en: 'Saint Lucia',
		dialing_code: '1758',
	},
	{
		id: 278,
		code: 'PM',
		title_ru: 'Сент-Пьер и Микелон',
		title_en: 'Saint Pierre and Miquelon',
		dialing_code: '',
	},
	{
		id: 91,
		code: 'RS',
		title_ru: 'Сербия',
		title_en: 'Serbia',
		dialing_code: '381',
	},
	{
		id: 227,
		code: 'CS',
		title_ru: 'Сербия и Черногория',
		title_en: 'SERBIA AND MONTENEGRO',
		dialing_code: '',
	},
	{
		id: 55,
		code: 'SG',
		title_ru: 'Сингапур',
		title_en: 'Singapore',
		dialing_code: '65',
	},
	{
		id: 266,
		code: 'SX',
		title_ru: 'Синт Мартен',
		title_en: 'Sint Maarten',
		dialing_code: '',
	},
	{
		id: 80,
		code: 'SY',
		title_ru: 'Сирия',
		title_en: 'Syria',
		dialing_code: '963',
	},
	{
		id: 73,
		code: 'SK',
		title_ru: 'Словакия',
		title_en: 'Slovakia',
		dialing_code: '421',
	},
	{
		id: 85,
		code: 'SI',
		title_ru: 'Словения',
		title_en: 'Slovenia',
		dialing_code: '386',
	},
	{
		id: 172,
		code: 'SB',
		title_ru: 'Соломоновы Острова',
		title_en: 'Solomon Islands',
		dialing_code: '677',
	},
	{
		id: 191,
		code: 'SO',
		title_ru: 'Сомали',
		title_en: 'Somalia',
		dialing_code: '252',
	},
	{
		id: 77,
		code: 'SD',
		title_ru: 'Судан',
		title_en: 'Sudan',
		dialing_code: '249',
	},
	{
		id: 150,
		code: 'SR',
		title_ru: 'Суринам',
		title_en: 'Suriname',
		dialing_code: '597',
	},
	{
		id: 157,
		code: 'SL',
		title_ru: 'Сьерра Леоне',
		title_en: 'Sierra Leone',
		dialing_code: '232',
	},
	{
		id: 267,
		code: 'TC',
		title_ru: 'ТЕРКС И КАЙКОС',
		title_en: 'Turks and Caicos Islands',
		dialing_code: '',
	},
	{
		id: 280,
		code: 'TK',
		title_ru: 'ТОКЕЛАУ',
		title_en: 'Tokelau',
		dialing_code: '',
	},
	{
		id: 9,
		code: 'TJ',
		title_ru: 'Таджикистан',
		title_en: 'Tajikistan',
		dialing_code: '992',
	},
	{
		id: 42,
		code: 'TW',
		title_ru: 'Тайвань',
		title_en: 'Taiwan',
		dialing_code: '886',
	},
	{
		id: 46,
		code: 'TH',
		title_ru: 'Тайланд',
		title_en: 'Thailand',
		dialing_code: '66',
	},
	{
		id: 100,
		code: 'TZ',
		title_ru: 'Танзания',
		title_en: 'Tanzania',
		dialing_code: '255',
	},
	{
		id: 284,
		code: 'MTC',
		title_ru: 'Тестовая Страна',
		title_en: 'Test Country',
		dialing_code: '',
	},
	{
		id: 153,
		code: 'TG',
		title_ru: 'Того',
		title_en: 'Togo',
		dialing_code: '228',
	},
	{
		id: 180,
		code: 'TO',
		title_ru: 'Тонга',
		title_en: 'Tonga',
		dialing_code: '676',
	},
	{
		id: 105,
		code: 'TT',
		title_ru: 'Тринидад и Тобаго',
		title_en: 'Trinidad and Tobago',
		dialing_code: '1868',
	},
	{
		id: 233,
		code: 'TV',
		title_ru: 'Тувалу',
		title_en: 'TUVALU',
		dialing_code: '',
	},
	{
		id: 87,
		code: 'TN',
		title_ru: 'Тунис',
		title_en: 'Tunisia',
		dialing_code: '216',
	},
	{
		id: 8,
		code: 'TM',
		title_ru: 'Туркменистан',
		title_en: 'Turkmenistan',
		dialing_code: '993',
	},
	{
		id: 36,
		code: 'TR',
		title_ru: 'Турция',
		title_en: 'Turkey',
		dialing_code: '90',
	},
	{
		id: 109,
		code: 'UG',
		title_ru: 'Уганда',
		title_en: 'Uganda',
		dialing_code: '256',
	},
	{
		id: 6,
		code: 'UZ',
		title_ru: 'Узбекистан',
		title_en: 'Uzbekistan',
		dialing_code: '998',
	},
	{
		id: 4,
		code: 'UA',
		title_ru: 'Украина',
		title_en: 'Ukraine',
		dialing_code: '380',
	},
	{
		id: 89,
		code: 'UY',
		title_ru: 'Уругвай',
		title_en: 'Uruguay',
		dialing_code: '598',
	},
	{
		id: 273,
		code: 'FO',
		title_ru: 'Фарерские острова',
		title_en: 'Faroe Islands',
		dialing_code: '',
	},
	{
		id: 246,
		code: 'FM',
		title_ru: 'Федеративные Штаты Микронезии',
		title_en: 'MICRONESIA, FEDERATED STATES OF',
		dialing_code: '',
	},
	{
		id: 152,
		code: 'FJ',
		title_ru: 'Фиджи',
		title_en: 'Fiji',
		dialing_code: '679',
	},
	{
		id: 62,
		code: 'PH',
		title_ru: 'Филиппины',
		title_en: 'Philippines',
		dialing_code: '63',
	},
	{
		id: 52,
		code: 'FI',
		title_ru: 'Финляндия',
		title_en: 'Finland',
		dialing_code: '358',
	},
	{
		id: 21,
		code: 'FR',
		title_ru: 'Франция',
		title_en: 'France',
		dialing_code: '33',
	},
	{
		id: 245,
		code: 'PF',
		title_ru: 'Французская Полинезия',
		title_en: 'FRENCH POLYNESIA',
		dialing_code: '',
	},
	{
		id: 79,
		code: 'HR',
		title_ru: 'Хорватия',
		title_en: 'Croatia',
		dialing_code: '385',
	},
	{
		id: 156,
		code: 'CF',
		title_ru: 'Центральноафриканская Республика',
		title_en: 'Central African Republic',
		dialing_code: '236',
	},
	{
		id: 134,
		code: 'TD',
		title_ru: 'Чад',
		title_en: 'Chad',
		dialing_code: '235',
	},
	{
		id: 148,
		code: 'ME',
		title_ru: 'Черногория',
		title_en: 'Montenegro',
		dialing_code: '382',
	},
	{
		id: 61,
		code: 'CZ',
		title_ru: 'Чехия',
		title_en: 'Czech Republic',
		dialing_code: '420',
	},
	{
		id: 237,
		code: 'CS',
		title_ru: 'Чехословакия',
		title_en: 'CZECHOSLOVAKIA',
		dialing_code: '',
	},
	{
		id: 60,
		code: 'CL',
		title_ru: 'Чили',
		title_en: 'Chile',
		dialing_code: '56',
	},
	{
		id: 279,
		code: 'SJ',
		title_ru: 'ШПИЦБЕРГЕН И ЯН-МАЙЕ',
		title_en: 'Svalbard and Jan Mayen',
		dialing_code: '',
	},
	{
		id: 38,
		code: 'CH',
		title_ru: 'Швейцария',
		title_en: 'Switzerland',
		dialing_code: '41',
	},
	{
		id: 20,
		code: 'SE',
		title_ru: 'Швеция',
		title_en: 'Sweden',
		dialing_code: '46',
	},
	{
		id: 84,
		code: 'LK',
		title_ru: 'Шри Ланка',
		title_en: 'Sri Lanka',
		dialing_code: '94',
	},
	{
		id: 78,
		code: 'EC',
		title_ru: 'Эквадор',
		title_en: 'Ecuador',
		dialing_code: '593',
	},
	{
		id: 115,
		code: 'GQ',
		title_ru: 'Экваториальная Гвинея',
		title_en: 'Equatorial Guinea',
		dialing_code: '240',
	},
	{
		id: 154,
		code: 'ER',
		title_ru: 'Эритрея',
		title_en: 'Eritrea',
		dialing_code: '291',
	},
	{
		id: 13,
		code: 'EE',
		title_ru: 'Эстония',
		title_en: 'Estonia',
		dialing_code: '372',
	},
	{
		id: 95,
		code: 'ET',
		title_ru: 'Эфиопия',
		title_en: 'Ethiopia',
		dialing_code: '251',
	},
	{
		id: 226,
		code: 'YU',
		title_ru: 'Югославия',
		title_en: 'YUGOSLAVIA',
		dialing_code: '',
	},
	{
		id: 34,
		code: 'KR',
		title_ru: 'Южная Корея',
		title_en: 'South Korea',
		dialing_code: '82',
	},
	{
		id: 44,
		code: 'ZA',
		title_ru: 'Южно Африканская Республика',
		title_en: 'South Africa',
		dialing_code: '27',
	},
	{
		id: 194,
		code: 'SS',
		title_ru: 'Южный Судан',
		title_en: 'SOUTH SUDAN',
		dialing_code: '',
	},
	{
		id: 114,
		code: 'JM',
		title_ru: 'Ямайка',
		title_en: 'Jamaica',
		dialing_code: '1876',
	},
	{
		id: 29,
		code: 'JP',
		title_ru: 'Япония',
		title_en: 'Japan',
		dialing_code: '81',
	},
];

export default countriesTitleLangs;
