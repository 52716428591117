// Core
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// Utils
import { fm } from 'utils';

// UI
import Field from 'pages/adminV2/components/Field/Field';
import { Label } from 'components/Labels';

const AccountPersonalData = ({ account }) => {
	return (
		<Row>
			<Col sm={4}>
				<Field title={fm('rod.admin.account.category')}>
					<Label
						text={
							account.personal_data.business_type ? (
								<FormattedMessage
									id={`rod.account.create.sign_as.${account.personal_data.business_type}`}
								/>
							) : (
								'\u2212'
							)
						}
						font="--gilroy-Medium-18"
					/>
				</Field>
			</Col>
			<Col sm={8}>
				<Field title={fm('rod.admin.account.repertoire')}>
					<Label
						text={
							account.scope_group ? (
								<FormattedMessage
									id={`rod.account.create.who_are_you.${account.scope_group}`}
								/>
							) : (
								'\u2212'
							)
						}
						font="--gilroy-Medium-18"
					/>
				</Field>
			</Col>
		</Row>
	);
};

export default AccountPersonalData;
