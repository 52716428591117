// Core
import React from 'react';
import PropTypes from 'prop-types';

// Data
import { timeZones } from 'containers/Modal/DistributionModals/DateTimeZone/timeZone';

// UI
import { CountrySelect } from 'components/CountrySelect';
import { Dropdown } from 'components/Dropdown';
import { DateInput } from 'components/Inputs/DateInput';
import { DatePickerInput } from 'components/Inputs/DatePickerInput';
import { DatePickerInputForAssets } from 'components/Inputs';
import { Input } from 'components/Inputs/Input';
import { PhoneInput } from 'components/Inputs/PhoneInput';
import { RadioGroup } from 'components/Radio/RadioGroup';
import MuiSelect from 'material-design/components/MuiSelect/MuiSelect';
import MuiAutocomplete from 'material-design/components/MuiAutocomplete/MuiAutocomplete';
import MuiAutocompleteAuthor from 'material-design/components/MuiAutocompleteAuthor/MuiAutocompleteAuthor';
import MuiAutocompleteMultiple from 'material-design/components/MuiAutocompleteMultiple/MuiAutocompleteMultiple';
import MuiAutocompleteMultipleUsers from 'material-design/components/MuiAutocompleteMultipleUsers/MuiAutocompleteMultipleUsers';
import MuiAutocompleteMultipleGenres from 'material-design/components/MuiAutocompleteMultipleGenres/MuiAutocompleteMultiple';
import MuiAutocompleteMultiplePseudonimsRoles from 'material-design/components/MuiAutocompleteMultiplePseudonimsRoles/MuiAutocompleteMultiple';
import MuiAutocompleteMultiplePseudonimsOutlets from 'material-design/components/MuiAutocompleteMultiplePseudonimsOutlets/MuiAutocompleteMultiple';
import MuiAutocompleteMultipleOutlets from 'material-design/components/MuiAutocompleteMultipleOutlets/MuiAutocompleteMultiple';
import MuiAutocompleteArtistOutlets from 'material-design/components/MuiAutocompleteArtistOutlets/MuiAutocompleteArtistOutlets';
import MuiAutocompleteMultipleTracks from 'material-design/components/MuiAutocompleteMultipleTracks/MuiAutocompleteMultiple';
import { MuiSliderShares } from 'material-design/components/MuiSliderShares/MuiSliderShares';
import MuiRadioButton from 'material-design/components/MuiRadioButton/MuiRadioButton';
import MuiCheckbox from 'material-design/components/MuiCheckbox/MuiCheckbox';
import MuiCheckboxBlack from 'material-design/components/MuiCheckboxBlack/MuiCheckboxBlack';
import MuiSwitch from 'material-design/components/MuiSwitch/MuiSwitch';
import MultipleSelectPlaceholder from 'material-design/components/MuiMultipleSelect/MuiMultipleSelect';
import MuiSelectReleaseType from 'material-design/components/MuiSelectReleaseType/MuiMultipleSelect';
import MuiSelectCurrencies from 'material-design/components/MuiSelectCurrencies/MuiSelectCurrencies';
import MuiSelectRubric from 'material-design/components/MuiSelectRubric/MuiMultipleSelect';
import MuiSelectNarrative from 'material-design/components/MuiSelectNarrative/MuiMultipleSelect';
import DatePicker from '@mui/lab/DatePicker';
import MuiSelectNarative from 'material-design/components/MuiSelectNarative/MuiSelectNarative';
import Textarea from 'material-design/components/MuiTextareaAutosize/MuiTextareaAutosize';
import { RadioTerritoriesGroup } from 'components/Radio/RadioTerritoriesGroup';
import MuiAutocompleteMultipleCheckboxOutlets from 'material-design/components/MuiAutocompleteMultipleCheckboxOutlets/MuiAutocompleteMultipleCheckboxOutlets';

// Utils
import { showError } from 'validators/showError';
import { subDays, addDays } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { getRenderedValue } from 'utils';
import { shouldDisableDate } from '../utils/shouldDisableDate';
import { shouldDisablePastDate } from '../utils/shouldDisablePastDate';

// Styles
import s from './FormInput.module.css';
import {
	TextFieldInput,
	TextFieldDate,
	TextFieldInputAdminReports,
	TextFieldInputWithEllipsis,
	TextFieldInputForTrends,
	TextFieldInputWithEllipsisForTrends,
} from 'material-design/MuiStyled/MuiStyled';

const localeMap = {
	en: enLocale,
	ru: ruLocale,
};

const maskMap = {
	en: '__.__.____',
	ru: '__.__.____',
};

function FormInput(props) {
	const {
		type,
		name,
		names,
		errors,
		data,
		onChange,
		onFocus,
		onBlur,
		tmpInputValue,
		onChanges,
		maxDate,
		handleOnBlurDateInput,
		languages,
		pseudonumOutletIndex,
		outletsArr,
		isTextAreaEnable,
		hideCross,
		isEdit,
		floatingLabel,
		...rest
	} = props;
	const lang = localStorage.getItem('lang');
	const [newDate, setNewDate] = React.useState(null);

	const onChangeRadio = (e) => {
		onChange(name)(e.currentTarget.value); //todo return values in radio
	};

	const onChangeRadioTerritories = (e) => {
		onChange('territories')(e.currentTarget.value);
	};

	const onChangePhone = (data) => {
		onChanges({
			[names[0]]: { $set: data.number },
			[names[1]]: { $set: data.code },
		});
	};

	const onChangePhoneFunc = (data) => {
		onChanges({
			[names[0]]: data.number,
			[names[1]]: data.code,
		});
	};

	switch (type) {
		case 'radio':
			return (
				<RadioGroup
					{...rest}
					errors={errors[name]}
					value={data[name]}
					onChange={onChangeRadio}
				/>
			);
		case 'radio-territories':
			return (
				<RadioTerritoriesGroup
					{...rest}
					name={name}
					value={data.territories}
					onChange={onChangeRadioTerritories}
				/>
			);
		case 'dropdown':
			return (
				<Dropdown
					{...rest}
					showRequired
					errors={errors[name]}
					showError={showError(name)}
					value={data[name]}
					onChange={onChange(name)}
				/>
			);
		case 'country':
			return (
				<CountrySelect
					{...rest}
					showRequired
					errors={errors[name]}
					showError={showError(name)}
					value={data[name]}
					onChange={onChange(name)}
				/>
			);
		case 'phone':
			return (
				<PhoneInput
					{...rest}
					showRequired
					errors={errors[names[0]]}
					phone={data[names[0]]}
					countryId={data[names[1]]}
					onChange={onChangePhone}
				/>
			);
		case 'phoneFunc':
			return (
				<PhoneInput
					{...rest}
					showRequired
					errors={errors[names[0]]}
					phone={data[names[0]]}
					countryId={data[names[1]]}
					onChange={onChangePhoneFunc}
				/>
			);
		case 'date':
			return (
				<DateInput
					{...rest}
					showRequired
					errors={errors[name]}
					value={data[name]}
					showError={showError(name)}
					onChange={onChange(name)}
					lang={lang}
					hideCross={hideCross}
					isEdit={isEdit}
					maxDate={maxDate}
				/>
			);
		case 'datePicker':
			return (
				<DatePickerInput
					{...rest}
					showRequired
					errors={errors[name]}
					value={data[name]}
					showError={showError(name)}
					onChange={onChange(name)}
					lang={lang}
					floatingLabel={floatingLabel}
				/>
			);
		case 'datePickerForAssets':
			return (
				<DatePickerInputForAssets
					{...rest}
					showRequired
					errors={errors ? errors[name] : {}}
					value={data[name]}
					showError={showError(name)}
					onChange={onChange(name)}
					lang={lang}
					floatingLabel={floatingLabel}
					maxDate={maxDate}
				/>
			);

		case 'muiInput':
			return (
				<TextFieldInput
					{...rest}
					error={
						errors[name] &&
						(typeof errors[name] === 'string' ||
							(Array.isArray(errors[name]) && errors[name].length > 0) ||
							(typeof errors[name] === 'object' &&
								Object.keys(errors[name]).length > 0))
					}
					value={data[name] ? getRenderedValue(data[name]) : ''}
					onChange={onChange(name)}
					onFocus={onFocus && onFocus(name)}
					onBlur={onBlur && onBlur(name)}
					variant="standard"
				/>
			);
		case 'muiInputTrends':
			return (
				<TextFieldInputForTrends
					{...rest}
					error={
						errors[name] &&
						(typeof errors[name] === 'string' ||
							(Array.isArray(errors[name]) && errors[name].length > 0) ||
							(typeof errors[name] === 'object' &&
								Object.keys(errors[name]).length > 0))
					}
					value={data[name] ? getRenderedValue(data[name]) : ''}
					onChange={onChange(name)}
					onFocus={onFocus && onFocus(name)}
					onBlur={onBlur && onBlur(name)}
					variant="standard"
				/>
			);
		case 'muiInputWithEllipsis':
			return (
				<TextFieldInputWithEllipsis
					{...rest}
					error={
						errors[name] &&
						(typeof errors[name] === 'string' ||
							(Array.isArray(errors[name]) && errors[name].length > 0) ||
							(typeof errors[name] === 'object' &&
								Object.keys(errors[name]).length > 0))
					}
					value={data[name] ? getRenderedValue(data[name]) : ''}
					onChange={onChange(name)}
					onFocus={onFocus && onFocus(name)}
					onBlur={onBlur && onBlur(name)}
					variant="standard"
					title={data[name] ? getRenderedValue(data[name]) : ''}
				/>
			);
		case 'muiInputWithEllipsisForTrends':
			return (
				<TextFieldInputWithEllipsisForTrends
					{...rest}
					error={
						errors[name] &&
						(typeof errors[name] === 'string' ||
							(Array.isArray(errors[name]) && errors[name].length > 0) ||
							(typeof errors[name] === 'object' &&
								Object.keys(errors[name]).length > 0))
					}
					value={data[name] ? getRenderedValue(data[name]) : ''}
					onChange={onChange(name)}
					onFocus={onFocus && onFocus(name)}
					onBlur={onBlur && onBlur(name)}
					variant="standard"
					title={data[name] ? getRenderedValue(data[name]) : ''}
				/>
			);

		case 'muiInputNumber':
			return (
				<TextFieldInput
					{...rest}
					inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
					error={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
					onFocus={onFocus && onFocus(name)}
					onBlur={onBlur && onBlur(name)}
					variant="standard"
				/>
			);
		case 'muiInputDate':
			return (
				<TextFieldDate
					{...rest}
					type="date"
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
					variant="standard"
				/>
			);
		case 'muiInputDate_year':
			const maskYear = lang === 'ru' ? 'гггг' : 'yyyy';
			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year']}
						inputFormat="yyyy"
						onError={(err, value) => handleOnBlurDateInput(err, value)}
						value={
							props.defaultValue
								? data[name] || Date.now() + 604800000
								: data[name] || null
						}
						onChange={onChange(name)}
						maxDate={props.maxDate && Date.now()}
						renderInput={(params) => {
							return (
								<TextFieldInput
									{...params}
									required={rest.required}
									variant="standard"
									type="text"
									inputProps={{
										...params.inputProps,
										placeholder: maskYear,
										readOnly: false,
										type: 'text',
										'aria-label': 'Choose date',
										'aria-readonly': false,
										onChange: (e) => {
											const value = e.target.value.replace(/\D/g, '');
											if (value.length === 4) {
												const year = parseInt(value, 10);
												if (year < 1900) {
													e.target.value = '1900';
												}
											}
											params.inputProps.onChange?.(e);
										},
									}}
									className={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										s.textFiledInputError
									}
									error={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										errors[name]
									}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);
		case 'muiInputDate_v2':
			const plus1Day = Date.now() + 86400000; // 1+ day
			const plus2Days = Date.now() + 172800000; // 2+ days (2 * 24 * 60 * 60 * 1000)
			const plus7Days = Date.now() + 604800000;

			const minDateValue = props.minDate
				? !props.isSlaPrivelegesForAccount && !props.additionalReleaseDate
					? plus7Days
					: !props.isSlaPrivelegesForAccount && props.additionalReleaseDate
					? plus2Days
					: props.isSlaPrivelegesForAccount
					? plus1Day
					: null
				: null;

			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year', 'month', 'day']}
						mask={maskMap[lang]}
						onError={(err, value) => handleOnBlurDateInput(err, value)}
						value={
							props.defaultValue
								? data[name] || Date.now() + 604800000
								: data[name] || null
						}
						inputFormat="dd.MM.yyyy"
						onChange={onChange(name)}
						minDate={minDateValue}
						maxDate={props.maxDate && Date.now()}
						renderInput={(params) => {
							return (
								<TextFieldInput
									{...params}
									required={rest.required}
									variant="standard"
									type="text"
									inputProps={{
										...params.inputProps,
										placeholder: lang === 'ru' ? 'дд-мм-ГГГГ' : 'dd-mm-YYYY',
										readOnly: false,
										type: 'text',
										'aria-label': 'Choose date',
										'aria-readonly': false,
									}}
									className={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										s.textFiledInputError
									}
									error={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										errors[name]
									}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);
		case 'muiInputDate_v2-2':
			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year', 'month', 'day']}
						mask={maskMap[lang]}
						onError={(err, value) => {
							handleOnBlurDateInput(err, value);
						}}
						value={
							props.defaultValue ? data[name] || new Date() : data[name] || null
						}
						inputFormat="dd.MM.yyyy"
						onChange={onChange(name)}
						maxDate={subDays(new Date(), 3)}
						renderInput={(params) => {
							return (
								<TextFieldInput
									{...params}
									required={rest.required}
									variant="standard"
									inputProps={{
										...params.inputProps,
										placeholder: lang === 'ru' ? 'дд-мм-ГГГГ' : 'dd-mm-YYYY',
									}}
									className={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										s.textFiledInputError
									}
									error={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										errors[name]
									}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);
		case 'muiInputDate_v2-3':
			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year', 'month', 'day']}
						mask={maskMap[lang]}
						onError={(err, value) => handleOnBlurDateInput(err, value)}
						value={
							props.defaultValue ? data[name] || new Date() : data[name] || null
						}
						inputFormat="dd.MM.yyyy"
						onChange={onChange(name)}
						shouldDisableDate={shouldDisableDate}
						renderInput={(params) => {
							return (
								<TextFieldInput
									{...params}
									required={rest.required}
									variant="standard"
									inputProps={{
										...params.inputProps,
										placeholder: lang === 'ru' ? 'дд-мм-ГГГГ' : 'dd-mm-YYYY',
									}}
									className={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										s.textFiledInputError
									}
									error={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										errors[name]
									}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);
		case 'muiInputDate_v2-4':
			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year', 'month', 'day']}
						mask={maskMap[lang]}
						onError={(err, value) => handleOnBlurDateInput(err, value)}
						value={
							props.defaultValue ? data[name] || new Date() : data[name] || null
						}
						inputFormat="dd.MM.yyyy"
						onChange={onChange(name)}
						shouldDisableDate={shouldDisablePastDate}
						renderInput={(params) => {
							return (
								<TextFieldInput
									{...params}
									required={rest.required}
									variant="standard"
									inputProps={{
										...params.inputProps,
										placeholder: lang === 'ru' ? 'дд-мм-ГГГГ' : 'dd-mm-YYYY',
									}}
									className={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										s.textFiledInputError
									}
									error={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										errors[name]
									}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);
		case 'muiInputDate_v2-5':
			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year', 'month', 'day']}
						mask={maskMap[lang]}
						onError={(err, value) => handleOnBlurDateInput(err, value)}
						value={
							props.defaultValue ? data[name] || new Date() : data[name] || null
						}
						inputFormat="dd.MM.yyyy"
						onChange={onChange(name)}
						minDate={addDays(new Date(), 2)}
						renderInput={(params) => {
							return (
								<TextFieldInput
									{...params}
									required={rest.required}
									variant="standard"
									inputProps={{
										...params.inputProps,
										placeholder: lang === 'ru' ? 'дд-мм-ГГГГ' : 'dd-mm-YYYY',
									}}
									className={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										s.textFiledInputError
									}
									error={
										errors &&
										(typeof errors === 'string' ||
											typeof errors === 'boolean' ||
											(Array.isArray(errors) && errors.length > 0) ||
											(typeof errors === 'object' &&
												Object.keys(errors).length > 0)) &&
										errors[name]
									}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);
		case 'muiInputDate_v3':
			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year', 'month', 'day']}
						mask={maskMap[lang]}
						onError={(err, value) => handleOnBlurDateInput(err, value)}
						value={
							props.defaultValue
								? data[name] || Date.now() + 604800000 /* 259200000 */
								: data[name] || null
						}
						inputFormat="dd.MM.yyyy"
						onYearChange={(value) => {
							setNewDate(value);
						}}
						onMonthChange={(value) => {
							setNewDate(value);
						}}
						onAccept={(value) => {
							const newYear = new Date(newDate).getFullYear();
							const year = new Date(value).getFullYear();
							const sameYear = newYear === year;
							const newMonth = new Date(newDate).getMonth();
							const month = new Date(value).getMonth();
							const day = new Date(value).getDate();
							const sameMonth = newMonth === month;
							onChange(name)(
								sameYear && sameMonth
									? value
									: (newDate && !sameMonth
											? new Date(newDate.getTime() + (day - 1) * 86400000)
											: newDate) || value
							);
							setNewDate(null);
						}}
						onChange={(value) => {
							onChange(name)(value);
						}}
						minDate={props.minDate && props.minDate}
						maxDate={props.maxDate && props.maxDate}
						disabled={props.disabled}
						renderInput={(params) => {
							return (
								<TextFieldInput
									{...params}
									required={rest.required}
									variant="standard"
									inputProps={{
										...params.inputProps,
										placeholder: lang === 'ru' ? 'дд-мм-ГГГГ' : 'dd-mm-YYYY',
									}}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);
		case 'muiInputDate_v4':
			return (
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					locale={localeMap[lang]}
				>
					<DatePicker
						{...rest}
						views={['year', 'month', 'day']}
						mask={maskMap[lang]}
						onError={(err, value) => handleOnBlurDateInput(err, value)}
						value={
							props.defaultValue
								? data[name] || Date.now() + 604800000
								: data[name] || null
						}
						inputFormat="dd.MM.yyyy"
						onChange={onChange(name)}
						minDate={props.minDate && Date.now() + 604800000}
						maxDate={props.maxDate && Date.now()}
						renderInput={(params) => {
							return (
								<TextFieldInputAdminReports
									{...params}
									required={rest.required}
									variant="standard"
									inputProps={
										rest.placeholder
											? {
													...params.inputProps,
													placeholder:
														lang === 'ru'
															? 'Дата публикации'
															: 'Publishing date',
											  }
											: {
													...params.inputProps,
													placeholder:
														lang === 'ru' ? 'дд-мм-ГГГГ' : 'dd-mm-YYYY',
											  }
									}
								/>
							);
						}}
					/>
				</LocalizationProvider>
			);

		case 'muiInputSelect':
			let formattedValue;
			if (name === 'time_zone' && typeof data[name] === 'string') {
				const timeZoneValue = timeZones.find((el) => el.id === data[name]);
				if (timeZoneValue) {
					formattedValue = timeZoneValue;
				}
			} else {
				formattedValue = data[name] || '';
			}

			return (
				<MuiSelect
					{...rest}
					errors={errors[name]}
					value={formattedValue}
					onChange={onChange(name)}
					name={name}
					languages={languages ? languages : false}
				/>
			);
		case 'muiInputSelectNarative':
			return (
				<MuiSelectNarative
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
					name={name}
				/>
			);
		case 'MultipleSelectPlaceholder':
			return (
				<MultipleSelectPlaceholder
					{...rest}
					errors={errors[name]}
					value={data[name] || []}
					onChange={onChange(name)}
				/>
			);
		case 'MuiSelectReleaseType':
			return (
				<MuiSelectReleaseType
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
				/>
			);
		case 'MuiSelectCurrencies':
			return (
				<MuiSelectCurrencies
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
				/>
			);
		case 'MuiSelectRubric':
			return (
				<MuiSelectRubric
					{...rest}
					errors={errors[name]}
					onChange={onChange(name)}
				/>
			);
		case 'MuiSelectNarrative':
			return (
				<MuiSelectNarrative
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
				/>
			);
		case 'muiAutocomplete':
			return (
				<MuiAutocomplete
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
				/>
			);
		case 'muiAutocompleteAuthor':
			return (
				<MuiAutocompleteAuthor
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
				/>
			);
		case 'muiAutocompleteMultiple':
			return (
				<div className={s.authorRole_wrapper}>
					<MuiAutocompleteMultiple
						{...rest}
						errors={errors[name]}
						value={data[name] || []}
						onChange={onChange(name)}
					/>
					{data[name] && data[name].length > 2 && (
						<span className={s.autoTag}>+{data[name].length - 2}</span>
					)}
				</div>
			);
		case 'muiAutocompleteMultipleOutlets':
			return (
				<div className={s.outlets_wrapper}>
					<MuiAutocompleteMultipleOutlets
						{...rest}
						errors={errors[name]}
						value={data[name] || []}
						onChange={onChange(name)}
					/>
					{data[name] && data[name].length > 2 && (
						<span className={s.autoTag}>+{data[name].length - 2}</span>
					)}
				</div>
			);
		case 'muiAutocompleteMultipleTracks':
			return (
				<div className={s.outlets_wrapper}>
					<MuiAutocompleteMultipleTracks
						{...rest}
						errors={errors[name]}
						value={data[name] || []}
						onChange={onChange(name)}
					/>
					{data[name] && data[name].length > 2 && (
						<span className={s.autoTag}>+{data[name].length - 2}</span>
					)}
				</div>
			);
		case 'muiAutocompleteMultipleGenres':
			return (
				<MuiAutocompleteMultipleGenres
					{...rest}
					errors={errors[name]}
					value={data[name] || []}
					onChange={onChange(name)}
				/>
			);
		case 'muiAutocompleteMultipleCheckboxOutlets':
			return (
				<MuiAutocompleteMultipleCheckboxOutlets
					{...rest}
					errors={errors[name]}
					value={data[name] || []}
					onChange={onChange(name)}
				/>
			);
		case 'muiAutocompleteMultiplePseudonimsRoles':
			return (
				<MuiAutocompleteMultiplePseudonimsRoles
					{...rest}
					errors={errors[name]}
					value={data[name] || []}
					onChange={onChange(name)}
				/>
			);
		case 'muiAutocompleteMultiplePseudonimsOutlets':
			return (
				<MuiAutocompleteMultiplePseudonimsOutlets
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
					index={pseudonumOutletIndex}
					outletsArr={outletsArr}
					name={name}
				/>
			);
		case 'MuiAutocompleteArtistOutlets':
			return (
				<MuiAutocompleteArtistOutlets
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
					index={pseudonumOutletIndex}
					outletsArr={outletsArr}
					name={name}
				/>
			);
		case 'muiAutocompleteMultipleUsers':
			return (
				<div className={s.authorRole_wrapper}>
					<MuiAutocompleteMultipleUsers
						{...rest}
						errors={errors[name]}
						value={data[name] || []}
						onChange={onChange}
					/>
				</div>
			);

		case 'muiSlider':
			return (
				<MuiSliderShares
					{...rest}
					errors={errors[name]}
					value={tmpInputValue?.field === name ? data[name] : data[name] || 0}
					onChange={onChange(name)}
					onFocus={onFocus(name)}
					onBlur={onBlur(name)}
				/>
			);
		case 'muiRadio':
			return (
				<MuiRadioButton
					{...rest}
					errors={errors[name]}
					value={data[name]}
					onChange={onChangeRadio}
				/>
			);
		case 'muiCheckbox':
			return (
				<MuiCheckbox
					{...rest}
					showRequired
					errors={errors[name]}
					value={data[name] || false}
					onChange={onChange(name)}
				/>
			);
		case 'muiCheckboxBlack':
			return (
				<MuiCheckboxBlack
					{...rest}
					showRequired
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
				/>
			);
		case 'muiSwitch':
			return (
				<MuiSwitch
					{...rest}
					showRequired
					errors={errors[name]}
					value={data[name]}
					showError={showError(name)}
					onChange={onChange(name)}
				/>
			);
		case 'muiTextarea':
			return (
				<Textarea
					{...rest}
					errors={errors[name]}
					value={data[name] || ''}
					onChange={onChange(name)}
					isTextAreaEnable={isTextAreaEnable}
				/>
			);

		default:
			return (
				<Input
					{...rest}
					showRequired
					showError={showError(name)}
					errors={errors[name]}
					value={data[name]}
					onChange={onChange(name)}
				/>
			);
	}
}

FormInput.defaultProps = {
	onChange: () => {},
};

FormInput.propTypes = {
	name: PropTypes.string,
	names: PropTypes.array,
	errors: PropTypes.object,
	data: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onChanges: PropTypes.func,
	type: PropTypes.oneOf([
		'input',
		'checkbox',
		'radio',
		'date',
		'dropdown',
		'muiInput',
		'muiInputDate',
		'muiInputDate_v2',
		'muiInputDate_v3',
		'muiInputSelect',
		'muiAutocomplete',
		'muiAutocompleteAuthor',
		'muiAutocompleteMultiple',
		'muiAutocompleteMultipleGenres',
		'MultipleSelectPlaceholder',
		'muiCheckbox',
		'MuiSelectLanguages',
		'MuiSelectNarrative',
		'muiSlider',
		'muiRadio',
		'country',
		'dropdownAuthor',
		'phone',
	]),
};

export default FormInput;
