import React from 'react';
import { FormattedMessage } from 'react-intl';

import TableElement from '../TableElement/TableElement';
import Skeleton from '../../Skeleton/Skeleton';
import EmptyData from '../../EmptyData/EmptyData';

import styles from './TopReleases.module.css';

const TopReleases = ({ topReleases, isLoading, topMode, accountId }) => {
	return (
		<div className={styles.wrapper}>
			<h2 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.top-releases.title'} />
			</h2>
			{isLoading ? (
				<Skeleton />
			) : topReleases.length ? (
				<div className={styles.table}>
					<div className={styles.tableHeading}>
						<span className={styles.tableColumnTitle}>
							<FormattedMessage id={'rod.statistic.trends.top.album'} />
						</span>
						<span className={styles.tableColumnTitle}>
							<FormattedMessage id={'rod.statistic.trends.top.streams'} />
						</span>
					</div>

					<div className={styles.tableBody}>
						{topReleases.map((item, index) => (
							<TableElement
								key={index}
								item={item}
								index={index}
								topMode={topMode}
								accountId={accountId}
							/>
						))}
					</div>
				</div>
			) : (
				<EmptyData />
			)}
		</div>
	);
};

export default TopReleases;
