// Core
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { Label } from 'components/Labels';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';

// Styles
import styles from './OutletsSelect.module.css';

const outletsList = [
	{ title: 'YouTube', code: 'youtube' },
	{ title: 'Apple', code: 'apple' },
	{ title: 'Spotify', code: 'spotify' },
];

const OutletsSelect = ({ outletSelected, setOutletSelected }) => {
	const [showSelect, setShowSelect] = useState(false);

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1 }}
				className={styles.formControl}
			>
				<Input id="demo-multiple-checkbox-label">
					<FormattedMessage id={'rod.statistic.outlets'} />
				</Input>
				<SelectCustom
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					open={showSelect}
					multiple
					MenuProps={{
						style: { zIndex: 2147483601 },
					}}
					value={['youtube', 'apple', 'spotify']}
					onClick={() => setShowSelect((prev) => !prev)}
					renderValue={() => {
						return (
							<p>
								{
									outletsList.filter((item) => item.code === outletSelected)[0]
										.title
								}
							</p>
						);
					}}
					className={styles.selectCustom}
				>
					<div className={styles.wrapper}>
						{outletsList.map((outlet) => (
							<MenuItem
								className={`${styles.menuItem} ${
									outlet.code === outletSelected ? styles.active : {}
								}`}
								key={outlet.title}
								value={outlet.title}
								onClick={() => setOutletSelected(outlet.code)}
							>
								<Label>{outlet.title}</Label>
							</MenuItem>
						))}
					</div>
				</SelectCustom>
			</FormControl>
		</div>
	);
};

export default OutletsSelect;
