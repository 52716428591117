const outletsColors = {
	apple: '#4E4E4E',
	spotify: '#89EFAD',
	zvuk: '#EFCEF8',
	vk: '#C5E0FF',
	ok: '#FFC598',
	yandex: '#FFECA1',
	deezer: '#A98DC2',
	youtube: '#FF706F',
	tiktok: '#9DF5E7',
	facebook: '#95AFE4',
	instagram: '#FF94C4',
};

export default outletsColors;
