// Core
import React, {
	useState,
	useCallback,
	useContext,
	useEffect,
	useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { showError } from 'validators/showError';
import debounce from 'lodash.debounce';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { getTop } from './utils/getTop';

// Hooks
import { useClickOutside } from 'hooks';

// UI
import { InputWithFloatingLabel } from '../InputWithFloatingLabel';
import Skeleton from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedAnimation';
import AddButton from './AddButton/AddButton';

// Icons
import artist_id from 'images/artist_id.svg';

// Styles
import styles from './ArtistsAutosuggestInput.module.css';

const ArtistsAutosuggestInput = ({
	value: initialValue,
	onChange,
	performers,
	placeholder,
	errors,
	cleanErrorsField,
	openModal,
	lastIndex,
	dataField,
	isH11Edit,
	variousArtists,
}) => {
	const [value, setValue] = useState(initialValue);
	const [items, setItems] = useState([]);
	const [text, setText] = useState(initialValue?.name || initialValue);
	const [isLoading, setLoading] = useState(false);
	const [noSuggestions, setNoSuggestions] = useState(false);
	const [isDropdownOpen, setDropdownOpen] = useState(false);

	const { accountId, isArtistIdForAccount } = useContext(AuthContext);
	const { isBlockArtistIdFeature } = useContext(UIContext);
	const { searchArtists, createArtist } = useContext(RootContext);

	const dropdownRef = useRef(null);
	useClickOutside(dropdownRef, () => {
		setDropdownOpen(false);
		if (initialValue) {
			setText(initialValue?.name || '');
		} else {
			setText('');
		}
	});

	useEffect(() => {
		if (initialValue !== value) {
			setValue(initialValue);
			setText(initialValue?.name || initialValue);
		}
	}, [initialValue, value]);

	const searchArtistsData = useCallback(
		async (searchValue) => {
			try {
				if (searchValue.length < 2) {
					setItems([]);
					return;
				}
				setLoading(true);
				const res = await searchArtists(accountId, searchValue);
				if (dataField === 'contributor_author_id') {
					setItems(res.data.data);
					setDropdownOpen(true);
					setNoSuggestions(res.data.data.length === 0 ? true : false);
				} else {
					const performersIds = performers
						.map((item) => item.artist_h11)
						.filter((id) => id !== null);

					let filteredPerformers = res.data.data;
					if (performersIds.length > 0 && res.data.data.length > 0) {
						filteredPerformers = res.data.data.filter(
							(item) => !performersIds.includes(item.artist_h11)
						);
					}
					setItems(
						dataField === 'performers' ? filteredPerformers : res.data.data
					);
					setDropdownOpen(true);
					if (!filteredPerformers.length) {
						setNoSuggestions(true);
					}
				}
			} catch (err) {
				console.error('Error:', err);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setItems]
	);

	const change = (item) => () => {
		setItems([]);
		setValue(item);
		setText(item.name);
		if (item.artist_h11 && !item.artist_id) {
			createArtist(accountId, item)
				.then((res) => {
					const result = res.data.data;
					const item = {
						name: result.name,
						artist_id: result.artist_id,
						artist_h11: result.artist_h11,
					};
					onChange(item, text);
				})
				.catch((error) => console.error(error.response.data.errors));
		} else {
			onChange(item, text);
		}
		onChange(item, text);
		setDropdownOpen(false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedChangeHandler = useCallback(
		debounce((searchValue) => searchArtistsData(searchValue), 500),
		[searchArtistsData]
	);

	const changeValue = (searchValue) => {
		cleanErrorsField('performers');
		if (isH11Edit || (!isArtistIdForAccount && isBlockArtistIdFeature)) {
			const performer = { name: searchValue };
			setText(searchValue);
			setValue(searchValue ? performer : searchValue);
			onChange(searchValue ? performer : searchValue, text);
		} else {
			if (searchValue.length > 2) {
				setLoading(true);
			} else if (searchValue.length < 2 && isLoading) {
				setLoading(false);
			}
			setText(searchValue);
			debouncedChangeHandler(searchValue);
		}
	};

	const customStyles =
		Object.keys(errors).length > 0 && errors.main_performer
			? { paddingRight: '24px', color: 'var(--color-red) !important' }
			: { paddingRight: '24px' };

	return (
		<div
			className={
				variousArtists
					? `${styles.component} ${styles.disabled}`
					: styles.component
			}
			ref={dropdownRef}
		>
			<InputWithFloatingLabel
				dataField={dataField}
				placeholder={placeholder}
				errors={errors}
				showError={showError('performers')}
				value={text}
				onChange={changeValue}
				customStyles={customStyles}
				variousArtists={variousArtists}
				required
			/>
			{!isH11Edit &&
				(isArtistIdForAccount || !isBlockArtistIdFeature) &&
				text &&
				value?.artist_id && (
					<img
						onClick={() => openModal(text)}
						className={styles.icon}
						src={artist_id}
						alt=""
					/>
				)}
			{isLoading && (
				<div className={styles.skeleton}>
					<Skeleton />
				</div>
			)}
			{isDropdownOpen &&
				!isLoading &&
				text &&
				text.length >= 2 &&
				noSuggestions && (
					<div className={styles.dropdown}>
						<ul className={styles.list}>
							<li className={styles.notFoundItem}>
								<FormattedMessage id="rod.suggest.search.not_found" />
							</li>
						</ul>
						<AddButton
							disabled={false}
							top="33px"
							onClick={() => openModal(text, lastIndex)}
						/>
					</div>
				)}
			{isDropdownOpen && items.length > 0 && (
				<div className={styles.dropdown}>
					<ul className={styles.list}>
						{items.map((item, index) => (
							<li key={index} onClick={change(item)}>
								{item.name}
							</li>
						))}
					</ul>
					<AddButton
						disabled={false}
						top={getTop(items.length)}
						onClick={() => openModal(text, lastIndex)}
					/>
				</div>
			)}
		</div>
	);
};

export default ArtistsAutosuggestInput;
