// Core
import { FormattedMessage } from 'react-intl';

export const catalogItems = [
	{
		text: <FormattedMessage id={'rod.field.upload.catalog.type.new'} />,
		value: 'new',
	},
	{
		text: <FormattedMessage id={'rod.field.upload.catalog.type.update'} />,
		value: 'change',
	},
	{
		text: <FormattedMessage id={'rod.field.upload.catalog.type.remove'} />,
		value: 'takedown',
	},
	{
		text: (
			<FormattedMessage id="rod.field.upload.catalog.type.new_agreements" />
		),
		value: 'new_agreements',
	},
	{
		text: (
			<FormattedMessage id="rod.field.upload.catalog.type.agreement_termination" />
		),
		value: 'agreement_termination',
	},
];
