// Core
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import isEqual from 'lodash/isEqual';
import lscache from 'lscache';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Services
import { accounts } from 'services';

// UI
import { Label } from 'components/Labels';
import { InfoButton } from 'components/Buttons';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';

// Styles
import s from './FilterOutlets.module.css';

const CACHE_KEY = 'trends_outlets_cache';
const CACHE_TTL = 10; // Cache time in minutes

const FilterOutlets = (props) => {
	const ref = React.useRef(false);
	const { accountId } = React.useContext(AuthContext);
	const { showModal } = React.useContext(UIContext);
	const {
		getAccountStatisticOutlets,
		getAccountStatisticAuthorOutlets,
	} = React.useContext(RootContext);

	const [outlets, setOutlets] = React.useState([]);
	const [selectedData, setSelectedData] = React.useState([]);
	const [isLoaded, setIsLoaded] = useState(true);
	const [cachedSelectedOutlet, setCachedSelectedOutlet] = useState([]);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const { buttonReset } = props;

	const outletRequest =
		props.chartMode === 'compo' && !props.releaseId
			? getAccountStatisticAuthorOutlets
			: getAccountStatisticOutlets;

	const getOutlets = () => {
		const cachedOutlets = lscache.get(CACHE_KEY);

		if (cachedOutlets) {
			setOutlets(cachedOutlets);
			setIsLoaded(false);
			return;
		}

		outletRequest().then((res) => {
			let data = res.data.data;
			data.forEach((item) => (item.checked = true));

			if (props.releaseId) {
				accounts
					.getReleaseInfoForBrowsing(accountId, props.releaseId)
					.then((response) => {
						const releaseTypeId = response.data.data.release_type_id;
						let filteredOutlets = [];

						switch (releaseTypeId) {
							case 70:
								filteredOutlets = data.filter((item) => item.code === 'tiktok');
								break;
							case 42:
								filteredOutlets = [];
								break;
							case 43:
								filteredOutlets = data.filter((item) => item.code === 'apple');
								break;
							default:
								filteredOutlets = data.filter((item) => item.code !== 'tiktok');
						}

						const finalOutlets =
							filteredOutlets.length > 0
								? [{ raw_title: 'All', checked: true }, ...filteredOutlets]
								: [];

						setOutlets(finalOutlets);
						lscache.set(CACHE_KEY, finalOutlets, CACHE_TTL);
					});
			} else {
				const finalOutlets = [{ raw_title: 'All', checked: true }, ...data];
				setOutlets(finalOutlets);
				lscache.set(CACHE_KEY, finalOutlets, CACHE_TTL);
			}
			setIsLoaded(false);
		});
	};

	useEffect(() => {
		setIsLoaded(true);
		getOutlets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.chartMode]);

	useEffect(() => {
		if (outlets.length) {
			const data = outlets.filter((item) => item.checked && item.code);
			setSelectedData(data);
			if (isFirstRender) {
				setIsFirstRender(false);
				return;
			}
			props.handleCheckedOutlets(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outlets]);

	useEffect(() => {
		if (selectedData.length > 0) {
			ref.current = true;
		}
	}, [selectedData]);

	const handleCheсked = (outlet) => {
		if (outlet.raw_title === 'All') {
			outlet.checked ? (outlet.checked = false) : (outlet.checked = true);
			const data = outlets.map((item) => {
				item.checked = outlet.checked;
				return item;
			});

			setOutlets(data);
		} else {
			const data = outlets.map((item) => {
				if (item.raw_title === 'All') item.checked = false;
				if (item.code === outlet.code) {
					item.checked ? (item.checked = false) : (item.checked = true);
				}

				return item;
			});

			setOutlets(data);
		}
	};

	const handleCloseSelect = () => {
		if (!isEqual(selectedData, cachedSelectedOutlet)) {
			props.handleCloseFilterOutlets();
		}
	};

	const getOutletTitle = (outlet) => {
		if (outlet.raw_title && outlet.raw_title === 'All')
			return <FormattedMessage id={'rod.statistic.search-all'} />;

		if (
			!outlet.raw_title &&
			(window.location.href.includes('.ru') ||
				window.location.href.includes('broma16ru-stage') ||
				window.location.href.includes('mts')) &&
			(outlet.code === 'instagram' || outlet.code === 'facebook')
		) {
			return (
				<InfoButton
					className={s.help_btn}
					onClick={showModal({
						text: <FormattedMessage id={'rod.statistic.outlets_info'} />,
					})}
				>
					{`${outlet[`title_${props.lang}`]}*`}
				</InfoButton>
			);
		}

		return outlet[`title_${props.lang}`];
	};

	const handleResetFilter = () => {
		setSelectedData([]);
		getOutlets();
	};

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1 }}
				className={s.formControl}
				error={!selectedData.length && ref.current}
			>
				<Input id="demo-multiple-checkbox-label">
					<FormattedMessage id={'rod.statistic.outlets'} />
				</Input>
				<SelectCustom
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					disabled={isLoaded}
					onOpen={() => setCachedSelectedOutlet(selectedData)}
					onClose={() => handleCloseSelect()}
					MenuProps={{
						style: props.zIndex ? { zIndex: 2147483601 } : {},
					}}
					value={selectedData}
					renderValue={(selected) => {
						if (selected.length === 1) {
							return (
								<>
									<p>
										{props.lang === 'en'
											? selected[0].title_en
											: selected[0].title_ru}
									</p>
								</>
							);
						}
						return selected.length <= outlets.length - 1 ? (
							<>
								<FormattedMessage id={'rod.statistic.tracks-selected'} />
								:&nbsp;
								{selected.length}/{outlets.length - 1}&nbsp;
								<FormattedMessage id={'rod.statistic.outlets'} />
							</>
						) : (
							<>
								<FormattedMessage id={'rod.statistic.tracks-selected'} />
								:&nbsp;
								{selected.length - 1}/{outlets.length - 1}&nbsp;
								<FormattedMessage id={'rod.statistic.outlets'} />
							</>
						);
					}}
					className={s.selectCustom}
				>
					<div className={s.wrapper}>
						{outlets.length > 0 ? (
							outlets.map((outlet, index) => (
								<MenuItem
									className={s.menuItem}
									key={index}
									onClick={() => {
										handleCheсked(outlet);
									}}
									value={outlet.raw_title}
								>
									<CheckboxBlack checked={outlet.checked} />
									<Label>{getOutletTitle(outlet)}</Label>
								</MenuItem>
							))
						) : (
							<Label font="--gilroy-Medium-18" className={s.noData}>
								<FormattedMessage id={'rod.no_data'} />
							</Label>
						)}
					</div>
				</SelectCustom>
				{buttonReset && (
					<button className={s.buttonResetFilter} onClick={handleResetFilter}>
						<FormattedMessage id={'rod.statistic.reset-filter'} />
					</button>
				)}
			</FormControl>
		</div>
	);
};

export default compose(withRoot, withAuth)(FilterOutlets);
