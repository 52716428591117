// Core
import { FormattedHTMLMessage } from 'react-intl';
import { useContext, useState } from 'react';

// Utils
import { getJSONParse, stylingDigit } from 'utils';
import { getStartEndDatesForOneYear } from '../../utils/getStartEndDatesForOneYear';
import {
	assignColorIndexToOutlet,
	strokeColorRange,
} from '../../StatisticByDatesSection/utils/assignColorIndexToOutlet';

// Styles
import styles from './TopTracksTable.module.css';

// Contexts
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';

// Components
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';
import chevronDownIcon from 'images/chevron_down_black.svg';

const TopTracksTable = ({
	topTracks,
	handleCheckedTopTrack,
	page,
	chartMode,
	statisticData,
	outlets,
	isAdmin,
	accountId,
	accountOutlets,
}) => {
	const { lang } = useContext(LangContext);
	const { getAccountStatisticStreamsV2 } = useContext(RootContext);

	const [openedIdx, setOpenedIdx] = useState([]);
	const [tracksData, setTracksData] = useState({});

	const handleCollapse = async (idx, track) => {
		// If a track is currently loading, do nothing
		if (Object.values(tracksData).includes('loading')) return;

		if (openedIdx.includes(idx)) {
			setOpenedIdx(openedIdx.filter((item) => item !== idx));
			return;
		}

		if (!tracksData[`${track.id}:${track.heaven11_release_id}`]) {
			setTracksData({
				...tracksData,
				[`${track.id}:${track.heaven11_release_id}`]: 'loading',
			});
			await fetchItem(track.id, track.heaven11_release_id);
		}
		setOpenedIdx([...openedIdx, idx]);
	};

	const fetchItem = async (trackId, releaseId) => {
		const outlets =
			statisticData['outlets']?.length > 0
				? statisticData['outlets']
				: accountOutlets;

		const outletsCodes = outlets.map((outlet) => outlet.code);
		const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);

		const { data } = await getAccountStatisticStreamsV2(
			isAdmin,
			accountId,
			startEndDates[0],
			startEndDates[1],
			outletsCodes,
			[trackId],
			releaseId ? [releaseId] : [],
			false
		);

		setTracksData({
			...tracksData,
			[`${trackId}:${releaseId}`]: data.data,
		});
	};
	return (
		<table className={styles.topTracksTable}>
			<thead className={styles.topTracksTable__header}>
				<tr>
					<td> </td>
					{chartMode === 'phono' && <td> </td>}
					{chartMode === 'phono' && (
						<td>
							<FormattedHTMLMessage id={'rod.statistic.top.track'} />
						</td>
					)}
					{chartMode === 'compo' && (
						<td>
							<FormattedHTMLMessage id={'rod.statistic.composition'} />
						</td>
					)}
					{chartMode !== 'compo' && (
						<td>
							<FormattedHTMLMessage id={'rod.statistic.top.performer'} />
						</td>
					)}
					{chartMode === 'compo' && (
						<td>
							<FormattedHTMLMessage id={'rod.statistic.top.author'} />
						</td>
					)}
					{chartMode !== 'compo' && (
						<td>
							<FormattedHTMLMessage id={'rod.statistic.top.album'} />
						</td>
					)}
					<td style={{ textAlign: 'right' }}>
						<FormattedHTMLMessage id={'rod.statistic.top.streams'} />
					</td>
				</tr>
			</thead>
			<tbody className={styles.topTracksTable__body}>
				{topTracks.map((item, index) => (
					<>
						<tr className={styles.tableRow}>
							{chartMode === 'phono' && (
								<td
									className={`${styles.topTracksTable__item} ${
										styles.moreIcon
									} ${openedIdx.includes(index) ? styles.active : ''} ${
										Object.values(tracksData).includes('loading')
											? styles.disable
											: ''
									}`}
									onClick={() => {
										// Prevent clicking if another track is still loading
										if (Object.values(tracksData).includes('loading')) return;

										if (
											tracksData[`${item.id}:${item.heaven11_release_id}`] &&
											tracksData[`${item.id}:${item.heaven11_release_id}`] ===
												'loading'
										) {
											return;
										}
										handleCollapse(index, item);
									}}
								>
									{tracksData[`${item.id}:${item.heaven11_release_id}`] &&
									tracksData[`${item.id}:${item.heaven11_release_id}`] ===
										'loading' ? (
										<div className={styles.loader}>
											<LoaderWhithoutProgress />
										</div>
									) : (
										<img alt="" src={chevronDownIcon} />
									)}
								</td>
							)}
							<td
								onClick={() => handleCheckedTopTrack(item)}
								className={styles.topTracksTable__item}
							>
								{index + 1 + (page - 1) * 10}
							</td>
							<td
								onClick={() => handleCheckedTopTrack(item)}
								className={styles.topTracksTable__item}
							>
								{item.raw_title}

								<span className={styles.topTracksTable__fullName}>
									{item.raw_title}
								</span>
							</td>
							<td
								onClick={() => handleCheckedTopTrack(item)}
								className={styles.topTracksTable__item}
							>
								{item.performers ? getJSONParse(item.performers) : '-'}
							</td>
							{chartMode !== 'compo' && (
								<td className={styles.topTracksTable__item}>
									{item.release_name}
								</td>
							)}
							<td
								onClick={() => handleCheckedTopTrack(item)}
								className={styles.topTracksTable__item}
								style={{ textAlign: 'right' }}
							>
								{Number(item.streams).toLocaleString('ru-RU')}
							</td>
						</tr>
						{tracksData[`${item.id}:${item.heaven11_release_id}`] &&
							tracksData[`${item.id}:${item.heaven11_release_id}`] !==
								'loading' &&
							tracksData[`${item.id}:${item.heaven11_release_id}`].map(
								(track, idx) => {
									const outletTitle = outlets.find(
										(outlet) => outlet.code === track.outlet
									);
									const outletColor =
										strokeColorRange[assignColorIndexToOutlet(track.outlet)];

									return (
										<tr
											className={`${styles.tableRow} ${styles.accordionRow} ${
												styles.noSeparate
											} ${
												idx ===
												tracksData[`${item.id}:${item.heaven11_release_id}`]
													.length -
													1
													? styles.lastRow
													: ''
											} ${openedIdx.includes(index) ? styles.rowOpen : ''}`}
										>
											<td></td>
											<td
												className={`${styles.topTracksTable__item}, ${styles.topTracksTable__itemNumber}`}
											></td>
											<td className={styles.outletInfo}>
												<div
													className={styles.outletItem}
													style={{ backgroundColor: outletColor }}
												/>
												<div>{outletTitle[`title_${lang}`]}</div>
											</td>
											<td className={styles.topTracksTable__item}></td>
											{chartMode !== 'compo' && (
												<td className={styles.topTracksTable__item}></td>
											)}
											<td className={styles.topTracksTable__item}>
												{stylingDigit(track.stat_count)}
											</td>
										</tr>
									);
								}
							)}
					</>
				))}
			</tbody>
		</table>
	);
};

export default TopTracksTable;
