// Core
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';

// UI
import { Label } from 'components/Labels';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';

// Styles
import s from './MuiMultipleCheckedDistributionOutlets.module.css';

const MuiMultipleCheckedDistributionOutlets = (props) => {
	const [outlets, setOutlets] = useState(props.outlets);
	const [selectedData, setSelectedData] = useState([]);

	useEffect(() => {
		const selectedOutlets = props.outlets.filter((item) => item.checked);
		setSelectedData(selectedOutlets);
		props.handleCheckedOutlets(selectedOutlets);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChecked = (outlet) => {
		let updatedOutlets = outlets.map((item) =>
			item.id === outlet.id ? { ...item, checked: !item.checked } : item
		);

		let selectedIds = updatedOutlets
			.filter((item) => item.checked)
			.map((item) => item.id);

		const hasYouTubeMusic = selectedIds.includes(21859);
		const hasYouTubeContentID = selectedIds.includes(436356);

		// If selecting YouTube Content ID, ensure YouTube Music is also selected
		if (outlet.id === 436356 && !hasYouTubeMusic) {
			updatedOutlets = updatedOutlets.map((item) =>
				item.id === 21859 || item.id === 436356
					? { ...item, checked: true }
					: item
			);
		}

		// If deselecting YouTube Music, also deselect YouTube Content ID
		if (outlet.id === 21859 && hasYouTubeContentID) {
			updatedOutlets = updatedOutlets.map((item) =>
				item.id === 436356 || item.id === 21859
					? { ...item, checked: false }
					: item
			);
		}

		// Recalculate selected outlets after enforcing rules
		const selectedArray = updatedOutlets.filter((item) => item.checked);

		setOutlets(updatedOutlets);
		setSelectedData(selectedArray);
		props.handleCheckedOutlets(selectedArray);
	};

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1 }}
				className={s.formControl}
				// TODO uncomment if you need initial error
				// error={!selectedData.length}
			>
				<Input id="demo-multiple-checkbox-label">
					<FormattedMessage
						id={'rod.distribution.outlet.modal.outlets.select.title'}
					/>
				</Input>
				<SelectCustom
					multiple
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					disabled={false}
					MenuProps={{
						style: { zIndex: 13000000000 },
					}}
					value={selectedData}
					renderValue={(selected) => {
						if (selected.length === 1) {
							return <p>{selected[0].title}</p>;
						}

						return (
							<>
								<FormattedMessage id={'rod.statistic.tracks-selected'} />
								:&nbsp;
								{selected.length}/{outlets.length}&nbsp;
								<FormattedMessage id={'rod.statistic.outlets'} />
							</>
						);
					}}
					className={s.selectCustom}
				>
					<div className={s.wrapper}>
						{outlets.length > 0 ? (
							outlets.map((outlet, index) => (
								<MenuItem
									className={s.menuItem}
									key={index}
									onClick={(e) => {
										if (e.target.tagName !== 'svg') {
											handleChecked(outlet);
										}
									}}
									value={outlet.raw_title}
								>
									<CheckboxBlack
										checked={outlet.checked}
										onChange={() => handleChecked(outlet)}
									/>
									<Label>{outlet.title}</Label>
								</MenuItem>
							))
						) : (
							<Label font="--gilroy-Medium-18" className={s.noData}>
								<FormattedMessage id={'rod.no_data'} />
							</Label>
						)}
					</div>
				</SelectCustom>
			</FormControl>
			{/* TODO uncomment if you need initial error */}
			{/* {!selectedData.length && (
				<span className={s.error}>
					{
						<FormattedMessage
							id={'rod.distribution.outlet.modal.outlets.select.error'}
						/>
					}
				</span>
			)} */}
			{selectedData.length === outlets.length && (
				<span className={s.error}>
					{
						<FormattedMessage
							id={'rod.distribution.outlet.modal.outlets.select.error.all'}
						/>
					}
				</span>
			)}
		</div>
	);
};

export default compose(
	withRoot,
	withAuth
)(MuiMultipleCheckedDistributionOutlets);
