import StatisticByDatesChart from './StatisticByDatesChart/StatisticByDatesChart';

const Streams = ({
	data,
	selectedOutlets,
	xAxisSize,
	amountYAxisTitle,
	accountAuditions,
	isLoadingStreams,
	outletSelected,
}) => {
	return (
		<div>
			<StatisticByDatesChart
				data={data}
				selectedOutlets={selectedOutlets}
				xAxisSize={xAxisSize}
				amountYAxisTitle={amountYAxisTitle}
				accountAuditions={accountAuditions}
				isLoadingStreams={isLoadingStreams}
				outletSelected={outletSelected}
			/>
		</div>
	);
};

export default Streams;
