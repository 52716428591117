// Core
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Utils
import { showError } from 'validators/showError';
import { handleSetGRID, handleSetUPC } from '../utils/handleSetIds';

// UI
import { AutoWrapper } from 'components';
import CatalogNumber from '../CatalogNumber/CatalogNumber';
import { FormInput } from 'components/Form';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

// Styles
import s from '../Release.module.css';

const IdsBlock = ({
	personal,
	changeField,
	errors,
	setPersonal,
	transferRelease,
	changeMask,
	checkIsValidMask,
	handleSetCatalogNumber,
	setErrors,
}) => {
	return (
		<>
			<span className={`${s.title} ${s.title__id}`}>
				<FormattedMessage
					id={'rod.release.create.step.release.label.identifiers'}
				/>
			</span>
			<div className={s.flex}>
				<div className={s.item}>
					<div
						className={
							personal.generate_ean || personal?.step?.includes('h11')
								? `${s.input} ${s.disabled}`
								: s.input
						}
					>
						<FormInput
							type="muiInput"
							name={'ean'}
							onChange={changeField}
							errors={errors}
							data={personal}
							label={'UPC'}
							disabled={personal.generate_ean || personal.step?.includes('h11')}
							required={!personal?.step?.includes('h11')}
						/>
						{Object.keys(errors).includes('ean') && (
							<span className={s.Helper}>
								{showError(errors.ean[0].rule)(errors.ean[0])}
							</span>
						)}
					</div>

					{(!transferRelease ||
						(transferRelease && personal.release_type_id === 70)) &&
						!personal?.step?.includes('h11') && (
							<AutoWrapper
								onClick={() =>
									handleSetUPC(personal?.generate_ean, personal, setPersonal)
								}
								check={personal?.generate_ean}
							/>
						)}
				</div>

				<div className={s.item}>
					<div
						className={
							personal.generate_grid || personal?.step?.includes('h11')
								? `${s.input} ${s.disabled}`
								: s.input
						}
					>
						<InputMask
							mask={'**-*****-**********-*'}
							name={'grid'}
							onChange={changeMask}
							errors={errors}
							value={personal.grid ? personal.grid : ''}
							onBlur={checkIsValidMask}
							disabled={
								personal.generate_grid || personal?.step?.includes('h11')
							}
						>
							{() => (
								<TextFieldInput
									variant="standard"
									type="muiInput"
									name={'grid'}
									error={Object.keys(errors).length > 0 && errors.grid}
									value={personal.grid ? personal.grid : ''}
									label={'GRID'}
									disabled={
										personal.generate_grid || personal?.step?.includes('h11')
									}
								/>
							)}
						</InputMask>
						{Object.keys(errors).includes('grid') && (
							<span className={s.Helper}>
								{showError(errors.grid[0].rule)(errors.grid[0])}
							</span>
						)}
					</div>

					{!transferRelease && !personal?.step?.includes('h11') && (
						<AutoWrapper
							onClick={() => {
								setErrors({});
								handleSetGRID(personal?.generate_grid, personal, setPersonal);
							}}
							check={personal?.generate_grid}
						/>
					)}
				</div>
				<CatalogNumber
					personal={personal}
					setPersonal={setPersonal}
					changeField={changeField}
					transferRelease={transferRelease}
					handleSetCatalogNumber={handleSetCatalogNumber}
					errors={errors}
				/>
			</div>
		</>
	);
};

export default IdsBlock;
