export const getReleaseType = (id) => {
	const lang = localStorage.getItem('lang');
	switch (id) {
		case 2:
			if (lang === 'ru') return 'Альбом';
			return 'Album';
		case 69:
			if (lang === 'ru') return 'Компиляция';
			return 'Compilation';
		case 64:
			return 'EP';
		case 42:
			if (lang === 'ru') return 'РБТ';
			return 'RBT';
		case 43:
			if (lang === 'ru') return 'РТ';
			return 'RT';
		case 51:
			if (lang === 'ru') return 'Сингл';
			return 'Single';
		case 70:
			return 'TikTok';
		default:
			return '';
	}
};
