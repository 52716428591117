import { FormattedMessage } from 'react-intl';

import otherIcon from 'images/trends/sources/other.svg';

// Devices
import mobileIcon from 'images/trends/sources/devices/mobile.svg';
import desktopIcon from 'images/trends/sources/devices/desktop.svg';
import tabletIcon from 'images/trends/sources/devices/tablet.svg';
import castIcon from 'images/trends/sources/devices/cast.svg';

// OS
import iOSIcon from 'images/trends/sources/os/ios.svg';
import androidIcon from 'images/trends/sources/os/android.svg';
import windowsIcon from 'images/trends/sources/os/windows.svg';

import styles from './Element.module.css';

const devices = {
	'Mobile phone': {
		title: 'Mobile',
		icon: mobileIcon,
	},
	Computer: {
		title: 'Desktop',
		icon: desktopIcon,
	},
	Tablet: {
		title: 'Tablet',
		icon: tabletIcon,
	},
	TV: {
		title: 'Cast',
		icon: castIcon,
	},
	Unknown: {
		title: 'Other',
		icon: otherIcon,
	},
};

const os = {
	Android: {
		title: 'Android',
		icon: androidIcon,
	},
	iOS: {
		title: 'iOS / MacOS',
		icon: iOSIcon,
	},
	Windows: {
		title: 'Windows',
		icon: windowsIcon,
	},
	'Smart TV': {
		title: 'Smart TV',
		icon: '1',
	},
	WebOS: {
		title: 'WebOS',
		icon: '1',
	},
	Macintosh: {
		title: 'Macintosh',
		icon: '1',
	},
	Other: {
		title: 'Other',
		icon: otherIcon,
	},
};

const Element = ({ item, type }) => {
	return (
		<li className={styles.wrapper}>
			<img
				className={styles.icon}
				src={
					type === 'device'
						? devices[item.device_type]?.icon
						: os[item.operating_system]?.icon
				}
				alt=""
				width={40}
				height={40}
			/>
			<span className={styles.title}>
				{type === 'device'
					? devices[item.device_type]?.title
					: os[item.operating_system]?.title}
			</span>
			<p className={styles.percentage}>
				{item.percentage !== null ? (
					`${item.percentage}%`
				) : (
					<span className={styles.noData}>
						<FormattedMessage id={'rod.statistic.trends.empty-data'} />
					</span>
				)}
			</p>
		</li>
	);
};

export default Element;
