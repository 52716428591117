// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

// UI
import { Col } from 'components/Grid';

// Icons
import close from 'images/close.svg';

// Styles
import styles from '../Notify.module.css';

const NotifyInfo = ({
	backHandling,
	willUnmount,
	currentItem,
	handleReadOne,
}) => {
	const htmlParsing = (text) => {
		return parse(DOMPurify.sanitize(text));
	};

	return (
		<Col className={styles.LeftSide}>
			<div className={styles.notifyHeader}>
				<div className={styles.notifyTitle}>
					<div className={styles.notifyTitle__back} onClick={backHandling}>
						<svg
							width="41"
							height="15"
							viewBox="0 0 41 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.292893 6.79289C-0.0976295 7.18341 -0.0976296 7.81658 0.292892 8.2071L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41422 7.5L8.07107 1.84314C8.46159 1.45262 8.46159 0.819454 8.07107 0.428929C7.68054 0.0384049 7.04738 0.0384048 6.65685 0.428929L0.292893 6.79289ZM41 6.5L1 6.5L1 8.5L41 8.5L41 6.5Z"
								fill="white"
							/>
						</svg>
						<span style={{ marginLeft: '10px' }}>
							<FormattedMessage id={'rod.action.back'} />
						</span>
					</div>
					<button
						type="button"
						className={styles.close__btn}
						onClick={() => willUnmount()}
						style={{ cursor: 'pointer' }}
					>
						<img src={close} alt="" />
					</button>
				</div>
			</div>
			{Object.keys(currentItem).length > 0 && (
				<div className={styles.notifyMain}>
					<div className={styles.itemHeading}>{currentItem.heading}</div>
					<span
						onClick={() => handleReadOne(currentItem.id)}
						className={styles.notifyHeader__flagOne}
						style={!currentItem['read_user'] ? {} : { visibility: 'hidden' }}
					>
						<FormattedMessage id={'rod.notification.mark_one'} />
					</span>
					<div className={styles.itemText}>{htmlParsing(currentItem.text)}</div>
					<div className={styles.itemDate}>{currentItem.created_at}</div>
				</div>
			)}
		</Col>
	);
};

export default NotifyInfo;
