// Core
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { PoseGroup } from 'react-pose';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';

// UI
import { Modal, Shade } from './Modal/Modal';
import BaseModal from './BaseModal';
import NotifyList from './NotifyList/NotifyList';
import NotifyHeader from './NotifyHeader/NotifyHeader';
import NotifyInfo from './NotifyInfo/NotifyInfo';
import { Col, Container, Row } from 'components/Grid';

// Styles
import styles from './Notify.module.css';

const Notify = ({ active, setActive }) => {
	const authContext = useContext(AuthContext);
	const { getUserNotificationsWithPage, putReadUserNotification } = useContext(
		RootContext
	);
	const accountId = authContext.user?.account_id;
	const containerRef = useRef(null);

	const [page, setPage] = useState(1);
	const [notifications, setNotifications] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [allNotificationsLoaded, setAllNotificationsLoaded] = useState(false);
	const [viewedCount, setViewedCount] = useState(0);
	const [isVisible, setIsVisible] = useState(false);
	const [showInfo, setShowInfo] = useState(false);
	const [currentItem, setCurrentItem] = useState({});

	const fetchNotifications = useCallback(
		async (pageNumber) => {
			if (!accountId || isLoading || allNotificationsLoaded) return;

			setIsLoading(true);

			try {
				const res = await getUserNotificationsWithPage(accountId, pageNumber);
				if (!res || res.length === 0) {
					setAllNotificationsLoaded(true);
					return;
				}

				const updatedData = res.map((item) => {
					const time = item.created_at.match(/\d{1,2}:\d{2}/)[0];
					const date = item.created_at
						.slice(0, item.created_at.indexOf('T'))
						.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
					return { ...item, created_at: `${time}, ${date}` };
				});

				setNotifications((prev) => [...prev, ...updatedData]);
				setPage((prevPage) => prevPage + 1);
			} catch (err) {
				console.error('Error fetching notifications:', err);
			} finally {
				setIsLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[accountId, isLoading, allNotificationsLoaded]
	);

	useEffect(() => {
		if (accountId) fetchNotifications(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountId]);

	const handleScroll = useCallback(() => {
		if (!containerRef.current || allNotificationsLoaded || isLoading) return;

		const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

		if (scrollHeight - scrollTop <= clientHeight + 5) {
			fetchNotifications(page);
		}
	}, [page, isLoading, allNotificationsLoaded, fetchNotifications]);

	useEffect(() => {
		const container = containerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [handleScroll]);

	useEffect(() => {
		setIsVisible(active);
	}, [active]);

	useEffect(() => {
		if (notifications) {
			const newViewedCount = notifications.reduce((a, n) => {
				if (!n.read_user) return a + 1;
				return a;
			}, 0);

			setViewedCount(newViewedCount);
		}
	}, [notifications]);

	const handleReadAll = () => {
		let readNotifications = [];

		notifications
			.filter((el) => !el.read_user)
			.forEach((item) => {
				putReadUserNotification(accountId, item.id).then((res) => {
					readNotifications.push({ ...item, read_user: true });
					setViewedCount(0);
				});
			});

		const intervalId = setInterval(() => {
			if (readNotifications.length === notifications.length) {
				setNotifications(readNotifications);
				setViewedCount(0);
				clearInterval(intervalId);
			}
		}, 500);
	};

	const handleReadOne = (selectedItemId) => {
		notifications.find((item) => item.id === selectedItemId)[
			'read_user'
		] = true;

		putReadUserNotification(accountId, selectedItemId).then((res) => {
			setViewedCount((prev) => prev - 1);
		});
	};

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			setActive(false);
		}, 300);
	};

	const backHandling = () => {
		setShowInfo(false);
	};

	const showInfoHandling = (id) => {
		setShowInfo(true);
		setCurrentItem(notifications.find((item) => item.id === id));
	};

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,
					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col
									className={styles.RightSide}
									onClick={(e) => {
										willUnmount(e);
									}}
								/>
								{!showInfo && (
									<Col className={styles.LeftSide}>
										<NotifyHeader
											viewedCount={viewedCount}
											willUnmount={willUnmount}
											handleReadAll={handleReadAll}
										/>
										<NotifyList
											notifications={notifications}
											showInfoHandling={showInfoHandling}
											viewedCount={viewedCount}
											containerRef={containerRef}
										/>
									</Col>
								)}
								{showInfo && (
									<NotifyInfo
										backHandling={backHandling}
										willUnmount={willUnmount}
										currentItem={currentItem}
										handleReadOne={handleReadOne}
									/>
								)}
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};
export default Notify;
