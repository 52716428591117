export const acceptedFormats = [
	'.zip',
	'.xlsx',
	'.xls',
	'.xlsb',
	'.csv',
	'.cr',
	'.cs',
	'.cwr',
	'.v21',
	'.v22',
	'.pdf',
	'.txt',
	'.doc',
	'.docx',
];
