// Core
import React from 'react';

// Utils
import { fm } from 'utils';

// UI
import { Label } from 'components/Labels';

// Styles
import styles from '../AccountData.module.css';

const AccountSocieties = ({ account }) => {
	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.admin.account.societies')}
					font="--gilroy-Medium-32"
				/>
			</div>
			<table className={styles.Table}>
				<thead>
					<tr>
						<th>{fm('rod.admin.account.societies.author_society')}</th>
						<th>{fm('rod.admin.account.societies.date')}</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(account.societies) &&
						account.societies.length > 0 &&
						account.societies.map((society) => (
							<tr>
								<td>{society.title || society.title_ru || society.title_en}</td>
								<td>
									{society.mebership_since ? society.mebership_since : ''}
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</section>
	);
};

export default AccountSocieties;
