// Icons
import error from 'images/error_attention_icon.svg';

// Styles
import styles from './ErrorBanner.module.css';

const ErrorBanner = ({ text, marginBottom }) => {
	return (
		<div
			className={styles.container}
			style={{ marginBottom: marginBottom || '0' }}
		>
			<img src={error} alt="error" />
			{text}
		</div>
	);
};

export default ErrorBanner;
