export const getChartColor = (chart) => {
	if (!Array.isArray(chart) || chart.length === 0) {
		throw new Error('Invalid chart data');
	}

	let maxVal = Math.max(...chart.map((num) => +num));
	let lastVal = +chart[chart.length - 1];

	return lastVal < maxVal ? '#df4e3c' : '#6c9400';
};
