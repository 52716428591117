// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Icons
import close from 'images/close.svg';
import settings from 'images/settings_notify.svg';

// Styles
import styles from '../Notify.module.css';

const NotifyHeader = ({ viewedCount, willUnmount, handleReadAll }) => {
	const authContext = useContext(AuthContext);
	const { lang } = useContext(LangContext);
	const {
		getUserNotificationsSettings,
		putUserNotificationsSettings,
	} = useContext(RootContext);
	const accountId = authContext.user?.account_id;
	const { showModal } = useContext(UIContext);

	return (
		<div className={styles.notifyHeader}>
			<div className={styles.notifyTitle}>
				<span className={styles.notifyTitle__titleWrapper}>
					<span className={styles.notifyTitle__title}>
						<FormattedMessage id={'rod.notification.title'} />
					</span>
					{viewedCount ? (
						<span className={styles.notifyTitle__notesCount}>
							{viewedCount}
						</span>
					) : (
						''
					)}
					<button
						type="button"
						className={styles.settingsBtn}
						onClick={showModal(
							{
								accountId,
								lang,
								getUserNotificationsSettings,
								putUserNotificationsSettings,
							},
							'notify_edit'
						)}
					>
						<img src={settings} alt="settings" />
					</button>
				</span>
				<button
					type="button"
					className={styles.close__btn}
					onClick={() => willUnmount()}
					style={{ cursor: 'pointer' }}
				>
					<img src={close} alt="" />
				</button>
			</div>
			<button
				onClick={() => handleReadAll()}
				className={styles.notifyHeader__flagAll}
				style={viewedCount ? {} : { display: 'none' }}
			>
				<FormattedMessage id={'rod.notification.mark_all'} />
			</button>
		</div>
	);
};

export default NotifyHeader;
