// Core
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

// Utils
import { getReleaseTypeById } from 'utils';
import { accounts } from 'services';

// UI
import SkeletonDetailHeading from './SkeletonDetailHeading/SkeletonDetailHeading';
import SkeletonStreams from './SkeletonStreams/SkeletonStreams';
import EmptyData from '../EmptyData/EmptyData';

// Icons
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';
import placeholderArtist from 'images/trends/topArtists/placeholderArtist.svg';

// Styles
import styles from './MetadataDetail.module.css';
import { SidepageModal } from 'material-design/modals';
import { ArtistForm } from 'material-design/ReleaseCreate/ArtistForm';

const labelSelectItem = ['week', 'month', 'threeMonths'];

const MetadataDetail = ({ accountId, params, typePage }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [dataStreams, setDataStreams] = useState({});
	const [release, setRelease] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [isLoadingTopReleases, setIsLoadingTopReleases] = useState(false);
	const [metadata, setMetadata] = useState({});
	const [isLoadingMetadata, setIsLoadingMetadata] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [isImageBroken, setIsImageBroken] = useState(false);
	const [coverRelease, setCoverRelease] = useState('');
	const [artistName, setArtistName] = useState('');
	const [coverRecording, setCoverRecording] = useState(null);
	const [dateFrom, setDateFrom] = useState(
		moment().subtract(8, 'days').format('YYYY-MM-DD')
	);
	const [showPeriod, setShowPeriod] = useState(false);
	const [checkedItemPeriod, setCheckedPeriodItem] = useState('week');
	const [isCopied, setIsCopied] = useState(false);
	const [artistId, setArtistId] = useState(null);
	const [isArtistModal, setIsArtistModal] = useState(false);

	const currentDay = new Date(new Date().getTime() - 86400000 * 2)
		.toISOString()
		.split('T')[0];

	const handleClipboard = (typePage) => {
		switch (typePage) {
			case 'release':
				navigator.clipboard.writeText(metadata?.ean ?? '');
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1000);
				break;
			case 'recording':
				navigator.clipboard.writeText(metadata?.isrc ?? '');
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1000);
				break;
			default:
				return '';
		}
	};

	const handleCheckedPeriodItem = (period) => {
		setCheckedPeriodItem(period);
		switch (period) {
			case 'week':
				const weekStart = moment().subtract(8, 'days').format('YYYY-MM-DD');
				setShowPeriod(false);
				setDateFrom(weekStart);
				break;
			case 'month':
				const monthStart = moment()
					.subtract(1, 'months')
					.subtract(2, 'days')
					.format('YYYY-MM-DD');
				setShowPeriod(false);
				setDateFrom(monthStart);
				break;
			case 'threeMonths':
				const threeMonths = moment()
					.subtract(3, 'months')
					.subtract(2, 'days')
					.format('YYYY-MM-DD');
				setShowPeriod(false);
				setDateFrom(threeMonths);
				break;
			default:
				break;
		}
	};

	const getStreams = () => {
		setIsLoading(true);
		accounts
			.getAccountStatisticTrendsDetailStreams(
				false,
				accountId,
				dateFrom,
				currentDay,
				[],
				typePage === 'recording' ? params.id : [],
				typePage === 'release' ? params.id : [],
				typePage === 'artist' ? [params.id] : []
			)
			.then((res) => {
				setDataStreams(res.data.data[0]);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	};

	// Get Metadata
	const getArtistMetadataInfo = () => {
		setIsLoadingMetadata(true);
		accounts.getInfoArtist(false, accountId, params.id).then((res) => {
			setArtistId(res.data.artist_id);
			setArtistName(res.data.name);
			setIsLoadingMetadata(false);
		});
	};

	const getReleaseMetadataInfo = () => {
		setIsLoadingMetadata(true);
		accounts.getReleaseInfoForBrowsing(accountId, params.id).then((res) => {
			setMetadata(res.data.data);
			setIsLoadingMetadata(false);
		});
	};

	const getRecordingMetadataInfo = async () => {
		setIsLoadingMetadata(true);
		const metadataRecording = await accounts.getRecordingMetadata(
			accountId,
			params.id
		);

		const getReleaseId = await accounts.getAccountStatisticTopTracks(
			false,
			accountId,
			'',
			'',
			'',
			[],
			1,
			typePage === 'recording' ? params.id : []
		);

		const getReleasesInfo = await accounts.getAccountStatisticTopReleasesInfo(
			accountId,
			getReleaseId.data.data.recordings[0].heaven11_release_id
		);

		if (getReleasesInfo.data.data.length) {
			const topReleasesData = getReleasesInfo.data.data;
			setCoverRecording(topReleasesData[0].covers.path_xm);
		}

		setMetadata(metadataRecording.data.data);
		setIsLoadingMetadata(false);
	};

	const getTopReleases = async () => {
		setIsLoadingTopReleases(true);
		setRelease([]);
		try {
			const topReleasesRes = await accounts.getAccountStatisticTrendsTopReleases(
				false,
				accountId,
				[],
				'',
				'',
				[],
				params.id,
				[],
				[],
				1
			);

			const topReleases = topReleasesRes.data.data.global;

			if (topReleasesRes.data.data.total) {
				const releasesIds = topReleases.map(
					(release) => release.heaven11_release_id
				);

				const getReleasesInfo = await accounts.getAccountStatisticTopReleasesInfo(
					accountId,
					releasesIds
				);

				if (getReleasesInfo.data.data.length) {
					const topReleasesData = getReleasesInfo.data.data;
					const topReleasesInfo = topReleases.map((release) => ({
						...release,
						cover:
							topReleasesData.find(
								(item) => item.id === release.heaven11_release_id
							)?.covers.path_xm ?? null,
					}));
					setRelease(topReleasesInfo);
				} else {
					setRelease(topReleases);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoadingTopReleases(false);
		}
	};

	const getCoverRelease = () => {
		accounts.getReleaseCoverForBrowsing(accountId, params.id).then((res) => {
			setCoverRelease(res.data.data?.path_xm);
		});
	};

	const renderPeriodItem = (period) => {
		switch (period) {
			case 'week':
				return (
					<FormattedMessage id="rod.statistic.trends.artist.changes-week" />
				);
			case 'month':
				return (
					<FormattedMessage id="rod.statistic.trends.artist.changes-month" />
				);
			case 'threeMonths':
				return (
					<FormattedMessage id="rod.statistic.trends.artist.changes-three-months" />
				);
			default:
				return (
					<FormattedMessage id="rod.statistic.trends.artist.changes-week" />
				);
		}
	};

	const renderPercentChange = (percentChange) => {
		return (
			<div
				className={`${styles.progress} ${styles.upStreams} ${
					String(percentChange).includes('-') && styles.downStreams
				} ${
					percentChange === 0 ||
					(dataStreams?.weekly_growth_percentage === null &&
						styles.normalPercent)
				}`}
			>
				{dataStreams?.weekly_growth_percentage !== null ? (
					<span className={styles.progressValue}>
						<svg
							width="11"
							height="12"
							viewBox="0 0 11 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className={styles.arrow}
						>
							<path
								d="M6.16645 3.21875V11.3334H4.83312V3.21875L1.25712 6.79475L0.314453 5.85208L5.49979 0.666748L10.6851 5.85208L9.74245 6.79475L6.16645 3.21875Z"
								fill="currentColor"
							/>
						</svg>
						{percentChange}%{' '}
					</span>
				) : (
					<span className={styles.progressValue}>—</span>
				)}

				<div
					className={`${styles.wrapperSelectPeriod} ${
						showPeriod && styles.active
					}`}
					onClick={() => setShowPeriod((prev) => !prev)}
				>
					<button className={styles.selectPeriod}>
						{renderPeriodItem(checkedItemPeriod)}
					</button>
					<svg
						width="11"
						height="6"
						viewBox="0 0 11 6"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={styles.chevron}
					>
						<path d="M10.5 0.5L5.5 5.5L0.5 0.499999L10.5 0.5Z" fill="#888888" />
					</svg>
				</div>
				{showPeriod && (
					<ul className={styles.listPeriod}>
						{labelSelectItem.map((period) => (
							<li
								key={period}
								className={`${styles.periodElement} ${
									period === checkedItemPeriod ? styles.periodActive : {}
								}`}
								onClick={() => handleCheckedPeriodItem(period)}
							>
								{renderPeriodItem(period)}
							</li>
						))}
					</ul>
				)}
			</div>
		);
	};

	const numericalRank = () => {
		if (dataStreams?.total_streams?.length >= 7) {
			return <FormattedMessage id={'rod.statistic.trends.millions'} />;
		} else if (
			dataStreams?.total_streams?.length <= 6 &&
			dataStreams?.total_streams?.length >= 4
		) {
			return <FormattedMessage id={'rod.statistic.trends.thousand'} />;
		} else {
			return '';
		}
	};

	const formatNumber = (number) => {
		if (number?.length === 9 || number?.length === 6) {
			return number.slice(0, 3);
		}

		if (number?.length === 8 || number?.length === 5) {
			return number.slice(0, 2);
		}

		if (number?.length === 7 || number?.length === 4) {
			return number.slice(0, 2).split('').join(',');
		}

		return number;
	};

	const checkImage = async () => {
		if (release.cover) {
			try {
				const response = await fetch(release.cover, { method: 'HEAD' });
				if (!response.ok) {
					setIsImageBroken(true);
				}
			} catch {
				setIsImageBroken(true);
			}
		} else {
			setIsImageBroken(true);
		}
	};

	const fullRequests = (typePage) => {
		switch (typePage) {
			case 'release':
				getReleaseMetadataInfo();
				getTopReleases();
				getCoverRelease();
				break;
			case 'recording':
				getRecordingMetadataInfo();
				getTopReleases();
				break;
			case 'artist':
				getArtistMetadataInfo();
				break;
			default:
				return;
		}
	};

	const handleOpenArtistId = () => {
		setIsArtistModal(true);
	};

	useEffect(() => {
		fullRequests(typePage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getStreams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateFrom]);

	useEffect(() => {
		checkImage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [release.cover]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.artistInfo}>
				{isLoadingMetadata ? (
					<SkeletonDetailHeading typePage={typePage} />
				) : (
					<>
						<div
							className={`${styles.photo} ${
								typePage === 'artist' ? styles.artistPhotoMain : {}
							}`}
						>
							{typePage === 'recording' && (
								<img
									src={coverRecording ?? emptyCoverAlbum}
									alt={''}
									className={styles.cover}
								/>
							)}
							{typePage === 'release' && (
								<img
									src={coverRelease ?? emptyCoverAlbum}
									alt={''}
									className={styles.cover}
								/>
							)}
							{typePage === 'artist' && (
								<img src={placeholderArtist} width={64} height={64} alt={''} />
							)}
						</div>
						<div>
							<div className={styles.wrapperName}>
								<h2 className={styles.name}>
									{typePage === 'artist' ? artistName : metadata?.title}
								</h2>
								{typePage === 'release' &&
									metadata?.release_type_id?.length && (
										<span className={styles.releaseType}>
											{getReleaseTypeById(metadata?.release_type_id)}
										</span>
									)}
							</div>
							{typePage === 'artist' ? (
								<button
									className={styles.wrapperIsrcBtn}
									onClick={handleOpenArtistId}
									style={
										typePage === 'artist'
											? {
													marginBottom: 0,
											  }
											: {}
									}
								>
									<span className={styles.artistCardTitle}>
										<FormattedMessage id="rod.statistic.trends.artist.artist_card" />
									</span>
								</button>
							) : (
								<div className={styles.wrapperIsrc}>
									<span className={styles.isrcLabel}>
										{typePage === 'release' && <>upc</>}
										{typePage === 'recording' && <>isrc</>}
										{typePage === 'artist' && <>id</>}
									</span>
									<span className={styles.isrcValue}>
										{typePage === 'release' && metadata?.ean}
										{typePage === 'recording' && metadata?.isrc}
										{typePage === 'artist' && params.id}
										<button
											className={styles.copyButton}
											onClick={() => handleClipboard(typePage)}
										>
											<svg
												width="16"
												height="18"
												viewBox="0 0 16 18"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M3.46322 4.00008V1.50008C3.46322 1.27907 3.55101 1.06711 3.70729 0.910826C3.86357 0.754545 4.07554 0.666748 4.29655 0.666748H14.2965C14.5176 0.666748 14.7295 0.754545 14.8858 0.910826C15.0421 1.06711 15.1299 1.27907 15.1299 1.50008V13.1667C15.1299 13.3878 15.0421 13.5997 14.8858 13.756C14.7295 13.9123 14.5176 14.0001 14.2965 14.0001H11.7965V16.5001C11.7965 16.9601 11.4215 17.3334 10.9574 17.3334H0.969049C0.859155 17.3341 0.750214 17.313 0.648485 17.2714C0.546756 17.2299 0.454244 17.1686 0.376265 17.0912C0.298286 17.0137 0.236377 16.9216 0.194095 16.8202C0.151812 16.7188 0.129991 16.61 0.129883 16.5001L0.132383 4.83341C0.132383 4.37341 0.507383 4.00008 0.970716 4.00008H3.46322ZM1.79822 5.66675L1.79655 15.6667H10.1299V5.66675H1.79822ZM5.12988 4.00008H11.7965V12.3334H13.4632V2.33341H5.12988V4.00008Z"
													fill="#888888"
												/>
											</svg>
											{isCopied && (
												<span className={styles.copiedTooltip}>
													<FormattedMessage
														id={'rod.statistic.trends.copied'}
													/>
												</span>
											)}
										</button>
									</span>
								</div>
							)}
							{(typePage === 'release' || typePage === 'recording') && (
								<ul className={styles.listArtists}>
									{metadata?.artists?.length > 0 &&
										metadata?.artists?.map((item, index) => (
											<li
												key={item.id}
												className={`${styles.artist} ${
													item.account_id.length === 0 ? styles.disabled : {}
												}`}
											>
												<div className={styles.artistPhoto}>
													<img
														src={placeholderArtist}
														alt={item}
														width={16}
														height={16}
													/>
												</div>
												<Link
													to={`/accounts/${accountId}/statistic/trends/artist/${item.account_id}`}
												>
													<p className={styles.artistName}>{item.title}</p>
												</Link>
											</li>
										))}
								</ul>
							)}
						</div>
					</>
				)}
			</div>
			<div className={styles.streamsWrapper}>
				{isLoading ? (
					<SkeletonStreams />
				) : dataStreams !== undefined ||
				  dataStreams?.total_streams.length ||
				  dataStreams?.weekly_growth_percentage.length ? (
					<>
						<p className={styles.streamsTitle}>
							<FormattedMessage
								id={'rod.statistic.trends.artist.streams-all-time'}
							/>
						</p>
						<p className={styles.streamsValue}>
							{formatNumber(dataStreams?.total_streams)} {numericalRank()}
						</p>
						{renderPercentChange(
							dataStreams?.weekly_growth_percentage?.toFixed(1)
						)}
					</>
				) : (
					<EmptyData />
				)}
			</div>
			{isArtistModal && (
				<SidepageModal
					customWidth="434px"
					headerBottom="12px"
					active={isArtistModal}
					setActive={() => setIsArtistModal(false)}
				>
					<ArtistForm
						trends
						isModalActive={isArtistModal}
						data={{ artist_id: artistId }}
						dataField="performers"
						index={0}
						setIsModalActive={setIsArtistModal}
					/>
				</SidepageModal>
			)}
		</div>
	);
};

export default MetadataDetail;
