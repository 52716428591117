// Core
import React, { useState, useContext } from 'react';
import { RadialChart, Hint } from 'react-vis';
import { FormattedMessage } from 'react-intl';

import { LangContext } from 'contexts/LangContext';

import Skeleton from '../Skeleton/Skeleton';
import EmptyData from '../EmptyData/EmptyData';

import codeFormattedOutlets from '../utils/codeFormattedOutlets';

// Styles
import styles from './TopOutletsGraph.module.css';

const ProductTypesGraph = ({ topOutlets, isLoadingTopOutlets }) => {
	const { lang } = useContext(LangContext);
	const [dataDonutSection, setDataDonutSection] = useState({});
	const [isShowHint, setIsShowHint] = useState(false);

	const [typeInfo, setTypeInfo] = useState('percentages');

	const handleMouseOverGraphic = (datapoint) => {
		const { outlet, percentage, x, y, othersOutlets, streams } = datapoint;

		setDataDonutSection({
			outlet: codeFormattedOutlets
				.filter((item) => item.outletCode === outlet)
				.map((item) => (lang === 'ru' ? item.titleRu : item.titleEn)),
			percentage,
			streams,
			x,
			y,
			othersOutlets,
		});
		setIsShowHint(true);
	};

	const handleMouseOutGraphic = () => {
		setDataDonutSection({});
		setIsShowHint(false);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.headingGraphic}>
				<h2 className={styles.title}>
					<FormattedMessage id={'rod.statistic.trends.donut-chart.title'} />
				</h2>
				<div className={styles.tabs}>
					<button
						className={`${styles.tabType} ${
							typeInfo === 'percentages' && styles.activeTabType
						}`}
						onClick={() => setTypeInfo('percentages')}
					>
						<FormattedMessage
							id={'rod.statistic.trends.donut-chart.top-outlets.percentages'}
						/>
					</button>
					<button
						className={`${styles.tabType} ${
							typeInfo === 'numbers' && styles.activeTabType
						}`}
						onClick={() => setTypeInfo('numbers')}
					>
						<FormattedMessage
							id={'rod.statistic.trends.donut-chart.top-outlets.numbers'}
						/>
					</button>
				</div>
			</div>
			{isLoadingTopOutlets ? (
				<Skeleton />
			) : (
				<div className={styles.graph}>
					{topOutlets.length ? (
						<>
							<RadialChart
								data={topOutlets}
								colorType="literal"
								width={227}
								height={227}
								innerRadius={55}
								radius={100}
								showLabels={false}
								onValueMouseOver={(datapoint) =>
									handleMouseOverGraphic(datapoint)
								}
								onValueMouseOut={() => handleMouseOutGraphic()}
							>
								{isShowHint && (
									<Hint
										value={dataDonutSection}
										style={{
											text: {
												display: 'none',
											},
										}}
									>
										{dataDonutSection.outlet[0] !== 'Others' &&
										dataDonutSection.outlet[0] !== 'Другие' ? (
											<div className={styles.tooltipGraphicWrapper}>
												<div className={styles.tooltipGraphic}>
													<p className={styles.tooltipGraphicOutlet}>
														{dataDonutSection.outlet}
													</p>
													<p className={styles.tooltipGraphicPercentage}>
														{typeInfo === 'percentages'
															? `${dataDonutSection.percentage}%`
															: Number(dataDonutSection.streams).toLocaleString(
																	'ru-RU'
															  )}
													</p>
												</div>
											</div>
										) : (
											<div className={styles.tooltipGraphicWrapper}>
												<div
													className={`${styles.tooltipGraphic} ${styles.tooltipGraphicOthers}`}
												>
													<div className={styles.tooltipGraphicHeading}>
														<p
															className={`${styles.tooltipGraphicOutlet} ${styles.tooltipGraphicOutletOthers}`}
														>
															<FormattedMessage
																id={
																	'rod.statistic.trends.donut-chart.top-outlets.others'
																}
															/>
														</p>
														<p className={styles.tooltipGraphicPercentage}>
															{typeInfo === 'percentages'
																? `${dataDonutSection.percentage}%`
																: Number(
																		dataDonutSection.streams
																  ).toLocaleString('ru-RU')}
														</p>
													</div>
													<ul className={styles.tooltipListOutletOthers}>
														{dataDonutSection.othersOutlets.map((item) => (
															<li
																className={styles.tooltipElementOutletOthers}
																key={item.outlet}
															>
																<span className={styles.tooltipGraphicOutlet}>
																	{codeFormattedOutlets
																		.filter(
																			(itemOutlet) =>
																				itemOutlet.outletCode === item.outlet
																		)
																		.map((itemOther) =>
																			lang === 'ru'
																				? itemOther.titleRu
																				: itemOther.titleEn
																		)}
																</span>
																<span
																	className={styles.tooltipGraphicPercentage}
																>
																	{typeInfo === 'percentages'
																		? `${item.percentage}%`
																		: Number(item.streams).toLocaleString(
																				'ru-RU'
																		  )}
																</span>
															</li>
														))}
													</ul>
												</div>
											</div>
										)}
									</Hint>
								)}
							</RadialChart>

							<div className={styles.labels}>
								{topOutlets.map((item) => (
									<div className={styles.label} key={item.outlet}>
										<div className={styles.leftSideLabel}>
											<span
												className={styles.labelColorLine}
												style={{ backgroundColor: item.color }}
											></span>
											<p className={styles.labelPercent}>
												{typeInfo === 'percentages'
													? `${item.percentage}%`
													: Number(item.streams).toLocaleString('ru-RU')}
												{/* {item.percentage === '0.0' ? 0 : item.percentage}% */}
											</p>
										</div>

										<span className={styles.labelText}>
											{codeFormattedOutlets.filter(
												(itemOutlet) => itemOutlet.outletCode !== 'others'
											) ? (
												codeFormattedOutlets
													.filter(
														(itemOutlet) =>
															itemOutlet.outletCode === item.outlet
													)
													.map((item) =>
														lang === 'ru' ? item.titleRu : item.titleEn
													)
											) : (
												<FormattedMessage
													id={
														'rod.statistic.trends.donut-chart.top-outlets.others'
													}
												/>
											)}
										</span>
										{item.outlet === 'others' && (
											<div
												className={`${styles.tooltipGraphicWrapper} ${styles.tooltipGraphicWrapperAbsolute}`}
											>
												<div
													className={`${styles.tooltipGraphic} ${styles.tooltipGraphicOthers}`}
												>
													<div className={styles.tooltipGraphicHeading}>
														<p
															className={`${styles.tooltipGraphicOutlet} ${styles.tooltipGraphicOutletOthers}`}
														>
															<FormattedMessage
																id={
																	'rod.statistic.trends.donut-chart.top-outlets.others'
																}
															/>
														</p>
														<p className={styles.tooltipGraphicPercentage}>
															{typeInfo === 'percentages'
																? `${item.percentage}%`
																: Number(item.streams).toLocaleString('ru-RU')}
														</p>
													</div>
													<ul className={styles.tooltipListOutletOthers}>
														{item.othersOutlets.map((item) => (
															<li
																className={styles.tooltipElementOutletOthers}
																key={item.outlet}
															>
																<span className={styles.tooltipGraphicOutlet}>
																	{codeFormattedOutlets
																		.filter(
																			(itemOutlet) =>
																				itemOutlet.outletCode === item.outlet
																		)
																		.map((item) =>
																			lang === 'ru'
																				? item.titleRu
																				: item.titleEn
																		)}
																</span>
																<span
																	className={styles.tooltipGraphicPercentage}
																>
																	{typeInfo === 'percentages'
																		? `${item.percentage}%`
																		: Number(item.streams).toLocaleString(
																				'ru-RU'
																		  )}
																</span>
															</li>
														))}
													</ul>
												</div>
											</div>
										)}
									</div>
								))}
							</div>
						</>
					) : (
						<EmptyData />
					)}
				</div>
			)}
		</div>
	);
};
export default ProductTypesGraph;
