// Core
import { capitalize, debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
	CustomSVGSeries,
	Hint,
	HorizontalBarSeries,
	VerticalGridLines,
	XAxis,
	XYPlot,
	YAxis,
} from 'react-vis';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';

// Styles
import styles from './StatisticByTerritoriesBar.module.css';

// Utils
import { getHorizontalBarColor } from '../../utils/getHorizontalBarColor';
import useWindowSize from 'utils/useResize';

const StatisticByTerritoriesBar = ({
	data,
	limit,
	allCountries,
	lang,
	handleSelectCountry,
	sort,
	dates,
}) => {
	const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
	const screenSize = useWindowSize();
	const [countries, setCountries] = useState([]);
	const [highlightedIndex, setHighlightedIndex] = useState(null);
	const [highlightedCountry, setHighlightedCountry] = useState(null);
	const [tenTopCountries, setTenTopCountries] = useState([]);
	const containerRef = useRef(null);

	const debouncedSetHighlighted = debounce(
		(highlighted) => setHighlightedIndex(highlighted),
		100
	);

	useEffect(() => {
		setCountries(
			allCountries.map((country) => {
				return {
					code: country.code,
					name: country[`title_${lang}`],
				};
			})
		);
	}, [allCountries, lang]);

	useEffect(() => {
		if (countries.length && data.length) {
			const getAuditionsByCountries = countries.map((item) => ({
				...item,
				auditions: sumByCountry(item),
			}));
			const filterNonEmptyCountries = getAuditionsByCountries.filter(
				(item) => item.auditions
			);
			const sortedCountriesByAuditions = filterNonEmptyCountries.sort(
				(a, b) => b.auditions - a.auditions
			);

			let topLimitItems = getTenTopCountries(sortedCountriesByAuditions);
			const maxAudition = topLimitItems[0].auditions;
			let divIndex = 1;

			if (maxAudition <= 10000) {
				divIndex = 1;
			} else if (maxAudition > 10000) {
				divIndex = 1000;
			}

			if (divIndex > 1) {
				topLimitItems = topLimitItems.map((item) => ({
					...item,
					auditions: Math.round(item.auditions / divIndex),
					totalAuditions: parseInt(item?.auditions).toLocaleString(),
				}));
			}
			topLimitItems = topLimitItems.map((item, idx) => {
				return {
					...item,
					x: item.auditions,
					y: item.name,
					totalAuditions:
						item.totalAuditions ?? parseInt(item?.auditions).toLocaleString(),
					customComponent: (row, positionInPixels) => {
						return (
							<text
								className={styles.clickable_text}
								opacity={row.opacity}
								color="#1E1E1E"
								textAnchor={positionInPixels.x > 60 ? 'end' : 'start'}
								fontSize={11}
								x={positionInPixels.x < 60 ? '4px' : '-10px'}
								y={3}
							>
								{row.auditions}
							</text>
						);
					},
				};
			});

			if (containerRef?.current?.parentElement?.offsetWidth) {
				setChartSize({
					width: containerRef?.current?.parentElement?.offsetWidth,
					height: Math.max(
						(containerRef?.current?.parentElement?.offsetWidth / 2.1) *
							(topLimitItems.length / 12),
						60
					),
				});
			}
			setTenTopCountries(
				sort === 'desc' ? topLimitItems.reverse() : topLimitItems
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries, data, limit]);

	useEffect(() => {
		if (containerRef?.current?.parentElement?.offsetWidth) {
			setChartSize({
				width: containerRef?.current?.parentElement?.offsetWidth,
				height: Math.max(
					(containerRef?.current?.parentElement?.offsetWidth / 2.1) * (10 / 12),
					60
				),
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerRef.current, screenSize]);

	const sumByCountry = (countryProps) => {
		const dataBySelectedCountry = data.filter(
			(element) => element.territory_code === countryProps.code
		);

		let auditions = 0;
		dataBySelectedCountry.forEach(
			(element) => (auditions = auditions + parseInt(element.stat_count))
		);

		return auditions;
	};

	const getTenTopCountries = (data) => {
		let result = [];
		result = data
			.slice(0, Math.min(limit, data.length))
			.filter((item) => item.auditions);
		return result;
	};

	const handleCountryClick = (barData) => {
		const selectedCountry = allCountries.find(
			(country) => country.code === barData.code
		);
		if (selectedCountry) {
			handleSelectCountry(selectedCountry);
		}
	};

	const countryTitleClick = (countryName) => {
		const selectedCountry = allCountries.find(
			(country) => country[`title_${lang}`] === countryName
		);
		if (selectedCountry) {
			handleSelectCountry(selectedCountry);
		}
	};

	const renderTick = (v) => {
		const capitalizedTitle = v
			.split(' ')
			.map((title) => capitalize(title))
			.join(' ');
		return (
			<tspan
				onMouseEnter={() => {
					setHighlightedCountry({
						value: v,
						y: v,
						x: 0,
					});
				}}
				onMouseLeave={() => {
					setHighlightedCountry(null);
				}}
				onMouseOut={() => {
					setHighlightedCountry(null);
				}}
				onClick={() => countryTitleClick(v)}
				style={{ cursor: 'pointer' }}
			>
				<tspan color="#888" fontSize={12} capitalize>
					{v.length > 11
						? `${capitalizedTitle.substring(0, 9)}...`
						: capitalizedTitle}
				</tspan>
			</tspan>
		);
	};

	const getDomainMax = () => {
		if (
			Number(
				tenTopCountries?.[sort !== 'desc' ? 0 : tenTopCountries.length - 1]
					?.totalAuditions
			) < 10
		) {
			return [0, 10];
		}
		return null;
	};

	const getDatesString = useCallback(() => {
		const startDateFormatted = format(new Date(dates.startDate), 'dd.MM.yy');
		const endDateFormatted = format(new Date(dates.endDate), 'dd.MM.yy');

		return `${startDateFormatted} - ${endDateFormatted}`;
	}, [dates.startDate, dates.endDate]);

	return (
		<>
			<div
				className={styles.chart_container}
				ref={containerRef}
				onMouseOut={() => {
					setHighlightedCountry(null);
				}}
			>
				<XYPlot
					animation
					width={chartSize.width}
					height={chartSize.height}
					margin={{ left: 100, right: 100, top: 0, bottom: 26 }}
					yType="ordinal"
					stackBy="x"
					xDomain={getDomainMax()}
				>
					<VerticalGridLines tickTotal={limit} />
					<XAxis hideLine tickTotal={10} tickSizeOuter={0} />
					<YAxis
						tickFormat={renderTick}
						hideLine
						tickSizeOuter={0}
						tickTotal={tenTopCountries.length}
					/>

					<HorizontalBarSeries
						colorType="literal"
						barWidth={0.6}
						onValueMouseOver={(d, _e) => {
							if (d.x !== highlightedIndex?.x) {
								debouncedSetHighlighted(d);
							}
							if (highlightedCountry) {
								setHighlightedCountry(null);
							}
						}}
						onValueMouseOut={() => {
							debouncedSetHighlighted(null);
						}}
						onValueClick={handleCountryClick}
						data={tenTopCountries.map((item, idx) => {
							return {
								code: item.code,
								index: idx,
								y: item.name,
								x: Number(item.auditions),
								totalAuditions: item.totalAuditions,
								color: getHorizontalBarColor(highlightedIndex, idx),
							};
						})}
					/>
					<CustomSVGSeries
						onValueMouseOver={(d, _e) => {
							debouncedSetHighlighted(d);
							setHighlightedCountry(null);
						}}
						onValueMouseOut={() => debouncedSetHighlighted(null)}
						data={tenTopCountries.map((item, idx) => {
							return {
								...item,
								index: idx,
								opacity: highlightedIndex?.index === idx ? 1 : 0.5,
							};
						})}
					/>
					{highlightedCountry ? (
						<Hint
							align={{ horizontal: 'right', vertical: 'bottom' }}
							value={{ x: 0, y: highlightedCountry?.y }}
						>
							<div className={styles.hint}>
								<p>{capitalize(highlightedCountry.value)}</p>
							</div>
						</Hint>
					) : null}
					{highlightedIndex ? (
						<Hint
							key={highlightedIndex.index}
							align={{
								vertical: 'bottom',
								horizontal: 'auto',
							}}
							value={{ x: highlightedIndex?.x, y: highlightedIndex?.y }}
							style={{ zIndex: 2 }}
						>
							<div className={styles.hint_total}>
								<h4>
									<FormattedMessage id={'rod.statistic.auditions'} />
								</h4>
								<p>
									<FormattedMessage id={'rod.statistic.dates'} />{' '}
									<span>{getDatesString()}</span>
								</p>
								<p>
									<FormattedMessage id={'rod.statistic.total'} />{' '}
									<span>{highlightedIndex?.totalAuditions}</span>
								</p>
							</div>
						</Hint>
					) : null}
				</XYPlot>
			</div>
		</>
	);
};

export default StatisticByTerritoriesBar;
