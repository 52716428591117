// Core
import React, { useEffect, useState } from 'react';
import {
	XYPlot,
	XAxis,
	YAxis,
	HorizontalGridLines,
	LineSeries,
	AreaSeries,
	Borders,
	Crosshair,
	MarkSeries,
} from 'react-vis';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Utils
import {
	assignColorIndexToOutlet,
	colorRange,
	strokeColorRange,
} from '../../..//utils/assignColorIndexToOutlet';

// UI
import { Loading } from 'components';
import MainContentHeader from './MainContentHeader/MainContentHeader';
import CrosshairData from './CrosshairData/CrosshairData';
import CrosshairDataWrapper from './CrosshairDataWrapper/CrosshairDataWrapper';
import Helper from './Helper/Helper';

import Skeleton from '../../../Skeleton/Skeleton';
import EmptyData from '../../../EmptyData/EmptyData';

import {
	sourcesListYoutube,
	sourcesListApple,
	sourcesListSpotify,
} from '../configs/sourcesList';
// Styles
import styles from './StatisticByDatesChart.module.css';

const StatisticByDatesChart = ({
	data,
	selectedOutlets,
	xAxisSize,
	amountYAxisTitle,
	accountAuditions,
	isLoadingStreams,
	outletSelected,
}) => {
	const [loading, setLoading] = useState(false);
	const [isCumulativeBttActive, setIsCumulativeBttActive] = useState(true);
	const [helper, setHelper] = useState(false);
	const [comparisonChartData, setComparisonChartData] = useState(data ?? null);
	const [width, setWidth] = useState(0);
	const [legend, setLegend] = useState([]);
	const [total, setTotal] = useState(0);
	const [crosshairData, setCrosshairData] = useState([]);

	const lang = localStorage.getItem('lang');
	const colorDomain = [
		0,
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		10,
		11,
		12,
		13,
		14,
		15,
		16,
		17,
		18,
	];

	const makeLegend = () => {
		let result = Object.values(data).map((element) => {
			let sourceData;
			if (outletSelected === 'youtube') {
				sourceData = sourcesListYoutube.find((outlet) => {
					return outlet.source === element.code;
				});
			} else if (outletSelected === 'apple') {
				sourceData = sourcesListApple.find((outlet) => {
					return outlet.source === element.code;
				});
			} else {
				sourceData = sourcesListSpotify.find((outlet) => {
					return outlet.source === element.code;
				});
			}

			if (sourceData) {
				return {
					title: sourceData[`title${lang.toUpperCase()}`],
					code: sourceData.source,
					color: strokeColorRange[assignColorIndexToOutlet(sourceData.source)],
				};
			}
		});

		setLegend(result.reverse());
	};

	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
	};

	const countTotal = () => {
		let result = 0;

		if (crosshairData) {
			crosshairData?.forEach((item) => {
				result = Math.round((result + item.y) * 1000000) / 1000000;
			});
			setTotal(result);
		}
	};

	useEffect(() => {
		countTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCumulativeBttActive]);

	useEffect(() => {
		countTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crosshairData]);

	useEffect(() => {
		setComparisonChartData(data);
		if (Object.values(data).length) {
			makeLegend();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, lang]);

	useEffect(() => {
		setLoading(false);
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);

		return () => {
			window.removeEventListener('resize', updateWindowDimensions);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chartModeSwitch = (e) => {
		e.preventDefault();
		setIsCumulativeBttActive(!isCumulativeBttActive);
	};

	const onNearestX = (value, { index }) => {
		let resultedCrosshairData = Object.values(comparisonChartData).map(
			(item) => {
				const itemData = item.data;
				return { ...itemData[index], code: item.code, title: item.title };
			}
		);
		setCrosshairData(resultedCrosshairData.sort((a, b) => b.y - a.y));
	};

	return (
		<>
			{helper && <Helper setHelper={setHelper} />}
			{comparisonChartData && (
				<div className={styles.statistic__mainContent}>
					<MainContentHeader
						amountYAxisTitle={amountYAxisTitle}
						isCumulativeBttActive={isCumulativeBttActive}
						chartModeSwitch={chartModeSwitch}
					/>
					{isLoadingStreams ? (
						<Skeleton />
					) : accountAuditions.length ? (
						<>
							{xAxisSize &&
								comparisonChartData &&
								Object.values(comparisonChartData)[0]?.data && (
									<XYPlot
										xType="time-utc"
										width={width > 1024 ? width - 275 : width - 100}
										margin={{
											left: 80,
											right: 40,
											top: 10,
											bottom: 40,
										}}
										height={536}
										stackBy={isCumulativeBttActive ? 'y' : ''}
										onMouseLeave={() => setCrosshairData([])}
										style={{ background: 'white' }}
										colorType="category"
										colorDomain={colorDomain}
										colorRange={colorRange}
									>
										<MarkSeries
											data={[
												{
													x: Object.values(comparisonChartData)[0].data[0].x,
													y: 0,
												},
											]}
											style={{ display: 'none' }}
										/>

										{!isCumulativeBttActive
											? Object.values(
													comparisonChartData
											  ).map((dataByOutlet, index) => (
													<LineSeries
														key={index}
														color={assignColorIndexToOutlet(dataByOutlet.code)}
														opacity={1}
														stroke={
															strokeColorRange[
																assignColorIndexToOutlet(dataByOutlet.code)
															]
														}
														data={dataByOutlet.data}
														onNearestX={onNearestX}
													/>
											  ))
											: Object.values(comparisonChartData).map(
													(dataByOutlet, index) => {
														return (
															<AreaSeries
																key={index}
																color={assignColorIndexToOutlet(
																	dataByOutlet.code
																)}
																opacity={1}
																data={dataByOutlet.data}
																className={styles.areaSeries}
															/>
														);
													}
											  )}
										{isCumulativeBttActive &&
											Object.values(comparisonChartData).map(
												(dataByOutlet, index) => {
													return (
														<LineSeries
															style={{ zIndex: -index }}
															key={index}
															color={assignColorIndexToOutlet(
																dataByOutlet.code
															)}
															opacity={1}
															stroke={
																strokeColorRange[
																	assignColorIndexToOutlet(dataByOutlet.code)
																]
															}
															data={dataByOutlet.data}
															onNearestX={onNearestX}
														/>
													);
												}
											)}
										<HorizontalGridLines />
										<Borders
											style={{
												bottom: { fill: '#fff' },
												left: { fill: '#fff' },
												right: { fill: '#fff' },
												top: { fill: '#fff' },
											}}
										/>
										<Crosshair values={crosshairData}>
											<CrosshairDataWrapper
												crosshairData={crosshairData}
												legend={legend}
												isCumulativeBttActive={isCumulativeBttActive}
												total={total}
											/>
										</Crosshair>
										<XAxis
											tickFormat={(v) =>
												v.toLocaleDateString('en-US', {
													day: 'numeric',
													month: 'short',
												})
											}
											tickTotal={xAxisSize < 10 ? xAxisSize : 10}
										/>
										<YAxis
											tickFormat={(v) =>
												v >= 0 ? (v.toString().includes('.') ? `` : `${v}`) : ``
											}
										/>
									</XYPlot>
								)}

							<CrosshairData legend={legend} />
						</>
					) : (
						<EmptyData typePage={'sources'} />
					)}
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(StatisticByDatesChart);
