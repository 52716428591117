// Core
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Constants
import ReleaseCompletionStepsIds from 'material-design/constants/releaseCompletionStepsIds';

// UI
import { WizardStep } from './index';

// Styles
import styles from './Wizard.module.css';

const Wizard = ({ steps, className, handleOpenPrevTabs, editNotices }) => {
	const [stateSteps, setStateSteps] = useState(steps);

	const isH11Edit = window.location.href.includes('/edit/');

	const showNotice = (stepId) => {
		if (!editNotices || !isH11Edit) return;

		return editNotices.some(({ code }) =>
			code.startsWith(ReleaseCompletionStepsIds[stepId])
		);
	};

	useEffect(() => {
		if (steps !== stateSteps) {
			setStateSteps(steps);
		}
	}, [steps, stateSteps]);

	useEffect(() => {
		// Force re-render when editNotices updates
		setStateSteps((prevSteps) => [...prevSteps]);
	}, [editNotices]);

	const classes = classNames(className, styles['Wizard']);

	return (
		<div className={classes}>
			{stateSteps.map((step) => (
				<WizardStep
					{...step}
					key={step.id}
					step={step}
					handleOpenPrevTabs={handleOpenPrevTabs}
					showNotice={showNotice(step.id)}
				/>
			))}
		</div>
	);
};

Wizard.propTypes = {
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			status: PropTypes.string,
			title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		})
	).isRequired,
	className: PropTypes.string,
	handleOpenPrevTabs: PropTypes.func,
};

export default Wizard;
