export const getOutletTitleById = (
	id,
	outlets,
	allShipmentsArr,
	allTakenDownsArr
) => {
	const sources = [outlets, allShipmentsArr, allTakenDownsArr];
	const outlet = sources
		.flatMap((arr) => arr)
		.find((el) => el.id.toString() === id.toString());

	return outlet ? outlet.title : '';
};
