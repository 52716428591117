// Core
import React from 'react';

// UI
import Loader from 'components/Loader';

// Styles
import s from '../../File.module.css';

const RecordingLoader = ({ item, uploadProgressPercents }) => {
	return (
		<div className={!item.done ? s.loader : ''}>
			<Loader
				uploadProgressPercents={uploadProgressPercents.find(
					(progressObj) => progressObj.key === item.name
				)}
			/>
		</div>
	);
};

export default RecordingLoader;
