// Core
import React from 'react';
import { XYPlot, LineSeries } from 'react-vis';
import { getJSONParse } from 'utils';
import { Link } from 'react-router-dom';

// Utils
import { getChartColor } from 'utils';

// UI
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

// Styles
import styles from './TableElement.module.css';

const TableElement = ({ item, index, currentPage, accountId, typePage }) => {
	const renderPercentChange = (percentChange) => {
		return (
			<p
				className={`${styles.progress} ${styles.upStreams} ${
					String(percentChange).includes('-') && styles.downStreams
				} ${percentChange === 0 && styles.normalPercent}`}
			>
				<span className={styles.progressValue}>
					<svg
						width="11"
						height="12"
						viewBox="0 0 11 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.16645 3.21875V11.3334H4.83312V3.21875L1.25712 6.79475L0.314453 5.85208L5.49979 0.666748L10.6851 5.85208L9.74245 6.79475L6.16645 3.21875Z"
							fill="currentColor"
						/>
					</svg>
					{percentChange}%
				</span>
			</p>
		);
	};
	return (
		<li className={styles.tableElement}>
			<div className={styles.infoWrapper}>
				<div className={styles.place}>{index + 1 + (currentPage - 1) * 10}</div>
				<div className={styles.cover}>
					<img src={item?.cover ?? emptyCoverAlbum} alt={''} />
				</div>

				<Link
					to={
						typePage === 'tracks'
							? `/accounts/${accountId}/statistic/trends/recording/${item.id}`
							: `/accounts/${accountId}/statistic/trends/release/${item.id}`
					}
				>
					<p className={`${styles.text} ${styles.title}`} title={item.title}>
						{item.title}
					</p>
				</Link>
			</div>
			<span className={styles.text} title={getJSONParse(item.performers)}>
				{getJSONParse(item.performers)}
			</span>
			<span className={styles.text}>
				{Number(item.streams).toLocaleString('ru-RU')}
			</span>
			<div className={styles.growth}>
				<span className={styles.text}>
					{Number(item.week_streams).toLocaleString('ru-RU') ?? <>—</>}
				</span>
				<span className={`${styles.text} ${styles.countryPercentage}`}>
					{item.week_growth_percentage === null ? (
						<>—</>
					) : (
						item.week_growth_percentage !== 0 &&
						renderPercentChange(item.week_growth_percentage)
					)}
				</span>
			</div>
			<div className={styles.growth}>
				<span className={styles.text}>
					{Number(item.month_streams).toLocaleString('ru-RU') ?? <>—</>}
				</span>

				<span className={`${styles.text} ${styles.countryPercentage}`}>
					{item.month_growth_percentage === null ? (
						<>—</>
					) : (
						item.month_growth_percentage !== 0 &&
						renderPercentChange(item.month_growth_percentage)
					)}
				</span>
			</div>
			<div>
				{item.chart.length >= 3 ? (
					<XYPlot xType="linear" width={120} height={60} margin={5}>
						<LineSeries
							stroke={getChartColor(item.chart)}
							curve={'curveMonotoneX'}
							data={item.chart.map((item, index) => ({
								x: index,
								y: Number(item),
							}))}
						/>
					</XYPlot>
				) : (
					<>—</>
				)}
			</div>
		</li>
	);
};

export default TableElement;
