// Core
import { FormattedHTMLMessage } from 'react-intl';

// Icons
import close from 'images/close_button.svg';

// Styles
import styles from './StatisticHelperBanner.module.css';

const StatisticHelperBanner = ({ setHelper }) => {
	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	return (
		<div className={styles.helper}>
			<img onClick={closeHelper} src={close} alt="" />
			<span>
				<FormattedHTMLMessage id={'rod.statistic.notification'} />
			</span>
		</div>
	);
};

export default StatisticHelperBanner;
