export const sourcesListYoutube = [
	{
		source: 'Others',
		titleRU: 'Другое',
		titleEN: 'Other',
		description: 'прочие страницы Ютуб',
	},
	{
		source: 'Playlists',
		titleRU: 'Плейлисты',
		titleEN: 'User&Editorial playlists',
		description: 'канал из подписок Ютуб',
	},
	{
		source: 'Searches',
		titleRU: 'Поиск',
		titleEN: 'Search',
		description: 'переход из внешнего сайта',
	},
	{
		source: 'Advertisings',
		titleRU: 'Реклама',
		titleEN: 'Advertisement',
		description: 'Реклама',
	},
	{
		source: 'Recommendations',
		titleRU: 'Рекомендации',
		titleEN: 'Recommendations',
		description: 'Рекомендации Ютуб',
	},
	{
		source: 'Shorts',
		titleRU: 'Youtube shorts',
		titleEN: 'Youtube shorts',
		description: 'Ютуб шортс',
	},
];

export const sourcesListApple = [
	{
		source: 'Other',
		titleRU: 'Другое',
		titleEN: 'Other',
		description: '',
	},
	{
		source: 'Library',
		titleRU: 'Библиотека слушателя',
		titleEN: 'User library',
		description: '',
	},
	{
		source: 'Search',
		titleRU: 'Поиск',
		titleEN: 'Search',
		description: '',
	},
	{
		source: 'Discovery',
		titleRU: 'Рекомендации',
		titleEN: 'Discovery',
		description: '',
	},
	{
		source: 'External',
		titleRU: 'Внешний источник',
		titleEN: 'External',
		description: '',
	},
];

export const sourcesListSpotify = [
	{
		source: 'search',
		titleRU: 'Поиск',
		titleEN: 'Search',
		description: '',
	},
	{
		source: 'artist',
		titleRU: 'Артист',
		titleEN: 'Artist',
		description: '',
	},
	{
		source: 'album',
		titleRU: 'Релиз',
		titleEN: 'Album',
		description: '',
	},
	{
		source: 'collection',
		titleRU: 'Библиотека слушателя',
		titleEN: 'User library',
		description: '',
	},
	{
		source: 'others_playlist',
		titleRU: 'Плейлисты',
		titleEN: 'Playlists',
		description: '',
	},
	{
		source: 'chart',
		titleRU: 'Чарты',
		titleEN: 'Chart',
		description: '',
	},
	{
		source: 'radio',
		titleRU: 'Радио',
		titleEN: 'Radio',
		description: '',
	},
	{
		source: 'other',
		titleRU: 'Другое',
		titleEN: 'Other',
		description: '',
	},
];
