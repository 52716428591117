// Core
import { FormattedMessage } from 'react-intl';

// UI
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { InfoNotice } from 'components';
import { PerformerItem } from 'material-design/ReleaseCreate/PerformerItem';

// Styles
import s from '../Release.module.css';

const PerformersBlock = ({
	releaseTypeId,
	personal,
	handleChangeArtist,
	handleOpenModal,
	handleAddArtist,
	handleDeleteArtist,
	isH11Edit,
	errors,
	cleanErrorsField,
	variousArtists,
	handleVariousArtist,
}) => {
	return (
		<>
			{(releaseTypeId === 70 ||
				releaseTypeId === 51 ||
				releaseTypeId === 42 ||
				releaseTypeId === 43) && (
				<InfoNotice
					marginTop="48px"
					marginBottom="18px"
					text="rod.release.create.step.release.performers_notice"
				/>
			)}
			{releaseTypeId !== 70 &&
				releaseTypeId !== 51 &&
				releaseTypeId !== 42 &&
				releaseTypeId !== 43 && (
					<InfoNotice
						marginTop="48px"
						marginBottom="18px"
						text="rod.release.create.step.release.various_artists"
					/>
				)}
			{releaseTypeId !== 70 &&
				releaseTypeId !== 51 &&
				releaseTypeId !== 42 &&
				releaseTypeId !== 43 && (
					<>
						<span className={`${s.title} ${s.title__artists}`}>
							<FormattedMessage
								id={'rod.release.create.step.release.label.artists'}
							/>
						</span>
						<div className={s.artistsBlock}>
							{personal.performers &&
								personal.performers.length > 0 &&
								personal.performers.map((performer, index) => (
									<PerformerItem
										performer={performer}
										index={index}
										lastIndex={personal.performers.length - 1}
										personal={personal}
										handleChangeArtist={handleChangeArtist}
										errors={errors}
										cleanErrorsField={cleanErrorsField}
										handleOpenModal={handleOpenModal}
										handleAddArtist={handleAddArtist}
										handleDeleteArtist={handleDeleteArtist}
										dataField="performers"
										isH11Edit={isH11Edit}
										variousArtists={variousArtists}
									/>
								))}
							{personal.performers && personal.performers.length >= 5 && (
								<span className={s.variousArtistHelper}>
									<FormattedMessage id="rod.release.create.step.release.various_artists.error" />
								</span>
							)}
						</div>
						{handleVariousArtist && (
							<Checkbox
								text="Various Artists"
								checked={variousArtists}
								onChange={handleVariousArtist}
								className={s.variousArtist}
							/>
						)}
					</>
				)}
		</>
	);
};

export default PerformersBlock;
