// Core
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withUI, withRoot, withAuth } from 'hocs';

// Utils
import { checkEnabledStatus } from 'utils';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

// UI
import { AddRoleContractsBanner } from 'material-design/components/Banners/AddRoleContractsBanner';
import ContractWrapper from './ContractWrapper/ContractWrapper';
import DownloadWrapper from './DownloadWrapper/DownloadWrapper';
import { Loading, NoData } from 'components';
import NewContractBtn from './NewContractBtn/NewContractBtn';

// Styles
import s from './TreatiesPage.module.css';

const TreatiesPage = (props) => {
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [btnDisabledWithoutMessage, setBtnDisabledWithoutMessage] = useState(
		false
	);
	const [isBtn, setIsBtn] = useState(true);
	const [hideBtn, setHideBtn] = useState(false);
	const [accountContracts, setAccountContracts] = useState('');
	const [contractsFiles, setContractsFiles] = useState([]);
	const [modifiedContracts, setModifiedContracts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noData, setNoData] = useState(false);
	const [contractsOnReview, setcontractsOnReview] = useState([]);
	const [isLicenseeAccount, setIsLicenseeAccount] = useState(false);
	const [services, setServices] = useState([]);
	const [downloadHref, setDownloadHref] = useState('');

	const {
		UIContext: {
			showTitle,
			hideTitle,
			allowAdditionalContractSigning,
			isShowAddServices,
		},
		rootContext: {
			getAccountContracts,
			getContractCondition,
			getContractAttachments,
			countries,
			getContractData,
			getAccountPersonalData,
			getAvailableServices,
			postAdditionalServices,
		},
		authContext: { accountId },
	} = props;
	const history = useHistory();

	useEffect(() => {
		showTitle('rod.navbar.treaties');
		getAvailableServices(accountId)
			.then((res) => {
				setServices(res);
			})
			.catch((err) => console.error('Error:', err));

		getAccountContracts(accountId).then((res) => {
			setIsLicenseeAccount(res.data.licensee_account_id ? true : false);
			res = res.data.data;
			if (!res) {
				setNoData(true);
				setLoading(false);
			} else {
				const isSigningContract = res.find(
					(item) => item.status && item.status !== 'signed'
				);
				const contractsOnReviewArr = res.filter(
					(item) => item.status === 'signed'
				);

				if (isSigningContract && isSigningContract.status !== 'signed') {
					history.push(`/treaties/form/${accountId}/`);
				} else {
					contractsOnReviewArr.length
						? setcontractsOnReview(contractsOnReviewArr)
						: getDataFromContracts();
					setAccountContracts(res ? res : []);
					setBtnDisabled(
						res.find((item) => item.status) || contractsOnReviewArr.length
					);
				}

				if (!res) {
					setLoading(false);
				}
			}
		});

		if (!contractsOnReview.length)
			return function cleanup() {
				hideTitle();
			};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let contracts = [...accountContracts];

		contracts = contracts.filter((item) => !item.status);

		if (contracts.length) {
			for (let i = 0; i < contracts.length; i++) {
				getContractAttachments(contracts[i].id).then((res) => {
					setContractsFiles((prev) => [
						...prev,
						{ contractId: contracts[i].id, files: res.data.data },
					]);
				});

				getContractCondition(contracts[i].id).then((res) => {
					let modifiedContract = contracts.find(
						(contr) => contr.id === contracts[i].id
					);
					modifiedContract['conditions'] = res.data.data;
					setModifiedContracts((prev) => [...prev, modifiedContract]);

					if (contractsOnReview.length) {
						setLoading(false);
					}
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountContracts]);

	useEffect(() => {
		if (document.getElementById('download')) {
			document.getElementById('download').click();
		}
	}, [downloadHref]);

	const getAttachments = (id) => {
		getContractAttachments(id).then((res) => {
			res = res.data.data;

			for (let i = 0; i < res.length; i++) {
				setTimeout(() => {
					setDownloadHref(res[i].url);
					// TODO переделать, когда решится проблема с CORS
					// downloadFromUrl(res[i].url);
				}, (res.length === 1 ? 10 : 1000) * (i + 1));
			}
		});
	};

	const getDataFromContracts = () => {
		getAccountPersonalData(accountId)
			.then((account) => {
				account = account.data.data;

				if (
					(account.completion_step === accountCompletionSteps.PERSONAL_AREA ||
						account.completion_step ===
							accountCompletionSteps.REPERTOIRE_APPLY) &&
					!account.contracts_signed
				) {
					getContractData(accountId)
						.then((res) => {
							res = res.data.data;

							if (!res || (Array.isArray(res) && !res.length)) {
								setHideBtn(true);
							}

							if (
								res &&
								res.contract_types &&
								Object.keys(res.contract_types).length > 0
							) {
								const contractTypes = res.contract_types;
								const isOnlyLyricsToSign = checkEnabledStatus(contractTypes);

								if (isOnlyLyricsToSign) {
									setHideBtn(true);
								}

								if (!allowAdditionalContractSigning) {
									setIsBtn(false);
								} else if (
									((contractTypes.lyrics &&
										!contractTypes.lyrics.signed_properties) ||
										(contractTypes.digital.resources.composition &&
											!contractTypes.digital.resources.composition
												.signed_properties) ||
										(contractTypes.digital.resources.recording &&
											!contractTypes.digital.resources.recording
												.signed_properties) ||
										(contractTypes.public_performance.resources.composition &&
											!contractTypes.public_performance.resources.composition
												.signed_properties) ||
										(contractTypes.public_performance.resources.recording &&
											!contractTypes.public_performance.resources.recording
												.signed_properties) ||
										(contractTypes.synchronization.resources.composition &&
											!contractTypes.synchronization.resources.composition
												.signed_properties) ||
										(contractTypes.synchronization.resources.recording &&
											!contractTypes.synchronization.resources.recording
												.signed_properties)) &&
									allowAdditionalContractSigning
								) {
									setIsBtn(true);
								} else setIsBtn(false);
							}
						})
						.catch((err) => {
							if (err.response.status === 422) {
								setBtnDisabledWithoutMessage(true);
							} else {
								console.error('Error:', err.response);
							}
						})
						.finally(() => {
							setLoading(false);
						});
				}
			})
			.catch((err) => {
				console.error('Error:', err);
			});
	};

	const correctData = (data) => {
		return data.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
	};

	const handleClickNewContract = () => {
		if (contractsOnReview.length) {
			setBtnDisabled(true);
		} else {
			history.push(`/treaties/form/${accountId}`);
		}
	};

	return (
		<div className={s.container}>
			{loading ? (
				<Loading className={s.loader} />
			) : isLicenseeAccount ? (
				<NoData textId={'rod.contracts.no_contracts_info'} />
			) : noData ? (
				<NoData />
			) : (
				modifiedContracts &&
				accountContracts &&
				modifiedContracts.length > 0 &&
				(modifiedContracts.length === accountContracts.length ||
					modifiedContracts.length + contractsOnReview?.length ===
						accountContracts.length) && (
					<>
						{isShowAddServices &&
						services.length &&
						contractsOnReview.length === 0 ? (
							<div className={s.addRoleContractsBanner}>
								<AddRoleContractsBanner
									data={{
										title: (
											<FormattedMessage
												id={'rod.account.add_service_banner_title'}
											/>
										),
										content: (
											<FormattedMessage
												id={'rod.account.add_service_banner_text'}
											/>
										),
										btnText: (
											<FormattedMessage id={'rod.account.add_service_btn'} />
										),

										data: services,
										postAdditionalServices,
										id: accountId,
									}}
								/>
							</div>
						) : (
							''
						)}
						<ul className={s.treaties__list}>
							{accountContracts.map((item, index) => (
								<li key={index} className={s.item}>
									<div className={s.itemWrapper}>
										<div className={s.mainData}>
											<div className={s.headerWrapper}>
												<div className={s.titleWrapper}>
													<div className={s.title}>{item.title}</div>
													<div className={s.typeId}>
														<FormattedMessage
															id={
																item.contract_type === 'license'
																	? 'rod.account.create.license-agreement'
																	: item.contract_type ===
																	  'single_song_publishing'
																	? 'rod.contracts.publishing'
																	: 'rod.contracts.sub'
															}
														/>
														<span>: #{item.id}</span>
													</div>
												</div>
												<DownloadWrapper
													item={item}
													contractsFiles={contractsFiles}
													getAttachments={getAttachments}
													downloadHref={downloadHref}
												/>
											</div>
											<ContractWrapper
												item={item}
												correctData={correctData}
												countries={countries}
											/>
										</div>
									</div>
								</li>
							))}
						</ul>

						{isBtn && !hideBtn && (
							<NewContractBtn
								btnDisabled={btnDisabled}
								btnDisabledWithoutMessage={btnDisabledWithoutMessage}
								handleClickNewContract={handleClickNewContract}
							/>
						)}
					</>
				)
			)}
		</div>
	);
};
export default compose(withUI, withRoot, withAuth)(TreatiesPage);
