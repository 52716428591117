import React from 'react';

// Components
import TopReleases from './TopReleases/TopReleases';
import TopRecordings from './TopRecordings/TopRecordings';

// Styles
import styles from './TopReleasesAndRecordings.module.css';

const TopReleasesAndRecordings = ({
	accountId,
	topReleases,
	topRecordings,
	isLoadingTopReleases,
	isLoadingTopRecordings,
}) => {
	return (
		<div className={styles.wrapper}>
			<TopReleases
				topReleases={topReleases}
				isLoading={isLoadingTopReleases}
				topMode={'releases'}
				accountId={accountId}
			/>
			<TopRecordings
				topRecordings={topRecordings}
				isLoading={isLoadingTopRecordings}
				topMode={'recordings'}
				accountId={accountId}
			/>
		</div>
	);
};

export default TopReleasesAndRecordings;
