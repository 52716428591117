// Core
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import {
	colorRange,
	assignColorIndexToOutlet,
} from '../../../../utils/assignColorIndexToOutlet';

// Icons
import question from 'images/question.svg';

// Styles
import styles from '../StatisticByDatesChart.module.css';

const CrosshairData = ({ legend }) => {
	return (
		<div className={styles.crosshairData__legend}>
			{legend &&
				legend.map((item, index) => {
					return (
						<div key={index} className={styles.crosshairData__legendItem}>
							<div
								className={styles.crosshairData__legendItemColor}
								style={{
									background: item?.code
										? colorRange[assignColorIndexToOutlet(item?.code)]
										: 'transparent',
									borderTop: `2px ${item?.color} solid`,
								}}
							/>
							<div className={styles.titleContainer}>
								<span className={styles.crosshairData__legendItemTitle}>
									{legend[index]?.title}
								</span>
								{(legend[index]?.code === 'Playlists' ||
									legend[index]?.code === 'Recommendations' ||
									legend[index]?.code === 'Discovery' ||
									legend[index]?.code === 'External') && (
									<span className={styles.question}>
										<img src={question} alt="question" />
									</span>
								)}

								{legend[index]?.code === 'Playlists' && (
									<div className={styles.tooltip}>
										<FormattedMessage
											id={'rod.statistic.trends.sources.informer.playlist'}
										/>
									</div>
								)}

								{legend[index]?.code === 'Recommendations' && (
									<div className={styles.tooltip}>
										<FormattedMessage
											id={'rod.statistic.trends.sources.informer.video'}
										/>
									</div>
								)}

								{legend[index]?.code === 'Discovery' && (
									<div className={styles.tooltip}>
										<FormattedMessage
											id={'rod.statistic.trends.sources.informer.discovery'}
										/>
									</div>
								)}

								{legend[index]?.code === 'External' && (
									<div className={styles.tooltip}>
										<FormattedMessage
											id={'rod.statistic.trends.sources.informer.external'}
										/>
									</div>
								)}
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default CrosshairData;
