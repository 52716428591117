import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';

import placeholderArtist from 'images/trends/topArtists/placeholderArtist.svg';

import styles from './Artists.module.css';

const Artist = ({ artist, accountId }) => {
	return (
		<div className={styles.artist}>
			<div className={styles.photo}>
				<img
					src={placeholderArtist}
					alt={artist.performer}
					width={64}
					height={64}
				/>
			</div>
			<Link
				to={`/accounts/${accountId}/statistic/trends/artist/${artist.artist_id}`}
			>
				<p className={styles.name}>{artist.artist_name}</p>
			</Link>

			<p className={styles.streams}>
				{Number(artist.streams).toLocaleString('ru-RU')}&nbsp;
				<FormattedMessage id={'rod.statistic.trends.top-artists.streams'} />
			</p>
		</div>
	);
};

export default Artist;
