// Core
import { FormattedMessage } from 'react-intl';

// UI
import SortMenu from '../SortMenu/SortMenu';
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';
import DatesBlockNew from '../../StatisticByDatesSection/DatesBlockNew/DatesBlockNew';

// Icons
import filter_svg from 'images/filter_small.svg';
import filter_dot_svg from 'images/filter_small_dot.svg';

// Styles
import styles from '../StatisticByTerritoriesSectionv2.module.css';

const InputsWrapper = ({
	changeField,
	errors,
	statisticData,
	setStatisticData,
	setIsFilterModalActive,
	isFiltersActive,
	isAdmin,
	isCopyrightingStatisticsEnabled,
	accountId,
	accountOutlets,
	datePickerData,
	tracksIds,
	isAllTracks,
	releaseId,
	lastStatDate,
	handleCheckedPeriod,
	setApplyDate,
	applyDateFilter,
	setIsLastCalendar,
	showHeading,
	pageLoading,
}) => {
	return (
		<div className={styles.inputsWrapper}>
			<div
				className={`${styles.wrapperDatesBlock} ${
					pageLoading ? styles.disabled : {}
				}`}
			>
				<DatesBlockNew
					accountId={accountId}
					accountOutlets={accountOutlets}
					isAdmin={isAdmin}
					changeField={changeField}
					statisticData={datePickerData}
					errors={errors}
					tracksIds={tracksIds}
					isAllTracks={isAllTracks}
					releaseId={releaseId}
					lastStatDate={lastStatDate}
					handleCheckedPeriod={handleCheckedPeriod}
					setApplyDate={setApplyDate}
					applyDateFilter={applyDateFilter}
					setIsLastCalendar={setIsLastCalendar}
				/>
			</div>
			<div>
				<div
					onClick={() => setIsFilterModalActive(true)}
					className={styles.statistics_filter__item}
				>
					<img src={!isFiltersActive() ? filter_svg : filter_dot_svg} alt="" />
					<span>
						<FormattedMessage id={'rod.admin.reports.filters'} />
					</span>
				</div>
				<SortMenu
					sort={statisticData.sort}
					handleSortChange={(sortValue) => {
						setStatisticData({
							...statisticData,
							sort: sortValue,
							page: 1,
						});
					}}
				/>
				{(!showHeading || !isCopyrightingStatisticsEnabled) && (
					<StatisticDownloadBtn
						accountId={accountId}
						statisticData={statisticData}
						accountOutlets={accountOutlets}
						isAdmin={isAdmin}
						withTitle
						tracksIds={
							statisticData.trackSearchValue
								? statisticData.tracksIds
								: statisticData.checkedAllTracks
								? []
								: statisticData.tracksIds
						}
						v2
					/>
				)}
			</div>
		</div>
	);
};

export default InputsWrapper;
