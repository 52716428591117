import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import EmptyData from '../../../EmptyData/EmptyData';
import Skeleton from '../../../Skeleton/Skeleton';

import Element from '../Element/Element';
import styles from './Devices.module.css';

const Devices = ({ data, isLoadingDevices }) => {
	const [showInformer, setShowInformer] = useState(false);
	return (
		<div className={styles.wrapper}>
			<div className={styles.heading}>
				<h2 className={styles.title}>
					<FormattedMessage id={'rod.statistic.trends.devices'} />
				</h2>
				<div
					className={styles.buttonInfo}
					onClick={() => setShowInformer(true)}
					onMouseLeave={() => setShowInformer(false)}
				>
					<svg
						width="25"
						height="25"
						viewBox="0 0 25 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_2605_21919)">
							<path
								d="M12.5 22.5C6.977 22.5 2.5 18.023 2.5 12.5C2.5 6.977 6.977 2.5 12.5 2.5C18.023 2.5 22.5 6.977 22.5 12.5C22.5 18.023 18.023 22.5 12.5 22.5ZM12.5 20.5C14.6217 20.5 16.6566 19.6571 18.1569 18.1569C19.6571 16.6566 20.5 14.6217 20.5 12.5C20.5 10.3783 19.6571 8.34344 18.1569 6.84315C16.6566 5.34285 14.6217 4.5 12.5 4.5C10.3783 4.5 8.34344 5.34285 6.84315 6.84315C5.34285 8.34344 4.5 10.3783 4.5 12.5C4.5 14.6217 5.34285 16.6566 6.84315 18.1569C8.34344 19.6571 10.3783 20.5 12.5 20.5ZM11.5 7.5H13.5V9.5H11.5V7.5ZM11.5 11.5H13.5V17.5H11.5V11.5Z"
								fill="currentColor"
							/>
						</g>
						<defs>
							<clipPath id="clip0_2605_21919">
								<rect
									width="24"
									height="24"
									fill="white"
									transform="translate(0.5 0.5)"
								/>
							</clipPath>
						</defs>
					</svg>
					<span>
						<FormattedMessage id={'rod.statistic.trends.source'} />
					</span>
				</div>
				{showInformer && (
					<div className={styles.wrapperInformer}>
						<div className={styles.wrapperInformerHeading}>
							<span>
								<FormattedMessage id={'rod.statistic.trends.source'} />
							</span>
							<span>
								<FormattedMessage id={'rod.statistic.trends.streams'} />
							</span>
						</div>
						<ul className={styles.listSources}>
							{['YouTube', 'Spotify', 'Apple music'].map((item) => (
								<li key={item} className={styles.source}>
									{item}{' '}
									<svg
										width="18"
										height="13"
										viewBox="0 0 18 13"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6.99974 10.172L16.1917 0.979004L17.6067 2.393L6.99974 13L0.635742 6.636L2.04974 5.222L6.99974 10.172Z"
											fill="var(--color-light-green)"
										/>
									</svg>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>

			{isLoadingDevices ? (
				<Skeleton />
			) : data.length ? (
				<ul className={styles.list}>
					{data
						.sort((a, b) => b.percentage - a.percentage)
						.map((item) => (
							<Element item={item} key={item.device_type} type={'device'} />
						))}
				</ul>
			) : (
				<EmptyData typePage={'sources'} />
			)}
		</div>
	);
};

export default Devices;
