export const colorRange = [
	'#F3C7CC',
	'#F5DCC1',
	'#CBECD7',
	'#C5E0FF',
	'#F7ECC0',
	'#F0CEF8',
	'#EEDAFF',
	'#CBF8F7',
];

export const strokeColorRange = [
	'#FB233B',
	'#FF9220',
	'#1ED760',
	'#0077FF',
	'#FCCA00',
	'#DC51FC',
	'#A237FF',
	'#2AF0EA',
];

export const assignColorIndexToOutlet = (outlet) => {
	let colorIndex = 0;

	switch (outlet) {
		case 'Others':
			colorIndex = 0;
			break;
		case 'Playlists':
			colorIndex = 1;
			break;
		case 'Searches':
			colorIndex = 2;
			break;
		case 'Recommendations':
			colorIndex = 3;
			break;
		case 'Advertisings':
			colorIndex = 4;
			break;
		case 'Shorts':
			colorIndex = 5;
			break;
		// Apple
		case 'Other':
			colorIndex = 0;
			break;
		case 'Library':
			colorIndex = 1;
			break;
		case 'Search':
			colorIndex = 2;
			break;
		case 'Discovery':
			colorIndex = 3;
			break;
		case 'External':
			colorIndex = 4;
			break;
		// Spotify
		case 'search':
			colorIndex = 0;
			break;
		case 'artist':
			colorIndex = 1;
			break;
		case 'album':
			colorIndex = 2;
			break;
		case 'collection':
			colorIndex = 3;
			break;
		case 'others_playlist':
			colorIndex = 4;
			break;
		case 'chart':
			colorIndex = 5;
			break;
		case 'radio':
			colorIndex = 6;
			break;
		case 'other':
			colorIndex = 7;
			break;
		default:
			break;
	}
	return colorIndex;
};
