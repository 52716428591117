// Core
import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Context
import { AuthContext } from 'contexts/AuthContext';

// Utils
import { accounts } from 'services';
import { correctDate } from '../../Distribution/utils/correctDate';
import { dateFormat } from 'logic';
import { getEndTime } from './utils/getEndTime';

// UI
import AddButton from './AddButton/AddButton';
import AdditionalReleaseForm from './AdditionalReleaseForm/AdditionalReleaseForm';
import { Divider } from '@mui/material';
import { InfoTitle } from 'components';

const AdditionalRelease = ({
	releaseTypeId,
	additionalReleases,
	setAdditionalReleases,
	selectedTrack,
	setSelectedTrack,
	errors,
	setErrors,
	additionalErrors,
	setAdditionalErrors,
	transferRelease,
	snippetDurationError,
	setSnippetDurationError,
}) => {
	const { accountId, isSlaPrivelegesForAccount } = useContext(AuthContext);
	const releaseId = localStorage.getItem('releaseId');

	const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);

	const deleteAdditionalRelease = (id) => {
		if (typeof id === 'string') {
			const updatedForms = additionalReleases.filter(
				(additionalRelease) => additionalRelease.id !== id
			);
			setAdditionalReleases(updatedForms);
		} else {
			setDeleteButtonDisabled(true);

			accounts
				.deleteAdditionalRelease(id)
				.then((res) => {
					if (res.status === 200 || res.status === 404) {
						const updatedForms = additionalReleases.filter(
							(additionalRelease) => additionalRelease.id !== id
						);

						setAdditionalReleases(updatedForms);
						setDeleteButtonDisabled(false);
						setSelectedTrack({
							...selectedTrack,
							additional: updatedForms.length ? updatedForms : null,
						});
					} else {
						console.error('Error: Unexpected status code - ' + res.status);
						setDeleteButtonDisabled(false);
					}
				})
				.catch((data) => {
					setErrors(data.response.data.errors);
					setDeleteButtonDisabled(false);
				});
		}
	};

	const addAdditionalRelease = () => {
		const defaultDate = !isSlaPrivelegesForAccount
			? correctDate(Date.now() + 604800000)
			: correctDate(Date.now() + 86400000);
		setAdditionalReleases((prevReleases) => [
			...prevReleases,
			{
				id: uuidv4() + 'local',
				release_type_id: null,
				generate_ean: false,
				ean: null,
				generate_grid: false,
				grid: null,
				generate_catalog_number: false,
				catalog_number: null,
				account_id: accountId,
				parent_release: +releaseId,
				sale_start_date: defaultDate,
				parent_recording: selectedTrack.id,
				snippet_start: null,
				snippet_end: null,
			},
		]);
	};

	const changeField = (field, selectedRelease) => (e) => {
		setErrors({});
		setAdditionalErrors({});

		const updatedReleases = additionalReleases.map((release) => {
			if (release.id === selectedRelease.id) {
				switch (field) {
					case 'release_type_id':
						const snippetEnd = getEndTime(e.id, release.snippet_start);
						return { ...release, [field]: e.id, snippet_end: snippetEnd };
					case 'sale_start_date':
						if (!release.sale_start_date) {
							if (e && e.getFullYear().toString().length === 4) {
								return {
									...release,
									[field]: new Date(e.getTime()).toISOString(),
								};
							}
						} else if (e && e.getFullYear().toString().length === 4) {
							e = dateFormat(e);
						}
						return {
							...release,
							[field]: e,
						};

					default:
						return { ...release, [field]: e.target.value };
				}
			}
			return release;
		});

		setAdditionalReleases(updatedReleases);
	};

	const handleSetId = (field, value, selectedRelease) => {
		setErrors({});
		setAdditionalErrors({});

		const updatedReleases = additionalReleases.map((release) => {
			if (release.id === selectedRelease.id) {
				const updatedRelease = { ...release, [field]: !value };

				if (updatedRelease[field]) {
					switch (field) {
						case 'generate_ean':
							updatedRelease.ean = null;
							break;
						case 'generate_grid':
							updatedRelease.grid = null;
							break;
						case 'generate_catalog_number':
							updatedRelease.catalog_number = null;
							break;
						default:
							break;
					}
				}
				return updatedRelease;
			}
			return release;
		});

		setAdditionalReleases(updatedReleases);
	};

	const changeMask = (e, selectedRelease) => {
		setErrors({});

		const updatedReleases = additionalReleases.map((release) => {
			if (release.id === selectedRelease.id) {
				return { ...release, grid: e.target.value };
			}
			return release;
		});

		setAdditionalReleases(updatedReleases);
	};

	const checkIsValidMask = (e, selectedRelease) => {
		if (!selectedRelease.generate_grid && selectedRelease.grid.includes('_')) {
			setErrors({ grid: [{ rule: 'wrong_format' }] });
		}
	};

	return (
		<>
			{(releaseTypeId === 51 ||
				releaseTypeId === 2 ||
				releaseTypeId === 64 ||
				releaseTypeId === 69) && (
				<>
					<Divider sx={{ marginTop: '48px', marginBottom: '48px' }} />
					<InfoTitle title="rod.release.create.step.tracks.additional_release.title" />
				</>
			)}

			{additionalReleases.map((additionalRelease) => (
				<AdditionalReleaseForm
					key={additionalRelease.id}
					additionalRelease={additionalRelease}
					changeField={changeField}
					changeMask={changeMask}
					checkIsValidMask={checkIsValidMask}
					errors={errors}
					additionalErrors={additionalErrors}
					setAdditionalErrors={setAdditionalErrors}
					deleteAdditionalRelease={deleteAdditionalRelease}
					deleteButtonDisabled={deleteButtonDisabled}
					handleSetId={handleSetId}
					additionalReleases={additionalReleases}
					setAdditionalReleases={setAdditionalReleases}
					transferRelease={transferRelease}
					snippetDurationError={snippetDurationError}
					setSnippetDurationError={setSnippetDurationError}
				/>
			))}
			{(releaseTypeId === 51 ||
				releaseTypeId === 2 ||
				releaseTypeId === 64 ||
				releaseTypeId === 69) && <AddButton onClick={addAdditionalRelease} />}
		</>
	);
};

export default AdditionalRelease;
