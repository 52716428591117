// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { fm } from 'utils';

// UI
import Field from 'pages/adminV2/components/Field/Field';
import { Bullet } from 'components/Bullet';
import { Label } from 'components/Labels';

// Styles
import styles from '../AccountData.module.css';

const AccountRoles = ({ roles }) => {
	return (
		<Field title={fm('rod.admin.account_roles')}>
			<div className={styles.Roles}>
				<ul>
					{roles.map((role) => (
						<li>
							<Bullet>
								<Label
									text={
										role.keyword ? (
											<FormattedMessage
												id={`rod.ref.roles.${role.keyword}.summary`}
											/>
										) : (
											'\u2212'
										)
									}
									font="--gilroy-Medium-18"
									black
								/>
							</Bullet>
						</li>
					))}
				</ul>
			</div>
		</Field>
	);
};

export default AccountRoles;
