export async function generateHmac(message, secretKey) {
	const encoder = new TextEncoder();
	const keyData = encoder.encode(secretKey);
	const messageData = encoder.encode(message);

	try {
		// Import key
		const key = await crypto.subtle.importKey(
			'raw',
			keyData,
			{ name: 'HMAC', hash: { name: 'SHA-256' } },
			false,
			['sign']
		);

		// Generate HMAC signature
		const signature = await crypto.subtle.sign('HMAC', key, messageData);

		// Convert to hex string
		return Array.from(new Uint8Array(signature))
			.map((b) => b.toString(16).padStart(2, '0'))
			.join('');
	} catch (error) {
		console.error('Error generating HMAC:', error);
		return null; // Return null or handle the error appropriately
	}
}
