import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import FilterSkeleton from '../FilterSkeleton/index';
import { Label } from 'components/Labels';

// Styles
import s from '../TrendFilters.module.css';

const FilterPerformers = ({
	rootContext: { getAccountStatisticAllPerformers },
	dateFrom,
	dateTo,
	accountId,
	isAdmin,
	setPerformersState,
	setIsResetPerformers,
	lang,
	setIsLoadedPerformers,
	isLoadedPerformers,
	setPerformersIds,
	setIsLoadedRecordings,
}) => {
	const [performers, setPerformers] = useState([]);
	const [performerIds, setPerformerIds] = useState([]);
	const [checkedPerformers, setCheckedPerformers] = useState([]);
	const [cachedPerformers, setCachedPerformers] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isShowSkeleton, setIsShowSkeleton] = useState(false);
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [page] = useState(1);

	const getPerformers = () => {
		setIsLoadedRecordings(false);
		setIsLoadedPerformers(false);
		setIsShowSkeleton(true);
		getAccountStatisticAllPerformers(
			isAdmin,
			accountId,
			searchValue,
			page,
			100,
			dateFrom,
			dateTo
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked: checkedPerformers.includes(item.performer) ? true : false,
			}));

			const allPerformersButton = {
				id: 0,
				artistId: 0,
				performer: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked:
					searchValue.length > 0 || !!data.find((item) => !item.checked)
						? false
						: true,
			};

			let performersData;

			if (data.length) {
				performersData = [allPerformersButton, ...data];
				setIsEmptyData(false);
			} else {
				performersData = [];
				setIsEmptyData(true);
			}

			if (!allPerformersButton.checked) {
				setCheckedPerformers(
					checkedPerformers.filter(
						(item) => item !== allPerformersButton.performer
					)
				);

				setPerformerIds(
					performerIds.filter((item) => item !== allPerformersButton.id)
				);
			}

			setPerformers(performersData);
			setIsShowSkeleton(false);
			setIsLoadedPerformers(true);
		});
	};

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}

		if (searchValue.length >= 3 || !searchValue.length) {
			getPerformers();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateFrom, dateTo, searchValue, lang]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSearchHandler = useCallback(
		debounce(handleSearchInput, 500),
		[]
	);

	const handleCheckedPerformer = (checkedPerformer) => {
		let mappedPerformers;

		if (checkedPerformer.id === 0 && checkedPerformer.checked) {
			mappedPerformers = performers.map((performer) => ({
				...performer,
				checked: false,
			}));

			setCheckedPerformers([]);
			setPerformerIds([]);
		}

		if (checkedPerformer.id === 0 && !checkedPerformer.checked) {
			mappedPerformers = performers.map((performer) => ({
				...performer,
				checked: true,
			}));

			setCheckedPerformers(
				mappedPerformers.map((performerName) => performerName.performer)
			);
			setPerformerIds(mappedPerformers.map((performer) => performer.artistId));
		}

		if (!(checkedPerformer?.id === 0) && checkedPerformer.checked) {
			mappedPerformers = performers.map((performerName) => {
				if (performerName.id === 0) {
					return { ...performerName, checked: false };
				}

				if (performerName.performer === checkedPerformer.performer) {
					return { ...performerName, checked: false };
				} else {
					return performerName;
				}
			});

			setCheckedPerformers(
				checkedPerformers.filter(
					(performer) =>
						performer !== checkedPerformer.performer &&
						performer !== (lang === 'en' ? 'Select all' : 'Выбрать все')
				)
			);

			setPerformerIds(
				performerIds.filter((id) => id !== checkedPerformer.artistId)
			);
		}

		if (!(checkedPerformer?.id === 0) && !checkedPerformer.checked) {
			mappedPerformers = performers.map((performerName) => {
				if (performerName?.id) {
					return { ...performerName, checked: false };
				}

				if (performerName.performer === checkedPerformer.performer) {
					return { ...performerName, checked: true };
				} else {
					return performerName;
				}
			});

			setCheckedPerformers([
				...checkedPerformers.filter(
					(performer) =>
						performer !== (lang === 'en' ? 'Select all' : 'Выбрать все')
				),
				checkedPerformer.performer,
			]);

			setPerformerIds([
				...performerIds.filter((performer) => performer !== 0),
				checkedPerformer.artistId,
			]);
		}

		setPerformers(mappedPerformers);
	};

	const handleClickOnly = (e, performer) => {
		e.stopPropagation();

		const mappedPerformer = performers.map((item) => ({
			...item,
			checked: item.performer === performer.performer,
		}));

		setCheckedPerformers([performer.performer]);
		setPerformerIds([performer.artistId]);
		setPerformers(mappedPerformer);
	};

	const handleSelectClose = () => {
		if (cachedPerformers !== checkedPerformers) {
			setIsLoadedRecordings(false);
			setPerformersState(
				checkedPerformers.filter(
					(performerName) =>
						performerName !== (lang === 'en' ? 'Select all' : 'Выбрать все')
				)
			);

			setPerformersIds(performerIds.filter((id) => id !== 0));
		}
	};

	const handleResetFilter = () => {
		setCheckedPerformers([]);
		setPerformerIds([]);
		setPerformersState([]);
		setIsShowSkeleton(true);
		setPerformers([]);
		setSearchValue('');
		setIsResetPerformers(true);
	};

	const handleSelectOpen = () => {
		setCachedPerformers(checkedPerformers);
	};

	useEffect(() => {
		if (!checkedPerformers.length && !performers.length && !isEmptyData) {
			getPerformers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkedPerformers, performers, isEmptyData]);

	const valueForInput = () => {
		if (isShowSkeleton || !isLoadedPerformers) {
			return [0];
		} else if (!checkedPerformers.length) {
			return [];
		} else if (checkedPerformers.length) {
			return checkedPerformers;
		} else {
			return [];
		}
	};

	return (
		<FormControl variant="standard" className={s.formControl}>
			<Input id="performers-select-label">
				<FormattedMessage id="rod.statistic.performers" />
			</Input>
			<SelectCustom
				labelId="performers-select-label"
				id="performers-select"
				multiple
				value={valueForInput()}
				onOpen={handleSelectOpen}
				onClose={handleSelectClose}
				disabled={isShowSkeleton}
				MenuProps={{
					style: { zIndex: 99999999999 },
				}}
				renderValue={() => {
					if (isShowSkeleton) {
						return <FormattedMessage id={'rod.statistic.updating-data'} />;
					}

					if (!performers.length) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} /> 0{' '}
								<FormattedMessage id={'rod.statistic.selected-performers'} />
							</>
						);
					}

					if (checkedPerformers.length === 1) {
						return <span>{checkedPerformers[0]}</span>;
					} else {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} />{' '}
								{checkedPerformers.includes('Выбрать все' || 'Select all')
									? checkedPerformers.length - 1
									: checkedPerformers.length}{' '}
								<FormattedMessage id={'rod.statistic.selected-performers'} />
							</>
						);
					}
				}}
			>
				<FormattedMessage id="rod.statistic.search-performers">
					{(label) => (
						<TextFieldInput
							className={s.search}
							autoFocus={false}
							label={label}
							initialValue={searchValue ? searchValue : ''}
							defaultValue={searchValue}
							clearOnBlur={false}
							onChange={debouncedSearchHandler}
							variant="standard"
						/>
					)}
				</FormattedMessage>

				<div className={s.wrapper}>
					{!isShowSkeleton ? (
						performers.map((performer) => (
							<MenuItem
								key={performer.performer}
								value={performer.performer}
								onClick={() => handleCheckedPerformer(performer)}
							>
								<CheckboxBlack
									checked={checkedPerformers.includes(performer.performer)}
								/>
								<Label>{performer.performer}</Label>
								{performer.id !== 0 && (
									<button
										className={s.btnOnly}
										onClick={(e) => handleClickOnly(e, performer)}
									>
										<FormattedMessage id={'rod.statistic.only'} />
									</button>
								)}
							</MenuItem>
						))
					) : (
						<FilterSkeleton />
					)}

					{!performers.length && !isShowSkeleton && (
						<p className={s.noData}>
							<FormattedMessage id={'rod.no_data_select'} />
						</p>
					)}
				</div>
			</SelectCustom>

			{checkedPerformers.length > 100 && !checkedPerformers.includes(0) && (
				<p className={s.validateInput}>
					<FormattedMessage id={'rod.statistic.counter-performers.error'} />
				</p>
			)}

			<button
				className={s.buttonResetFilter}
				onClick={handleResetFilter}
				style={
					!performers.length && isShowSkeleton
						? { opacity: '0.4', pointerEvents: 'none' }
						: {}
				}
			>
				<FormattedMessage id={'rod.statistic.reset-filter'} />
			</button>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(FilterPerformers);
