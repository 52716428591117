// Core
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Utils
import { formatTime } from '../utils/formatTime';

// Context
import { AuthContext } from 'contexts/AuthContext';

// Utils
import { showError as showPhoneError } from 'validators/showError';
import { showError } from 'components/Inputs/CodeInputRegister/utils/codeInputUtils';
import { getPhone } from './utils/getPhone';
import { reachGoal } from 'utils';
import { EVENTS } from 'utils/reachParams';

// UI
import { Input, PhoneInput } from 'components/Inputs';
import { Button } from 'components/Buttons';
import { Label } from 'components/Labels';

// Styles
import styles from 'forms/auth/Registration/SMSCheckForm/RegisterVerifySMS.module.css';

const RegisterVerifySMS = ({ onSubmit, onResend }) => {
	const codeRef = useRef(null);
	const countryId = localStorage.getItem('countryId');
	const {
		user,
		errors,
		registrationUpdate,
		clearAllErrors,
		countEqualLimitSms,
		regUpdateErrors,
		clearRegUpdateErrors,
	} = useContext(AuthContext);
	const MINUTE = 60;

	const [phone, setPhone] = useState(getPhone(user.phone) ?? '');
	const [phoneDisabled, setPhoneDisabled] = useState(true);
	const [phoneSecondsLeft, setPhoneSecondsLeft] = useState(MINUTE);
	const [codeSecondsLeft, setCodeSecondsLeft] = useState(MINUTE);
	const [showChangePhoneButton, setShowChangePhoneButton] = useState(false);
	const [showResendButton, setShowResendButton] = useState(false);

	useEffect(() => {
		clearRegUpdateErrors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (phoneSecondsLeft > 0) {
			const timer = setInterval(() => {
				setPhoneSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			if (
				Object.keys(regUpdateErrors) &&
				regUpdateErrors?.phone &&
				regUpdateErrors?.phone[0]?.rule === 'forbidden'
			) {
				setShowChangePhoneButton(false);
			} else {
				setShowChangePhoneButton(true);
			}
		}
	}, [phoneSecondsLeft, regUpdateErrors]);

	useEffect(() => {
		if (codeSecondsLeft > 0) {
			const timer = setInterval(() => {
				setCodeSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			if (
				Object.keys(errors) &&
				errors?.code &&
				errors?.code[0]?.rule === 'forbidden'
			) {
				setShowResendButton(false);
			} else {
				setShowResendButton(true);
			}
		}
	}, [codeSecondsLeft, errors]);

	useEffect(() => {
		if (
			Object.keys(regUpdateErrors) &&
			regUpdateErrors?.phone &&
			(regUpdateErrors.phone[0]?.rule === 'min_length' ||
				regUpdateErrors.phone[0]?.rule === 'max_length' ||
				regUpdateErrors.phone === 'exists_in_user')
		) {
			setPhoneSecondsLeft(0);
			setShowChangePhoneButton(false);
			setPhoneDisabled(false);
		}
	}, [regUpdateErrors]);

	useEffect(() => {
		if (
			Object.keys(errors) &&
			errors?.code &&
			errors?.code[0]?.rule === 'forbidden'
		) {
			setCodeSecondsLeft(0);
			setShowResendButton(false);
		}
	}, [errors]);

	const handleChangePhone = (event) => {
		event.preventDefault();
		setPhoneDisabled(false);
		clearAllErrors();
	};

	const onChangePhone = (data) => {
		setPhone(data.number);
		clearRegUpdateErrors();
	};

	const onChange = () => {
		clearAllErrors();
	};

	const handleSendCode = () => {
		setPhoneDisabled(true);
		setPhoneSecondsLeft(MINUTE);
		setCodeSecondsLeft(MINUTE);
		setShowChangePhoneButton(false);
		setShowResendButton(false);
		const phonePayload = `${localStorage.getItem('code')}${phone}`;
		try {
			registrationUpdate(undefined, phonePayload);
		} catch (error) {
			console.error(error);
		}
	};

	const handleResend = () => {
		codeRef.current.value = '';
		clearAllErrors();
		setCodeSecondsLeft(MINUTE);
		setShowResendButton(false);
		if (onResend) {
			const phonePayload = `${localStorage.getItem('code')}${phone}`;
			onResend(phonePayload);
		}
	};

	const handleConfirm = () => {
		reachGoal(EVENTS.submitRequestSms);
		if (onSubmit) {
			onSubmit({
				code: codeRef.current.value,
			});
		}
	};

	const handleBlur = () => {
		if (user.phone === phone) {
			setPhoneDisabled(true);
			setShowChangePhoneButton(true);
		}
	};

	return (
		<form className={styles.Form}>
			<div className={styles.Title}>
				<Label className={styles.verifySms__header}>
					<FormattedMessage id={'rod.verify_sms.header'} />
				</Label>
				<Label className={styles.verifySms__subheader}>
					<FormattedMessage id={'rod.verify_sms.subtitle'} />
				</Label>
			</div>

			<div className={styles.Fields}>
				<div className={styles.phone}>
					<FormattedHTMLMessage id={'rod.field.phone.helper'}>
						{(helper) => {
							return (
								<div className={styles.codeInput}>
									<FormattedMessage id={'rod.field.phone'}>
										{(placeholder) => (
											<PhoneInput
												className={styles.phoneInput}
												name={'phone'}
												countryId={+countryId}
												phone={phone}
												showError={showPhoneError('phone')}
												placeholder={placeholder}
												errors={regUpdateErrors?.phone}
												onBlur={handleBlur}
												helper={
													!showChangePhoneButton && !countEqualLimitSms
														? `${helper} ${
																phoneSecondsLeft > 0
																	? formatTime(phoneSecondsLeft)
																	: '0:00'
														  }`
														: undefined
												}
												onChange={onChangePhone}
												disabled={phoneDisabled || !showChangePhoneButton}
												registerPhone
												forbidEnter
											/>
										)}
									</FormattedMessage>
									{showChangePhoneButton &&
										phoneDisabled &&
										!countEqualLimitSms && (
											<button
												className={styles.resendBtn}
												onClick={(e) => handleChangePhone(e)}
												style={
													Object.keys(regUpdateErrors).length > 0 &&
													regUpdateErrors.phone
														? { bottom: '5px' }
														: {}
												}
											>
												<FormattedMessage id={'rod.field.phone.change'} />
											</button>
										)}
								</div>
							);
						}}
					</FormattedHTMLMessage>
				</div>
				<FormattedHTMLMessage id={'rod.field.sms.helper'}>
					{(helper) => {
						return (
							<div className={styles.codeInput}>
								<FormattedMessage id="rod.verify_sms_call.placeholder">
									{(placeholder) => (
										<Input
											forwardRef={codeRef}
											className={
												!phoneDisabled
													? `${styles.Input} ${styles.disabled}`
													: `${styles.Input}`
											}
											placeholder={placeholder}
											clearInputIfHasError={true}
											errors={errors.code}
											onChange={(s) => {
												onChange(s);
											}}
											name={'code'}
											showError={(error) => showError(error, false, true)}
											helper={
												!showResendButton
													? `${helper} ${
															codeSecondsLeft > 0
																? formatTime(codeSecondsLeft)
																: '0:00'
													  }`
													: undefined
											}
											disabled={
												!phoneDisabled ||
												(Object.keys(errors) &&
													errors?.code &&
													(errors.code[0]?.rule === 'code_exhausted' ||
														errors.code[0]?.rule === 'forbidden' ||
														(errors.code[0]?.rule === 'invalid' &&
															errors.code[0]?.remaining === 0)))
											}
											forbidEnter
										/>
									)}
								</FormattedMessage>
								{showResendButton && phoneDisabled && (
									<button
										className={styles.resendBtn}
										style={
											Object.keys(errors).length > 0 &&
											errors.code &&
											showResendButton
												? { bottom: '5px' }
												: {}
										}
										onClick={handleResend}
									>
										<FormattedHTMLMessage id={'rod.verify_sms.send_again'} />
									</button>
								)}
							</div>
						);
					}}
				</FormattedHTMLMessage>
			</div>
			<div className={styles.Controls}>
				<Button
					className={styles.Button_send}
					text={
						<FormattedMessage
							id={
								!phoneDisabled ? 'rod.verify.send_code' : 'rod.action.confirm'
							}
						/>
					}
					onClick={!phoneDisabled ? handleSendCode : handleConfirm}
					disabled={
						(phoneDisabled && !codeRef?.current?.value) ||
						(!phoneDisabled && !phone) ||
						(phoneDisabled &&
							Object.keys(errors) &&
							errors?.code &&
							(errors.code[0]?.rule === 'code_exhausted' ||
								errors.code[0]?.rule === 'forbidden'))
					}
					primary
				/>
				{Object.keys(errors) &&
					errors?.code &&
					(errors.code[0]?.rule === 'code_exhausted' ||
						errors.code[0]?.rule === 'forbidden' ||
						(errors.code[0]?.rule === 'invalid' &&
							errors.code[0]?.remaining === 0)) && (
						<Button
							className={styles.Button_resend}
							to={'/registration/sms/nocode'}
							text={<FormattedMessage id={'rod.verify_sms.no_code'} />}
							link
						/>
					)}
			</div>
		</form>
	);
};

export default RegisterVerifySMS;
