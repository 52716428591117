// Core
import React, { useEffect, useState } from 'react';

// Utils
import { getDateData } from 'material-design/ReleaseCreate/steps/Distribution/utils/getDateData.js';

// UI
import DistributionModal from '../DistributionModal';
import DateTimeZone from '../DateTimeZone/DateTimeZone';
import MuiMultipleCheckedDistributionOutlets from 'material-design/components/MuiMultipleCheckedDistributionOutlets/MuiMultipleCheckedDistributionOutlets';

// Styles
import styles from './DistributionOutletsModal.module.css';

const DistributionOutletsModal = ({
	item,
	outlets,
	onClose,
	personal,
	setPersonal,
	resetErrors,
	resetBackErrors,
}) => {
	const [outletsArr, setOutletsArr] = useState([]);
	const [data, setData] = useState({
		selected_outlets: [],
		delivery_date: null,
		delivery_time: '00:00',
		time_zone: '+03:00',
	});
	const [errors, setErrors] = useState({});

	const minDate =
		item.value === '7+'
			? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
			: new Date(Date.now() + 24 * 60 * 60 * 1000);

	const handleCheckedOutlets = (outlets) => {
		const ids = outlets.map((item) => item.id);
		setData({ ...data, selected_outlets: ids });
	};

	const setDeliveryTime = (value) => {
		setErrors({});
		setData({ ...data, delivery_time: value });
	};

	const changeField = (field) => (e) => {
		setErrors({});

		switch (field) {
			case 'time_zone':
				setData({ ...data, time_zone: e.id });
				break;
			default:
				setData({ ...data, [field]: e });
				break;
		}
	};

	const submitHandler = () => {
		const { selected_outlets, delivery_date, delivery_time, time_zone } = data;

		const minDateStartDay = minDate.setHours(0, 0, 0, 0);
		const deliveryDateObj = new Date(delivery_date);
		const deliveryDateObjWithTime = new Date(
			`${delivery_date}T${delivery_time}:00${time_zone}`
		);
		const saleDateObjWithTime = new Date(personal.sale_start_date);
		const deliveryStartTime = `${delivery_date}T${delivery_time}:00${time_zone}`;

		const currentErrors = {};

		if (deliveryDateObj.setHours(0, 0, 0, 0) < minDateStartDay) {
			currentErrors.delivery_date =
				item.value === '7+'
					? 'delivery_min_date_seven'
					: 'delivery_min_date_one';
		}

		if (deliveryDateObjWithTime >= saleDateObjWithTime) {
			currentErrors.delivery_date = 'delivery_later_sale';
		}

		if (Object.keys(currentErrors).length > 0) {
			setErrors(currentErrors);
		} else {
			setErrors({});

			if (resetErrors && resetBackErrors) {
				resetErrors();
				resetBackErrors();
			}

			setPersonal({
				...personal,
				distribution_outlets: [
					{
						delivery_start_time: deliveryStartTime,
						outlets: selected_outlets,
					},
				],
			});
			onClose();
		}
	};

	useEffect(() => {
		const filteredOutlets = outlets.filter((item) => !item.is_recommended);

		if (personal.distribution_outlets?.length > 0) {
			setData({
				...data,
				selected_outlets: personal.distribution_outlets[0].outlets,
			});

			const checkedArr = filteredOutlets.map((item) => {
				return {
					...item,
					checked:
						typeof personal.distribution_outlets[0].outlets === 'string' ||
						(Array.isArray(personal.distribution_outlets[0].outlets) &&
							personal.distribution_outlets[0].outlets.every(
								(item) => typeof item === 'string'
							))
							? personal.distribution_outlets[0].outlets.includes(
									item.id.toString()
							  )
							: personal.distribution_outlets[0].outlets.includes(item.id),
				};
			});
			setOutletsArr(checkedArr);
		} else {
			const checkedArr = filteredOutlets.map((item) => {
				return {
					...item,
					checked: false,
				};
			});
			setOutletsArr(checkedArr);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const updatedData = { ...data };

		if (
			personal.distribution_outlets &&
			personal.distribution_outlets[0]?.delivery_start_time
		) {
			const { date, time, gmt } = getDateData(
				personal.distribution_outlets[0].delivery_start_time
			);
			updatedData.delivery_date = date;
			updatedData.delivery_time = time;
			updatedData.time_zone = gmt || null;
		}

		setData(updatedData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DistributionModal
			title="rod.distribution.outlet.modal.title"
			onClose={onClose}
			disableSubmitBtn={
				!data.delivery_date ||
				!data.delivery_time ||
				!data.time_zone ||
				!data.selected_outlets.length ||
				outletsArr.length === data.selected_outlets.length ||
				Object.keys(errors).length > 0
			}
			submitHandler={submitHandler}
		>
			<div className={styles.outletsSelect}>
				<MuiMultipleCheckedDistributionOutlets
					handleCheckedOutlets={handleCheckedOutlets}
					outlets={outletsArr}
				/>
			</div>
			<DateTimeZone
				data={data}
				changeField={changeField}
				setDeliveryTimeInHHMM={setDeliveryTime}
				itemValue={item.value}
				errors={errors}
			/>
		</DistributionModal>
	);
};

export default DistributionOutletsModal;
