const codeFormattedOutlets = [
	{ outletCode: 'apple', titleRu: 'Apple', titleEn: 'Apple' },
	{ outletCode: 'spotify', titleRu: 'Spotify', titleEn: 'Spotify' },
	{
		outletCode: 'zvuk',
		titleRu: 'Zvuk (СберЗвук)',
		titleEn: 'Zvuk (SberZvuk)',
	},
	{ outletCode: 'vk', titleRu: 'VK Музыка', titleEn: 'VK Music' },
	{ outletCode: 'ok', titleRu: 'Одноклассники', titleEn: 'Odnolkassniki' },
	{ outletCode: 'yandex', titleRu: 'Яндекс.Музыка', titleEn: 'Yandex.Music' },
	{ outletCode: 'deezer', titleRu: 'Deezer', titleEn: 'Deezer' },
	{ outletCode: 'youtube', titleRu: 'YouTube', titleEn: 'YouTube' },
	{ outletCode: 'tiktok', titleRu: 'TikTok', titleEn: 'TikTok' },
	{ outletCode: 'facebook', titleRu: 'Facebook', titleEn: 'Facebook' },
	{ outletCode: 'instagram', titleRu: 'Instagram', titleEn: 'Instagram' },
	{ outletCode: 'others', titleRu: 'Другие', titleEn: 'Others' },
];

export default codeFormattedOutlets;
