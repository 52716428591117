// Core
import React, { useContext } from 'react';

// Context
import { RootContext } from 'contexts/RootContext';

// Utils
import { fm } from 'utils';
import { statusLocalization } from '../utils/statusLocalization';

// UI
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

// Styles
import styles from '../AccountData.module.css';

const AccountFiles = ({
	account,
	moderationStatus,
	isDocumentsControlsEnabled,
	handleSubmit,
	toggleModerationStatus,
	completionStep,
	prevCompletionStep,
}) => {
	const { getDocumentTitle } = useContext(RootContext);

	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.admin.account.files.documents')}
					font="--gilroy-Medium-32"
				/>
			</div>
			<table className={styles.Table}>
				<thead>
					<tr>
						<th>{fm('rod.admin.account.files.document_type')}</th>
						<th>{fm('rod.admin.account.files.file')}</th>
						<th className="cb">{fm('rod.admin.account.files.quality')}</th>
						<th className="cb">{fm('rod.admin.account.files.data_match')}</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(account.files).map((key) => (
						<tr>
							<td>{getDocumentTitle(key)}</td>
							<td
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<a
									href={account.files[key].url}
									target="_blank"
									rel="noopener noreferrer"
								>
									{account.files[key].client_name}
								</a>
								<a
									href={account.files[key].url}
									target="_blank"
									rel="noreferrer"
									style={{
										marginLeft: 'auto',
									}}
								>
									<i className="fa fa-download" />
								</a>
							</td>
							<td className="cb">
								<Checkbox
									className={styles.Checkbox}
									checked={
										moderationStatus[key]
											? moderationStatus[key]['quality']
											: false
									}
									disabled={!isDocumentsControlsEnabled}
									onChange={toggleModerationStatus(key, 'quality')}
								/>
							</td>
							<td className="cb">
								<Checkbox
									className={styles.Checkbox}
									disabled={!isDocumentsControlsEnabled}
									checked={
										moderationStatus[key]
											? moderationStatus[key]['valid']
											: false
									}
									onChange={toggleModerationStatus(key, 'valid')}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{(prevCompletionStep === accountCompletionSteps.PENDING ||
				prevCompletionStep === accountCompletionSteps.APPROVED ||
				prevCompletionStep === accountCompletionSteps.DECLINED ||
				prevCompletionStep === accountCompletionSteps.DECLINED_DOCUMENTS) && (
				<div>
					<div>
						{fm('rod.admin.account.files.set_status')}
						{': '}
						{statusLocalization(completionStep)}
					</div>
					<Button
						primary
						text={
							prevCompletionStep === accountCompletionSteps.APPROVED
								? fm('rod.admin.account.files.approved')
								: prevCompletionStep ===
								  accountCompletionSteps.DECLINED_DOCUMENTS
								? fm('rod.admin.account.files.sent')
								: fm('rod.admin.account.files.to_send')
						}
						disabled={!isDocumentsControlsEnabled}
						className={styles.Button}
						onClick={handleSubmit}
					/>
				</div>
			)}
		</section>
	);
};

export default AccountFiles;
