import React from 'react';
import styles from './SkeletonDetailHeading.module.css';

const SkeletonDetailHeading = ({ typePage }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.photo}></div>
			<div className={styles.info}>
				<div className={styles.title}></div>
				<div className={styles.attr}></div>
				{typePage !== 'artist' && <div className={styles.list}></div>}
			</div>
		</div>
	);
};

export default SkeletonDetailHeading;
