// Core
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// UI
import Flag from 'components/Flag';
import { Col, Row } from 'react-grid-system';
import EmptyContract from './EmptyContract/EmptyContract';

// Icons
import worldwide from 'images/worldwide.svg';

// Styles
import s from '../TreatiesPage.module.css';

const ContractWrapper = ({ item, correctData, countries }) => {
	const history = useHistory();

	return (
		<div className={s.main__wrapper}>
			{item.conditions && item.conditions.length > 0 ? (
				<div className={s.main}>
					{item.conditions.map((contact, index) => (
						<div key={index} className={s.item__body}>
							<span
								className={s.item__title}
								onClick={() =>
									history.push(
										`/treaties/info/${item.id}/condition/${contact.id}`
									)
								}
							>
								<FormattedMessage id={'rod.composition.contract.condition'} />
								{` ${index + 1}: ${contact.title}`}
							</span>
							<Row className={s.datesSection}>
								<Col className={s.dateColumn}>
									<div className={s.table__item}>
										<div className={s.condition__title}>
											<FormattedMessage id={'rod.treaties_page.dates'} />
										</div>

										<div
											className={s.contact__last}
											style={{
												textDecoration:
													contact.is_terminated || contact.is_expired
														? 'line-through'
														: '',
												color:
													contact.is_terminated || contact.is_expired
														? 'var(--color-grey100)'
														: '',
											}}
										>
											{contact.begins ? correctData(contact.begins) : '...'} -{' '}
											{contact.ends ? (
												<span
													className={
														contact.expires_soon ? s.expires : s.normal
													}
												>
													{correctData(contact.ends)}
												</span>
											) : (
												'...'
											)}
										</div>
										{contact.is_terminated ? (
											<div className={s.table__item}>
												<div className={s.contact__last}>
													{contact.begins ? correctData(contact.begins) : '...'}{' '}
													-{' '}
													{contact.is_terminated ? (
														<span
															className={
																contact.expires_soon ? s.expires : s.normal
															}
														>
															{correctData(contact.termination_date)}
														</span>
													) : (
														'...'
													)}
												</div>
											</div>
										) : (
											''
										)}
									</div>
								</Col>
								<Col className={s.dateColumn}>
									<div className={s.table__item}>
										<div className={''}>
											<div className={s.condition__title}>
												<FormattedMessage id={'rod.treaties_page.collection'} />
											</div>
											<div
												className={s.contact__last}
												style={{
													textDecoration:
														contact.is_collection_expired
															? 'line-through'
															: '',
													color:
														contact.is_collection_expired
															? 'var(--color-grey100)'
															: '',
												}}
											>
												{contact.collection_begins
													? correctData(contact.collection_begins)
													: '...'}{' '}
												-{' '}
												{contact.collection_ends ? (
													<span
														className={
															contact.is_collection_expires_soon
																? s.expires
																: s.normal
														}
													>
														{correctData(contact.collection_ends)}
													</span>
												) : (
													'...'
												)}
											</div>
											{contact.is_terminated ? (
												<div className={s.table__item}>
													<div className={''}>
														<div className={s.contact__last}>
															{contact.collection_begins
																? correctData(contact.collection_begins)
																: '...'}{' '}
															-{' '}
															{contact.is_terminated ? (
																<span
																	className={
																		contact.is_collection_expires_soon
																			? s.expires
																			: s.normal
																	}
																>
																	{correctData(contact.termination_date)}
																</span>
															) : (
																'...'
															)}
														</div>
													</div>
												</div>
											) : (
												''
											)}
										</div>
									</div>
								</Col>
								<Col>
									<div className={s.territory__title}>
										<FormattedMessage id={'rod.contracts.regions'} />
									</div>

									{contact.territory?.include &&
									contact.territory?.include?.length &&
									contact.territory?.include?.includes(17) ? (
										<div className={s.country}>
											<span style={{ textTransform: 'lowercase' }}>
												<FormattedMessage id={'rod.contracts.whole_world'} />
											</span>
											<img src={worldwide} alt="" />
										</div>
									) : (
										<div className={s.flagsBox}>
											{contact.territory?.include &&
												contact.territory?.include?.length &&
												contact.territory?.include?.map((territory) => {
													const code = countries.find(
														(country) => country.id === territory
													);

													return code ? (
														<Flag code={code.code} className={s.flag} />
													) : (
														<></>
													);
												})}
										</div>
									)}
								</Col>
								<Col>
									<div className={s.territory__title}>
										<FormattedMessage id={'rod.contracts.regions_excluded'} />
									</div>

									{contact.territory?.exclude &&
									contact.territory?.exclude?.length &&
									contact.territory?.exclude?.includes(17) ? (
										<div className={s.country}>
											<img src={worldwide} alt="" />
											<span style={{ textTransform: 'lowercase' }}>
												<FormattedMessage id={'rod.contracts.whole_world'} />
											</span>
										</div>
									) : (
										<div className={s.flagsBox}>
											{contact.territory?.exclude &&
											contact.territory?.exclude?.length
												? contact.territory?.exclude?.map((territory) => {
														const code = countries.find(
															(country) => country.id === territory
														);

														return code ? (
															<Flag code={code.code} className={s.flag} />
														) : (
															<></>
														);
												  })
												: '\u2212'}
										</div>
									)}
								</Col>
							</Row>

							<div className={s.dates}></div>
						</div>
					))}
				</div>
			) : (
				<EmptyContract />
			)}
		</div>
	);
};

export default ContractWrapper;
