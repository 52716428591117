import React from 'react';

import styles from './ContentPage.module.css';
import Recording from './Recording/Recording';
import Audience from '../../Audience/Audience';
import Sources from '../../Sources/Sources';

const ContentPage = ({ accountId, tab }) => {
	const renderScreen = (tab, accountId) => {
		switch (tab) {
			case 'recording':
				return <Recording accountId={accountId} />;
			case 'audience':
				return <Audience accountId={accountId} typePage={'recording'} />;
			case 'sources':
				return <Sources accountId={accountId} typePage={'recording'} />;
			default:
				return <Recording accountId={accountId} />;
		}
	};

	return <div className={styles.wrapper}>{renderScreen(tab, accountId)}</div>;
};

export default ContentPage;
