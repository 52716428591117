// Core
import React, { useContext } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// Context
import { RootContext } from 'contexts/RootContext';

// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from '../utils/isDataValidOrDash';

// UI
import Field from 'pages/adminV2/components/Field/Field';
import { Label } from 'components/Labels';

// Styles
import styles from '../AccountData.module.css';

const AccountPersonalDataCompany = ({ account }) => {
	const { getCountryById } = useContext(RootContext);

	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.admin.account.company_data')}
					font="--gilroy-Medium-32"
				/>
			</div>
			<Field
				title={fm('rod.admin.account.company_name')}
				value={isDataValidOrDash(account.personal_data.title)}
			/>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.behalf')}
						value={isDataValidOrDash(account.personal_data.behalf)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.behalf_short')}
						value={isDataValidOrDash(account.personal_data.behalf_short)}
					/>
				</Col>
				{account.personal_data.attorney && (
					<Col sm={4}>
						<Field
							title={fm('rod.admin.account.attorney')}
							value={isDataValidOrDash(account.personal_data.attorney)}
						/>
					</Col>
				)}
			</Row>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.signature_position')}
						value={isDataValidOrDash(account.personal_data.signature_position)}
					/>
				</Col>
				<Col sm={6}>
					<Field
						title={fm('rod.admin.account.acting_on')}
						value={
							account.personal_data.acting_on ? (
								<FormattedMessage
									id={`rod.field.${account.personal_data.acting_on}`}
								/>
							) : (
								'\u2212'
							)
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.company_reg_number')}
						value={isDataValidOrDash(
							account.personal_data.company_registration_number
						)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.company_reg_date')}
						value={isDataValidOrDash(
							account.personal_data.company_registration_date
						)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.country_id')}
						value={
							account.personal_data.country_id
								? getCountryById(account.personal_data.country_id)
								: '\u2212'
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.itin')}
						value={isDataValidOrDash(account.personal_data.itin)}
					/>
				</Col>
				<Col sm={6}>
					<Field
						title={fm('rod.admin.account.company_vat_number')}
						value={isDataValidOrDash(account.personal_data.company_vat_number)}
					/>
				</Col>
			</Row>
		</section>
	);
};

export default AccountPersonalDataCompany;
