// Core
import { useContext, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import {
	CustomSVGSeries,
	Hint,
	HorizontalBarSeries,
	VerticalGridLines,
	XAxis,
	XYPlot,
	YAxis,
} from 'react-vis';
import { FormattedMessage } from 'react-intl';

import { LangContext } from 'contexts/LangContext';

// Styles
import styles from './Graphic.module.css';

// Utils
import useWindowSize from 'utils/useResize';

const Graphic = ({ data }) => {
	const { lang } = useContext(LangContext);
	const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
	const screenSize = useWindowSize();
	const [highlightedIndex, setHighlightedIndex] = useState(null);
	const [highlightedElement, setHighlightedElement] = useState(null);
	const [elements, setElements] = useState([]);
	const containerRef = useRef(null);
	const debouncedSetHighlighted = debounce(
		(highlighted) => setHighlightedIndex(highlighted),
		100
	);

	useEffect(() => {
		let topLimitItems = data;

		topLimitItems = topLimitItems.map((item) => ({
			...item,
			auditions: item.total_percent,
			totalAuditions: item.total_streams,
		}));

		topLimitItems = topLimitItems.map((item) => {
			return {
				...item,
				x: item.total_percent,
				y:
					item?.ageGroup === 'u'
						? lang === 'ru'
							? 'неизвестно'
							: 'undefined'
						: item?.ageGroup,
				totalAuditions:
					item.totalAuditions ?? parseInt(item?.auditions).toLocaleString(),
				customComponent: (row, positionInPixels) => {
					return (
						<text
							className={styles.clickable_text}
							opacity={row.opacity}
							color="#1E1E1E"
							textAnchor={positionInPixels.x > 60 ? 'end' : 'start'}
							fontSize={11}
							x={positionInPixels.x < 60 ? '4px' : '-10px'}
							y={3}
						>
							{row.auditions}%
						</text>
					);
				},
			};
		});

		setElements(topLimitItems);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, lang]);

	useEffect(() => {
		if (containerRef?.current?.offsetWidth) {
			setChartSize({
				width: containerRef?.current?.offsetWidth,
				height: 276,
			});
		}
	}, [containerRef.current, screenSize]);

	const renderTick = (v) => {
		return (
			<tspan
				onMouseEnter={() => {
					setHighlightedElement({
						value: v,
						y: v,
						x: 0,
					});
				}}
				onMouseLeave={() => {
					setHighlightedElement(null);
				}}
				onMouseOut={() => {
					setHighlightedElement(null);
				}}
				style={{ cursor: 'pointer' }}
			>
				<tspan color="#888" fontSize={12} capitalize>
					{v.length > 6 ? `${v.substring(0, 4)}...` : v}
				</tspan>
			</tspan>
		);
	};

	return (
		<>
			<div
				className={styles.chart_container}
				ref={containerRef}
				onMouseOut={() => {
					setHighlightedElement(null);
				}}
			>
				<XYPlot
					animation
					width={chartSize.width}
					height={chartSize.height}
					margin={{ left: 50, right: 45, top: 0, bottom: 26 }}
					yType="ordinal"
					xDomain={[0, 100]}
				>
					<VerticalGridLines tickTotal={7} />
					<XAxis
						hideLine
						tickTotal={10}
						tickSizeOuter={0}
						tickFormat={(v) => `${v}%`}
					/>
					<YAxis
						tickFormat={renderTick}
						hideLine
						tickSizeOuter={0}
						tickTotal={elements.length}
					></YAxis>

					<HorizontalBarSeries
						colorType="literal"
						cluster="gender"
						barWidth={0.6}
						onValueMouseOver={(d, _e) => {
							if (d.x !== highlightedIndex?.x) {
								debouncedSetHighlighted(d);
							}
							if (highlightedElement) {
								setHighlightedElement(null);
							}
						}}
						onValueMouseOut={() => {
							debouncedSetHighlighted(null);
						}}
						data={elements.map((item, idx) => {
							return {
								index: idx,
								y:
									item?.ageGroup === 'u'
										? lang === 'ru'
											? 'неизвестно'
											: 'undefined'
										: item.ageGroup,
								x: Number(item.total_percent),
								totalAuditions: item.total_percent,
								color: 'var(--color-green)',
								m: item.m,
								f: item.f,
								total_percent: item.total_percent,
							};
						})}
					/>

					<HorizontalBarSeries
						colorType="literal"
						cluster="gender"
						barWidth={0.6}
						onValueMouseOver={(d, _e) => {
							if (d.x !== highlightedIndex?.x) {
								debouncedSetHighlighted(d);
							}
							if (highlightedElement) {
								setHighlightedElement(null);
							}
						}}
						onValueMouseOut={() => {
							debouncedSetHighlighted(null);
						}}
						data={elements.map((item, idx) => {
							return {
								index: idx,
								y:
									item.ageGroup === 'u'
										? lang === 'ru'
											? 'неизвестно'
											: 'undefined'
										: item.ageGroup,
								x: (item.f / 100) * item.total_percent,
								totalAuditions: (item.f / 100) * item.total_percent,
								color: 'var(--color-light-green)',
								m: item.m,
								f: item.f,
								total_percent: item.total_percent,
							};
						})}
					/>

					<CustomSVGSeries
						onValueMouseOver={(d, _e) => {
							debouncedSetHighlighted(d);
							setHighlightedElement(null);
						}}
						onValueMouseOut={() => debouncedSetHighlighted(null)}
						data={elements.map((item, idx) => {
							return {
								...item,
								index: idx,
								opacity: highlightedIndex?.index === idx ? 1 : 0.5,
							};
						})}
					/>

					{highlightedElement ? (
						<Hint
							align={{ horizontal: 'right', vertical: 'bottom' }}
							value={{ x: 0, y: highlightedElement?.y }}
						>
							<div className={styles.hint}>
								<p>{highlightedElement.value}</p>
							</div>
						</Hint>
					) : null}

					{highlightedIndex ? (
						<Hint
							key={highlightedIndex.index}
							align={{
								vertical: 'bottom',
								horizontal: 'auto',
							}}
							value={{ x: highlightedIndex?.x, y: highlightedIndex?.y }}
							style={{ zIndex: 2 }}
						>
							<div className={styles.hintTotal}>
								<div className={styles.hintHeading}>
									<span>
										<FormattedMessage id={'rod.statistic.trends.age'} />
									</span>
									<span>{highlightedIndex.y}</span>
								</div>
								<div className={styles.hintContent}>
									{highlightedIndex.total_percent > 0.1 ||
									highlightedIndex.total_percent === 0.1 ? (
										<>
											<div className={styles.hintContentHeading}>
												<span>
													<FormattedMessage id={'rod.statistic.trends.total'} />
												</span>
												<span>{highlightedIndex.total_percent}%</span>
											</div>

											<ul className={styles.listGenders}>
												<li className={styles.genderListElement}>
													<span className={styles.genderTitle}>
														<FormattedMessage
															id={'rod.statistic.trends.female'}
														/>
													</span>
													<span className={styles.genderPercent}>
														{highlightedIndex.f}%
													</span>
												</li>
												<li className={styles.genderListElement}>
													<span className={styles.genderTitle}>
														<FormattedMessage id={'rod.statistic.trends.men'} />
													</span>
													<span className={styles.genderPercent}>
														{highlightedIndex.m}%
													</span>
												</li>
											</ul>
										</>
									) : (
										<p className={styles.noData}>
											<FormattedMessage
												id={'rod.statistic.trends.empty-data'}
											/>
										</p>
									)}
								</div>
							</div>
						</Hint>
					) : null}
				</XYPlot>
			</div>
		</>
	);
};

export default Graphic;
