// Core
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';

// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from '../utils/isDataValidOrDash';
import { statusLocalization } from '../utils/statusLocalization';
import { getTooltip } from '../utils/getTooltip';

// UI
import TableHead from '../TableHead/TableHead';
import Field from 'pages/adminV2/components/Field/Field';
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

// Styles
import styles from '../AccountData.module.css';

const AccountContracts = ({
	account,
	additionalContracts,
	switchSignToPhysical,
	warnBlockNum,
	warnBlockNumAddContract,
	isContractsControlsEnabled,
	isSubmitPressed,
	moderationStatus,
	toggleModerationStatus,
	prevCompletionStep,
	statusLocalizationAdditional,
	handleSubmit,
	isAdditionalBtnDisabled,
	additionalSubmitBtnData,
	isDocumentsControlsEnabled,
	handleWarningBlockShow,
	completionStep,
}) => {
	return (
		<>
			<section>
				<div className={styles.HeaderLine}>
					<Label
						text={fm('rod.admin.account.contracts')}
						font="--gilroy-Medium-32"
					/>
				</div>
				<Row>
					<Col sm={6}>
						{account.step && (
							<Field
								title={fm('rod.admin.account.contracts.signing_variants')}
								value={
									account.step === accountCompletionSteps.SIGN_SMS ||
									account.step === accountCompletionSteps.SIGN_PHYSICAL ||
									account.step === accountCompletionSteps.PENDING_CONTRACT
										? account.step === accountCompletionSteps.SIGN_SMS
											? 'SMS'
											: fm('rod.admin.account.contracts.by_hand')
										: '\u2212'
								}
							/>
						)}
					</Col>
					<Col sm={6}>
						{account['allow_sign_change'] && !additionalContracts.length ? (
							<Button
								primary
								text={fm(
									'rod.admin.account.contracts.switch_to_by_hand_signing'
								)}
								onClick={() => switchSignToPhysical(account.personal_data.id)}
							/>
						) : (
							''
						)}
					</Col>
				</Row>

				<table className={styles.Table}>
					<TableHead />
					<tbody>
						{Object.keys(account.contracts).map((key, index) => {
							return !account.contracts[key].additional ? (
								<tr>
									<td>{account.contracts[key].type}</td>
									<td
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										{account.isSMSSign ? (
											<div>{account.contracts[key].client_name}</div>
										) : (
											<>
												{account.contracts[key].url ? (
													<Link
														to={`contracts/${account.contracts[
															key
														].type.replace('contract-', '')}/${key}`}
													>
														{account.contracts[key].client_name}
													</Link>
												) : (
													<span>{account.contracts[key].client_name}</span>
												)}
											</>
										)}

										{account.contracts[key].url && (
											<a
												href={account.contracts[key].url}
												target="_blank"
												rel="noreferrer"
												style={{
													marginLeft: 'auto',
												}}
											>
												<i className="fa fa-download" />
											</a>
										)}
									</td>
									<td className="cb">
										<div
											data-name={'1' + index}
											onClick={(e) => handleWarningBlockShow(e, key)}
											title={warnBlockNum === '1' + index ? getTooltip() : ''}
										>
											<Checkbox
												className={styles.Checkbox}
												disabled={
													!isContractsControlsEnabled ||
													isSubmitPressed ||
													moderationStatus[key]['sms']
												}
												checked={
													moderationStatus[key]
														? moderationStatus[key]['sms']
															? true
															: moderationStatus[key]['quality']
														: false
												}
												onChange={toggleModerationStatus(key, 'quality')}
											/>
										</div>
									</td>
									<td className="cb">
										<div
											data-name={'2' + index}
											onClick={(e) => handleWarningBlockShow(e, key)}
											title={warnBlockNum === '2' + index ? getTooltip() : ''}
										>
											<Checkbox
												className={styles.Checkbox}
												disabled={
													!isContractsControlsEnabled ||
													isSubmitPressed ||
													moderationStatus[key]['sms']
												}
												checked={
													moderationStatus[key]
														? moderationStatus[key]['sms']
															? true
															: moderationStatus[key]['signature']
														: false
												}
												onChange={toggleModerationStatus(key, 'signature')}
											/>
										</div>
									</td>
									<td className="cb">
										<div
											data-name={'3' + index}
											onClick={(e) => handleWarningBlockShow(e, key)}
											title={warnBlockNum === '3' + index ? getTooltip() : ''}
										>
											<Checkbox
												className={styles.Checkbox}
												disabled={
													!isContractsControlsEnabled ||
													isSubmitPressed ||
													moderationStatus[key]['sms']
												}
												checked={
													moderationStatus[key]
														? moderationStatus[key]['sms']
															? true
															: moderationStatus[key]['valid']
														: false
												}
												onChange={toggleModerationStatus(key, 'valid')}
											/>
										</div>
									</td>
								</tr>
							) : (
								''
							);
						})}
					</tbody>
				</table>

				{(prevCompletionStep === accountCompletionSteps.PENDING_CONTRACT ||
					prevCompletionStep === accountCompletionSteps.DECLINED_CONTRACTS ||
					prevCompletionStep === accountCompletionSteps.APPROVED_CONTRACTS) && (
					<div>
						<div>
							{fm('rod.admin.account.files.set_status')}
							{': '}
							{statusLocalization(completionStep)}
						</div>
						<Button
							primary
							text={
								prevCompletionStep === accountCompletionSteps.APPROVED_CONTRACTS
									? fm('rod.admin.account.files.approved')
									: prevCompletionStep ===
									  accountCompletionSteps.DECLINED_CONTRACTS
									? fm('rod.admin.account.files.sent')
									: fm('rod.admin.account.files.to_send')
							}
							disabled={
								!(isDocumentsControlsEnabled || isContractsControlsEnabled)
							}
							className={styles.Button}
							onClick={handleSubmit}
						/>
					</div>
				)}
				{account.sms &&
					account.sms.contract &&
					account.sms.contract.length > 0 && (
						<React.Fragment>
							<Field
								title={fm('rod.verify_sms.placeholder')}
								value={isDataValidOrDash(account.sms.contract)}
							/>
						</React.Fragment>
					)}
			</section>
			<section>
				<div className={styles.HeaderLine}>
					<Label
						text={fm('rod.field.additional_contracts')}
						font="--gilroy-Medium-32"
					/>
				</div>
				<Row>
					<Col sm={6}>
						{account.step && (
							<Field
								title={fm('rod.admin.account.contracts.signing_variants')}
								value={
									additionalContracts.length
										? account.isSMSSign
											? 'SMS'
											: fm('rod.admin.account.contracts.by_hand')
										: '\u2212'
								}
							/>
						)}
					</Col>
					<Col sm={6}>
						{account['allow_sign_change'] && additionalContracts.length ? (
							<Button
								primary
								text={fm(
									'rod.admin.account.contracts.switch_to_by_hand_signing'
								)}
								onClick={() => switchSignToPhysical(account.personal_data.id)}
							/>
						) : (
							''
						)}
					</Col>
				</Row>
				<table className={styles.Table}>
					<TableHead />
					<tbody>
						{Object.keys(account.contracts).map((key, index) => {
							return account.contracts[key].additional ? (
								<tr key={key}>
									<td>{isDataValidOrDash(account.contracts[key].type)}</td>
									<td
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										{account.isSMSSign ? (
											<div>
												{isDataValidOrDash(account.contracts[key].client_name)}
											</div>
										) : (
											<Link
												to={`contracts/${account.contracts[key].type.replace(
													'contract-',
													''
												)}/${key}`}
											>
												{isDataValidOrDash(account.contracts[key].client_name)}
											</Link>
										)}
										<a
											href={account.contracts[key].url}
											target="_blank"
											rel="noreferrer"
											style={{
												marginLeft: 'auto',
											}}
										>
											<i className="fa fa-download" />
										</a>
									</td>
									<td className="cb">
										<div
											data-name={'01' + index}
											onClick={(e) => handleWarningBlockShow(e, key)}
											title={
												warnBlockNumAddContract === '01' + index
													? getTooltip()
													: ''
											}
										>
											<Checkbox
												className={styles.Checkbox}
												disabled={
													!account.contracts[key].additional_contracts_signed ||
													isSubmitPressed ||
													moderationStatus[key]['sms']
												}
												checked={
													moderationStatus[key]
														? moderationStatus[key]['sms']
															? true
															: moderationStatus[key]['quality']
														: false
												}
												onChange={toggleModerationStatus(key, 'quality')}
											/>
										</div>
									</td>
									<td className="cb">
										<div
											data-name={'02' + index}
											onClick={(e) => handleWarningBlockShow(e, key)}
											title={
												warnBlockNumAddContract === '02' + index
													? getTooltip()
													: ''
											}
										>
											<Checkbox
												className={styles.Checkbox}
												disabled={
													!account.contracts[key].additional_contracts_signed ||
													isSubmitPressed ||
													moderationStatus[key]['sms']
												}
												checked={
													moderationStatus[key]
														? moderationStatus[key]['sms']
															? true
															: moderationStatus[key]['signature']
														: false
												}
												onChange={toggleModerationStatus(key, 'signature')}
											/>
										</div>
									</td>
									<td className="cb">
										<div
											data-name={'03' + index}
											onClick={(e) => handleWarningBlockShow(e, key)}
											title={
												warnBlockNumAddContract === '03' + index
													? getTooltip()
													: ''
											}
										>
											<Checkbox
												className={styles.Checkbox}
												disabled={
													!account.contracts[key].additional_contracts_signed ||
													isSubmitPressed ||
													moderationStatus[key]['sms']
												}
												checked={
													moderationStatus[key]
														? moderationStatus[key]['sms']
															? true
															: moderationStatus[key]['valid']
														: false
												}
												onChange={toggleModerationStatus(key, 'valid')}
											/>
										</div>
									</td>
								</tr>
							) : (
								''
							);
						})}
					</tbody>
				</table>

				{additionalContracts.length ? (
					<div>
						<div>
							{fm('rod.admin.account.files.set_status')}
							{': '}
							{statusLocalizationAdditional()
								? fm('rod.admin.account.files.approved')
								: fm('rod.repertoire.rejected')}
						</div>
						<Button
							primary
							text={
								additionalSubmitBtnData.message
									? additionalSubmitBtnData.message
									: fm('rod.admin.account.files.to_send')
							}
							disabled={isAdditionalBtnDisabled()}
							className={styles.Button}
							onClick={handleSubmit}
						/>
					</div>
				) : (
					''
				)}
				{account.sms &&
					account.sms.additional &&
					account.sms.additional.length > 0 && (
						<React.Fragment>
							<Field
								title={fm('rod.verify_sms.placeholder')}
								value={isDataValidOrDash(account.sms.additional)}
							/>
						</React.Fragment>
					)}
			</section>
		</>
	);
};

export default AccountContracts;
