// Core
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import lscache from 'lscache';

// Context
import { LangContext } from 'contexts/LangContext';

// Services
import { accounts, dictionaries } from 'services';
import debounce from 'lodash.debounce';
import { compose } from 'recompose';

// Utils
import { setCache } from 'services/cacheHelper/setCache';
import { addTypesToDrafts } from './utils/addTypesToDrafts';

// UI
import Banner from '../RepertoireReleaseSection/Banner/Banner';
import DraftsWrapper from './DraftsWrapper/DraftsWrapper';
import { Pagination, Input } from 'material-design/components';
import { Loading } from 'components';
import { withUI, withAuth, withRoot } from 'hocs';

// Icons
import ContentEmpty from '../ContentEmpty/ContentEmpty';

// Styles
import styles from './RepertoireDraftSection.module.css';

const RepertoireDraftSection = (props) => {
	const { lang } = useContext(LangContext);

	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [banner, setBanner] = useState(true);
	const [releases, setReleases] = useState([]);
	const [compositionTypes, setCompositionTypes] = useState([]);
	const [drafts, setDrafts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [value, setValue] = useState('');
	const [isReqLive] = useState(false);

	const {
		authContext: { accountId },
		UIContext: { upload, changeBackTargetUrl },
	} = props;

	useEffect(() => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			setReleases(releaseTypesCache.data);
		} else {
			accounts
				.getReleaseTypes(lang)
				.then((releases) => {
					setCache('releaseTypes', 'releaseTypesCache', releases.data.data);
					setReleases(releases.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		changeBackTargetUrl('/repertoire/drafts');

		if (releases.length === 0) {
			new Promise((resolve, reject) => {
				const releaseTypesCache = lscache.get('releaseTypesCache');
				if (
					releaseTypesCache &&
					!isReqLive &&
					releaseTypesCache.lang === lang
				) {
					setReleases(releaseTypesCache.data);
					resolve(releaseTypesCache.data);
				} else {
					accounts
						.getReleaseTypes(lang)
						.then((releases) => {
							setCache('releaseTypes', 'releaseTypesCache', releases.data.data);
							setReleases(releases.data.data);
							resolve(releases.data.data);
						})
						.catch((error) => {
							console.error('Error', error);
						});
				}
			});
		} else {
			new Promise((resovle, reject) => {
				resovle(releases);
			});
		}

		if (compositionTypes.length === 0) {
			new Promise((resolve, reject) => {
				dictionaries
					.getCompositionTypes()
					.then((res) => {
						setCompositionTypes(res.data.data);
						resolve(res.data.data);
					})
					.catch((error) => {
						console.error('Error', error);
					});
			});
		} else {
			new Promise((resolve, reject) => {
				resolve(compositionTypes);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (releases?.length > 0 && compositionTypes.length > 0)
			if (value.length >= 3) {
				setLoading(true);
				accounts
					.getAssetsDraftsText(accountId, value, page)
					.then((res) => {
						const draftsWithType = addTypesToDrafts(
							res.data.data,
							releases,
							compositionTypes,
							setLoading
						);
						setDrafts(draftsWithType);
						setTotal(res.data.total);
					})
					.catch((err) => {
						console.error('Drafts -changePage - error: ', err);
					})
					.finally(() => {
						setLoading(false);
					});
			} else {
				accounts
					.getAssetsDrafts(accountId, page)
					.then((res) => {
						res = res.data;
						setTotal(res.total);
						const draftsWithType = addTypesToDrafts(
							res.data,
							releases,
							compositionTypes,
							setLoading
						);
						setDrafts(draftsWithType);
					})
					.catch((error) => {
						console.error('Error', error);
					})
					.finally(() => {
						setLoading(false);
					});
			}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releases, compositionTypes, page, value]);

	const onChange = (filter) => {
		setValue(filter);
		setPage(1);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleFilter = useCallback(debounce(onChange, 500), []);

	const getReleasesAfterRemove = (releases) => {
		setReleases(releases);
	};

	return (
		<>
			{banner && process.env.REACT_APP_CLIENT !== 'dgtal' && (
				<Banner setBanner={setBanner} title="rod.drafts.title" />
			)}
			<Input handleOnChange={handleFilter} />
			{loading ? (
				<Loading className={styles.loader} />
			) : drafts?.length ? (
				<div className={styles.repertoire__mainContent}>
					<div>
						<div
							className={`${styles.repertoire__songTitle} ${styles.song__table} ${styles.adaptive__songTitle}`}
						>
							<FormattedHTMLMessage id={'rod.for_all.title'} />
							<FormattedHTMLMessage id={'rod.for_all.performers'} />
							<FormattedHTMLMessage id={'rod.for_all.type'} />
							<FormattedHTMLMessage id={'rod.for_all.status'} />
						</div>

						{drafts && (
							<DraftsWrapper
								drafts={drafts}
								releases={releases}
								getReleasesAfterRemove={getReleasesAfterRemove}
							/>
						)}
					</div>
					{total > 10 && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => setPage(pageNumber)}
							items_on_page={10}
						/>
					)}
				</div>
			) : (
				<ContentEmpty upload={upload} />
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(RepertoireDraftSection);
