// Core
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useContext, useEffect, useRef } from 'react';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { FormInput } from 'components/Form/FormInput';
import { InfoButton } from 'components/Buttons';

// Styles
import s from './DistributionDate.module.css';

const DistributionDate = ({
	additionalRelease,
	changeField,
	additionalErrors,
	setAdditionalErrors,
}) => {
	const { isSlaPrivelegesForAccount } = useContext(AuthContext);
	const { showModal } = useContext(UIContext);
	const sale_start_date = useRef(null);

	const isErrors =
		additionalErrors &&
		additionalErrors.id === additionalRelease.id &&
		Object.keys(additionalErrors).includes('sale_start_date');

	const handleOnBlurDateInput = () => {
		setAdditionalErrors({});
	};

	useEffect(() => {
		if (isErrors) {
			if (sale_start_date?.current) {
				sale_start_date.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isErrors]);

	return (
		<div className={s.item} ref={sale_start_date}>
			<FormInput
				type={'muiInputDate_v2'}
				name={'sale_start_date'}
				onChange={() => changeField('sale_start_date', additionalRelease)}
				handleOnBlurDateInput={handleOnBlurDateInput}
				minDate={true}
				errors={isErrors ? additionalErrors : []}
				data={additionalRelease}
				label={
					<FormattedMessage
						id={
							'rod.release.create.step.tracks.additional_release.distribution_date'
						}
					/>
				}
				isSlaPrivelegesForAccount={isSlaPrivelegesForAccount}
				additionalReleaseDate
				required
			/>
			{isSlaPrivelegesForAccount && (
				<div className={s.info}>
					<InfoButton
						onClick={showModal({
							text: (
								<FormattedHTMLMessage
									id={
										'rod.release.create.step.distribution.desired-date.sla.help'
									}
								/>
							),
						})}
					/>
				</div>
			)}
			{isErrors && (
				<span className={s.helper}>
					<FormattedMessage
						id={`rod.error.${additionalErrors.sale_start_date[0].rule}`}
					/>
				</span>
			)}
		</div>
	);
};

export default DistributionDate;
