// Core
import { FormattedMessage } from 'react-intl';

// Icons
import alert from 'images/alert-triangle.svg';

// Styles
import styles from './NewHelpInfo.module.css';

const NewHelpInfo = ({ text, customStyles }) => {
	return (
		<div className={styles.container} style={customStyles ? {...customStyles} : {}}>
			<img src={alert} alt="alert" />
			<FormattedMessage id={text} />
		</div>
	);
};

export default NewHelpInfo;
