// Core
import { useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { accounts } from 'services';
import moment from 'moment';

// Context
import { LangContext } from 'contexts/LangContext';
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// Components
import OutletTabs from './OutletTabs/OutletTabs';
import ContentTrackPage from './ContentTrack/ContentTrack';

const PlaylistsPage = () => {
	const { accountId } = useContext(AuthContext);
	const { showBackNavi, hideBackNavi, showTitle } = useContext(UIContext);
	const { params } = useRouteMatch();
	const { lang } = useContext(LangContext);
	const [outlet, setOutlet] = useState('spotify');
	const [recording, setRecording] = useState({});
	const [playlists, setPlaylists] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);

	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 8)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
		tracks: [],
	});

	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [lastStatDate, setLastStatDate] = useState(null);
	const [applyDate, setApplyDate] = useState(false);
	const [errors, setErrors] = useState({});

	const [noCountries, setNoCountries] = useState(false);
	const [isInitial, setIsInitial] = useState(false);
	const [countries, setCountries] = useState([]);
	const [countryFromPage, setCountryFromPage] = useState(null);
	const [filtersData, setFiltersData] = useState({
		countries: [],
	});
	const [checkedAllCountries, setAllCheckedCountries] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [coverTrack, setCoverTrack] = useState({});
	const [startStatDate, setStartStatDate] = useState('');
	const monthStart = moment()
		.subtract(1, 'months')
		.subtract(2, 'days')
		.format('YYYY-MM-DD');

	const [disabledMonth, setDisabledMonth] = useState(true);
	const [disabledThreeMonth, setDisabledThreeMonth] = useState(true);

	const getCoverTrack = () => {
		accounts
			.getAccountStatisticTopReleasesInfo(accountId, params.releaseId)
			.then((res) => {
				if (res.data.data?.length) {
					setCoverTrack(res.data.data[0].covers);
				}
			});
	};

	const getRecordingAndPlaylists = () => {
		const countriesCode = filtersData.countries.map((item) => item.code);
		setLoading(true);
		accounts
			.getAccountRecordingPageInPlaylists(
				accountId,
				params.releaseId,
				params.trackId,
				outlet,
				page,
				statisticData.start_date,
				statisticData.end_date,
				countriesCode.length ? countriesCode : []
			)
			.then((res) => {
				setPlaylists(res.data.playlists);
				setTotal(res.data.playlists.total);
				setRecording(res.data.recording);
				setCurrentPage(res.data.playlists.current_page);
				setStartStatDate(res.data.start_stat_date.date);
				setLoading(false);
			});
	};

	const handleClickOutlet = (outlet) => {
		if (outlet === 'apple music') {
			setOutlet('apple');
		} else {
			setOutlet('spotify');
		}
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setPage(1);
		setStatisticData({ ...statisticData });
	};

	const handleCheckedCountry = (checkedCountries, isAllChecked) => {
		setPage(1);
		if (checkedCountries || isAllChecked) {
			setNoCountries(false);
		}

		if (!checkedCountries) {
			return;
		}

		if (isInitial) {
			setCountries(checkedCountries);
			return;
		}

		setCountryFromPage(null);
		filtersData.countries = isAllChecked ? [] : checkedCountries;

		if (isAllChecked) {
			setAllCheckedCountries(true);
		}

		setFiltersData({ ...filtersData });

		if (!isAllChecked && !filtersData.countries.length) {
			setDisabled(true);
			setNoCountries(true);
		} else {
			setNoCountries(false);
		}
	};

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		getRecordingAndPlaylists();
	}, [outlet, page, statisticData, filtersData]);

	useEffect(() => {
		getCoverTrack();
		showTitle('rod.navbar.statistic.playlists');
		showBackNavi(`/accounts/${accountId}/statistic/playlist`);

		return () => {
			hideBackNavi();
		};
	}, []);

	useEffect(() => {
		if (startStatDate < monthStart) {
			setDisabledMonth(false);
			setDisabledThreeMonth(false);
		} else {
			setDisabledThreeMonth(true);
		}
	}, [startStatDate]);

	return (
		<>
			<OutletTabs handleClickOutlet={handleClickOutlet} outlet={outlet} />
			<ContentTrackPage
				accountId={accountId}
				recording={recording}
				playlists={playlists}
				total={total}
				page={page}
				setPage={setPage}
				outlet={outlet}
				currentPage={currentPage}
				lang={lang}
				handleCheckedPeriod={handleCheckedPeriod}
				loadingPage={loading}
				handleCheckedCountry={handleCheckedCountry}
				coverTrack={coverTrack}
				disabledMonth={disabledMonth}
				disabledThreeMonth={disabledThreeMonth}
			/>
		</>
	);
};

export default PlaylistsPage;
