// Core
import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { format } from 'date-fns';

// Constants
import VARIOUS_ARTISTS from 'constants/variousArtists';

// Utils
import { setCache } from 'services/cacheHelper/setCache';
import { accounts, dictionaries } from 'services';
import { dateFormat } from 'logic';
import { getCountries } from './utils/getCountries';
import { getReleaseTypes } from './utils/getReleaseTypes';
import { handleSetCatalogNumber } from './utils/handleSetIds';
import {
	getYearFromDate,
	getCreatedDateString,
	isValidISODate,
	isValidDateFormat,
} from 'utils';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { ArtistForm } from 'material-design/ReleaseCreate/ArtistForm';
import { BottomNavi, EditNotice, InfoHelper } from 'components';
import MainTable from './MainTable/MainTable';
import { SidepageModal } from 'material-design/modals';
import PerformersBlock from './PerformersBlock/PerformersBlock';
import IdsBlock from './IdsBlock/IdsBlock';

// Styles
import s from './Release.module.css';

const ReleaseInfo = (props) => {
	const { editNotices, setEditNotices } = props;
	const allReleaseTypeIds = [2, 42, 43, 51, 64, 69, 70];

	const accountId = localStorage.getItem('accountId');
	const { lang } = useContext(LangContext);
	const { isTransferRelease, setIsBottomNaviShow } = useContext(UIContext);

	const [personal, setPersonal] = useState({
		created_date: new Date().toISOString(),
		performers: [''],
	});
	const [selectedPerformer, setSelectedPerformer] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [errors, setErrors] = useState({});
	const [countries, setCountries] = useState([]);
	const [releaseTypes, setReleaseTypes] = useState([]);
	const [isReqLive] = useState(false);
	const [isShowPlineHelpBtn, setIsShowPlineHelpBtn] = useState(true);
	const [isShowClineHelpBtn, setIsShowClineHelpBtn] = useState(true);
	const [transferRelease, setTransferRelease] = useState(isTransferRelease);
	const [isH11Edit, setIsH11Edit] = useState(
		window.location.href.includes('/edit/')
	);
	const [countryId, setCountryId] = useState(null);
	const [disableBtn, setDisableBtn] = useState(false);
	const [isModalActive, setIsModalActive] = useState(false);
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [variousArtists, setVariousArtists] = useState(false);

	const releaseId = localStorage.getItem('releaseId');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const transferRelease = localStorage.getItem('isTransferRelease');
		if (transferRelease) setTransferRelease(true);
	}, []);

	const changeMask = (e) => {
		setErrors({});
		personal.grid = e.target.value;
	};

	const checkIsValidMask = (e) => {
		if (!personal.generate_grid && personal.grid.includes('_')) {
			setErrors({ grid: [{ rule: 'wrong_format' }] });
		}
	};

	const handleOnBlurDateInput = (err, value) => {
		delete errors.created_date;
		props.checkDateErrors(err);

		if (err) {
			setErrors({ ...errors, created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const handleOnBlurPlineDateInput = (err, value) => {
		delete errors.date_p_line;
		props.checkDateErrors(err);

		if (err) {
			setErrors({ ...errors, date_p_line: [{ rule: 'wrong_format' }] });
		}
	};

	const handleOnBlurClineDateInput = (err, value) => {
		delete errors.date_c_line;
		props.checkDateErrors(err);

		if (err) {
			setErrors({ ...errors, date_c_line: [{ rule: 'wrong_format' }] });
		}
	};

	const changeField = (field) => (e) => {
		props.cleanErrorsField(field);
		delete errors[field];

		if (editNotices) {
			const cleanEditNotices = editNotices.filter(
				({ code }) => !code.startsWith('release.')
			);
			localStorage.setItem('editNotices', JSON.stringify(cleanEditNotices));
			setEditNotices(cleanEditNotices);
			// Manually dispatch an event to notify all tabs/components
			window.dispatchEvent(new Event('storage'));
		}

		switch (field) {
			case 'created_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = new Date(e);
					e = format(e, 'yyyy-MM-dd');
				}
				personal[field] = e;
				break;
			case 'date_p_line':
			case 'date_c_line':
				if (e && e.getFullYear().toString().length === 4) {
					e = dateFormat(e);
				}
				personal[field] = e;
				break;
			case 'genres':
				const newArr = e.map((item) => (item.code ? item.code : item));
				personal[field] = newArr;
				break;
			case 'release_type_id':
				props.cleanErrorsField('performers');
				delete errors.performers;

				if (
					transferRelease &&
					personal.release_type_id === 70 &&
					personal.generate_ean
				) {
					personal.generate_ean = null;
				}
				personal[field] = e.id;
				setReleaseTypeId(e.id);
				break;
			case 'created_country_id':
				personal[field] = e;
				break;
			default:
				personal[field] = e.target.value;
				break;
		}
		setPersonal({ ...personal });
	};

	const handleFocus = (field) => (e) => {
		switch (field) {
			case 'p_line':
				setIsShowPlineHelpBtn(false);
				break;
			case 'c_line':
				setIsShowClineHelpBtn(false);
				break;

			default:
				break;
		}
	};

	const handleBlur = (field) => (e) => {
		switch (field) {
			case 'p_line':
				setIsShowPlineHelpBtn(true);
				break;
			case 'c_line':
				setIsShowClineHelpBtn(true);
				break;

			default:
				break;
		}
	};

	const nextHandler = () => {
		if (
			releaseTypeId === 70 ||
			releaseTypeId === 51 ||
			releaseTypeId === 42 ||
			releaseTypeId === 43
		) {
			delete errors.performers;
		}

		if (typeof props.onChange === 'function' && !Object.keys(errors).length) {
			setDisableBtn(true);

			const clone = cloneDeep(personal);

			if (clone.created_date) {
				if (
					!isValidDateFormat(clone.created_date) &&
					!isValidISODate(clone.created_date)
				) {
					setErrors({ ...errors, created_date: [{ rule: 'wrong_format' }] });
				} else {
					const time = moment().format();
					clone.created_date = getCreatedDateString(time, clone.created_date);
				}
			}

			if (clone.date_p_line && isValidISODate(clone.date_p_line)) {
				clone.date_p_line = getYearFromDate(clone.date_p_line);
			}

			if (clone.date_c_line && isValidISODate(clone.date_c_line)) {
				clone.date_c_line = getYearFromDate(clone.date_c_line);
			}

			if (clone.genres) {
				const genres = clone.genres.map((item) =>
					item.code ? item.code : item
				);
				clone.genres = genres;
			}

			if (clone.performers) {
				if (
					releaseTypeId === 70 ||
					releaseTypeId === 51 ||
					releaseTypeId === 42 ||
					releaseTypeId === 43
				) {
					clone.performers = null;
				} else {
					if (variousArtists) {
						clone.performers = VARIOUS_ARTISTS;
						clone.various_artists = true;
					} else {
						const performersArr = [...clone.performers];
						const formattedArr = performersArr.map((item) =>
							item.artist_id ? item.artist_id : item.name ? item.name : item
						);
						clone.performers = formattedArr.filter(
							(item) =>
								item !== '' && !(typeof item === 'object' && item.name === '')
						);
						clone.various_artists = false;
					}
				}
			}

			props
				.onChange(clone)
				.then()
				.catch()
				.finally(() => setDisableBtn(false));
		}
	};

	const handleVariousArtist = () => {
		if (variousArtists) {
			setVariousArtists(false);
			setPersonal({ ...personal, performers: [''] });
		} else {
			setVariousArtists(true);
			setPersonal({ ...personal, performers: [{ name: VARIOUS_ARTISTS }] });
		}
	};

	const disabledNextBtn =
		!personal.title ||
		!personal.release_type_id ||
		!personal.genres ||
		!personal.genres.length ||
		!personal.p_line ||
		!personal.c_line ||
		!personal.date_p_line ||
		!personal.date_c_line ||
		!personal.created_date ||
		(!isH11Edit && !personal.generate_ean && !personal.ean) ||
		Object.keys(errors).includes('created_date') ||
		Object.keys(errors).includes('date_p_line') ||
		Object.keys(errors).includes('date_c_line') ||
		(!transferRelease &&
			!isH11Edit &&
			!personal.generate_catalog_number &&
			!personal.catalog_number);

	const validForm = (personal) => {
		const newErrors = {};

		if (!personal.title) {
			newErrors.title = [{ rule: 'required' }];
		}
		if (!personal.release_type_id) {
			newErrors.release_type_id = [{ rule: 'required' }];
		}
		if (!personal.genres || !personal.genres.length) {
			newErrors.genres = [{ rule: 'required' }];
		}
		if (!personal.p_line) {
			newErrors.p_line = [{ rule: 'required' }];
		}
		if (!personal.c_line) {
			newErrors.c_line = [{ rule: 'required' }];
		}
		if (!personal.date_p_line) {
			newErrors.date_p_line = [{ rule: 'required' }];
		}
		if (!personal.date_c_line) {
			newErrors.date_c_line = [{ rule: 'required' }];
		}
		if (!personal.created_date) {
			newErrors.created_date = [{ rule: 'required' }];
		}
		if (!isH11Edit && !personal.generate_ean && !personal.ean) {
			newErrors.ean = [{ rule: 'required' }];
		}
		if (
			!transferRelease &&
			!isH11Edit &&
			!personal.generate_catalog_number &&
			!personal.catalog_number
		) {
			newErrors.catalog_number = [{ rule: 'required' }];
		}
		if (
			releaseTypeId !== 70 &&
			releaseTypeId !== 51 &&
			releaseTypeId !== 42 &&
			releaseTypeId !== 43 &&
			(!personal.performers.length ||
				personal.performers.every((item) => item === ''))
		) {
			newErrors.performers = [{ rule: 'required' }];
		}

		setErrors(newErrors);
	};

	const getClassnameForInfoLineBtn = (param) => {
		switch (param) {
			case 'c_line':
				return isShowClineHelpBtn && !personal[param] ? s.info : s.infoActive;
			default:
				return isShowPlineHelpBtn && !personal[param] ? s.info : s.infoActive;
		}
	};

	const handleChangeArtist = (data, index) => {
		delete errors.performers;

		let updatedPerformers = [...personal.performers];
		updatedPerformers[index] = data;

		if (
			updatedPerformers[updatedPerformers.length - 1] !== '' &&
			updatedPerformers.length < 5
		) {
			updatedPerformers.push('');
		}

		const updatedPersonal = {
			...personal,
			performers: updatedPerformers,
		};

		setPersonal(updatedPersonal);
	};

	const handleAddArtist = () => {
		const updatedPerformers = [...personal.performers, ''];
		const updatedPersonal = {
			...personal,
			performers: updatedPerformers,
		};
		setPersonal(updatedPersonal);
	};

	const handleDeleteArtist = (index) => {
		if (props.errors.performers && props.errors.performers[0].rule === 'max') {
			props.cleanErrorsField('performers');
		}

		const updatedPerformers = personal.performers.filter((_, i) => i !== index);

		const updatedPersonal = {
			...personal,
			performers: !updatedPerformers.length ? [''] : updatedPerformers,
		};

		setPersonal(updatedPersonal);
	};

	const handleSavePerformer = (updatedPerformer, index) => {
		const updatedPerformers = [...personal.performers];
		updatedPerformers[index] = updatedPerformer;
		if (updatedPerformers[updatedPerformers.length - 1] !== '') {
			updatedPerformers.push('');
		}
		setPersonal({
			...personal,
			performers: updatedPerformers,
		});
	};

	const handleOpenModal = (performer, index, text) => {
		setSelectedPerformer(!performer.name ? { name: text } : performer);
		setSelectedIndex(index);
		setIsModalActive(true);
	};

	useEffect(() => {
		accounts
			.getAccountContactsData(accountId)
			.then((res) => {
				res = res.data.data;
				if (res.country_id) {
					setCountryId(res.country_id);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getCountries(isReqLive, setCountries, dictionaries, setCache);

		if (
			!releaseId &&
			accountId &&
			personal &&
			!personal.created_country_id &&
			countryId
		) {
			setPersonal({ ...personal, created_country_id: countryId });
		}

		if (releaseId && !isH11Edit) {
			accounts
				.getReleaseData(releaseId)
				.then((res) => {
					const step = res.data.data?.step;
					const releaseTypeId = res.data.data.release_type_id;
					setReleaseTypeId(releaseTypeId);

					if (res.data.data.grid) {
						res.data.data.grid.replace(
							/(\d{2})\.(\d{5})\.(\d{10})\.(\d{1})/,
							'$1-$2-$3-$4'
						);
					}
					res.data.data['created_date'] = res.data.data['created_date']?.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];

					if (
						typeof res.data.data.performers === 'string' ||
						typeof res.data.data.performers === 'number'
					) {
						if (res.data.data.various_artists) {
							setVariousArtists(true);
							res.data.data.performers = [{ name: VARIOUS_ARTISTS }];
						} else {
							if (
								res.data.data.performers &&
								Array.isArray(res.data.data.performers) &&
								res.data.data.performers.length === 4
							) {
								res.data.data.performers = [
									{ name: res.data.data.performers.toString() },
								];
							} else {
								res.data.data.performers = [
									{ name: res.data.data.performers.toString() },
									'',
								];
							}
						}
					} else {
						if (
							releaseTypeId === 70 ||
							releaseTypeId === 51 ||
							releaseTypeId === 42 ||
							releaseTypeId === 43 ||
							!res.data.data.performers
						) {
							res.data.data.performers = [''];
						} else {
							const formattedPerformers = res.data.data.performers.map(
								(item) => {
									if (typeof item === 'string' || typeof item === 'number') {
										return { name: item.toString() };
									}
									return item;
								}
							);
							if (res.data.data.performers.length === 4) {
								res.data.data.performers = [...formattedPerformers];
							} else {
								res.data.data.performers = [...formattedPerformers, ''];
							}
						}
					}

					setIsH11Edit(step.includes('h11'));
					if (step.includes('h11')) {
						const releaseEdit = { id: h11EditId, step: 'release' };
						localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					}
					setPersonal(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		if (isH11Edit) {
			accounts
				.getReleaseData(h11EditId)
				.then((res) => {
					const releaseTypeId = res.data.data.release_type_id;

					if (!allReleaseTypeIds.includes(releaseTypeId)) {
						setReleaseTypeId(null);
						res.data.data.release_type_id = null;
					} else {
						setReleaseTypeId(releaseTypeId);
					}

					if (res.data.data.grid) {
						res.data.data.grid.replace(
							/(\d{2})\.(\d{5})\.(\d{10})\.(\d{1})/,
							'$1-$2-$3-$4'
						);
					}
					res.data.data['created_date'] = res.data.data['created_date']?.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];

					if (typeof res.data.data.performers === 'string') {
						res.data.data.performers = [{ name: res.data.data.performers }, ''];
					} else {
						if (
							releaseTypeId === 70 ||
							releaseTypeId === 51 ||
							releaseTypeId === 42 ||
							releaseTypeId === 43 ||
							!res.data.data.performers
						) {
							res.data.data.performers = [''];
						} else {
							const formattedPerformers = res.data.data.performers.map(
								(item) => {
									if (typeof item === 'string') {
										return { name: item };
									}
									return item;
								}
							);
							if (res.data.data.performers.length === 4) {
								res.data.data.performers = [...formattedPerformers];
							} else {
								res.data.data.performers = [...formattedPerformers, ''];
							}
						}
					}

					res.data.data.created_country_id = countryId;
					const step = res.data.data?.step;
					setIsH11Edit(step.includes('h11'));

					if (step.includes('h11')) {
						const releaseEdit = { id: h11EditId, step: 'release' };
						localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					}

					validForm(res.data.data);
					setPersonal(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		// eslint-disable-next-line
	}, [countryId]);

	useEffect(() => {
		getReleaseTypes(
			personal,
			isReqLive,
			lang,
			setReleaseTypes,
			accounts,
			setCache
		);
		// eslint-disable-next-line
	}, [lang, personal]);

	return (
		<>
			<div className={s.page}>
				<div className={s.main}>
					{editNotices &&
						editNotices.length > 0 &&
						editNotices.filter(({ code }) => code === 'release.title').length >
							0 && <EditNotice editNotices={editNotices} step="release." />}
					<span className={s.title}>
						<FormattedMessage id={'rod.add_composition_page.step1'} />
					</span>
					<MainTable
						changeField={changeField}
						errors={errors}
						errorsFromBack={props.errors}
						personal={personal}
						handleOnBlurDateInput={handleOnBlurDateInput}
						handleOnBlurPlineDateInput={handleOnBlurPlineDateInput}
						handleOnBlurClineDateInput={handleOnBlurClineDateInput}
						handleFocus={handleFocus}
						handleBlur={handleBlur}
						countries={countries}
						getClassnameForInfoLineBtn={getClassnameForInfoLineBtn}
						releaseTypes={releaseTypes}
						isH11Edit={isH11Edit}
						editNotices={editNotices}
					/>
					<PerformersBlock
						releaseTypeId={releaseTypeId}
						personal={personal}
						handleChangeArtist={handleChangeArtist}
						handleOpenModal={handleOpenModal}
						handleAddArtist={handleAddArtist}
						handleDeleteArtist={handleDeleteArtist}
						isH11Edit={isH11Edit}
						errors={
							Object.keys(props.errors).length > 0 ? props.errors : errors
						}
						cleanErrorsField={props.cleanErrorsField}
						variousArtists={variousArtists}
						handleVariousArtist={handleVariousArtist}
					/>
					<IdsBlock
						personal={personal}
						changeField={changeField}
						errors={props.errors}
						setErrors={setErrors}
						setPersonal={setPersonal}
						transferRelease={transferRelease}
						changeMask={changeMask}
						checkIsValidMask={checkIsValidMask}
						handleSetCatalogNumber={handleSetCatalogNumber}
					/>
				</div>
				<InfoHelper text="rod.release.create.step.release.description" />
				{isModalActive && (
					<SidepageModal
						customWidth="434px"
						headerBottom="12px"
						active={isModalActive}
						setActive={() => setIsModalActive(false)}
					>
						<ArtistForm
							isModalActive={isModalActive}
							data={selectedPerformer}
							dataField="performers"
							index={selectedIndex}
							setIsModalActive={setIsModalActive}
							onSave={handleSavePerformer}
						/>
					</SidepageModal>
				)}
			</div>
			<BottomNavi
				showPrevBtn={false}
				disabled={
					(!isH11Edit && disabledNextBtn) ||
					!personal.created_date ||
					(releaseTypeId !== 70 &&
						releaseTypeId !== 51 &&
						releaseTypeId !== 42 &&
						releaseTypeId !== 43 &&
						(!personal.performers.length ||
							personal.performers.every((item) => item === ''))) ||
					disableBtn ||
					(editNotices &&
						editNotices.length > 0 &&
						editNotices.filter(({ code }) => code === 'release.title').length >
							0)
				}
				next={nextHandler}
			/>
		</>
	);
};

export default ReleaseInfo;
