import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { accounts } from 'services';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getDateInYYYYMMDD } from 'material-design/Statistic/StatisticCategory/utils/getDateInYYYYMMDD';
import { checkRangeInOneYear } from 'material-design/Statistic/StatisticCategory/utils/checkRangeInOneYear';

//Components
import FiltersDetail from 'material-design/Statistic/Trends/FiltersDetail/FiltersDetail';

import Subscriptions from './Subscriptions/Subscriptions';
import GenderAndAge from './GenderAndAge/GenderAndAge';
import Map from './Map/Map';
import Columnar from './Columnar/Columnar';
import styles from './Audience.module.css';

const Audience = ({ accountId, typePage }) => {
	let { pathname } = useLocation();
	const { params } = useRouteMatch();
	const { lang } = useContext(LangContext);

	const chartRef = useRef(null);

	// Date Picker
	const { showModal } = useContext(UIContext);
	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		tracksIds: [],
		outlets: [],
		countries: [],
		per_page: 10,
		sort: 'desc',
		page: 1,
		checkedAllTracks: false,
		trackSearchValue: '',
	});
	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});
	// eslint-disable-next-line no-unused-vars
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [errors, setErrors] = useState({});
	const [applyDate, setApplyDate] = useState(false);
	const [perPage] = useState(10);
	const [page, setPage] = useState(1);
	const [sidebarFiltersData, setSideBarFiltersData] = useState({
		performers: [],
		releases: [],
		recordings: [],
		countries: [],
	});

	// Outlets
	const [outletCodes, setOutletCodes] = useState([]);

	// Countries
	// eslint-disable-next-line no-unused-vars
	const [noCountries, setNoCountries] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isInitial, setIsInitial] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [countries, setCountries] = useState([]);
	const [countriesForColumnar, setCountriesForColumnar] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [countryFromPage, setCountryFromPage] = useState(null);
	const [filtersData, setFiltersData] = useState({
		countries: [],
	});
	// eslint-disable-next-line no-unused-vars
	const [checkedAllCountries, setAllCheckedCountries] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [disabled, setDisabled] = useState(false);
	const [isAllLoading, setIsAllLoading] = useState(true);
	const [subscriptions, setSubscriptions] = useState({});
	const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(true);
	const [genderAudience, setGenderAudience] = useState([]);
	const [isLoadingGenderAudience, setIsLoadingGenderAudience] = useState(true);
	const [accountMap, setAccountMap] = useState([]);
	const [isLoadingMap, setIsLoadingMap] = useState(true);
	const [outletStreams, setOutletStreams] = useState('YouTube');

	// Columnar
	const [amountTitle, setAmountTitle] = useState('');
		// eslint-disable-next-line no-unused-vars
	const [initialLoading, setInitialLoading] = useState(true);
		// eslint-disable-next-line no-unused-vars
	const [accountOutlets, setAccountOutlets] = useState([]);
		// eslint-disable-next-line no-unused-vars
	const [outletsFetched, setOutletsFetched] = useState(false);
	const [mapTotalPages, setMapTotalPages] = useState(0);
	const [columnarMap, setColumnarMap] = useState([]);
		// eslint-disable-next-line no-unused-vars
	const [isPaginationPressed, setIsPaginationPressed] = useState(false);

	const outlets = {
		YouTube: 'youtube',
		Spotify: 'spotify',
		'Apple Music': 'apple',
	};

	const handleCheckedOutlets = (outlets) => {
		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData.outlets = outlets;
		}
	};

	const handleCloseFilterOutlets = () => {
		setStatisticData({ ...statisticData });
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'per_page': {
				statisticData[field] = e.id;
				statisticData.page = 1;
				setStatisticData({ ...statisticData });
				chartRef.current.scrollIntoView({ behavior: 'smooth' });
				break;
			}
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	const handleCheckedCountry = (checkedCountries, isAllChecked) => {
		if (checkedCountries || isAllChecked) {
			setNoCountries(false);
		}

		if (!checkedCountries) {
			return;
		}

		if (isInitial) {
			setCountries(checkedCountries);
			return;
		}

		setCountryFromPage(null);
		filtersData.countries = isAllChecked ? [] : checkedCountries;

		if (isAllChecked) {
			setAllCheckedCountries(true);
		}

		setFiltersData({ ...filtersData });
		setSideBarFiltersData({ ...filtersData });

		if (!isAllChecked && !filtersData.countries.length) {
			setDisabled(true);
			setNoCountries(true);
		} else {
			setNoCountries(false);
		}
	};

	const getSubscriptions = async () => {
		setIsLoadingSubscriptions(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getAudienceSubscriptions(
				false,
				accountId,
				outlets[outletStreams],
				statisticData.start_date,
				statisticData.end_date,
				typePage === 'release' ? [params.id] : [],
				typePage === 'recording' ? [params.id] : [],
				typePage === 'artist' ? [params.id] : [],
				countriesCode?.length ? countriesCode : []
			);
			setSubscriptions(response.data.data);
			setIsLoadingSubscriptions(false);
		} catch (error) {
			console.error(error);
			setIsLoadingSubscriptions(false);
		}
	};

	const getAudienceGenderAge = async () => {
		setGenderAudience([]);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getAudienceAge(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				typePage === 'release' ? [params.id] : [],
				typePage === 'recording' ? [params.id] : [],
				typePage === 'artist' ? [params.id] : [],
				countriesCode?.length ? countriesCode : []
			);

			const result = Object.entries(response.data.data[0]).map(
				([ageGroup, data]) => ({
					ageGroup,
					...data,
				})
			);

			const unknowAge = result.filter((item) => item.ageGroup === 'u');
			if (unknowAge && result.slice(-1)[0].ageGroup === 'u') {
				result.unshift(...unknowAge);
				result.pop();
			}

			setGenderAudience(result);
			setIsLoadingGenderAudience(false);
		} catch (error) {
			console.error(error);
			setIsLoadingGenderAudience(false);
		}
	};

	const getMapCountries = async () => {
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getAccountStatisticMapByDate(
				false,
				accountId,
				statisticData.start_date,
				statisticData.end_date,
				outletCodes,
				typePage === 'recording' ? [params.id] : [],
				'',
				countriesCode,
				typePage === 'artist' ? [params.id] : [],
				typePage === 'release' ? [params.id] : []
			);
			setAccountMap(response.data.data.global);
			setIsLoadingMap(false);
		} catch (error) {
			console.error(error);
			setIsLoadingMap(false);
		}
	};

	const getColumnarStreams = async () => {
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		setInitialLoading(true);
		try {
			const outletsRes = await accounts.getAccountStatisticOutlets();
			const accountOutlets = outletsRes.data.data;
			setAccountOutlets(accountOutlets);
			setOutletsFetched(true);

			const columnarPromise = await accounts.getAccountStatisticsColumnar(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				typePage === 'recording' ? [params.id] : [],
				perPage,
				page,
				statisticData.sort,
				countriesCode.length
					? countriesCode
					: countriesForColumnar.map((item) => item.code),
				typePage === 'release' ? [params.id] : [],
				typePage === 'artist' ? [params.id] : []
			);

			const responseData = columnarPromise.data.data;
			setApplyDate(false);
			setMapTotalPages(responseData.last_page * perPage);
			setColumnarMap(responseData.global);

			if (responseData.global?.length) {
				let maxAuditions = Number(responseData.global[0].stat_count);
				if (
					statisticData.sort !== 'desc' &&
					responseData.global[responseData.global.length - 1]
				) {
					maxAuditions = Number(
						responseData.global[responseData.global.length - 1].stat_count
					);
				}
				if (maxAuditions <= 10000) {
					setAmountTitle('rod.statistic.chart-amount-units');
				} else if (maxAuditions > 10000) {
					setAmountTitle('rod.statistic.chart-amount-thousands');
				}
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setInitialLoading(false);
		}
	};

	const fullRequests = async () => {
		setIsLoadingSubscriptions(true);
		setIsLoadingGenderAudience(true);
		setIsLoadingMap(true);
		setIsAllLoading(true);
		try {
			await getSubscriptions();
			await getAudienceGenderAge();
			if (process.env.REACT_APP_CLIENT !== 'mts') {
				await getMapCountries();
			} else {
				await getColumnarStreams();
			}

			setIsAllLoading(false);
		} catch (error) {
			console.error(error);
			setIsAllLoading(false);
		}
	};

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	useEffect(() => {
		fullRequests();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statisticData, sidebarFiltersData, typePage]);

	useEffect(() => {
		getSubscriptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outletStreams]);

	useEffect(() => {
		getColumnarStreams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [perPage, page]);

	return (
		<>
			<FiltersDetail
				accountId={accountId}
				lang={lang}
				handleCheckedOutlets={handleCheckedOutlets}
				changeField={changeField}
				statisticData={datePickerData}
				errors={errors}
				handleCheckedPeriod={handleCheckedPeriod}
				setApplyDate={setApplyDate}
				applyDateFilter={applyDateFilter}
				setIsLastCalendar={setIsLastCalendar}
				handleCheckedCountry={handleCheckedCountry}
				handleCloseFilterOutlets={handleCloseFilterOutlets}
				isAllLoading={isAllLoading}
				audiencePage={true}
				setCountriesForColumnar={setCountriesForColumnar}
			/>
			<div className={styles.graphicsWrapper}>
				<Subscriptions
					subscriptions={subscriptions}
					isLoadingSubscriptions={isLoadingSubscriptions}
					outletStreams={outletStreams}
					setOutletStreams={setOutletStreams}
					isAllLoading={isAllLoading}
				/>
				<GenderAndAge
					genderAudience={genderAudience}
					isLoadingGenderAudience={isLoadingGenderAudience}
					statisticData={statisticData}
				/>
			</div>

			{process.env.REACT_APP_CLIENT !== 'mts' ? (
				<Map
					data={accountMap}
					selectedOutlets={
						!outletCodes.length
							? [
									'apple',
									'deezer',
									'facebook',
									'instagram',
									'ok',
									'spotify',
									'tiktok',
									'vk',
									'yandex',
									'youtube',
									'zvuk',
							  ]
							: outletCodes
					}
					sort={statisticData.sort}
					isLoadingMap={isLoadingMap}
				/>
			) : (
				<Columnar
					chartRef={chartRef}
					amountTitle={amountTitle}
					statisticData={statisticData}
					setStatisticData={setStatisticData}
					countries={countriesForColumnar}
					startEndDates={[statisticData.start_date, statisticData.end_date]}
					mapTotalPages={mapTotalPages}
					changeField={changeField}
					errors={errors}
					accountMap={columnarMap}
					setIsPaginationPressed={setIsPaginationPressed}
					pageLoading={isAllLoading}
					perPage={perPage}
					setPage={setPage}
					page={page}
				/>
			)}
		</>
	);
};

export default Audience;
