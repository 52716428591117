import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import isEqual from 'lodash/isEqual';
import { getJSONParse } from 'utils';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import MuiMultipleCheckedSkeleton from '../MuiMultipleCheckedSkeleton/MuiMultipleCheckedSkeleton';
import { Label } from 'components/Labels';

// Styles
import s from './MuiMultipleCheckedTracks.module.css';

const MultipleSelectRecordings = ({
	rootContext: {
		getAccountStatisticAllRecordings,
		getAccountStatisticAuthorAllRecordings,
	},
	dateFrom,
	dateTo,
	accountId,
	isAdmin,
	releaseIds,
	artists,
	recordingsState,
	lang,
	chartMode,
	outlets,
	topTrack,
	setHasRecordingsForRender,
	setResetRecordings,
	setIsGetRecordings,
	handleResetTopTrack,
	setIsResetPerformers,
	setIsResetReleases,
	isResetReleases,
	isResetPerformers,
	isLoadedReleases,
	isLoadedPerformers,
	performersIds,
	setCheckedTopTrack,
	setIsLoadedRecordings,
}) => {
	const [recordings, setRecordings] = useState([]);
	const [checkedRecordingIds, setCheckedRecordingIds] = useState([]);
	const [cachedIds, setCachedIds] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isShowSkeleton, setIsShowSkeleton] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [topTrackChoose, setTopTrackChoose] = useState({});
	const [resetActive, setResetActive] = useState(false);

	const [cachedDateFrom, setCachedDateFrom] = useState('');
	const [cachedDateTo, setCachedDateTo] = useState('');
	const [cachedReleasesIds, setCachedReleasesIds] = useState([]);
	const [cachedPerformers, setCachedPerformers] = useState([]);

	const [checkedRecordings, setCheckedRecordings] = useState([]);

	const [page] = useState(1);
	const [limit] = useState(100);

	const [isReqLive, setIsReqLive] = useState(false);
	const [isValidInput, setIsValidInput] = useState(true);

	const allRecordingsRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorAllRecordings
			: getAccountStatisticAllRecordings;

	const getRecordings = (releaseIds = []) => {
		setRecordings([]);
		setIsLoadedRecordings(false);
		const topTrackId = topTrack ? topTrack.id : [];
		setIsGetRecordings(false);
		setIsShowSkeleton(true);
		allRecordingsRequest(
			isAdmin,
			accountId,
			searchValue,
			page,
			limit,
			dateFrom,
			dateTo,
			isResetPerformers || isResetReleases ? [] : releaseIds,
			resetActive || isResetPerformers || isResetReleases
				? []
				: topTrackId !== undefined
				? topTrackId
				: [],
			outlets,
			performersIds.length ? performersIds.filter((item) => item !== '') : []
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked:
					!isEqual(releaseIds, cachedReleasesIds) ||
					checkedRecordingIds.includes(item.heaven11_track_id) ||
					topTrack?.raw_title?.length ||
					(releaseIds.length &&
						!searchValue.length &&
						!checkedRecordingIds.length)
						? true
						: false,
			}));

			setHasRecordingsForRender(data.length ? true : false);

			const allRecordingsButton = {
				heaven11_track_id: 0,
				raw_title: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			setCachedDateFrom(dateFrom);
			setCachedDateTo(dateTo);
			setCachedReleasesIds(releaseIds);

			let recordingsData;

			if (data.length) {
				recordingsData = [allRecordingsButton, ...data];
				setIsEmptyData(false);
			} else {
				recordingsData = [];
				setIsEmptyData(true);
			}

			if (dateFrom !== cachedDateFrom || dateTo !== cachedDateTo) {
				const newRecordings = recordingsData.map((item) => ({
					...item,
					checked: checkedRecordingIds.includes(item.heaven11_track_id),
				}));
				setCheckedRecordingIds(
					newRecordings
						.filter((item) => item.checked)
						?.map((item) => item.heaven11_track_id)
				);
				setCheckedRecordings(
					newRecordings
						.filter((item) => item.checked && item.heaven11_track_id !== 0)
						?.map((item) => item)
				);
				recordingsState(
					newRecordings
						.filter((item) => item.checked && item.heaven11_track_id !== 0)
						?.map((item) => item)
				);
			} else if (
				(!isEqual(releaseIds, cachedReleasesIds) && releaseIds.length) ||
				topTrack?.raw_title ||
				(resetActive && releaseIds.length)
			) {
				const allRecordings = recordingsData.map(
					(item) => item.heaven11_track_id
				);
				setCheckedRecordingIds(allRecordings);
				setCheckedRecordings(
					recordingsData
						.filter((item) => item.heaven11_track_id !== 0)
						.map((item) => item)
				);
				recordingsState(
					recordingsData
						.filter((item) => item.heaven11_track_id !== 0)
						.map((item) => item.heaven11_track_id)
				);
			}

			if (isResetPerformers || isResetReleases) {
				setCheckedRecordingIds([]);
				setCheckedRecordings([]);
				recordingsState([]);
				setTopTrackChoose(null);
				handleResetTopTrack();
				setSearchValue('');
			}
			setRecordings(recordingsData);
			setIsShowSkeleton(false);
			setIsGetRecordings(true);
			setIsLoadedRecordings(true);
		});
	};

	const getAuthorRecordings = (artists = []) => {
		setIsLoadedRecordings(false);
		const topTrackId = topTrack ? topTrack.id : [];
		setIsGetRecordings(false);
		setIsShowSkeleton(true);
		allRecordingsRequest(
			isAdmin,
			accountId,
			searchValue,
			page,
			limit,
			dateFrom,
			dateTo,
			artists,
			resetActive || isResetPerformers
				? []
				: topTrackId !== undefined
				? topTrackId
				: [],
			outlets
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked:
					!isEqual(artists, cachedPerformers) ||
					checkedRecordingIds.includes(item.heaven11_track_id) ||
					topTrack?.raw_title?.length ||
					(artists.length && !searchValue.length && !checkedRecordingIds.length)
						? true
						: false,
			}));
			setHasRecordingsForRender(data.length ? true : false);

			const allRecordingsButton = {
				heaven11_track_id: 0,
				raw_title: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			setCachedDateFrom(dateFrom);
			setCachedDateTo(dateTo);
			setCachedPerformers(artists);

			let recordingsData;

			if (data.length) {
				recordingsData = [allRecordingsButton, ...data];
				setIsEmptyData(false);
			} else {
				recordingsData = [];
				setIsEmptyData(true);
			}

			if (dateFrom !== cachedDateFrom || dateTo !== cachedDateTo) {
				const newRecordings = recordingsData.map((item) => ({
					...item,
					checked: checkedRecordingIds.includes(item.heaven11_track_id),
				}));
				setCheckedRecordingIds(
					newRecordings
						.filter((item) => item.checked)
						?.map((item) => item.heaven11_track_id)
				);
				setCheckedRecordings(
					newRecordings
						.filter((item) => item.checked && item.heaven11_track_id !== 0)
						?.map((item) => item)
				);
				recordingsState(
					newRecordings.filter(
						(item) => item.checked && item.heaven11_track_id !== 0
					)
				);
			} else if (
				!isEqual(artists, cachedPerformers) ||
				topTrack?.raw_title ||
				(resetActive && artists?.length)
			) {
				const allRecordings = recordingsData.map(
					(item) => item.heaven11_track_id
				);
				setCheckedRecordingIds(allRecordings);
				setCheckedRecordings(
					recordingsData
						.filter((item) => item.heaven11_track_id !== 0)
						.map((item) => item)
				);
				recordingsState(
					recordingsData
						.filter((item) => item.heaven11_track_id !== 0)
						.map((item) => item.heaven11_track_id)
				);
			}

			if (isResetPerformers) {
				setCheckedRecordingIds([]);
				setCheckedRecordings([]);
				recordingsState([]);
				setTopTrackChoose(null);
				handleResetTopTrack();
				setSearchValue('');
			}

			setRecordings(recordingsData);
			setIsShowSkeleton(false);
			setIsGetRecordings(true);
			setIsLoadedRecordings(true);
		});
	};

	useEffect(() => {
		chartMode === 'phono'
			? setIsReqLive(isLoadedReleases)
			: setIsReqLive(isLoadedPerformers);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadedPerformers, isLoadedReleases]);

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		if (chartMode === 'phono') {
			if (
				(searchValue.length >= 3 ||
					!searchValue.length ||
					isResetPerformers ||
					isResetReleases) &&
				isReqLive
			) {
				getRecordings(releaseIds);
				setIsResetPerformers(false);
				setIsResetReleases(false);
			} else if (
				!isShowSkeleton &&
				isReqLive &&
				(searchValue.length >= 3 || !searchValue.length)
			) {
				getRecordings(releaseIds);
			}
		} else {
			if (
				(searchValue.length >= 3 ||
					!searchValue.length ||
					isResetPerformers ||
					isResetReleases) &&
				isReqLive
			) {
				getAuthorRecordings(artists);
				setIsResetPerformers(false);
				setIsResetReleases(false);
			} else if (
				!isShowSkeleton &&
				isReqLive &&
				(searchValue.length >= 3 || !searchValue.length)
			) {
				getAuthorRecordings(artists);
			}
		}

		setTopTrackChoose(topTrack);
		setResetActive(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseIds, searchValue, artists, topTrack, isReqLive]);

	const handleCheckedRecording = (checkedRecording) => {
		let mappedRecordings;

		if (checkedRecording.heaven11_track_id === 0 && checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: false,
			}));

			setCheckedRecordingIds([]);
			setCheckedRecordings([]);
		}

		if (checkedRecording.heaven11_track_id === 0 && !checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: true,
			}));

			setCheckedRecordingIds(
				recordings.map((release) => release.heaven11_track_id)
			);

			setCheckedRecordings(recordings);
		}

		if (
			!(checkedRecording.heaven11_track_id === 0) &&
			checkedRecording.checked
		) {
			mappedRecordings = recordings.map((release) => {
				if (release.heaven11_track_id === 0) {
					return { ...release, checked: false };
				}

				if (release.heaven11_track_id === checkedRecording.heaven11_track_id) {
					return { ...release, checked: false };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds(
				checkedRecordingIds.filter(
					(id) => id !== checkedRecording.heaven11_track_id && id !== 0
				)
			);

			setCheckedRecordings(
				checkedRecordings.filter(
					(item) =>
						item.heaven11_track_id !== checkedRecording.heaven11_track_id &&
						item.heaven11_track_id !== 0
				)
			);
		}

		if (
			!(checkedRecording.heaven11_track_id === 0) &&
			!checkedRecording.checked
		) {
			mappedRecordings = recordings.map((release) => {
				if (release.heaven11_track_id === 0) {
					return { ...release, checked: false };
				}

				if (release.heaven11_track_id === checkedRecording.heaven11_track_id) {
					return { ...release, checked: true };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds([
				...checkedRecordingIds.filter((checkedId) => checkedId !== 0),
				checkedRecording.heaven11_track_id,
			]);

			setCheckedRecordings([
				...checkedRecordings.filter((item) => item.heaven11_track_id !== 0),
				checkedRecording,
			]);
		}

		setRecordings(mappedRecordings);
	};

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSearchHandler = useCallback(
		debounce(handleSearchInput, 900),
		[]
	);

	const handleClickOnly = (e, recording) => {
		setCheckedRecordings([recording]);
		e.stopPropagation();

		const mappedRecordings = recordings.map((item) => ({
			...item,
			checked: item.heaven11_track_id === recording.heaven11_track_id,
		}));

		setCheckedRecordingIds([recording.heaven11_track_id]);

		setRecordings(mappedRecordings);
	};

	const handleSelectClose = () => {
		if (cachedIds !== checkedRecordingIds) {
			recordingsState(checkedRecordingIds.filter((id) => id !== 0));
			setResetRecordings(false);
		}

		if (checkedRecordingIds.length) {
			setIsValidInput(true);
		} else {
			setIsValidInput(false);
		}
	};

	const handleResetFilter = () => {
		if (chartMode === 'phono') {
			getRecordings(releaseIds);
		} else {
			getAuthorRecordings(artists);
		}
		setCheckedRecordingIds([]);
		setCheckedRecordings([]);
		recordingsState([]);
		setIsEmptyData(false);
		setTopTrackChoose(null);
		setResetActive(true);
		setCheckedTopTrack({});
		setSearchValue('');
	};

	const handleSelectOpen = () => {
		setCachedIds(checkedRecordingIds);
	};

	useEffect(() => {
		setTopTrackChoose(null);
	}, [resetActive]);

	const valueForInput = () => {
		if (isShowSkeleton || !isLoadedPerformers) {
			return [0];
		} else if (!checkedRecordingIds.length) {
			return [];
		} else if (checkedRecordingIds.length) {
			return checkedRecordingIds;
		} else {
			return [];
		}
	};

	return (
		<FormControl variant="standard" className={s.formControl}>
			<Input
				error={!checkedRecordingIds.length && !isValidInput}
				id="tracks-select-label"
			>
				<FormattedMessage id="rod.statistic.tracks" />
			</Input>

			{chartMode === 'phono' ? (
				<SelectCustom
					labelId="tracks-select-label"
					id="tracks-select"
					multiple
					error={!checkedRecordingIds.length && !isValidInput}
					value={valueForInput()}
					onOpen={handleSelectOpen}
					onClose={handleSelectClose}
					disabled={isShowSkeleton || !isLoadedReleases || !isLoadedPerformers}
					MenuProps={{
						style: { zIndex: 99999999999 },
					}}
					renderValue={() => {
						if (topTrackChoose?.raw_title && !resetActive) {
							return (
								<span className={s.valueInputText}>
									{recordings[1]?.raw_title}
								</span>
							);
						}

						if (
							recordings.length === 1 &&
							recordings[0].heaven11_track_id === 0
						) {
							return (
								<>
									<FormattedMessage id={'rod.statistic.selected'} /> 0{' '}
									<FormattedMessage id={'rod.statistic.selected-tracks'} />
								</>
							);
						}

						if (checkedRecordingIds.filter((id) => id !== 0).length === 1) {
							return (
								<span className={s.valueInputText}>
									{checkedRecordings[0]?.raw_title}
								</span>
							);
						} else if (
							isShowSkeleton ||
							!isLoadedReleases ||
							!isLoadedPerformers
						) {
							return <FormattedMessage id={'rod.statistic.updating-data'} />;
						} else if (!isShowSkeleton && recordings.length !== 0) {
							return (
								<>
									<FormattedMessage id={'rod.statistic.selected'} />{' '}
									{checkedRecordingIds.includes(0)
										? checkedRecordingIds.length - 1
										: checkedRecordingIds.length}{' '}
									<FormattedMessage id={'rod.statistic.selected-tracks'} />
								</>
							);
						}
					}}
				>
					<FormattedMessage id="rod.statistic.search-recordings">
						{(label) => (
							<TextFieldInput
								className={s.search}
								label={label}
								initialValue={searchValue ? searchValue : ''}
								defaultValue={searchValue}
								onChange={debouncedSearchHandler}
								variant="standard"
							/>
						)}
					</FormattedMessage>
					<div className={s.wrapper}>
						{!isShowSkeleton ? (
							recordings.map((recording, index) => (
								<MenuItem
									key={recording.heaven11_track_id}
									value={recording.raw_title}
									onClick={() => handleCheckedRecording(recording)}
								>
									<CheckboxBlack
										checked={checkedRecordingIds.includes(
											recording.heaven11_track_id
										)}
									/>
									<Label
										className={s.selectElement}
										title={`${recording.raw_title} - ${getJSONParse(
											recording.performers
										)}`}
									>
										{recording.raw_title}{' '}
										{recording.heaven11_track_id !== 0
											? `- ${getJSONParse(recording.performers)}`
											: ''}
									</Label>
									{recording.heaven11_track_id !== 0 && (
										<button
											className={s.btnOnly}
											onClick={(e) => handleClickOnly(e, recording)}
										>
											<FormattedMessage id={'rod.statistic.only'} />
										</button>
									)}
								</MenuItem>
							))
						) : (
							<MuiMultipleCheckedSkeleton />
						)}

						{!recordings.length && !isShowSkeleton && (
							<p className={s.noData}>
								<FormattedMessage id={'rod.no_data_select'} />
							</p>
						)}
					</div>
				</SelectCustom>
			) : (
				<SelectCustom
					labelId="tracks-select-label"
					id="tracks-select"
					multiple
					error={!checkedRecordingIds.length && recordings.length !== 0}
					value={valueForInput()}
					onOpen={handleSelectOpen}
					onClose={handleSelectClose}
					disabled={isShowSkeleton || !isLoadedPerformers}
					MenuProps={{
						style: { zIndex: 99999999999 },
					}}
					renderValue={() => {
						if (topTrackChoose?.raw_title && !resetActive) {
							return (
								<span className={s.valueInputText}>
									{recordings[1]?.raw_title}
								</span>
							);
						}

						if (
							recordings.length === 1 &&
							recordings[0].heaven11_track_id === 0
						) {
							return (
								<>
									<FormattedMessage id={'rod.statistic.selected'} /> 0{' '}
									<FormattedMessage id={'rod.statistic.selected-tracks'} />
								</>
							);
						}

						if (checkedRecordingIds.filter((id) => id !== 0).length === 1) {
							return (
								<span className={s.valueInputText}>
									{checkedRecordings[0]?.raw_title}
								</span>
							);
						} else if (isShowSkeleton || !isLoadedPerformers) {
							return <FormattedMessage id={'rod.statistic.updating-data'} />;
						} else if (!isShowSkeleton && recordings.length !== 0) {
							return (
								<>
									<FormattedMessage id={'rod.statistic.selected'} />{' '}
									{checkedRecordingIds.includes(0)
										? checkedRecordingIds.length - 1
										: checkedRecordingIds.length}{' '}
									<FormattedMessage id={'rod.statistic.selected-tracks'} />
								</>
							);
						}
					}}
				>
					<FormattedMessage id="rod.statistic.search-recordings">
						{(label) => (
							<TextFieldInput
								className={s.search}
								label={label}
								initialValue={searchValue ? searchValue : ''}
								defaultValue={searchValue}
								onChange={debouncedSearchHandler}
								variant="standard"
							/>
						)}
					</FormattedMessage>
					<div className={s.wrapper}>
						{!recordings.length && (
							<p className={s.noData}>
								<FormattedMessage id={'rod.no_data_select'} />
							</p>
						)}

						{!isShowSkeleton ? (
							recordings.map((recording, index) => (
								<MenuItem
									key={recording.heaven11_track_id}
									value={recording.raw_title}
									onClick={() => handleCheckedRecording(recording)}
								>
									<CheckboxBlack
										checked={checkedRecordingIds.includes(
											recording.heaven11_track_id
										)}
									/>
									<Label
										className={s.selectElement}
										title={`${recording.raw_title} - ${getJSONParse(
											recording.performers
										)}`}
									>
										{recording.raw_title}{' '}
										{recording.heaven11_track_id !== 0
											? `- ${getJSONParse(recording.performers)}`
											: ''}
									</Label>
									{recording.heaven11_track_id !== 0 && (
										<button
											className={s.btnOnly}
											onClick={(e) => handleClickOnly(e, recording)}
										>
											<FormattedMessage id={'rod.statistic.only'} />
										</button>
									)}
								</MenuItem>
							))
						) : (
							<MuiMultipleCheckedSkeleton />
						)}
					</div>
				</SelectCustom>
			)}

			{!checkedRecordingIds.length && !isValidInput && (
				<p className={s.validateInput}>
					<FormattedMessage id={'rod.release.info.filters.modal.track.error'} />
				</p>
			)}

			<button
				className={s.buttonResetFilter}
				onClick={handleResetFilter}
				style={
					isShowSkeleton || !isLoadedPerformers
						? { opacity: '0.4', pointerEvents: 'none' }
						: {}
				}
			>
				<FormattedMessage id={'rod.statistic.reset-filter'} />
			</button>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectRecordings);
