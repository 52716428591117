export const filterListArray = [
	{
		id: 1,
		title_ru: 'почта',
		title_en: 'email',
		code: 'confirm_email',
		checked: false,
	},
	{
		id: 2,
		title_ru: 'телефон',
		title_en: 'phone',
		code: 'confirm_phone',
		checked: false,
	},
	{
		id: 3,
		title_ru: 'аккаунт создан',
		title_en: 'account created',
		code: 'account,begin',
		checked: false,
	},
	{
		id: 4,
		title_ru: 'выбор роли',
		title_en: 'role(s) selected',
		code: 'role',
		checked: false,
	},
	{
		id: 5,
		title_ru: 'данные',
		title_en: 'personal data',
		code: 'personal_data',
		checked: false,
	},
	{
		id: 6,
		title_ru: 'контакты',
		title_en: 'contact data',
		code: 'contact_data',
		checked: false,
	},
	{
		id: 7,
		title_ru: 'документы',
		title_en: 'documents',
		code: 'documents',
		checked: false,
	},
	{
		id: 8,
		title_ru: 'идентификаторы',
		title_en: 'identifiers',
		code: 'identity',
		checked: false,
	},

	{
		id: 9,
		title_ru: 'платежная информация',
		title_en: 'payment details',
		code: 'money',
		checked: false,
	},
	{
		id: 10,
		title_ru: 'проверка',
		title_en: 'check documents',
		code: "'pending', 'declined_docs'",
		checked: false,
	},
	{
		id: 11,
		title_ru: 'условия',
		title_en: 'conditions',
		code: 'approved',
		checked: false,
	},
	{
		id: 12,
		title_ru: 'подписание',
		title_en: 'signing',
		code: "'sign_sms','sign_physical'",
		checked: false,
	},
	{
		id: 13,
		title_ru: 'проверка договора',
		title_en: 'contracts revision',
		code: "'approved_contracts', 'declined_contracts', 'pending_contracts'",
		checked: false,
	},
	{
		id: 14,
		title_ru: 'репертуар',
		title_en: 'repertoire',
		code: "'repertoire_apply', 'personal_area'",
		checked: false,
	},
];
