// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { getCountryName } from 'material-design/Statistic/StatisticCategory/StatisticByTerritoriesSection/StatisticByTerritoriesChart/utils/getCountryName';

// Styles
import styles from './TopTen.module.css';
import Flag from 'components/Flag';

const TopTen = ({ tenTopCountries }) => {
	const lang = localStorage.getItem('lang');

	return (
		<div className={styles.topTen}>
			<div className={styles.tenTopTableHeading}>
				<span>
					<FormattedHTMLMessage id={'rod.statistic.top-ten-table-country'} />
				</span>
				<span>
					<FormattedHTMLMessage id={'rod.statistic.top-ten-table-listening'} />
				</span>
			</div>
			<ul className={styles.topTenData}>
				{tenTopCountries.map((item, index) => (
					<li>
						<span className={styles.countryPlace}>{index + 1}</span>
						<div className={styles.wrapperCountry}>
							<Flag code={item.code} />{' '}
							<span className={styles.countryName}>{item.code}</span>
						</div>

						<span className={styles.countryValue}>
							{item.auditions.toLocaleString('ru-RU')}
						</span>
						<span className={styles.countryNameTooltip}>
							{getCountryName(item, lang)}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};
export default TopTen;
