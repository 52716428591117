// Core
import { FormattedMessage } from 'react-intl';

// Icons
import info_notice from 'images/info_notice.svg';

// Styles
import styles from './InfoNotice.module.css';

const InfoNotice = ({ text, marginTop, marginBottom }) => {
	return (
		<div
			className={styles.container}
			style={{ marginTop: marginTop || '0', marginBottom: marginBottom || '0' }}
		>
			<img src={info_notice} alt="exclamation mark" />
			<FormattedMessage id={text} />
		</div>
	);
};

export default InfoNotice;
