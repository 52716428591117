import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Tabs.module.css';

const Tabs = ({ handleClickTab, tab }) => {
	const renderItem = (tab) => {
		switch (tab) {
			case 'artist':
				return <FormattedMessage id={'rod.statistic.trends.artist.artist'} />;

			case 'tracks':
				return (
					<FormattedMessage id={'rod.statistic.trends.artist.recordings'} />
				);

			case 'releases':
				return <FormattedMessage id={'rod.statistic.trends.artist.releases'} />;

			case 'audience':
				return <FormattedMessage id={'rod.statistic.trends.artist.audience'} />;

			case 'sources':
				return <FormattedMessage id={'rod.statistic.trends.artist.sources'} />;

			default:
				return <FormattedMessage id={'rod.statistic.trends.artist.artist'} />;
		}
	};

	return (
		<div className={styles.wrapperTabs}>
			{['artist', 'tracks', 'releases', 'audience', 'sources'].map((item) => (
				<button
					key={item}
					className={`${styles.tab} ${tab === item ? styles.active : {}}`}
					onClick={() => handleClickTab(item)}
				>
					<span>{renderItem(item)}</span>
				</button>
			))}
		</div>
	);
};

export default Tabs;
