// Core
import React, { useContext } from 'react';
import { Col, Row } from 'react-grid-system';

// Context
import { RootContext } from 'contexts/RootContext';

// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from '../utils/isDataValidOrDash';

// UI
import { Label } from 'components/Labels';
import Field from 'pages/adminV2/components/Field/Field';

// Styles
import styles from '../AccountData.module.css';

const AccountPaymentInfo = ({ account }) => {
	const { getCountryById } = useContext(RootContext);

	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.admin.account.payment.data')}
					font="--gilroy-Medium-32"
				/>
			</div>
			<Field
				title={fm('rod.admin.account.payment.method')}
				value={
					account.payment_info.type &&
					account.payment_info.type === 'bank_account'
						? fm('rod.admin.account.payment.bank_data')
						: account.payment_info.type
						? 'Paypal'
						: '\u2212'
				}
			/>
			{account.payment_info.type === 'bank_account' && (
				<React.Fragment>
					<Field
						title={fm('rod.admin.account.payment.beneficiary_name')}
						value={isDataValidOrDash(account.payment_info.beneficiary_name)}
					/>
					<Field
						title={fm('rod.admin.account.payment.bank_account_name')}
						value={isDataValidOrDash(account.payment_info.bank_account_name)}
					/>
					<Row>
						<Col sm={6}>
							<Field
								title={fm('rod.admin.account.payment.bank_name')}
								value={isDataValidOrDash(account.payment_info.bank_name)}
							/>
						</Col>
						<Col sm={6}>
							<Field
								title={fm('rod.admin.account.payment.swift_bic')}
								value={isDataValidOrDash(account.payment_info.swift_code)}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<Field
								title={fm('rod.admin.account.payment.bank_address')}
								value={isDataValidOrDash(account.payment_info.bank_address)}
							/>
						</Col>
						<Col sm={6}>
							<Field
								title={fm('rod.admin.account.payment.bank_country')}
								value={
									account.payment_info.bank_country
										? getCountryById(account.payment_info.bank_country)
										: '\u2212'
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<Field
								title={fm('rod.admin.account.payment.correspondent_account')}
								value={isDataValidOrDash(
									account.payment_info.bank_correspondent_account
								)}
							/>
						</Col>
						<Col sm={6}>
							<Field
								title={fm(
									'rod.admin.account.payment.correspondent_account_swift'
								)}
								value={isDataValidOrDash(
									account.payment_info.bank_correspondent_account_swift_code
								)}
							/>
						</Col>
					</Row>
				</React.Fragment>
			)}
			{account.payment_info.type === 'paypal' && (
				<React.Fragment>
					<Field
						title={fm('rod.admin.account.payment.paypal_account')}
						value={isDataValidOrDash(account.payment_info.paypal_accoun)}
					/>
				</React.Fragment>
			)}
		</section>
	);
};

export default AccountPaymentInfo;
