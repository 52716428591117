// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { useHistory, useRouteMatch } from 'react-router-dom';
import lscache from 'lscache';

// Utils
import { isAllRequiredFilledPersonal } from './utils/isAllRequiredFilledPersonal';

// Context
import { LangContext } from 'contexts/LangContext';

// Constants
import accountTypes from 'constants/accountTypes';

// UI
import {
	SettingsPrivateDataSection,
	SettingsContactDataSection,
	SettingsBankDataSection,
	SettingsPseudonymsSection,
} from './SettingsCategory';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import paymentTypes from 'constants/paymentTypes';
import { Loading } from 'components';
import { ReactComponent as AttentionTriangle } from 'images/attention_triangle.svg';

// Styles
import { styled } from '@mui/material/styles';
import styles from './AccountSettings.module.css';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: 'var(--color-light-grey)',
	color: 'var(--color-dark-grey)',
	font: 'var(--gilroy-Medium-16)',
	fontWeight: '700',
	lineHeight: '20px',
	letterSpacing: '1px',
	padding: '20px 24px',
	border: 'none',
	textAlign: 'center',
	margin: 0,
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		'& > *:first-child': {
			textDecoration: 'line-through',
		},
	},
}));

function AccountSettings(props) {
	const { lang } = useContext(LangContext);

	const [isSectionPrivateReady, setIsSectionPrivateReady] = useState(true);
	const [isSectionContactReady, setIsSectionContactReady] = useState(true);
	const [isSectionBankReady, setIsSectionBankReady] = useState(true);
	const [isSectionPseudonymsReady, setIsSectionPseudonymsReady] = useState(
		true
	);
	const [completedSectionsCount, setCompletedSectionsCount] = useState(4);
	const [privateErrors, setPrivateErrors] = useState({});
	const [contactErrors, setContactErrors] = useState({});
	const [bankErrors, setBankErrors] = useState({});
	const [selectTab, setSelectTab] = useState('private_data');

	const [privateData, setPrivateData] = useState({});
	const [contactData, setContactData] = useState({});
	const [bankData, setBankData] = useState({});
	const [pseudonymsData, setPseudonymsData] = useState({});

	const [loading, setLoading] = useState(true);

	const [personalFromBackFlag, setPersonalFromBackFlag] = useState(false);
	const [contactFromBackFlag, setContactFromBackFlag] = useState(false);
	const [bankFromBackFlag, setBankFromBackFlag] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [pseudonymsFromBackFlag, setPseudonymsFromBackFlag] = useState(false);

	const {
		UIContext: {
			showTitle,
			hideTitle,
			selectSettingsStep,
			setSelectedSettingsStep,
			isPseudonymsEnable,
			isHideBankSettings,
		},
		authContext: { accountId },
	} = props;

	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		if (isHideBankSettings) {
			setCompletedSectionsCount(
				+isSectionPrivateReady +
					+isSectionContactReady +
					+isSectionPseudonymsReady
			);
		} else {
			if (isPseudonymsEnable) {
				setCompletedSectionsCount(
					+isSectionPrivateReady +
						+isSectionContactReady +
						+isSectionBankReady +
						+isSectionPseudonymsReady
				);
			} else {
				setCompletedSectionsCount(
					+isSectionPrivateReady + +isSectionContactReady + +isSectionBankReady
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isSectionPrivateReady,
		isSectionContactReady,
		isSectionBankReady,
		isSectionPseudonymsReady,
	]);

	const isAllRequiredFilledContacts = (data) => {
		if (
			data.country_id &&
			data.zip_code &&
			data.address &&
			data.email &&
			data.phone_code_country_id &&
			data.phone_number
		) {
			return true;
		} else {
			return false;
		}
	};

	const isAllRequiredFilledBank = (data) => {
		switch (data.payment_channel) {
			case paymentTypes.PAYPAL:
				if (data.paypal) {
					return true;
				} else {
					return false;
				}
			case paymentTypes.BANK:
				if (
					data.bank_account_title &&
					data.bank_account_number &&
					data.bank_title &&
					data.bank_account_swift &&
					data.bank_country_id &&
					data.bank_address
				) {
					return true;
				} else {
					return false;
				}
			default:
				break;
		}
	};

	const isAllRequiredFilledPseudonyms = (data = true) => {
		if (data) {
			return true;
		} else {
			return false;
		}
	};

	const isAllDone = (section) => (value) => {
		switch (value) {
			case true:
				if (section === 'private') {
					setIsSectionPrivateReady(true);
				}
				if (section === 'contact') {
					setIsSectionContactReady(true);
				}
				if (section === 'bank') {
					setIsSectionBankReady(true);
				}
				if (section === 'pseudonyms') {
					setIsSectionPseudonymsReady(true);
				}
				break;
			case false:
				if (section === 'private') setIsSectionPrivateReady(false);
				if (section === 'contact') setIsSectionContactReady(false);
				if (section === 'bank') setIsSectionBankReady(false);
				if (section === 'pseudonyms') setIsSectionPseudonymsReady(false);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		setLoading(true);
		const {
			rootContext: {
				getAccountPersonalData,
				getAccountContactsData,
				getAccountPaymentData,
				getAccountPseudonymsData,
			},
		} = props;

		getAccountPersonalData(accountId)
			.then((res) => {
				res = res.data.data;
				isAllRequiredFilledPersonal(res)
					? setIsSectionPrivateReady(true)
					: setIsSectionPrivateReady(false);
				setPrivateData(res);
				setPersonalFromBackFlag(true);
			})
			.catch((err) => {
				console.error('personalData error:', err);
				setLoading(false);
			});

		getAccountContactsData(accountId)
			.then((res) => {
				res = res.data.data;
				!isAllRequiredFilledContacts(res)
					? isAllDone('contact')(false)
					: isAllDone('contact')(true);
				setContactData(res);
				setContactFromBackFlag(true);
			})
			.catch((err) => {
				console.error('contactData error:', err);
				setLoading(false);
			});

		getAccountPaymentData(accountId)
			.then((res) => {
				res = res.data.data;
				!isAllRequiredFilledBank(res)
					? isAllDone('bank')(false)
					: isAllDone('bank')(true);
				setBankData(res);
				setBankFromBackFlag(true);
			})
			.catch((err) => {
				console.error('paymentData error:', err);
				setLoading(false);
			});

		if (isPseudonymsEnable) {
			getAccountPseudonymsData(accountId)
				.then((res) => {
					res = res.data.data;
					!isAllRequiredFilledPseudonyms(res)
						? isAllDone('contact')(false)
						: isAllDone('contact')(true);
					setPseudonymsData(res);
					setPseudonymsFromBackFlag(true);
				})
				.catch((err) => {
					console.error('pseudonymsData error:', err);
					setLoading(false);
				});
		}

		if (match.path.includes('private_data')) {
			setSelectTab('private_data');
			setSelectedSettingsStep('private_data');
		} else if (match.path.includes('contact_data')) {
			setSelectTab('contact_data');
			setSelectedSettingsStep('contact_data');
		} else if (match.path.includes('bank_data')) {
			if (isHideBankSettings) {
				setSelectTab('private_data');
				setSelectedSettingsStep('private_data');
				history.push(`/profile/settings/private_data`);
			} else {
				setSelectTab('bank_data');
				setSelectedSettingsStep('bank_data');
			}
		} else if (match.path.includes('pseudonyms')) {
			setSelectedSettingsStep('pseudonyms');
		} else {
			setSelectTab('private_data');
			setSelectedSettingsStep('private_data');
		}

		showTitle('rod.navbar.settings');
		return function cleanup() {
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (personalFromBackFlag && contactFromBackFlag && bankFromBackFlag) {
			setLoading(false);
		}
	}, [personalFromBackFlag, contactFromBackFlag, bankFromBackFlag]);

	const getLink = (name) => {
		switch (name) {
			case 'private_data':
				setSelectTab('private_data');
				setSelectedSettingsStep('private_data');
				return history.push(`/profile/settings/private_data`);
			case 'contact_data':
				setSelectTab('contact_data');
				setSelectedSettingsStep('contact_data');
				return history.push(`/profile/settings/contact_data`);
			case 'bank_data':
				setSelectTab('bank_data');
				setSelectedSettingsStep('bank_data');
				return history.push(`/profile/settings/bank_data`);
			case 'pseudonyms':
				setSelectedSettingsStep('pseudonyms');
				return history.push(`/profile/settings/pseudonyms`);
			default:
				history.push(`/profile/settings/private_data`);
				break;
		}
	};

	const submitPrivateData = (data) => {
		const {
			rootContext: { updateAccountPersonalData, updateAccountCompanyData },
		} = props;

		switch (data.business_type) {
			case accountTypes.COMPANY:
				updateAccountCompanyData(accountId, data)
					.then((res) => {
						res = res.data.data;
						setPrivateData(res);
						setPrivateErrors({});
					})
					.catch((data) => {
						console.error(
							'Create - updateAccountCompanyData error.response: ',
							data.response
						);
						setIsSectionPrivateReady(false);
						setPrivateErrors(data.response.data.errors);
					});

				break;
			case accountTypes.PERSONAL:
				updateAccountPersonalData(accountId, data)
					.then((res) => {
						res = res.data.data;
						setPrivateData(res);
						setPrivateErrors({});
					})
					.catch((data) => {
						console.error(
							'Create - updateAccountPersonalData error.response: ',
							data.response
						);
						setIsSectionPrivateReady(false);
						setPrivateErrors(data.response.data.errors);
					});
				break;
			case accountTypes.PERSONAL_ENTREPRENEUR:
				updateAccountPersonalData(accountId, data)
					.then((res) => {
						res = res.data.data;
						setPrivateData(res);
						setPrivateErrors({});
					})
					.catch((data) => {
						console.error(
							'Create - updateAccountPersonalData-PE error.response: ',
							data.response
						);
						setIsSectionPrivateReady(false);
						setPrivateErrors(data.response.data.errors);
					})
					.finally(() => {
						if (!data.national_id_expiration_date) {
							setPrivateErrors((prev) => ({
								...prev,
								national_id_expiration_date: [
									{ rule: 'required_if', value: 'individual' },
								],
							}));
						}
					});
				break;
			default:
				break;
		}
	};

	const submitContactData = (data) => {
		const {
			rootContext: { updateAccountContactsData },
		} = props;

		updateAccountContactsData(accountId, data)
			.then((res) => {
				res = res.data.data;
				setContactData(res);
				setContactErrors({});
				lscache.remove('currencyCash');
			})
			.catch((data) => {
				console.error(
					'Create - updateAccountContactsData error.response: ',
					data.response
				);
				setIsSectionContactReady(false);
				setContactErrors(data.response.data.errors);
			});
	};

	const submitBankData = (data) => {
		const {
			rootContext: { updateAccountPaymentData },
		} = props;

		updateAccountPaymentData(accountId, data)
			.then((res) => {
				res = res.data.data;
				setBankData(res);
				setBankErrors({});
			})
			.catch((data) => {
				console.error(
					'Create - updateAccountPersonalData error.response: ',
					data.response
				);
				setIsSectionBankReady(false);
				setBankErrors(data.response.data.errors);
			});
	};

	const refreshPseudonymsList = (accId) => {
		const {
			rootContext: { getAccountPseudonymsData },
		} = props;
		getAccountPseudonymsData(accId)
			.then((res) => {
				res = res.data.data;
				!isAllRequiredFilledPseudonyms(res)
					? isAllDone('pseudonyms')(false)
					: isAllDone('pseudonyms')(true);
				setPseudonymsData(res);
				setPseudonymsFromBackFlag(true);
			})
			.catch((err) => {
				console.error('pseudonymsData error:', err);
				setLoading(false);
			});
	};

	const submitPseudonymsData = (action, data) => {
		const {
			rootContext: { updatePseudonym, deletePseudonym },
		} = props;

		switch (action) {
			case 'update':
				return updatePseudonym(
					data.accountId,
					data.pseudonymId,
					data.body
				).then(() => refreshPseudonymsList(data.accountId));
			case 'delete':
				return deletePseudonym(data.accountId, data.pseudonymId).then(() =>
					refreshPseudonymsList(data.accountId)
				);

			default:
				return Promise.reject(new Error('Invalid action'));
		}
	};

	const getTabsCount = () => {
		if (!isHideBankSettings && isPseudonymsEnable) {
			return 4;
		} else if (isHideBankSettings && !isPseudonymsEnable) {
			return 2;
		} else {
			return 3;
		}
	};

	return (
		<>
			{loading ? (
				<Loading non_margin={true} />
			) : (
				<>
					{completedSectionsCount !== getTabsCount() && (
						<div className={styles.info}>
							<AttentionTriangle className={styles.attentionIcon} />
							<FormattedHTMLMessage id={'rod.account.settings.header'} />
							&nbsp;{' '}
							<span className={styles.Red_color}>
								({completedSectionsCount} {lang === 'en' ? `of` : `из`}{' '}
								{getTabsCount()})
							</span>
						</div>
					)}
					<TabsUnstyled
						defaultValue={selectSettingsStep ? selectSettingsStep : selectTab}
						className={styles.settings}
					>
						<TabsListUnstyled className={styles.header}>
							<HeaderButton
								value={'private_data'}
								onClick={() => getLink('private_data')}
							>
								<FormattedHTMLMessage
									id={'rod.account.settings.private_data'}
								/>
								{!isSectionPrivateReady && (
									<span className={styles.notReady}></span>
								)}
							</HeaderButton>
							<HeaderButton
								value={'contact_data'}
								onClick={() => getLink('contact_data')}
							>
								<FormattedHTMLMessage
									id={'rod.account.settings.contact_data'}
								/>
								{!isSectionContactReady && (
									<span className={styles.notReady}></span>
								)}
							</HeaderButton>
							{!isHideBankSettings && (
								<HeaderButton
									value={'bank_data'}
									onClick={() => getLink('bank_data')}
								>
									<FormattedHTMLMessage id={'rod.account.settings.bank_data'} />
									{!isSectionBankReady && (
										<span className={styles.notReady}></span>
									)}
								</HeaderButton>
							)}
							{isPseudonymsEnable && (
								<HeaderButton
									value={'pseudonyms'}
									onClick={() => getLink('pseudonyms')}
								>
									<FormattedHTMLMessage
										id={'rod.account.settings.pseudonyms'}
									/>
									{!isSectionPseudonymsReady && (
										<span className={styles.notReady}></span>
									)}
								</HeaderButton>
							)}
						</TabsListUnstyled>
						<div className={styles.main}>
							<TabPanelUnstyled value={'private_data'}>
								<SettingsPrivateDataSection
									data={privateData}
									onChange={submitPrivateData}
									isAllDone={isAllDone}
									errors={privateErrors}
									setIsSectionPrivateReady={setIsSectionPrivateReady}
								/>
							</TabPanelUnstyled>
							<TabPanelUnstyled value={'contact_data'}>
								<SettingsContactDataSection
									data={contactData}
									accountType={privateData}
									onChange={submitContactData}
									isAllDone={isAllDone}
									errors={contactErrors}
								/>
							</TabPanelUnstyled>
							{!isHideBankSettings && (
								<TabPanelUnstyled value={'bank_data'}>
									<SettingsBankDataSection
										data={bankData}
										accountType={privateData}
										onChange={submitBankData}
										isAllDone={isAllDone}
										errors={bankErrors}
									/>
								</TabPanelUnstyled>
							)}
							{isPseudonymsEnable && (
								<TabPanelUnstyled value={'pseudonyms'}>
									<SettingsPseudonymsSection
										data={pseudonymsData}
										onChange={submitPseudonymsData}
										isAllDone={isAllDone}
										errors={bankErrors}
									/>
								</TabPanelUnstyled>
							)}
						</div>
					</TabsUnstyled>
				</>
			)}
		</>
	);
}

export default compose(withUI, withAuth, withRoot)(AccountSettings);
