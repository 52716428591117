// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import NotifyItem from '../NotifyItem/NotifyItem';
import bell from 'images/bell_dash.svg';

// Styles
import styles from '../Notify.module.css';

const NotifyList = ({
	notifications,
	showInfoHandling,
	viewedCount,
	containerRef,
}) => {
	return (
		<div ref={containerRef} style={{ overflowY: 'auto', maxHeight: '800px' }}>
			{notifications.length ? (
				<div>
					{notifications.map((item, index) => (
						<div
							key={index}
							className={styles.itemWrapper}
							onClick={() => {
								showInfoHandling(item.id);
							}}
						>
							<NotifyItem key={item.id} item={item} viewedCount={viewedCount} />
						</div>
					))}
				</div>
			) : (
				<div className={styles.notifyEmpty}>
					<img className={styles.notifyEmpty__img} src={bell} alt="" />
					<p className={styles.notifyEmpty__text}>
						<FormattedMessage id={'rod.notification.empty'} />
					</p>
				</div>
			)}
		</div>
	);
};

export default NotifyList;
