// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Icons
import error from 'images/error_attention_icon.svg';

// Styles
import styles from './Wizard.module.css';

class WizardStep extends Component {
	static propTypes = {
		status: PropTypes.oneOf(['disabled', 'active', 'complete', 'default']),
		title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	};

	handleOnClick = (status, step) => {
		if (status === 'complete' && this.props.handleOpenPrevTabs) {
			this.props.handleOpenPrevTabs(step.id);
		}
	};

	render() {
		const {
			title,
			status,
			step,
			handleOpenPrevTabs,
			showNotice,
			...props
		} = this.props;
		const isH11Edit = window.location.href.includes('/edit/');

		return (
			<div
				{...props}
				className={cn(
					this.props.handleOpenPrevTabs
						? styles.WizardStepClickable
						: styles.WizardStep,
					status
				)}
				style={isH11Edit && step.id === 3 ? { cursor: 'not-allowed' } : {}}
				onClick={() => this.handleOnClick(status, step)}
			>
				<div className={styles.flex}>
					{showNotice && <img src={error} alt="error" />}
					{title}
				</div>
			</div>
		);
	}
}

export default WizardStep;
