import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { accounts } from 'services';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { convertDataToCumulative } from '../utils/convertDataToCumulative';
import { cumulativeDataToUnitsThousMill } from '../utils/cumulativeDataToUnitsThousMill';
import { getDateInYYYYMMDD } from 'material-design/Statistic/StatisticCategory/utils/getDateInYYYYMMDD';
import { checkRangeInOneYear } from 'material-design/Statistic/StatisticCategory/utils/checkRangeInOneYear';

//Components
import FiltersDetail from 'material-design/Statistic/Trends/FiltersDetail/FiltersDetail';

import DevicesAndOs from './DevicesAndOs/DevicesAndOs';
import Streams from './Streams/Streams';

import {
	sourcesListYoutube,
	sourcesListApple,
	sourcesListSpotify,
} from './Streams/configs/sourcesList';
import styles from './Sources.module.css';

const Sources = ({ accountId, typePage }) => {
	let { pathname } = useLocation();
	const { params } = useRouteMatch();
	const { lang } = useContext(LangContext);

	// Date Picker
	const { showModal } = useContext(UIContext);
	let maxY = 0;
	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
		sort: 'desc',
	});
	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [errors, setErrors] = useState({});
	const [applyDate, setApplyDate] = useState(false);

	const [isFirstRender, setIsFirstRender] = useState(true);

	const [sidebarFiltersData, setSideBarFiltersData] = useState({
		performers: [],
		releases: [],
		recordings: [],
		countries: [],
	});

	const [devices, setDevices] = useState([]);
	const [isLoadingDevices, setIsLoadingDevices] = useState(true);

	const [os, setOs] = useState([]);
	const [isLoadingOs, setIsLoadingOs] = useState(true);

	// streams
	const [amountYAxisTitle, setAmountYAxisTitle] = useState('');
	const [accountAuditions, setAccountAuditions] = useState([]);
	const [xAxisSize, setXAxisSize] = useState(0);
	const [outletsStatistic, setOutletsStatistic] = useState({});
	const [isLoadingStreams, setIsLoadingStreams] = useState(true);

	// Outlets
	const [outletCodes, setOutletCodes] = useState([]);

	// Countries
	const [noCountries, setNoCountries] = useState(false);
	const [isInitial, setIsInitial] = useState(false);
	const [countries, setCountries] = useState([]);
	const [countryFromPage, setCountryFromPage] = useState(null);
	const [filtersData, setFiltersData] = useState({
		countries: [],
	});
	const [checkedAllCountries, setAllCheckedCountries] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const [isAllLoading, setIsAllLoading] = useState(true);

	const [outletSelected, setOutletSelected] = useState('youtube');

	const handleCheckedOutlets = (outlets) => {
		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData.outlets = outlets;
		}
	};

	const handleCloseFilterOutlets = () => {
		setStatisticData({ ...statisticData });
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	const handleCheckedCountry = (checkedCountries, isAllChecked) => {
		if (checkedCountries || isAllChecked) {
			setNoCountries(false);
		}

		if (!checkedCountries) {
			return;
		}

		if (isInitial) {
			setCountries(checkedCountries);
			return;
		}

		setCountryFromPage(null);
		filtersData.countries = isAllChecked ? [] : checkedCountries;

		if (isAllChecked) {
			setAllCheckedCountries(true);
		}

		setFiltersData({ ...filtersData });
		setSideBarFiltersData({ ...filtersData });

		if (!isAllChecked && !filtersData.countries.length) {
			setDisabled(true);
			setNoCountries(true);
		} else {
			setNoCountries(false);
		}
	};

	const getDevices = async () => {
		let sourceDevices = [
			{
				device_type: 'Mobile phone',
				percentage: null,
			},
			{
				device_type: 'Computer',
				percentage: null,
			},
			{
				device_type: 'Tablet',
				percentage: null,
			},
			{
				device_type: 'TV',
				percentage: null,
			},
			{
				device_type: 'Unknown',
				percentage: null,
			},
		];

		setIsLoadingDevices(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getSourcesDevices(
				false,
				accountId,
				outletSelected,
				statisticData.start_date,
				statisticData.end_date,
				typePage === 'release' ? [params.id] : [],
				typePage === 'recording' ? [params.id] : [],
				typePage === 'artist' ? [params.id] : [],
				countriesCode?.length ? countriesCode : []
			);

			const data = response.data.data;

			if (data.length) {
				sourceDevices = sourceDevices.map((device) => {
					const updatedDevice = data.find(
						(item) => item.device_type === device.device_type
					);
					return updatedDevice
						? { ...device, percentage: updatedDevice.percentage }
						: device;
				});
				setDevices(sourceDevices);
			} else {
				setDevices([]);
			}

			setIsLoadingDevices(false);
		} catch (error) {
			console.error(error);
			setIsLoadingDevices(false);
		}
	};

	const getOs = async () => {
		let sourceOs = [
			{
				operating_system: 'iOS',
				percentage: null,
			},
			{
				operating_system: 'Android',
				percentage: null,
			},
			{
				operating_system: 'Windows',
				percentage: null,
			},
			{
				operating_system: 'Other',
				percentage: null,
			},
		];
		setIsLoadingOs(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getSourcesOS(
				false,
				accountId,
				outletSelected,
				statisticData.start_date,
				statisticData.end_date,
				typePage === 'release' ? [params.id] : [],
				typePage === 'recording' ? [params.id] : [],
				typePage === 'artist' ? [params.id] : [],
				countriesCode?.length ? countriesCode : []
			);

			const data = response.data.data;

			if (data.length) {
				sourceOs = sourceOs.map((os) => {
					const updatedOs = data.find(
						(item) => item.operating_system === os.operating_system
					);

					return updatedOs ? { ...os, percentage: updatedOs.percentage } : os;
				});
				setOs(sourceOs);
			} else {
				setOs([]);
			}

			setIsLoadingOs(false);
		} catch (error) {
			console.error(error);
			setIsLoadingOs(false);
		}
	};

	const getStreams = async () => {
		setIsLoadingStreams(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getSourcesStreams(
				false,
				accountId,
				outletSelected,
				statisticData.start_date,
				statisticData.end_date,
				typePage === 'release' ? [params.id] : [],
				typePage === 'recording' ? [params.id] : [],
				typePage === 'artist' ? [params.id] : [],
				countriesCode?.length ? countriesCode : []
			);

			if (outletSelected === 'youtube' && response.data.data.length) {
				const trafficSources = [
					{
						source: 'Others',
						types: [
							'YT_OTHER_PAGE',
							'NOTIFICATION',
							'REMIXED_VIDEO',
							'VERTICAL_LIVE_VIDEO',
							'LIVE_REDIRECT',
						],
					},
					{
						source: 'Playlists',
						types: ['SUBSCRIBER', 'PLAYLIST', 'YT_PLAYLIST_PAGE'],
					},
					{
						source: 'Searches',
						types: ['EXT_URL', 'YT_SEARCH', 'YT_CHANNEL', 'HASHTAG_PAGES'],
					},
					{
						source: 'Recommendations',
						types: ['YT_RELATED', 'END_SCREEN', 'RELATED_VIDEO', 'ANNOTATION'],
					},
					{
						source: 'Advertisings',
						types: ['ADVERTISING'],
					},
					{
						source: 'Shorts',
						types: ['SHORTS', 'SOUND_PAGES'],
					},
				];

				const processTrafficSource = (data, { source, types }) => {
					const filteredArray = data.filter((item) =>
						types.includes(item.traffic_source_type)
					);

					if (filteredArray.length === 0) {
						return [];
					}

					const groupedByDate = filteredArray.reduce((acc, item) => {
						const key = `${source}_${item.date}`;
						if (!acc[key]) {
							acc[key] = {
								traffic_source_type: source,
								streams: 0,
								date: item.date,
							};
						}
						acc[key].streams += Number(item.streams);
						return acc;
					}, {});

					return Object.values(groupedByDate);
				};

				const processedData = trafficSources.flatMap((source) =>
					processTrafficSource(response.data.data || [], source)
				);

				setAccountAuditions(processedData);
			} else {
				setAccountAuditions(response.data.data);
			}

			setIsLoadingStreams(false);
		} catch (error) {
			console.error(error);
			setIsLoadingStreams(false);
		}
	};

	const getDataForEachOutlet = (selectedOutlet, startDate, endDate) => {
		let result = [];

		// working with date
		if (startDate.includes('T')) {
			startDate = startDate.slice(0, startDate.indexOf('T'));
		}
		if (endDate.includes('T')) {
			endDate = endDate.slice(0, endDate.indexOf('T'));
		}

		const rangeInDays = Math.ceil(
			(new Date(endDate).getTime() - new Date(startDate).getTime()) / 86400000
		);

		const xSize =
			rangeInDays <= 10
				? rangeInDays
				: Math.ceil(rangeInDays / Math.ceil(rangeInDays / 10));

		setXAxisSize(xSize);

		const accountAuditionsByOutlet = accountAuditions.filter(
			(element) => element.traffic_source_type === selectedOutlet.source
		);
		maxY = 0;
		for (let i = 0; i <= rangeInDays; i++) {
			let startDateinMs = new Date(
				new Date(startDate).getTime() + 86400000 * i
			);
			let y;
			let x =
				startDateinMs.getFullYear() +
				'-' +
				(startDateinMs.getMonth() + 1).toString().padStart(2, '0') +
				'-' +
				startDateinMs.getDate().toString().padStart(2, '0');
			let succesfullyCompared = accountAuditionsByOutlet.find(
				(data) => data.date === x
			);
			y = succesfullyCompared ? +succesfullyCompared.streams : 0;
			if (y > maxY) {
				maxY = y;
			}
			result.push({
				x: new Date(x),
				y: y,
			});
		}
		return result;
	};

	const prepareFinalData = () => {
		setApplyDate(false);
		let newStatForSelectedOutlets = {};
		let index = 1;

		if (outletSelected === 'youtube') {
			sourcesListYoutube.forEach((selectedOutlet) => {
				newStatForSelectedOutlets['id' + index++] = {
					title: selectedOutlet[`title${lang.toUpperCase()}`],
					code: selectedOutlet.source,
					data: getDataForEachOutlet(
						selectedOutlet,
						statisticData.start_date,
						statisticData.end_date
					),
					maxY: maxY,
				};
			});
		} else if (outletSelected === 'apple') {
			sourcesListApple.forEach((selectedOutlet) => {
				newStatForSelectedOutlets['id' + index++] = {
					title: selectedOutlet[`title${lang.toUpperCase()}`],
					code: selectedOutlet.source,
					data: getDataForEachOutlet(
						selectedOutlet,
						statisticData.start_date,
						statisticData.end_date
					),
					maxY: maxY,
				};
			});
		} else {
			sourcesListSpotify.forEach((selectedOutlet) => {
				newStatForSelectedOutlets['id' + index++] = {
					title: selectedOutlet[`title${lang.toUpperCase()}`],
					code: selectedOutlet.source,
					data: getDataForEachOutlet(
						selectedOutlet,
						statisticData.start_date,
						statisticData.end_date
					),
					maxY: maxY,
				};
			});
		}

		const cumulativeVariant = convertDataToCumulative(
			newStatForSelectedOutlets
		);

		cumulativeDataToUnitsThousMill(cumulativeVariant, setAmountYAxisTitle);
		setOutletsStatistic({ ...cumulativeVariant });
	};

	const fullRequests = async () => {
		setIsLoadingStreams(true);
		setIsLoadingDevices(true);
		setIsLoadingOs(true);
		setIsAllLoading(true);
		try {
			await getStreams();
			await getDevices();
			await getOs();
			setIsAllLoading(false);
		} catch (error) {
			console.error(error);
			setIsAllLoading(false);
		}
	};

	useEffect(() => {
		prepareFinalData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountAuditions]);

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	useEffect(() => {
		fullRequests();
	}, [statisticData, sidebarFiltersData, typePage, outletSelected]);

	return (
		<>
			<FiltersDetail
				accountId={accountId}
				lang={lang}
				handleCheckedOutlets={handleCheckedOutlets}
				changeField={changeField}
				statisticData={datePickerData}
				errors={errors}
				handleCheckedPeriod={handleCheckedPeriod}
				setApplyDate={setApplyDate}
				applyDateFilter={applyDateFilter}
				setIsLastCalendar={setIsLastCalendar}
				handleCheckedCountry={handleCheckedCountry}
				handleCloseFilterOutlets={handleCloseFilterOutlets}
				isAllLoading={isAllLoading}
				sourcesPage={true}
				setOutletSelected={setOutletSelected}
				outletSelected={outletSelected}
			/>
			<Streams
				data={outletsStatistic}
				selectedOutlets={
					statisticData['outlets'].length
						? statisticData['outlets']
						: outletCodes
				}
				xAxisSize={xAxisSize}
				amountYAxisTitle={amountYAxisTitle}
				accountAuditions={accountAuditions}
				isLoadingStreams={isLoadingStreams}
				outletSelected={outletSelected}
			/>
			<DevicesAndOs
				dataDevices={devices}
				dataOs={os}
				isLoadingDevices={isLoadingDevices}
				isLoadingOs={isLoadingOs}
			/>
		</>
	);
};

export default Sources;
