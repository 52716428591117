import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getJSONParse } from 'utils';

import styles from './TableElement.module.css';
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

const TableElement = ({ item, index, topMode, accountId }) => {
	const [isImageBroken, setIsImageBroken] = useState(false);

	// const checkImage = async () => {
	// 	if (item.cover) {
	// 		try {
	// 			const response = await fetch(item.cover, { method: 'HEAD' });
	// 			if (!response.ok) {
	// 				setIsImageBroken(true);
	// 			}
	// 		} catch {
	// 			setIsImageBroken(true);
	// 		}
	// 	} else {
	// 		setIsImageBroken(true);
	// 	}
	// };

	// useEffect(() => {
	// 	checkImage();
	// }, [item.cover]);

	return (
		<div className={styles.wrapper}>
			<span className={styles.number}>{index + 1}</span>
			<div className={styles.content}>
				<div className={styles.cover}>
					<img
						src={!item.cover?.length ? emptyCoverAlbum : item.cover}
						alt=""
						width={40}
						height={40}
					/>
					{/* <img
						src={
							!item.cover?.length || isImageBroken
								? emptyCoverAlbum
								: item.cover
						}
						alt=""
						width={40}
						height={40}
					/> */}
				</div>
				<div className={styles.info}>
					<Link
						to={
							topMode !== 'releases'
								? `/accounts/${accountId}/statistic/trends/recording/${item.id}`
								: `/accounts/${accountId}/statistic/trends/release/${item.heaven11_release_id}`
						}
					>
						<p className={styles.title}>
							{topMode === 'releases' ? item.release_name : item.raw_title}
						</p>
					</Link>
					<p className={styles.description}>{getJSONParse(item.performers)}</p>
				</div>
				<p className={styles.streams}>
					{topMode === 'releases'
						? Number(item.stat_count).toLocaleString('ru-RU')
						: Number(item.streams).toLocaleString('ru-RU')}
				</p>
			</div>
		</div>
	);
};

export default TableElement;
