// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import CmoBtn from '../CmoBtn/CmoBtn';
import { PerformerItem } from 'material-design/ReleaseCreate/PerformerItem';

// Styles
import s from './AuthorMain.module.css';

const AuthorMain = ({
	index,
	handleOnChange,
	handleOnFocus,
	handleOnBlur,
	deleteAuthor,
	errors,
	selectPerson,
	tmpInputValue,
	onSave,
	isLoading,
	handleChangeArtist,
	isH11Edit,
	handleOpenModal,
}) => {
	const getPerformer = () => {
		if (selectPerson.contributors && selectPerson.contributors[index]) {
			if (selectPerson.contributors[index].contributor_author_id) {
				return selectPerson.contributors[index].contributor_author_id;
			} else if (selectPerson.contributors[index].title) {
				return { name: selectPerson.contributors[index].title };
			} else return '';
		} else return '';
	};

	return (
		<div className={s.grid} key={index}>
			<div className={s.input}>
				<PerformerItem
					key={index}
					index={index}
					performer={getPerformer()}
					personal={selectPerson.contributors[index]}
					handleChangeArtist={handleChangeArtist}
					errors={
						errors.title && errors.title.some((item) => item.key === index)
							? errors
							: {}
					}
					cleanErrorsField={() => {}}
					handleOpenModal={handleOpenModal}
					handleAddArtist={() => {}}
					handleDeleteArtist={() => {}}
					dataField="contributor_author_id"
					label="rod.release.create.step.recording_info.label.author"
					values={{ id: index + 1 }}
					styles={{ width: '100%', paddingTop: '8px' }}
					hideCrossIcon
					isH11Edit={isH11Edit}
					lastIndex={1}
				/>
				{Object.keys(errors).includes('title') &&
					errors['title'].map(
						(item) =>
							item.key === index &&
							(item.type === 'max_length' ? (
								<span className={s.helper}>
									<FormattedMessage
										id={`rod.error.max_length`}
										values={{
											num: item.value,
										}}
									/>
								</span>
							) : (
								<>
									{errors.title &&
										errors.title.some((item) => item.key === index) && (
											<span className={s.helper}>
												<FormattedMessage
													id={`rod.release_create.error.required`}
												/>
											</span>
										)}
								</>
							))
					)}
			</div>
			<div className={s.input}>
				<FormInput
					type={'muiAutocompleteMultiple'}
					name={'roles'}
					onChange={handleOnChange(index)}
					errors={
						errors.roles && errors.roles.some((item) => item === index)
							? errors
							: {}
					}
					data={selectPerson.contributors[index]}
					label={
						<FormattedMessage
							id={'rod.release.create.step.recording_info.label.author_role'}
							values={{ id: index + 1 }}
						/>
					}
				/>
				{Object.keys(errors).includes('roles') &&
					errors['roles'].includes(index) &&
					errors.roles.some((item) => item === index) && (
						<span className={s.rolesHelper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
			</div>
			<div
				className={`${s.input} ${s.info} ${s.title__info} ${s.authorData} ${s.ownershipContainer}`}
			>
				<FormInput
					type={'muiSlider'}
					name={'ownership'}
					onChange={handleOnChange(index)}
					onFocus={handleOnFocus(index)}
					onBlur={handleOnBlur(index)}
					tmpInputValue={tmpInputValue}
					errors={errors}
					data={selectPerson.contributors[index]}
					labelSlider={
						<FormattedMessage
							id={'rod.release.create.step.recording_info.label.author_share'}
							values={{ id: index + 1 }}
						/>
					}
					width={92}
					required
					bind={selectPerson?.contributors[index]?.publisher_show}
				/>
				{Object.keys(errors).includes('ownership') &&
					errors['ownership'].includes(index) && (
						<span className={s.helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				<CmoBtn
					selectPerson={selectPerson}
					index={index}
					onSave={onSave}
					deleteContributor={deleteAuthor}
					isLoading={isLoading}
					type="author"
					release
				/>
			</div>
		</div>
	);
};

export default AuthorMain;
