// Core
import React, { useEffect, useState, useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import lscache from 'lscache';

// Context
import { UIContext } from 'contexts/UIContext';

// Data
import { narrative } from './utils/narrative';

// Services
import { accounts } from 'services';
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { BottomNavi, Loading, NewHelpInfo } from 'components';
import { InfoButton } from 'components/Buttons';
import { FormInput } from 'components/Form/FormInput';
import LyricWrapper from './LyricWrapper/LyricWrapper';
import InstrumentalTrackSwitcher from 'components/InstrumentalTrackSwitcher/InstrumentalTrackSwitcher';

// Styles
import s from './CompositionLyrics.module.css';

const CompositionLyrics = (props) => {
	const [loading, setLoading] = useState(true);
	const [compositionData, setCompositionData] = useState({});
	const [languages, setLanguages] = useState([]);
	const [isReqLive] = useState(false);
	const [errors, setErrors] = useState({});
	const [disableBtn, setDisableBtn] = useState(false);

	const compositionId = localStorage.getItem('compositionId');
	const history = useHistory();
	const {
		showModal,
		showBackNavi,
		hideBackNavi,
		setIsBottomNaviShow,
	} = useContext(UIContext);

	const MAX_LYRICS_LENGTH = 10000;

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (compositionId) {
			accounts
				.getCompositionData(compositionId)
				.then((res) => {
					res.data.data['created_date'] = res.data.data['created_date']?.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];
					res.data.data['composition_type_id'] = +res.data.data[
						'composition_type_id'
					];
					if (res.data.data?.lyrics_id?.url) {
						getLyricTextFromFile(res.data.data);
					} else {
						setCompositionData(res.data.data);
					}
				})
				.catch((err) => {
					console.error('Error', err);
				})
				.finally(() => setLoading(false));
		}

		const languagesCache = lscache.get('languagesCache');
		if (languagesCache && !isReqLive) {
			setLanguages(languagesCache);
		} else {
			accounts
				.getLanguages()
				.then((res) => {
					res = res.data.data;
					setCache('languages', 'languagesCache', res);
					setLanguages(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		showBackNavi();
		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getLyricTextFromFile = (data) => {
		fetch(data.lyrics_id.url)
			.then((res) => res.text())
			.then((text) => {
				data.lyrics = text;
				setCompositionData(data);
			})

			.catch((err) => {
				setCompositionData(data);
				console.error('Error:', err);
			});
	};

	const changeField = (field) => (e) => {
		setErrors({});

		switch (field) {
			case 'language':
				compositionData[field] = e.id;
				break;
			case 'parental_warning_type':
				compositionData[field] = e.code;
				break;
			default:
				compositionData[field] = e.target.value;
				break;
		}

		setCompositionData({ ...compositionData });
	};

	const handleInstrumental = (value) => {
		compositionData.is_instrumental = value;
		setCompositionData({ ...compositionData });
		setErrors({});
	};

	const isValid = () => {
		let errorCount = 0;
		const newError = {};

		if (!compositionData?.is_instrumental) {
			if (!compositionData.language) {
				newError.language = 'required';
				errorCount++;
			}

			if (!compositionData.parental_warning_type) {
				newError.parental_warning_type = 'required';
				errorCount++;
			}

			if (
				compositionData.lyrics &&
				+compositionData.lyrics.length > MAX_LYRICS_LENGTH
			) {
				newError.lyrics = 'max_length';
				errorCount++;
			}
		}

		if (!errorCount) {
			setErrors({});
			return true;
		} else {
			setErrors(newError);
			return false;
		}
	};

	const nextHandler = () => {
		setDisableBtn(true);
		const valid = isValid();
		if (typeof props.onChange === 'function' && valid) {
			if (compositionData?.is_instrumental) {
				compositionData.lyrics = null;
				compositionData.lyrics_id = null;
			}
			props
				.onChange({ ...compositionData })
				.then()
				.catch()
				.finally(() => setDisableBtn(false));
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			history.push(`/compositions/${compositionId}/authors_shares`);

			props.handlePrevStep();
		}
	};

	const disabledNextBtn =
		!compositionData?.is_instrumental &&
		(!compositionData.language || !compositionData.parental_warning_type);

	return (
		<>
			<div className={s.page}>
				<div className={s.form}>
					{loading ? (
						<Loading />
					) : (
						<>
							{' '}
							<div className={s.titleBlock}>
								<InfoButton
									className={`${s.info} ${s.title__info}`}
									onClick={showModal({
										text: (
											<FormattedHTMLMessage
												id={'rod.release.create.step.tracks.title.help'}
											/>
										),
									})}
								>
									<span className={`${s.title}`}>
										<FormattedHTMLMessage
											id={'rod.release.create.step.lyrics'}
										/>
									</span>
								</InfoButton>
								<InstrumentalTrackSwitcher
									data={compositionData}
									handler={handleInstrumental}
								/>
							</div>
							<NewHelpInfo text="rod.lyrics.help" />
							{!compositionData?.is_instrumental && (
								<>
									<div className={`${s.form__section} ${s.first__section}`}>
										<div className={s.input_leftCol}>
											<FormInput
												type="muiInputSelect"
												name={'language'}
												onChange={changeField}
												errors={errors}
												data={compositionData}
												label={
													<FormattedMessage
														id={
															'rod.release.create.step.recording_info.label.lang'
														}
													/>
												}
												items={languages}
												languages={true}
												required
											/>
											{errors && Object.keys(errors).includes('language') && (
												<span className={s.Helper}>
													{
														<FormattedMessage
															id={`rod.release_create.error.required`}
														/>
													}
												</span>
											)}
										</div>
										<div className={s.input_rightCol}>
											<FormInput
												className={s.narrative}
												type={'MuiSelectNarrative'}
												name={'parental_warning_type'}
												onChange={changeField}
												errors={errors}
												data={compositionData}
												label={
													<FormattedMessage
														id={
															'rod.release.create.step.recording_info.label.non_narrative'
														}
													/>
												}
												items={narrative}
												required
											/>
											{errors &&
												Object.keys(errors).includes(
													'parental_warning_type'
												) && (
													<span className={s.Helper}>
														{
															<FormattedMessage
																id={`rod.release_create.error.required`}
															/>
														}
													</span>
												)}
										</div>
									</div>
									<LyricWrapper
										changeField={changeField}
										errors={errors}
										compositionData={compositionData}
									/>
								</>
							)}
						</>
					)}
				</div>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn || disableBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
};

export default CompositionLyrics;
