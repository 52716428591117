// Core
import React from 'react';

// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from '../utils/isDataValidOrDash';

// UI
import { Label } from 'components/Labels';

// Styles
import styles from '../AccountData.module.css';

const AccountPseudonyms = ({ account }) => {
	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.admin.account.pseudonyms.names_identifiers')}
					font="--gilroy-Medium-32"
				/>
			</div>
			<table className={styles.Table}>
				<thead>
					<tr>
						<th>{fm('rod.admin.account.pseudonyms.identifier')}</th>
						<th>IPI</th>
						<th>ISNI</th>
					</tr>
				</thead>
				<tbody>
					{account.pseudonyms.map((pseudonym) => (
						<tr>
							<td>
								{pseudonym.title || pseudonym.title_ru || pseudonym.title_en}
							</td>
							<td>{isDataValidOrDash(pseudonym.ipi_name_number)}</td>
							<td>{isDataValidOrDash(pseudonym.isni)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</section>
	);
};

export default AccountPseudonyms;
