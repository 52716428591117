// Core
import { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { FaqAutosuggestInput } from 'components/Inputs';

// Styles
import styles from './FaqArticle.module.css';

const FaqArticle = (props) => {
	const { lang } = useContext(LangContext);

	const [faqData, setFaqData] = useState('');
	const [faqSectionData, setFaqSectionData] = useState('');

	const history = useHistory();
	const {
		UIContext: {
			showTitle,
			hideTitle,
			showBackNavi,
			hideBackNavi,
			parentFaq,
			setFaqSearchResult,
		},
		rootContext: { getFaqSearchBySectionId, getFaqSearchByArticleId },
	} = props;

	useEffect(() => {
		history.push(`/faq/section/${props.sectionId}/article/${props.articleId}`);
		getFaqSearchByArticleId(props.sectionId, props.articleId, lang).then(
			(res) => {
				if (res.data) {
					setFaqData(res.data);
				} else {
					history.push(`/faq`);
				}
			}
		);

		getFaqSearchBySectionId(props.sectionId, lang).then((res) => {
			let data = res.data[0];
			data.articles = data.articles.filter(
				(item) => item.slug !== props.articleId
			);

			setFaqSectionData(data);
			showTitle(data.title);
		});

		showBackNavi(parentFaq);

		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.articleId, lang]);

	const updateSearchResult = (result, searchText) => {
		setFaqSearchResult({
			text: searchText,
			data: Array.isArray(result) ? result : [result],
		});

		history.push(`/faq/search`);
	};

	const handleClickArticle = () => {
		const ellementToScroll = document.getElementById('title');
		if (ellementToScroll) {
			ellementToScroll.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
		}
	};

	return (
		<>
			<div className={styles.mainWrapper}>
				<Row className={styles.search__wrapper}>
					<Col className={styles.columnLeft}>
						<div className={styles.wayTitle}>
							<Link to={`/faq`}>
								<FormattedMessage id={'rod.nav.link.faq'} />
							</Link>
							{` / `}
							{faqSectionData && faqSectionData.title ? (
								<Link to={`/faq/section/${faqSectionData.id}`}>
									{faqSectionData.title}
								</Link>
							) : (
								''
							)}
						</div>
					</Col>
					<Col className={styles.columnRight}>
						<FormattedMessage id={'rod.statistic.search'}>
							{(placeholder) => (
								<FaqAutosuggestInput
									className={styles.Input}
									required
									value={{ title: '' }}
									lang={lang}
									onChange={updateSearchResult}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
				{faqSectionData && (
					<Row className={styles.articleText}>
						<Col className={styles.columnLeft}>
							<p className={styles.title}>
								{faqData && faqData.heading ? faqData.heading : ''}
							</p>
							{faqData && faqData.description ? (
								<p
									className={styles.text}
									dangerouslySetInnerHTML={{ __html: faqData.description }}
								/>
							) : (
								''
							)}
							{faqData.file && (
								<div className={styles.picWrapper}>
									<img className={styles.picture} src={faqData.file} alt="" />
								</div>
							)}
						</Col>
						<Col className={styles.columnRight}>
							{faqSectionData && (
								<>
									<Row className={styles.articleTitle}>
										<div className={styles.subTitle__text} id={'title'}>
											<FormattedMessage id={'rod.faq.related_articles'} />
										</div>
									</Row>

									<div className={styles.section__wrapper}>
										{!faqSectionData.articles.length && (
											<span className={styles.noArticlesTitle}>
												<FormattedMessage id='rod.faq.no_related_articles' />
											</span>
										)}
										{faqSectionData.articles.map((item) => (
											<>
												<Row className={styles.textRow}>
													<Col className={styles.columnLeft}>
														<Link
															to={`/faq/section/${props.sectionId}/article/${item.slug}`}
														>
															<span
																className={styles.articlesList}
																onClick={handleClickArticle}
															>
																{item.heading}
															</span>
														</Link>
													</Col>
												</Row>
											</>
										))}
									</div>
								</>
							)}
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};
export default compose(withUI, withAuth, withRoot)(FaqArticle);
