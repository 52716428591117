// Core
import React, { useContext } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

// Context
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getHelpMessage } from '../../../../../../messages/helper';

// UI
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';
import { InfoButton } from 'components/Buttons';
import accountTypes from 'constants/accountTypes';

// Styles
import styles from 'pages/account/Create/steps/03-Info/Info.module.css';

const InfoPE = ({
	business_type,
	errors,
	personal,
	changeField,
	patronymic,
	patronymicCheckboxHandler,
}) => {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);
	const { countries } = useContext(RootContext);

	const personalInfoMessage = DOMPurify.sanitize(
		...getHelpMessage(lang, 'personal_info')
	);
	const personalPeMessage = DOMPurify.sanitize(
		...getHelpMessage(lang, 'personal_info_PE')
	);

	return (
		<div>
			<Row>
				<Col sm={11} offset={{ sm: 1 }} className={styles.Header}>
					<InfoButton
						onClick={showModal({
							text: parse(personalInfoMessage),
						})}
					>
						<Label
							className="header"
							text={<FormattedMessage id={'rod.account.personal.title'} />}
							font="--gilroy-Medium-40"
							display="inline"
							black
						/>
					</InfoButton>

					<Label
						className="subheader"
						text={<FormattedMessage id={'rod.form.required_title'} />}
						font="--gilroy-Medium-18"
						black
					/>
				</Col>
			</Row>
			<Row>
				<Col sm={5} offset={{ sm: 1 }}>
					<div className={styles.Field}>
						<FormInput
							type={'radio'}
							name={'sex'}
							onChange={changeField}
							errors={errors}
							data={personal}
							horizontal
							items={[
								{
									text: <FormattedMessage id={'rod.field.sex.male'} />,
									value: 'male',
								},
								{
									text: <FormattedMessage id={'rod.field.sex.female'} />,
									value: 'female',
								},
							]}
						/>
					</div>
					<FormattedMessage id={'rod.field.firstname'}>
						{(placeholder) => (
							<FormInput
								placeholder={placeholder}
								name={'first_name'}
								onChange={changeField}
								errors={errors}
								required
								data={personal}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.lastname'}>
						{(placeholder) => (
							<FormInput
								placeholder={placeholder}
								name={'last_name'}
								onChange={changeField}
								errors={errors}
								required
								data={personal}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.patronymic'}>
						{(placeholder) => (
							<>
								<FormInput
									placeholder={placeholder}
									name={'patronymic'}
									onChange={changeField}
									errors={errors}
									data={personal}
									style={{
										pointerEvents: patronymic ? 'all' : 'none',
										opacity: patronymic ? '1' : '0.6',
									}}
								/>
								<Checkbox
									text={<FormattedMessage id={'rod.field.no_patronymic'} />}
									className={
										errors.patronymic
											? styles.checkboxWithError
											: styles.checkbox
									}
									checked={!patronymic}
									onChange={patronymicCheckboxHandler}
								/>
							</>
						)}
					</FormattedMessage>
				</Col>
				<Col sm={5}>
					<FormattedMessage id={'rod.field.birthdate'}>
						{(placeholder) => (
							<FormInput
								type="date"
								name={'birth_date'}
								placeholder={placeholder}
								onChange={changeField}
								errors={errors}
								required
								data={personal}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.registration_address'}>
						{(placeholder) => (
							<FormInput
								placeholder={placeholder}
								name={'birth_place'}
								onChange={changeField}
								errors={errors}
								required
								data={personal}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.passport'}>
						{(placeholder) => (
							<FormInput
								placeholder={placeholder}
								name={'national_id_number'}
								onChange={changeField}
								errors={errors}
								required
								data={personal}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.tax_number'}>
						{(placeholder) => (
							<FormInput
								placeholder={placeholder}
								name={'itin'}
								onChange={changeField}
								errors={errors}
								data={personal}
								required={
									window.location.href.includes('.ru') ||
									window.location.href.includes('broma16ru-stage')
										? true
										: false
								}
							/>
						)}
					</FormattedMessage>
					{business_type === accountTypes.PERSONAL && (
						<FormattedMessage id={'rod.field.residence_country'}>
							{(placeholder) => (
								<FormInput
									type="country"
									countries={countries}
									placeholder={placeholder}
									name={'country_id'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									className={styles.CountryInput}
								/>
							)}
						</FormattedMessage>
					)}
				</Col>
			</Row>
			<Row>
				<Col sm={11} offset={{ sm: 1 }} className={styles.Header}>
					<InfoButton
						onClick={showModal({
							text: parse(personalPeMessage),
						})}
					>
						<Label
							text={
								<FormattedMessage
									id={'rod.account.personal_entrepreneur.title'}
								/>
							}
							font="--gilroy-Medium-40"
							display="inline"
							black
						/>
					</InfoButton>
				</Col>
			</Row>
			<Row>
				<Col sm={5} offset={{ sm: 1 }}>
					<div className={styles.FieldsCol}>
						<FormattedMessage id={'rod.field.personal_entrepreneur.name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={'rod.field.personal_entrepreneur.name.helper'}
										/>
									}
									name={'title'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.personal_entrepreneur.signer'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={'rod.field.personal_entrepreneur.signer.helper'}
										/>
									}
									name={'behalf'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage
							id={'rod.field.personal_entrepreneur.signer_short_name'}
						>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={
												'rod.field.personal_entrepreneur.signer_short_name.helper'
											}
										/>
									}
									name={'behalf_short'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
					</div>
				</Col>
				<Col sm={5}>
					<div className={styles.FieldsCol}>
						<FormattedMessage
							id={'rod.field.personal_entrepreneur.registration_date'}
						>
							{(placeholder) => (
								<FormInput
									type="date"
									placeholder={placeholder}
									name={'company_registration_date'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									maxDate={new Date()}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage
							id={'rod.field.personal_entrepreneur.registration_place'}
						>
							{(placeholder) => (
								<FormInput
									type="country"
									countries={countries}
									placeholder={placeholder}
									name={'country_id'}
									onChange={changeField}
									errors={errors}
									data={personal}
									className={styles.CountryInput}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage
							id={'rod.field.personal_entrepreneur.registration_number'}
						>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'company_registration_number'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default InfoPE;
