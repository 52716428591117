// Core
import React, { useContext } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from '../utils/isDataValidOrDash';
import { getPhoneNumber } from '../utils/getPhoneNumber';

// UI
import Field from 'pages/adminV2/components/Field/Field';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels';

// Styles
import styles from '../AccountData.module.css';

const AccountContactData = ({ account, handleChangeLocale }) => {
	const { isAllowLanguageDetection } = useContext(AuthContext);
	const { getCountryById, getCountryDialingCode } = useContext(RootContext);

	return (
		<section>
			<div className={styles.HeaderLine}>
				<Label
					text={fm('rod.admin.account.contact_information')}
					font="--gilroy-Medium-32"
				/>
			</div>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.country')}
						value={
							account.contact_data.country_id
								? getCountryById(account.contact_data.country_id)
								: '\u2212'
						}
					/>
					<Field
						title={fm('rod.admin.account.city')}
						value={isDataValidOrDash(account.contact_data.city)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.zip_code')}
						value={isDataValidOrDash(account.contact_data.zip_code)}
					/>
					<Field
						title={fm('rod.admin.account.address')}
						value={isDataValidOrDash(account.contact_data.address)}
					/>
				</Col>
				<Col sm={4}></Col>
			</Row>

			<Field
				title={fm('rod.admin.account.user_data.email')}
				value={isDataValidOrDash(account.contact_data.email)}
			/>

			<Field
				title={fm('rod.admin.account.user_data.phone')}
				value={getPhoneNumber(
					account.contact_data.phone_code_country_id,
					account.contact_data.phone_number,
					getCountryDialingCode
				)}
			/>
			{account.contact_data.site_url && (
				<Field
					title={fm('rod.admin.account.site')}
					value={isDataValidOrDash(account.contact_data.site_url)}
				/>
			)}
			{isAllowLanguageDetection && (
				<Row>
					<Col>
						<div className={styles.HeaderLine}>
							<Field
								title={fm('rod.admin.account.locale')}
								font="--gilroy-Medium-32"
								value={
									<FormattedMessage
										id={'rod.admin.account.current_locale'}
										values={{
											current_locale: document.domain,
										}}
									/>
								}
							/>
						</div>

						<Checkbox
							onChange={handleChangeLocale}
							checked={account.redirect ? true : false}
						>
							<FormattedMessage id={'rod.admin.account.redirect_locale'} />
						</Checkbox>
					</Col>
				</Row>
			)}
		</section>
	);
};

export default AccountContactData;
