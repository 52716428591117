const ReleaseCompletionStepsIds = {
	0: 'release',
	1: 'file',
	2: 'tracks',
	3: 'authors',
	4: 'lyrics',
	5: 'cover',
	6: 'distribution',
	7: 'confirm',
	8: 'moderation',
};

export default ReleaseCompletionStepsIds;
