// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { compose } from 'recompose';
import { subDays } from 'date-fns';

// UI
import { FormInput } from 'components/Form';
import RadioButton from 'components/Radio/RadioButton/RadioButton';
import H11EditRadioGroup from './H11EditRadioGroup/H11EditRadioGroup';
import DateButton from './DateButton/DateButton';
import ReleaseDateBlock from './ReleaseDateBlock/ReleaseDateBlock';

// Styles
import styles from './RadioGroup.module.css';

class RadioGroup extends React.Component {
	static propTypes = {};

	static defaultProps = {
		name: 'radio_test',
	};

	static displayName = 'RadioGroup';

	state = {
		selected: '',
		errors: this.props.errors ?? {},
	};

	saleStartDateRef = React.createRef();

	componentDidUpdate(prevProps) {
		if (prevProps.errors !== this.props.errors) {
			this.setState((prevState) => ({
				errors: {
					...prevState.errors,
					...this.props.errors,
				},
			}));
		}

		if (this.props.isDateEmpty) {
			this.handleOnBlurDateInput('required');
		}

		if (this.state.errors.sale_start_date) {
			this.saleStartDateRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}

	getChecked = (item) => {
		if (typeof this.props.onChange === 'function') {
			return this.props.value === item.value;
		} else {
			return this.state.selected === item.value;
		}
	};

	handleChange = (item) => (e) => {
		if (this.props.errorsFromInput) {
			this.setState({ errors: {} }, () => {
				this.props.errorsFromInput(this.state.errors);
			});
		}
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(e);
		} else {
			this.setState({ selected: item.value });
		}
	};

	changeField = (field) => (e) => {
		const { editNotices, setEditNotices } = this.props;

		if (editNotices) {
			const cleanEditNotices = editNotices.filter(
				({ code }) => !code.startsWith('distribution')
			);
			localStorage.setItem('editNotices', JSON.stringify(cleanEditNotices));
			setEditNotices(cleanEditNotices);
			// Manually dispatch an event to notify all tabs/components
			window.dispatchEvent(new Event('storage'));
		}

		switch (field) {
			case 'sale_start_date':
				if (this.state.errors.sale_start_date) {
					this.setState({ errors: {} });
					this.props.errorsFromInput({});
				}
				if (this.props.transferRelease) {
					this.props.personal.type = 'transfer';
				}
				if (this.props.inputDate) {
					this.props.personal[field] = e;
				} else {
					if (this.props.errorsFromInput) {
						this.setState({ errors: {} }, () => {
							this.props.errorsFromInput(this.state.errors);
						});
					}
					this.props.personal[field] = e;
					const date = Date.parse(e);
					if (date < 0) {
						return this.setState({
							errors: { sale_start_date: [{ rule: 'time_below-1970' }] },
						});
					}
				}
				break;
			default:
				this.props.personal[field] = e.target.value;
				break;
		}

		this.props.inputChangeField(this.props.personal);
	};

	handleOnBlurDateInput = (err, value) => {
		if (this.props.errorsFromInput) {
			this.setState({ errors: {} }, () => {
				this.props.errorsFromInput(this.state.errors);
			});
		}

		const date = Date.parse(value);

		if (this.props.inputTransferDate && date < 0) {
			return this.setState({
				errors: { sale_start_date: [{ rule: 'time_below-1970' }] },
			});
		}

		if (err === 'required') {
			this.setState(
				{
					errors: { sale_start_date: [{ rule: 'required' }] },
				},
				() => {
					this.props.clearIsDateEmpty();
				}
			);
		}

		if (err === 'maxDate' && this.props.transferRelease) {
			this.setState({
				errors: {
					...this.state.errors,
					sale_start_date: [{ rule: 'maxDate' }],
				},
			});
		}

		if (err && this.props.inputDate) {
			err === 'invalidDate'
				? this.setState(
						{ errors: { sale_start_date: [{ rule: 'wrong_format' }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  )
				: this.setState(
						{ errors: { sale_start_date: [{ rule: err }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  );
		} else if (err && this.props.inputTransferDate) {
			err === 'minDate' || err === 'invalidDate'
				? this.setState(
						{ errors: { sale_start_date: [{ rule: 'wrong_format' }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  )
				: this.setState(
						{ errors: { sale_start_date: [{ rule: err }] } },
						() => {
							this.props.errorsFromInput(this.state.errors);
						}
				  );
		}
	};

	render() {
		const {
			className,
			disabled,
			horizontal,
			items,
			value,
			withFocusRef,
			children,
			transferRelease,
			isH11Edit,
			showRequiredDateError,
			h11Moderation,
			isSlaPrivelegesForAccount,
			lang,
			selectOutletsDateCheckbox,
			changeSelectDateOutletsCheckbox,
			outlets,
			isTimeZoneForAccount,
			personal,
			setPersonal,
			outletsList,
			resetBackErrors,
			setDeletedDistributionOutlets,
			checks,
			setChecks,
			checked,
			setChecked,
			...props
		} = this.props;

		const { errors } = this.state;

		const classes = classNames(
			className,
			styles['RadioGroup'],
			disabled && 'disabled',
			horizontal && 'horizontal'
		);

		if (
			isH11Edit &&
			Object.keys(errors).includes('sale_start_date') &&
			errors?.sale_start_date[0]?.rule === 'minDate'
		) {
			delete errors.sale_start_date;
		}

		return (
			<div className={classes}>
				{!transferRelease &&
					!isH11Edit &&
					items.map((item) => (
						<>
							<div
								key={item.id}
								className={
									item.value === '7+' ||
									item.value === '1-' ||
									item.value === '1+'
										? styles.Input__date
										: styles.radioItem
								}
							>
								<RadioButton
									{...props}
									key={item.id || item.value}
									text={item.text}
									value={item.value}
									disabled={
										item.hasOwnProperty('disabled') ? item.disabled : false
									}
									onChange={this.handleChange(item)}
									checked={this.getChecked(item)}
									className={styles.radioBtnItem}
									itemChildren={item.children}
									showSlaHelp={isSlaPrivelegesForAccount && item.value === '1+'}
								/>

								{item.value === '7+' && !isTimeZoneForAccount && (
									<>
										{this.props.inputDate && (
											<div
												className={styles.input}
												style={{ marginTop: '0px' }}
												ref={this.saleStartDateRef}
											>
												<FormattedMessage
													id={'rod.release.create.step.distribution.input_date'}
												>
													{(placeholder) => (
														<FormInput
															type={'datePickerForAssets'}
															placeholder={placeholder}
															name={'sale_start_date'}
															onChange={this.changeField}
															errors={this.state.errors}
															data={personal}
															className={styles.floatingInput}
															minDate={
																new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
															}
															floatingLabel
														/>
													)}
												</FormattedMessage>
												{showRequiredDateError && (
													<span className={styles.Helper}>
														{<FormattedMessage id={`rod.error.required`} />}
													</span>
												)}
											</div>
										)}
									</>
								)}

								{item.value === '1+' && !isTimeZoneForAccount && (
									<>
										{this.props.inputDate && (
											<div
												className={styles.input}
												style={{ marginTop: '0px' }}
											>
												<FormattedMessage
													id={'rod.release.create.step.distribution.input_date'}
												>
													{(placeholder) => (
														<FormInput
															type={'datePickerForAssets'}
															placeholder={placeholder}
															name={'sale_start_date'}
															onChange={this.changeField}
															errors={this.state.errors}
															data={personal}
															className={styles.floatingInput}
															minDate={
																new Date(Date.now() + 24 * 60 * 60 * 1000)
															}
															floatingLabel
														/>
													)}
												</FormattedMessage>
												{showRequiredDateError && (
													<span className={styles.Helper}>
														{<FormattedMessage id={`rod.error.required`} />}
													</span>
												)}
											</div>
										)}
									</>
								)}

								{item.value === '1-' && (
									<>
										{this.props.inputTransferDate && (
											<div>
												<div className={styles.input}>
													<FormInput
														className={styles.date}
														type={'muiInputDate_v2'}
														maxDate={true}
														defaultValue={false}
														name={'sale_start_date'}
														handleOnBlurDateInput={this.handleOnBlurDateInput}
														onChange={this.changeField}
														errors={this.state.errors}
														data={props.personal}
														label={
															<FormattedMessage
																id={
																	'rod.release.create.step.distribution.input_date'
																}
															/>
														}
													/>
													{Object.keys(this.state.errors).includes(
														'sale_start_date'
													) && (
														<span className={styles.Helper}>
															{
																<FormattedMessage
																	id={`rod.error.${this.state.errors.sale_start_date[0].rule}`}
																/>
															}
														</span>
													)}
												</div>
											</div>
										)}
									</>
								)}

								{(item.value === '7+' || item.value === '1+') &&
									isTimeZoneForAccount &&
									this.props.inputDate &&
									!personal.delivery_start_time && (
										<div>
											<DateButton
												item={item}
												title="rod.release.create.step.distribution_date.button"
												modalType="distribution_date_edit"
												personal={personal}
												setPersonal={setPersonal}
												resetErrors={() => this.setState({ errors: {} })}
												resetBackErrors={resetBackErrors}
											/>
										</div>
									)}
							</div>

							{(item.value === '7+' || item.value === '1+') &&
								isTimeZoneForAccount &&
								personal.delivery_start_time &&
								personal.sale_start_date &&
								this.props.inputDate && (
									<ReleaseDateBlock
										item={item}
										personal={personal}
										setPersonal={setPersonal}
										outlets={outlets}
										selectOutletsDateCheckbox={selectOutletsDateCheckbox}
										changeSelectDateOutletsCheckbox={
											changeSelectDateOutletsCheckbox
										}
										outletsList={outletsList}
										errors={this.state.errors}
										resetErrors={() => this.setState({ errors: {} })}
										resetBackErrors={resetBackErrors}
									/>
								)}
						</>
					))}

				{transferRelease && (
					<div className={styles.input}>
						<FormattedMessage
							id={'rod.release.transfer.step.distribution.input_date'}
						>
							{(placeholder) => (
								<FormInput
									type={'datePickerForAssets'}
									className={`${styles.floatingInput} ${styles.widthCorrect}`}
									placeholder={placeholder}
									name={'sale_start_date'}
									onChange={this.changeField}
									errors={this.state.errors}
									data={personal}
									maxDate={subDays(new Date(), 3)}
									floatingLabel
								/>
							)}
						</FormattedMessage>
					</div>
				)}

				{isH11Edit && (
					<H11EditRadioGroup
						h11Moderation={h11Moderation}
						personal={personal}
						changeField={this.changeField}
						errors={this.state.errors}
						handleOnBlurDateInput={this.handleOnBlurDateInput}
					/>
				)}
				{children}
			</div>
		);
	}
}

export default compose()(RadioGroup);
