// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';

// Services
import { accounts } from 'services';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { showError } from 'validators/showError';
import {
	getMarginLeftForInfoActive,
	getMarginLeftForInfoNotActive,
} from './utils/getMarginLeftForInfo';

// Constants
import ModalTypes from 'constants/modalTypes';

// UI
import { Loading, NewHelpInfo } from 'components';
import { Button } from 'components/Buttons';
import { FormInput } from 'components/Form/FormInput';
import {
	TextFieldInput,
	TextFieldInputForTrends,
} from 'material-design/MuiStyled/MuiStyled';
import Outlets from './Outlets/Outlets';
import { InfoButton } from 'components/Buttons';
import InfoHelp from './InfoHelp/InfoHelp';

// Styles
import styles from './ArtistForm.module.css';

const ArtistForm = ({
	isModalActive,
	data,
	dataField,
	index,
	setIsModalActive,
	onSave,
	trends,
}) => {
	const { accountId } = useContext(AuthContext);
	const { lang } = useContext(LangContext);
	const {
		getArtist,
		getExternalArtist,
		createArtist,
		updateArtist,
	} = useContext(RootContext);
	const { showModal } = useContext(UIContext);

	const formFields = [
		'first_name',
		'last_name',
		'ipi_name_number',
		'ipn',
		'isni',
	];

	const [loading, setLoading] = useState(false);
	const [disableData, setDisableData] = useState(null);
	const [disabledOutlets, setDisabledOutlets] = useState([]);
	const [artist, setArtist] = useState({
		name: '',
		first_name: '',
		last_name: '',
		ipi_name_number: '',
		ipn: '',
		isni: '',
		outlets: [],
	});
	const [disabled, setDisabled] = useState(false);
	const [errors, setErrors] = useState({});
	const [initialOutlets, setInitialOutlets] = useState([]);
	const [initialArtistOutlets, setInitialArtistOutlets] = useState([]);
	const [outletsArr, setOutletsArr] = useState([]);
	const [expanded, setExpanded] = useState(null);
	const [addOutletDisabled, setAddOutletDisabled] = useState(false);
	const [tip, setTip] = useState(null);
	const [showTip, setShowTip] = useState(false);
	const [whereHelpBtnUP, setWhereHelpBtnUP] = useState('');
	const [isArtistNotFilled, setIsArtistNotFilled] = useState(false);

	const artistRequest = trends ? getExternalArtist : getArtist;
	const isShowArtistIdModal =
		!artist.name ||
		!artist.first_name ||
		!artist.last_name ||
		!artist.ipi_name_number ||
		!artist.ipn ||
		!artist.isni;

	useEffect(() => {
		if (isModalActive && data?.artist_id) {
			setLoading(true);
			artistRequest(accountId, data.artist_id)
				.then((res) => {
					const result = res.data.data;
					const artistOutletsIds = result?.outlets?.map((item) => item.id);
					setInitialArtistOutlets(artistOutletsIds);
					setArtist(result);
					if (result.disable) {
						setDisableData(Object.values(result.disable));
						const array = Object.values(result.disable).find((item) =>
							Array.isArray(item)
						);
						if (array) {
							setDisabledOutlets(array);
						}
					}
				})
				.catch()
				.finally(() => setLoading(false));
		} else {
			setArtist({ ...artist, name: data?.name });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		setIsArtistNotFilled(isShowArtistIdModal);
	}, [artist, isShowArtistIdModal]);

	useEffect(() => {
		if (isModalActive) {
			accounts
				.getOutlets(lang)
				.then((res) => {
					res = res.data.data;
					const artistOutlets_Ids = artist?.outlets?.map(
						(item) => item.outlet_id
					);
					const sortedArr = res.filter(
						(item) => !artistOutlets_Ids?.includes(item.id)
					);
					setOutletsArr(sortedArr);
				})
				.catch((err) => console.error('err', err));
		}
	}, [artist.outlets, isModalActive, lang]);

	useEffect(() => {
		if (isModalActive) {
			const artistOutletsIds = artist?.outlets?.map((item) => item.id);
			setInitialOutlets(artistOutletsIds);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			artist.outlets &&
			artist.outlets.some((item) => !item.outlet_id || !item.id_outlet_user)
		) {
			setAddOutletDisabled(true);
		} else {
			setAddOutletDisabled(false);
		}
	}, [artist, artist.outlets]);

	const getClassnameForInfoBtn = (value) => {
		if (whereHelpBtnUP === value || artist[value]) {
			if (process.env.REACT_APP_CLIENT === 'mts') {
				return styles.infoActiveMts;
			} else {
				return styles.infoActive;
			}
		} else {
			if (process.env.REACT_APP_CLIENT === 'mts') {
				return styles.infoMts;
			} else {
				return styles.info;
			}
		}
	};

	const cleanErrorsField = (field) => {
		delete errors[field];
		setErrors(errors);
	};

	const changeField = (field) => (e) => {
		cleanErrorsField(field);
		let updatedArtist = { ...artist };

		switch (field) {
			case 'ipi_name_number':
				if (
					!e.target.value.includes(' ') &&
					!isNaN(+e.target.value) &&
					e.target.value.length <= 11
				) {
					updatedArtist[field] = e.target.value;
				}
				break;
			case 'ipn':
				if (
					!e.target.value.includes(' ') &&
					!isNaN(+e.target.value) &&
					e.target.value.length <= 8
				) {
					updatedArtist[field] = e.target.value;
				}
				break;
			default:
				updatedArtist[field] = e.target.value;
				break;
		}

		setArtist(updatedArtist);
	};

	const changeMask = (e) => {
		setErrors({});
		let updatedArtist = { ...artist };
		updatedArtist.isni = e.target.value;
		setArtist(updatedArtist);
	};

	const changeFieldOutlet = (index) => (field) => (e) => {
		setErrors({});
		let updatedArtist = { ...artist };

		switch (field) {
			case 'outlets':
				updatedArtist[field][index].id = e.id;
				updatedArtist[field][index].outlet_id = e.id;
				updatedArtist[field][index].outlet = e.title;
				setOutletsArr(outletsArr);
				break;
			case 'id_outlet_user':
				const regex = /^[\x20-\x7E]*$/;

				if (
					(regex.test(e.target.value) && e.target.value.length <= 40) ||
					e.target.value === ''
				) {
					updatedArtist['outlets'][index].id_outlet_user = e.target.value;
				}
				break;
			default:
				break;
		}

		setArtist(updatedArtist);
	};

	const handleAddOutlet = () => {
		setErrors({});
		let updatedArtist = { ...artist };
		updatedArtist.outlets.push({
			id: uuidv4(),
			outlet_id: '',
			outlet: '',
			id_outlet_user: '',
		});
		setArtist(updatedArtist);
		setExpanded(updatedArtist.outlets.length - 1);
		setAddOutletDisabled(true);
	};

	const onDeleteOutlet = (id) => {
		setErrors({});
		let updatedArtist = { ...artist };
		updatedArtist.outlets = updatedArtist.outlets.filter(
			(item) => item.id !== id
		);
		setArtist(updatedArtist);
		setExpanded(null);
	};

	const handleFocus = (field) => (e) => {
		setWhereHelpBtnUP(field);
	};

	const handleBlur = (field) => (e) => {
		setTip(null);
		setWhereHelpBtnUP('');
	};

	const onSaveHandler = () => {
		if (isShowArtistIdModal) {
			showModal(
				{
					artistId: true,
					title: <FormattedMessage id="rod.modal.not_filled_artist.title" />,
					text: <FormattedMessage id="rod.modal.not_filled_artist.text" />,
					withClose: true,
					onAction: () => {},
					onCancel: () => handleSubmit(),
					confirmBtnTxt: (
						<FormattedMessage id="rod.modal.not_filled_artist.fill_in" />
					),
					declineBtnTxt: (
						<FormattedMessage id="rod.modal.not_filled_artist.skip" />
					),
				},
				ModalTypes.FULL_MODAL
			)();
		} else {
			handleSubmit();
		}
	};

	const handleSubmit = () => {
		let isError = false;

		if (
			artist.outlets &&
			artist.outlets.length > 0 &&
			artist.outlets.find((item) => !item['id_outlet_user'] && item['outlet'])
		) {
			const index = artist.outlets.findIndex(
				(item) => !item['id_outlet_user'] && item['outlet']
			);

			setErrors((prev) => ({
				...prev,
				id_outlet_user: [{ rule: 'required' }],
			}));
			isError = true;
			if (!expanded && index) {
				setExpanded(index);
			}
		}

		if (
			artist.outlets &&
			artist.outlets.length > 0 &&
			artist.outlets.find((item) => !item['outlet_id'])
		) {
			const index = artist.outlets.findIndex((item) => !item['outlet_id']);

			setErrors((prev) => ({
				...prev,
				outlet_id: [{ rule: 'required' }],
			}));
			isError = true;
			if (!expanded && index) {
				setExpanded(index);
			}
		}

		if (isError) return;
		setDisabled(true);

		// Clean isni field by removing any '_' characters
		const sanitizedArtist = {
			...artist,
			isni: artist.isni ? artist.isni.replace(/_/g, '') : '',
			outlets:
				artist.outlets.length > 0
					? artist.outlets.map((outlet) => {
							if (
								!initialOutlets.includes(outlet.id) &&
								!initialArtistOutlets.includes(outlet.id)
							) {
								const { id, ...rest } = outlet;
								return rest;
							}
							return outlet;
					  })
					: [],
		};

		const artistWithOutletsWithoutId = {
			...sanitizedArtist,
			outlets:
				artist.outlets.length > 0
					? artist.outlets.map((outlet) => {
							const { id, ...rest } = outlet;
							return rest;
					  })
					: [],
		};

		const submitAction = data?.artist_id
			? updateArtist(accountId, data.artist_id, sanitizedArtist)
			: createArtist(accountId, artistWithOutletsWithoutId);

		submitAction
			.then((res) => {
				const savedArtist = res.data.data || sanitizedArtist;
				onSave(savedArtist, index, dataField);
				setIsModalActive(false);
			})
			.catch((error) => {
				setErrors(error.response.data.errors);
				if (
					Object.keys(error.response.data.errors).some((key) =>
						key.includes('id_outlet_user')
					) &&
					Object.values(error.response.data.errors).some(
						(item) => item[0].rule === 'unique_value' && item[0].id
					)
				) {
					const outlet = Object.values(error.response.data.errors).find(
						(item) => item[0].rule === 'unique_value' && item[0].id
					);
					if (outlet) {
						const id = outlet[0].id;
						const outletIndex = artist.outlets.findIndex(
							(item) => item.outlet_id === id
						);
						setExpanded(outletIndex);
					}
				}
			})
			.finally(() => setDisabled(false));
	};

	const getInputType = (field) => {
		if (trends) {
			return field === 'first_name' || field === 'last_name'
				? 'muiInputWithEllipsisForTrends'
				: 'muiInputTrends';
		} else {
			return field === 'first_name' || field === 'last_name'
				? 'muiInputWithEllipsis'
				: 'muiInput';
		}
	};

	return (
		<>
			{loading ? (
				<Loading className={styles.loader} />
			) : (
				<>
					<div key={index}>
						<div className={styles.form}>
							<div className={`${styles.input} ${styles.pseudonym}`}>
								<FormInput
									type={
										trends
											? 'muiInputWithEllipsisForTrends'
											: 'muiInputWithEllipsis'
									}
									name={'name'}
									onChange={changeField}
									errors={errors}
									data={artist}
									label={
										<FormattedMessage
											id={'rod.artist_form.performer_pseudonym'}
										/>
									}
									disabled={
										trends || (disableData && disableData.includes('name'))
									}
									required
								/>
								{Object.keys(errors).includes('name') && (
									<span className={styles.errorHelper}>
										{showError('name')(errors.name[0])}
									</span>
								)}
							</div>
							{formFields.map((field, i) => (
								<div className={styles.input}>
									{field === 'isni' ? (
										<InputMask
											key={i}
											mask={'9999 9999 9999 9999'}
											name={'isni'}
											onChange={changeMask}
											onFocus={handleFocus('isni')}
											onBlur={handleBlur('isni')}
											value={artist.isni ? artist.isni : ''}
											disabled={
												trends || (disableData && disableData.includes('isni'))
											}
										>
											{trends
												? () => (
														<TextFieldInputForTrends
															variant="standard"
															type="muiInput"
															name={'isni'}
															error={
																Object.keys(errors).length > 0 && errors.isni
															}
															value={artist.isni ? artist.isni : ''}
															label={
																<FormattedMessage
																	id={`rod.artist_form.performer_${field}`}
																/>
															}
															disabled={
																trends ||
																(disableData && disableData.includes('isni'))
															}
														/>
												  )
												: () => (
														<TextFieldInput
															variant="standard"
															type="muiInput"
															name={'isni'}
															error={
																Object.keys(errors).length > 0 && errors.isni
															}
															value={artist.isni ? artist.isni : ''}
															label={
																<FormattedMessage
																	id={`rod.artist_form.performer_${field}`}
																/>
															}
															disabled={
																trends ||
																(disableData && disableData.includes('isni'))
															}
														/>
												  )}
										</InputMask>
									) : (
										<FormInput
											key={i}
											type={getInputType(field)}
											name={field}
											onChange={changeField}
											onFocus={handleFocus}
											onBlur={handleBlur}
											errors={errors}
											data={artist}
											label={
												<FormattedMessage
													id={`rod.artist_form.performer_${field}`}
												/>
											}
											disabled={
												trends || (disableData && disableData.includes(field))
											}
										/>
									)}
									{(field === 'ipi_name_number' ||
										field === 'ipn' ||
										field === 'isni') && (
										<InfoButton
											className={getClassnameForInfoBtn(field)}
											style={{
												marginLeft:
													whereHelpBtnUP === field || artist[field]
														? getMarginLeftForInfoActive(field, lang)
														: getMarginLeftForInfoNotActive(field, lang),
											}}
											onClick={() => {
												setShowTip(!showTip);
												setTip({ field, text: `rod.field.${field}.help` });
											}}
										/>
									)}
									{showTip &&
										tip &&
										tip.field === field &&
										field !== 'first_name' &&
										field !== 'last_name' && (
											<div className={styles.tip}>
												<FormattedHTMLMessage id={tip.text} />
											</div>
										)}
									{Object.keys(errors).includes(field) && (
										<span className={styles.errorHelper}>
											{showError(field)(errors[field][0])}
										</span>
									)}
								</div>
							))}
						</div>
						<Outlets
							artist={artist}
							handleAddOutlet={handleAddOutlet}
							onDeleteOutlet={onDeleteOutlet}
							changeFieldOutlet={changeFieldOutlet}
							outletsArr={outletsArr}
							errors={errors}
							expanded={expanded}
							setExpanded={setExpanded}
							addOutletDisabled={addOutletDisabled}
							disabledOutlets={disabledOutlets}
							trends={trends}
						/>
					</div>
					{!trends && <InfoHelp artist={artist} />}
					{!trends && isArtistNotFilled && (
						<NewHelpInfo
							text="rod.modal.not_filled_artist.text.info_help"
							customStyles={{
								font: 'var(--gilroy-Medium-14)',
								marginTop: '-24px',
								marginBottom: '48px',
							}}
						/>
					)}
				</>
			)}
			{onSave && (
				<Button
					className={styles.submitButton}
					variant="primary"
					onClick={onSaveHandler}
					disabled={!artist.name || disabled}
				>
					<FormattedMessage
						id={
							data?.artist_id
								? 'rod.action.save'
								: 'rod.release.create.step.release.label.artists.create_performer'
						}
					/>
				</Button>
			)}
		</>
	);
};

export default ArtistForm;
