// Core
import React, { useEffect, useState } from 'react';

// Icons
import linkIcon from 'images/playlist/link-to-platform.svg';
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

// Styles
import styles from './PlaylistItemInTrack.module.css';

function PlaylistItem({ item, idx, currentPage, outlet }) {
	const [isImageBroken, setIsImageBroken] = useState(false);

	const checkImage = async () => {
		if (item.image) {
			try {
				const response = await fetch(item.image, { method: 'HEAD' });
				if (!response.ok) {
					setIsImageBroken(true);
				}
			} catch {
				setIsImageBroken(true);
			}
		} else {
			setIsImageBroken(true);
		}
	};

	useEffect(() => {
		checkImage();
	}, [item.image]);

	const renderChangeValue = (change, trackPose) => {
		switch (change) {
			case '+':
				return (
					<div className={`${styles.subscribersQuantity} ${styles.up}`}>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.83258 10.4766L12.3026 6.00659L13.4809 7.18492L6.99924 13.6666L0.517578 7.18492L1.69591 6.00659L6.16591 10.4766V0.333252H7.83258V10.4766Z"
								fill="currentColor"
							/>
						</svg>
						{trackPose}
					</div>
				);
			case '-':
				return (
					<div className={`${styles.subscribersQuantity} ${styles.down}`}>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.83258 10.4766L12.3026 6.00659L13.4809 7.18492L6.99924 13.6666L0.517578 7.18492L1.69591 6.00659L6.16591 10.4766V0.333252H7.83258V10.4766Z"
								fill="currentColor"
							/>
						</svg>
						{trackPose}
					</div>
				);
			default:
				return <div className={styles.subscribersQuantity}>{trackPose}</div>;
		}
	};

	const renderItem = (outlet) => {
		switch (outlet) {
			case 'apple':
				return (
					<li
						className={`${styles.playlistListItem} ${
							outlet === 'spotify' ? styles.spotify : {}
						} ${outlet === 'apple' ? styles.apple : {}}`}
					>
						<div className={styles.playlistInfoWrapper}>
							<div className={styles.playlistNumber}>
								{idx + 1 + (currentPage - 1) * 10}
							</div>
							<img
								className={styles.playlistImg}
								src={emptyCoverAlbum}
								alt={item.title}
							/>
							<div className={styles.playlistName}>{item.title}</div>
						</div>
						{renderChangeValue(item.track_pos_change, item.track_pos)}
						<a
							href={item.uri}
							target="_blank"
							className={styles.linkToPlatform}
						>
							<img src={linkIcon} />
						</a>
					</li>
				);
			default:
				return (
					<li className={styles.playlistListItem}>
						<div className={styles.playlistInfoWrapper}>
							<div className={styles.playlistNumber}>
								{idx + 1 + (currentPage - 1) * 10}
							</div>
							<img
								className={styles.playlistImg}
								src={
									!item.image?.length || isImageBroken
										? emptyCoverAlbum
										: item.image
								}
								alt={item.title}
							/>
							<div className={styles.playlistName}>{item.title}</div>
						</div>
						<div className={styles.auditionsQuantity}>{item.streams}</div>
						<div className={styles.subscribersQuantity}>{item.followers}</div>

						<a
							href={item.uri}
							target="_blank"
							className={styles.linkToPlatform}
						>
							<img src={linkIcon} />
						</a>
					</li>
				);
		}
	};

	return <>{renderItem(outlet)}</>;
}

export default PlaylistItem;
