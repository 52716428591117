// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';

// Styles
import styles from '../../ContractTerms.module.css';

const ContractsCurrency = ({ state, changeField, currencies }) => {
	// Extract currency and currency_value from state
	const { currency_value } = state.data;

	// Ensure the selected currency is included in the dropdown options
	const filteredCurrencies = currencies.filter((curr) => curr.available);
	const isCurrencyPresent = currency_value && filteredCurrencies.some(
		(curr) => curr.id === currency_value.id
	);
	const updatedCurrencies = isCurrencyPresent
		? filteredCurrencies
		: [currency_value, ...filteredCurrencies].filter(item => item != null);

	return (
		<div className={styles.Field}>
			<Label className={'header'} black font="--gilroy-Bold-24">
				<FormattedMessage id={'rod.field.contracts.currency.header'} />
			</Label>
			<FormattedMessage id={'rod.field.contracts.currency.placeholder'}>
				{(placeholder) => (
					<FormInput
						defaultEmpty
						type={'dropdown'}
						placeholder={placeholder}
						name={'currency'}
						className="input"
						onChange={changeField}
						errors={state.errors}
						data={state.data}
						items={updatedCurrencies}
						getItem={(item) => {
							return (
								<FormattedHTMLMessage
									id={`rod.field.contracts.currency.${item.title}`}
								/>
							);
						}}
						getListItem={(item) => (
							<FormattedHTMLMessage
								id={`rod.field.contracts.currency.${item.title}`}
							/>
						)}
						getItemValue={(item) => item.id}
						required
					/>
				)}
			</FormattedMessage>
		</div>
	);
};

export default ContractsCurrency;
