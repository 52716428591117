// Core
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { ErrorBanner } from 'components';

// Styles
import styles from './EditNotice.module.css';

const EditNotice = ({ editNotices, step, marginTop, marginBottom }) => {
	const { lang } = useContext(LangContext);

	return (
		<div
			key={uuidv4()}
			className={styles.container}
			style={{
				marginTop: marginTop || '0',
				marginBottom: marginBottom || '24px',
			}}
		>
			{editNotices
				.filter((item) => item.code.includes(step))
				.map((el) => (
					<ErrorBanner text={lang === 'ru' ? el.text_ru : el.text_en} />
				))}
		</div>
	);
};

export default EditNotice;
