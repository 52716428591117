import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { accounts } from 'services';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getDateInYYYYMMDD } from 'material-design/Statistic/StatisticCategory/utils/getDateInYYYYMMDD';
import { checkRangeInOneYear } from 'material-design/Statistic/StatisticCategory/utils/checkRangeInOneYear';
import countriesFiles from 'countries.js';
import outletsColors from '../../../utils/outletsColors';

//Components
import FiltersDetail from 'material-design/Statistic/Trends/FiltersDetail/FiltersDetail';
import ProductTypesGraph from 'material-design/Statistic/Trends/ProductTypesGraph/ProductTypesGraph';
import TopOutletsGraphVis from 'material-design/Statistic/Trends/TopOutletsGraphVis/TopOutletsGraphVis';
import TopCountries from 'material-design/Statistic/Trends/TopCountries/TopCountries';

// Styles
import styles from './Artist.module.css';

const Artist = ({ accountId }) => {
	let { pathname } = useLocation();
	const { params } = useRouteMatch();
	const { lang } = useContext(LangContext);

	// Date Picker
	const { showModal } = useContext(UIContext);
	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
	});
	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [isLastCalendar, setIsLastCalendar] = useState(false);
	const [errors, setErrors] = useState({});
	const [applyDate, setApplyDate] = useState(false);

	const [sidebarFiltersData, setSideBarFiltersData] = useState({
		performers: [],
		releases: [],
		recordings: [],
		countries: [],
	});

	const [isAllLoading, setIsAllLoading] = useState(true);
	// Outlets
	const [outletCodes, setOutletCodes] = useState([]);

	// Countries
	const [noCountries, setNoCountries] = useState(false);
	const [isInitial, setIsInitial] = useState(false);
	const [countries, setCountries] = useState([]);
	const [countryFromPage, setCountryFromPage] = useState(null);
	const [filtersData, setFiltersData] = useState({
		countries: [],
	});
	const [checkedAllCountries, setAllCheckedCountries] = useState(false);
	const [disabled, setDisabled] = useState(false);

	//ProductsType
	const [isLoadingProductsType, setIsLoadingProductsType] = useState(true);
	const [typeProducts, setTypeProducts] = useState([]);
	// TopOutlets
	const [isLoadingTopOutlets, setIsLoadingTopOutlets] = useState(true);
	const [topOutlets, setTopOutlets] = useState([]);
	//TopCountries
	const [isLoadingTopCountries, setIsLoadingTopCountries] = useState(true);
	const [topCountries, setTopCountries] = useState([]);
	const [geo, setGeo] = useState([]);

	const handleCheckedOutlets = (outlets) => {
		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData.outlets = outlets;
			// setStatisticData({ ...statisticData });
		}
	};

	const handleCloseFilterOutlets = () => {
		setStatisticData({ ...statisticData });
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	const handleCheckedCountry = (checkedCountries, isAllChecked) => {
		if (checkedCountries || isAllChecked) {
			setNoCountries(false);
		}

		if (!checkedCountries) {
			return;
		}

		if (isInitial) {
			setCountries(checkedCountries);
			return;
		}

		setCountryFromPage(null);
		filtersData.countries = isAllChecked ? [] : checkedCountries;

		if (isAllChecked) {
			setAllCheckedCountries(true);
		}

		setFiltersData({ ...filtersData });
		setSideBarFiltersData({ ...filtersData });

		if (!isAllChecked && !filtersData.countries.length) {
			setDisabled(true);
			setNoCountries(true);
		} else {
			setNoCountries(false);
		}
	};

	//Requests
	const getTypeProducts = async () => {
		setIsLoadingProductsType(true);
		setIsLoadingTopOutlets(true);
		setIsLoadingTopCountries(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});

		try {
			const response = await accounts.getAccountStatisticTypeProducts(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				sidebarFiltersData.releases?.length ? sidebarFiltersData.releases : [],
				sidebarFiltersData.recordings?.length
					? sidebarFiltersData.recordings
					: [],
				[],
				countriesCode?.length ? countriesCode : [],
				[params.id]
			);
			setTypeProducts(response.data.data.data);
			setIsLoadingProductsType(false);
		} catch (error) {
			console.error(error);
			setIsLoadingProductsType(false);
		}
	};

	const getTopOutlets = async () => {
		setIsLoadingTopOutlets(true);
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});

		try {
			const response = await accounts.getAccountStatisticTopOutlets(
				false,
				accountId,
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				sidebarFiltersData.releases?.length ? sidebarFiltersData.releases : [],
				sidebarFiltersData.recordings?.length
					? sidebarFiltersData.recordings
					: [],
				[],
				countriesCode?.length ? countriesCode : [],
				[params.id]
			);
			let data;
			if (response.data.data.data.length > 6) {
				data = response.data.data.data.slice(0, 5).map((item) => ({
					...item,
					angle: item.percentage,
					color: outletsColors[item.outlet],
				}));
				const othersOutlets = response.data.data.data.slice(5).map((item) => ({
					...item,
					angle: item.percentage,
					color: outletsColors[item.outlet],
				}));

				const sumOtherOutlets = othersOutlets.reduce(
					(acc, cur) => acc + cur.percentage,
					0
				);

				const sumStreamsOtherOutlets = othersOutlets.reduce(
					(acc, cur) => acc + Number(cur.streams),
					0
				);

				data[5] = {
					outlet: 'others',
					angle: Number(sumOtherOutlets.toFixed(1)),
					color: '#D4D4D4',
					percentage: sumOtherOutlets.toFixed(1),
					streams: sumStreamsOtherOutlets,
					othersOutlets,
				};
			} else {
				data = response.data.data.data.map((item) => ({
					...item,
					angle: item.percentage,
					color: outletsColors[item.outlet],
				}));
			}
			setTopOutlets(data);
			setIsLoadingTopOutlets(false);
		} catch (error) {
			console.error(error);
			setIsLoadingTopOutlets(false);
		}
	};

	const getTopCountriesDetail = async () => {
		const countriesCode = sidebarFiltersData.countries.map((item) => {
			if (typeof item === 'object') {
				return item.code;
			} else {
				return item;
			}
		});
		try {
			const response = await accounts.getCountriesArtist(
				false,
				accountId,
				[params.id],
				outletCodes,
				statisticData.start_date,
				statisticData.end_date,
				countriesCode
			);
			setTopCountries(response.data.data);
			setGeo(countriesFiles[0].objects.world.geometries);
			setIsLoadingTopCountries(false);
		} catch (error) {
			console.error(error);
			setIsLoadingTopCountries(false);
		}
	};

	const getFullRequests = async () => {
		setIsAllLoading(true);
		try {
			await getTypeProducts();
			await getTopOutlets();
			await getTopCountriesDetail();
			setIsAllLoading(false);
		} catch (error) {
			console.error(error);
			setIsAllLoading(false);
		}
	};

	useEffect(() => {
		getFullRequests();
	}, [statisticData, sidebarFiltersData]);

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	return (
		<>
			<FiltersDetail
				accountId={accountId}
				lang={lang}
				handleCheckedOutlets={handleCheckedOutlets}
				changeField={changeField}
				statisticData={datePickerData}
				errors={errors}
				handleCheckedPeriod={handleCheckedPeriod}
				setApplyDate={setApplyDate}
				applyDateFilter={applyDateFilter}
				setIsLastCalendar={setIsLastCalendar}
				handleCheckedCountry={handleCheckedCountry}
				handleCloseFilterOutlets={handleCloseFilterOutlets}
				isAllLoading={isAllLoading}
			/>

			<div className={styles.graphsWrapper}>
				<ProductTypesGraph
					typeProducts={typeProducts}
					isLoadingProductsType={isLoadingProductsType}
				/>
				<TopOutletsGraphVis
					topOutlets={topOutlets}
					isLoadingTopOutlets={isLoadingTopOutlets}
				/>
			</div>
			<TopCountries
				geo={geo}
				isLoadingTopCountries={isLoadingTopCountries}
				topCountries={topCountries}
			/>
		</>
	);
};

export default Artist;
