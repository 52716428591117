import Devices from './Devices/Devices';
import Os from './Os/Os';

import styles from './DevicesAndOs.module.css';

const DevicesAndOs = ({
	dataDevices,
	dataOs,
	isLoadingDevices,
	isLoadingOs,
}) => {
	return (
		<div className={styles.wrapper}>
			<Devices data={dataDevices} isLoadingDevices={isLoadingDevices} />
			<Os data={dataOs} isLoadingOs={isLoadingOs} />
		</div>
	);
};

export default DevicesAndOs;
