// Core
import { useState, useEffect } from 'react';

const useReleaseEditNotices = (isH11Edit, h11EditId) => {
	const [editNotices, setEditNotices] = useState(
		JSON.parse(localStorage.getItem('editNotices')) || []
	);

	// Listen for storage changes from other parts of the app
	useEffect(() => {
		const handleStorageChange = () => {
			const updatedNotices =
				JSON.parse(localStorage.getItem('editNotices')) || [];
			setEditNotices(updatedNotices);
		};

		window.addEventListener('storage', handleStorageChange);
		return () => window.removeEventListener('storage', handleStorageChange);
	}, []);

	return [editNotices, setEditNotices];
};

export default useReleaseEditNotices;
