// Core
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import api from 'services/interceptor';
import { withAuth, withRoot, withUI } from 'hocs';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

// Messages
import fieldsEn from 'messages/EN/fields';
import fieldsRu from 'messages/RU/fields';

import { compose } from 'recompose';

// Constants
import STATUSES from './constants/statuses';
import STEPS from './constants/steps';

// Types
import modalTypes from 'constants/modalTypes';

// Context
import { RootContext } from 'contexts/RootContext';
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getIcon } from './utils/getIcon';
import { filterListArray } from './pages/AccountData/utils/filterListArray';

// UI
import ListTable from './components/ListTable/ListTable';
import { Pagination, Input } from 'material-design/components';
import { ReportsAdminModal } from 'material-design/modals/ReportsAdminModal';

// Icons
import filter_svg from 'images/filter.svg';
import filter_dot_svg from 'images/filter_dot.svg';
import remove_cart from 'images/remove_cart.svg';
import remove_cart_dis from 'images/remove_cart_dis.svg';

// Styles
import styles from './List.module.css';

const List = (props) => {
	let { url } = useRouteMatch();
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);
	const { getFlagsWithId, deleteUsersAdmin } = useContext(RootContext);

	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [accountsData, setAccountsData] = useState([]);
	const [settings, setSettings] = useState({});
	const [modal, setModal] = useState(false);
	const [isFilterEnable, setIsFilterEnable] = useState(false);
	const [isFilterApplied, setIsFilterApplied] = useState(false);
	const [filterSettings, setFilterSettings] = useState([]);
	const [disableBtn, setDisableBtn] = useState(false);
	const [deletion, setDeletion] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState({});

	const textRef = useRef(null);
	textRef.current = settings;
	moment.locale(lang === 'ru' ? 'ru' : 'en-gb');

	useEffect(() => {
		const {
			authContext: { adminV2Settings, setAdminV2Settings },
		} = props;

		const fetchData = async () => {
			try {
				const data = await getFlagsWithId();
				let newFilterSettings = [
					{
						id: 1,
						title_ru: 'статус',
						title_en: 'status',
						list: filterListArray,
					},
					{
						id: 2,
						title_ru: 'настройки привилегий',
						title_en: 'privilege settings',
						list: data.account.map((item) => ({
							...item,
							name_ru: item['RU'].toLowerCase(),
							name_en: item['EN'].toLowerCase(),
						})),
					},
					{
						id: 3,
						title_ru: 'прочие фильтры',
						title_en: 'other filters',
						list: [
							{
								id: uuidv4(),
								name_ru: 'неактивные учетки',
								name_en: 'inactive accounts',
								checked: false,
							},
							{
								id: uuidv4(),
								name_ru: fieldsRu['rod.field.admin.accounts-to-check'],
								name_en: fieldsEn['rod.field.admin.accounts-to-check'],
								code: "'pending', 'pending_contracts'",
								checked: false,
							},
							{
								id: uuidv4(),
								name_ru:
									fieldsRu['rod.field.admin.accounts-moderation-requests'],
								name_en:
									fieldsEn['rod.field.admin.accounts-moderation-requests'],
								code: 'payment_moderation',
								checked: false,
							},
						],
					},
				];

				// Check if there are saved settings in localStorage
				const savedSettings = localStorage.getItem('userFilterSettings');
				if (savedSettings) {
					const savedData = JSON.parse(savedSettings);
					if (savedData) {
						const savedStatus = savedData[0]?.list.find((item) => item.checked);
						if (savedStatus) {
							setSelectedStatus(savedStatus);
							adminV2Settings.filterStatus = savedStatus.code;
						} else {
							adminV2Settings.filterStatus = [];
						}

						if (savedData[2].list[1].checked) {
							adminV2Settings.filterStatus = savedData[2].list[1].code;
						}

						if (savedData[2].list[2].checked) {
							adminV2Settings.filterStatus = savedData[2].list[2].code;
						}

						setDeletion(savedData[2].list[0].checked);
						newFilterSettings = savedData;
						setIsFilterApplied(true);
					}
				} else {
					adminV2Settings.filterStatus = [];
				}

				setFilterSettings(newFilterSettings);
				setSettings(adminV2Settings);
			} catch (err) {
				console.error(err);
			}
		};

		fetchData();

		return () => {
			setAdminV2Settings(textRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const makeURL = (
		currentPage = settings.page,
		selectedStatus = settings.filterStatus,
		selectedSearchValue = settings.searchValue
	) => {
		const obj = {
			page: currentPage && `&page=${currentPage}`,
			status: selectedStatus?.length ? `&status=[${selectedStatus}]` : '',
			searchValue: selectedSearchValue ? `&search=${selectedSearchValue}` : '',
		};

		let result = `/admin/users?limit=50${obj.page}${obj.status}${obj.searchValue}`;
		const flagFilterIds = filterSettings[1]?.list.find(
			(filter) => filter.checked
		);
		if (flagFilterIds) {
			result = `${result}&flag=${flagFilterIds.id}`;
		}
		if (deletion) {
			result = `${result}&deletion=true`;
		}
		return result;
	};

	const getSettingsData = () => {
		if (
			settings?.searchValue?.length === 0 ||
			settings?.searchValue?.length >= 3
		) {
			setLoading(true);
			api.get(makeURL()).then((res) => {
				setAccountsData(res.data.data);
				setTotal(res.data.total);
				setLoading(false);
			});
		}
	};

	useEffect(() => {
		getSettingsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings]);

	const getMark = (step) => {
		let marks = [];
		let index = STEPS[step];
		for (let i = 0; i < 15; i++) {
			marks.push(
				<td className="mark checked">
					{index > i
						? getIcon(STATUSES.CHECKED)
						: index === i
						? getIcon(step)
						: ''}
				</td>
			);
		}
		return marks;
	};

	const handleSearchInput = (value) => {
		setSettings((prev) => ({ ...prev, searchValue: value, page: 1 }));
	};

	const showLastActiveTime = (time) => {
		if (moment.utc().local() < moment.utc(time).local()) {
			return <FormattedMessage id={'rod.admin.time.just_now'} />;
		}

		if (moment.utc(time).local().fromNow() === 'Invalid date') {
			return <FormattedMessage id={'rod.admin.invalid-date'} />;
		} else return <>{moment.utc(time).local().fromNow()}</>;
	};

	const modalOpen = () => {
		setModal(!modal);
	};

	const handleCheckedFilter = (id) => {
		setSelectedStatus({});

		// Create new array to avoid direct state mutation
		const updatedFilters = filterSettings.map((group, index) => {
			if (index === 1 || index === 2) {
				return {
					...group,
					list: group.list.map((item) => ({
						...item,
						checked: item.id === id,
					})),
				};
			}
			return group;
		});

		setIsFilterEnable(
			updatedFilters[1]?.list.some((item) => item.checked) ||
				updatedFilters[2]?.list.some((item) => item.checked)
		);
		setFilterSettings(updatedFilters);
	};

	const handleSelectStatus = (value) => {
		setSelectedStatus(value);

		const updatedFilters = filterSettings.map((group) => ({
			...group,
			list: group.list.map((item) => ({
				...item,
				checked: item.code === value.code,
			})),
		}));

		setFilterSettings(updatedFilters);
	};

	const handleClearFilter = () => {
		const clearedFilters = filterSettings.map((group) => ({
			...group,
			list: group.list.map((item) => ({ ...item, checked: false })),
		}));

		setSelectedStatus({});
		setIsFilterEnable(false);
		setFilterSettings(clearedFilters);
		localStorage.removeItem('userFilterSettings');
	};

	const getReportsWithFilterFromModal = () => {
		let newSettings = { ...settings, page: 1 };

		if (Object.keys(selectedStatus).length > 0) {
			newSettings.filterStatus = [selectedStatus.code];
			setDeletion(false);
		} else {
			filterSettings[0].list.forEach((el) => (el.checked = false));
			const list = filterSettings[2]?.list;
			if (list[0]?.checked && !list[1]?.checked && !list[2]?.checked) {
				newSettings.filterStatus = [];
				setDeletion(true);
			} else if (!list[0]?.checked && list[1]?.checked && !list[2]?.checked) {
				newSettings.filterStatus = ['pending', 'pending_contracts'];
				setDeletion(false);
			} else if (!list[0]?.checked && !list[1]?.checked && list[2]?.checked) {
				newSettings.filterStatus = ['payment_moderation'];
				setDeletion(false);
			} else {
				newSettings.filterStatus = [];
			}
		}

		setSettings(newSettings);
		const isFilterApplied =
			!!Object.keys(selectedStatus).length ||
			newSettings.filterStatus.length > 0 ||
			filterSettings.some((group) => group.list.some((item) => item.checked));
		setIsFilterApplied(isFilterApplied);
		if (isFilterApplied) {
			localStorage.setItem(
				'userFilterSettings',
				JSON.stringify(filterSettings)
			);
		}
		modalOpen();
	};

	const handleRemoveUsers = () => {
		deleteUsersAdmin({ ids: selectedUsers })
			.then(() => {
				setSelectedUsers([]);
				setLoading(true);
				api
					.get(makeURL(1, settings.filterStatus, settings.searchValue))
					.then((res) => {
						setAccountsData(res.data.data);
						setTotal(res.data.total);
						setLoading(false);
					});
			})
			.catch((err) => {
				console.error('Error:', err);
			})
			.finally(() => setDisableBtn(false));
	};

	const selectAll = () => {
		const ids = accountsData?.map((user) => {
			if (user.type === 'user') {
				return { signup_id: user.signup_id, type: user.type };
			} else {
				return {
					account_id: user.account_id,
					type: user.type,
				};
			}
		});
		if (ids.length === selectedUsers.length) {
			setSelectedUsers([]);
		} else {
			setSelectedUsers(ids);
		}
	};

	const handleCheckboxChange = (item) => {
		setSelectedUsers((prevSelectedUsers) => {
			if (
				prevSelectedUsers.some((user) =>
					user.type === 'user'
						? user.signup_id === item.signup_id
						: user.account_id === item.account_id
				)
			) {
				// If the item is already selected, remove it
				return prevSelectedUsers.filter((user) =>
					user.type === 'user'
						? user.signup_id !== item.signup_id
						: user.account_id !== item.account_id
				);
			} else {
				if (item.type === 'user') {
					return [
						...prevSelectedUsers,
						{ signup_id: item.signup_id, type: item.type },
					];
				} else {
					return [
						...prevSelectedUsers,
						{ account_id: item.account_id, type: item.type },
					];
				}
			}
		});
	};

	return (
		<>
			<div className={styles.page__searchAndFilterWrapper}>
				<div className={styles.inputWrapper}>
					<Input
						className={styles.page__input}
						handleOnChange={handleSearchInput}
						initialValue={settings?.searchValue}
						placeholder={'rod.admin.search.placeholder'}
					/>
				</div>
				<img
					src={isFilterApplied ? filter_dot_svg : filter_svg}
					alt="filter"
					className={styles.filterIcon}
					onClick={modalOpen}
				/>
				{deletion && (
					<button
						className={
							disableBtn || !selectedUsers.length
								? styles.disabledSectionDeleteBtn
								: styles.sectionDeleteBtn
						}
						disabled={disableBtn || !selectedUsers.length}
						onClick={showModal(
							{
								title: (
									<FormattedMessage
										id={'rod.admin.handling.drafts.modal.delete_title'}
									/>
								),
								text: (
									<FormattedMessage
										id={'rod.admin.handling.accounts.modal.delete_text'}
									/>
								),
								onAction: () => {
									handleRemoveUsers(selectedUsers);
								},
								confirmBtnTxt: <FormattedMessage id={'rod.modal.yes'} />,
								declineBtnTxt: <FormattedMessage id={'rod.modal.no'} />,
							},
							modalTypes.FULL_MODAL
						)}
					>
						<FormattedMessage id={'rod.admin.handling.drafts_delete_button'} />
						<img
							src={
								disableBtn || !selectedUsers.length
									? remove_cart_dis
									: remove_cart
							}
							alt=""
						/>
					</button>
				)}
			</div>
			<div className={styles.page}>
				<ListTable
					loading={loading}
					accountsData={accountsData}
					url={url}
					getMark={getMark}
					showLastActiveTime={showLastActiveTime}
					deletion={deletion}
					selectedUsers={selectedUsers}
					handleCheckboxChange={handleCheckboxChange}
					checkedAll={accountsData?.length === selectedUsers?.length}
					checkedNotAll={
						selectedUsers?.length > 0 &&
						accountsData?.length !== selectedUsers?.length
					}
					selectAll={selectAll}
				/>
				<ReportsAdminModal
					active={modal}
					setActive={modalOpen}
					filterSettings={filterSettings}
					handleCheckedFilter={handleCheckedFilter}
					submitFilter={getReportsWithFilterFromModal}
					isFilterEnable={isFilterEnable}
					handleClearFilter={handleClearFilter}
					selectedStatus={selectedStatus}
					handleSelectStatus={handleSelectStatus}
					moderationRequests
				/>

				{!loading && total > 50 && settings && (
					<Pagination
						page={settings.page}
						total={total}
						paginate={(pageNumber) =>
							setSettings((prev) => ({ ...prev, page: pageNumber }))
						}
						items_on_page={50}
					/>
				)}
			</div>
		</>
	);
};

export default compose(withRoot, withAuth, withUI, withRouter)(List);
