// Core
import { ClickAwayListener, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import sort_svg from 'images/sort.svg';
import sort_dot_svg from 'images/sort_dot.svg';

// Styles
import styles from './SortMenu.module.css';

const MenuWrapper = styled(Menu)(() => ({
	'& .MuiMenu-paper': {
		width: 'fit-content',
		borderRadius: 0,
	},
	'& fieldset': {
		border: 'none',
	},
	'& .MuiMenu-list ': {
		padding: '0',
	},
}));
const MenuItemStyled = styled(MenuItem)(() => ({
	padding: '13px 20px',
	background: 'var(--color-light-grey)',
	font: 'var(--gilroy-Medium-16)',
	'&:hover': {
		background: 'var(--color-dark-grey100)',
		color: 'var(--color-white)',
	},
	'&.Mui-selected': {
		background: 'var(--color-dark-grey100)',
		color: 'var(--color-white)',
	},
	'&.Mui-selected:hover': {
		background: 'var(--color-grey)',
		color: 'var(--color-white)',
	},
	'&.Mui-selected.Mui-focusVisible': {
		background: 'var(--color-dark-grey100)',
		color: 'var(--color-white)',
	},
}));

const SortMenu = ({ sort, handleSortChange }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event) => {
		event && event?.stopPropagation();
		setAnchorEl(null);
	};

	const handleSelectItem = (event, menuValue) => {
		if (sort !== menuValue) {
			handleSortChange(menuValue);
		}
		event && event?.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div onClick={handleClick} className={styles.statistics_filter__item}>
				<img src={sort === 'desc' ? sort_svg : sort_dot_svg} alt="" />
				<span>
					<FormattedMessage id={'rod.statistic.sort'} />
				</span>
				<MenuWrapper
					id="simple-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<MenuItemStyled
						selected={sort === 'desc'}
						onClick={(e) => handleSelectItem(e, 'desc')}
					>
						<FormattedMessage id={'rod.statistic.sort-desc'} />
					</MenuItemStyled>
					<MenuItemStyled
						selected={sort === 'asc'}
						onClick={(e) => handleSelectItem(e, 'asc')}
					>
						<FormattedMessage id={'rod.statistic.sort-asc'} />
					</MenuItemStyled>
				</MenuWrapper>
			</div>
		</ClickAwayListener>
	);
};

export default SortMenu;
