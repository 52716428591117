// Core
import lscache from 'lscache';

export const getReleaseTypes = (
	personal,
	isReqLive,
	lang,
	setReleaseTypes,
	accounts,
	setCache
) => {
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseTypesCache = lscache.get('releaseTypesCache');
	const isNeedToFilter =
		isH11Edit &&
		personal.release_type_id !== 42 &&
		personal.release_type_id !== 43 &&
		personal.release_type_id !== 70;

	if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
		const filteredTypes = releaseTypesCache.data.filter(
			(item) => item.id !== 42 && item.id !== 43 && item.id !== 70
		);
		setReleaseTypes(isNeedToFilter ? filteredTypes : releaseTypesCache.data);
		return releaseTypesCache.data;
	} else {
		return accounts
			.getReleaseTypes(lang)
			.then((res) => {
				res = res.data.data;
				const filteredTypes = res.filter(
					(item) => item.id !== 42 && item.id !== 43 && item.id !== 70
				);
				setCache('releaseTypes', 'releaseTypesCache', res);
				setReleaseTypes(isNeedToFilter ? filteredTypes : res);
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				return Promise.reject();
			});
	}
};
