// Core
import { FormattedMessage } from 'react-intl';

export const checkSnippetErrors = (field, errors, releaseTypeId) => {
	switch (field) {
		case 'snippet_start':
			return (
				<FormattedMessage
					id={`rod.release_create_snipped.${errors.snippet_start[0].rule}`}
				/>
			);
		case 'snippet_end':
			if (
				errors?.snippet_end && errors?.snippet_end[0]?.rule ===
				'the segment is too long for the selected type'
			) {
				return (
					<FormattedMessage id="rod.release_create_snipped.length.error" />
				);
			}

			return (
				<FormattedMessage
					id={
						releaseTypeId === 43
							? 'rod.release_create_snipped.ringtone.end'
							: releaseTypeId === 42
							? 'rod.release_create_snipped.ringbacktone.end'
							: 'rod.release_create_snipped.tiktok.end'
					}
				/>
			);

		default:
			break;
	}
};
